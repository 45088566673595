import { FC } from 'react';

export const SvgClose: FC = () => (
	<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.37492 23.4166H15.6249C20.8333 23.4166 22.9166 21.3333 22.9166 16.1249V9.87492C22.9166 4.66659 20.8333 2.58325 15.6249 2.58325H9.37492C4.16659 2.58325 2.08325 4.66659 2.08325 9.87492V16.1249C2.08325 21.3333 4.16659 23.4166 9.37492 23.4166Z"
			stroke="#25A353"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.073 12.9999L11.0209 15.9478L16.9272 10.052"
			stroke="#25A353"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgCheck: FC = () => (
	<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.552 15.9478L15.4478 10.052"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.4478 15.9478L9.552 10.052"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.37492 23.4166H15.6249C20.8333 23.4166 22.9166 21.3333 22.9166 16.1249V9.87492C22.9166 4.66659 20.8333 2.58325 15.6249 2.58325H9.37492C4.16659 2.58325 2.08325 4.66659 2.08325 9.87492V16.1249C2.08325 21.3333 4.16659 23.4166 9.37492 23.4166Z"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgEdit: FC = () => (
	<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.20825 18.7508H9.62492C9.76201 18.7516 9.89791 18.7253 10.0248 18.6735C10.1517 18.6217 10.2672 18.5453 10.3645 18.4487L17.5728 11.23L20.5312 8.33416C20.6288 8.23733 20.7063 8.12212 20.7592 7.99518C20.8121 7.86825 20.8393 7.73209 20.8393 7.59458C20.8393 7.45707 20.8121 7.32092 20.7592 7.19398C20.7063 7.06704 20.6288 6.95183 20.5312 6.855L16.1145 2.38625C16.0177 2.28861 15.9025 2.21112 15.7755 2.15824C15.6486 2.10535 15.5124 2.07812 15.3749 2.07812C15.2374 2.07813 15.1013 2.10535 14.9743 2.15824C14.8474 2.21112 14.7322 2.28861 14.6353 2.38625L11.6978 5.33416L4.46867 12.5529C4.37213 12.6502 4.29574 12.7657 4.24391 12.8926C4.19207 13.0195 4.16579 13.1554 4.16659 13.2925V17.7092C4.16659 17.9854 4.27633 18.2504 4.47168 18.4457C4.66703 18.6411 4.93198 18.7508 5.20825 18.7508ZM15.3749 4.59458L18.3228 7.5425L16.8437 9.02166L13.8958 6.07375L15.3749 4.59458ZM6.24992 13.7196L12.427 7.5425L15.3749 10.4904L9.19783 16.6675H6.24992V13.7196ZM21.8749 20.8342H3.12492C2.84865 20.8342 2.5837 20.9439 2.38835 21.1393C2.193 21.3346 2.08325 21.5996 2.08325 21.8758C2.08325 22.1521 2.193 22.4171 2.38835 22.6124C2.5837 22.8078 2.84865 22.9175 3.12492 22.9175H21.8749C22.1512 22.9175 22.4161 22.8078 22.6115 22.6124C22.8068 22.4171 22.9166 22.1521 22.9166 21.8758C22.9166 21.5996 22.8068 21.3346 22.6115 21.1393C22.4161 20.9439 22.1512 20.8342 21.8749 20.8342Z"
			fill="#E41472"
		/>
	</svg>
);
