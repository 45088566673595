/* eslint-disable no-nested-ternary */
import { Pagination } from 'components/Pagination';
import { Sidebar } from 'components/Sidebar';
import { BodyTableHistory } from 'components/TrasactionHistoryComponents/BodyTableHistory';
import { HeaderTableHistory } from 'components/TrasactionHistoryComponents/HeaderTableHistory';
import { TabsHistory } from 'components/TrasactionHistoryComponents/TabsHistory';
import { TransactionHistoryApartmentInvest } from 'components/TrasactionHistoryComponents/TransactionHistoryApartmentInvest';
import { TransactionHistoryConvert } from 'components/TrasactionHistoryComponents/TransactionHistoryConvert';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
	getHistoryConvertsReguest,
	getInvestmentsAllReguest,
	getTransactionHistoryAllReguest,
} from 'redux/reducers/wallets/reducer';
import {
	getHistoryConverts,
	getInvestmentsAll,
	getTransactionHistotyAll,
} from 'redux/reducers/wallets/selectors';
import { IApartmentInvest, IHistoryConverts } from 'redux/reducers/wallets/types';
import { EParamsNameWallet } from 'services/constants/list-links-sidebar';
import {
	ETransactionTabsName,
	filterTransactionHistory,
} from 'services/constants/transaction-history-tabs';

const TransactionHistory: FC = () => {
	const dispatch = useDispatch();
	const params: { tab: string; subTab: string } = useParams();
	const transactionHistotyAll = useSelector(getTransactionHistotyAll);
	const historyConverts = useSelector(getHistoryConverts);
	const investmentsAll = useSelector(getInvestmentsAll);
	const { location } = useHistory();

	const historyAll =
		params.tab === ETransactionTabsName.CONVERT
			? historyConverts
			: ETransactionTabsName.APARTMENT_INVEST
			? investmentsAll
			: transactionHistotyAll;

	const submitFilter = (value: number) => {
		filterTransactionHistory.includes(params.tab) &&
			dispatch(
				getTransactionHistoryAllReguest({
					asset: params.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
					type: params.tab === ETransactionTabsName.DEPOSIT ? 1 : 2,
					current_page: value || 1,
				}),
			);
		params.tab === ETransactionTabsName.APARTMENT_INVEST &&
			dispatch(getInvestmentsAllReguest({ current_page: value || 1 }));
		params.tab === ETransactionTabsName.CONVERT &&
			dispatch(getHistoryConvertsReguest({ current_page: value || 1 }));
	};

	useEffect(() => {
		if (!params.tab) {
			dispatch(
				getTransactionHistoryAllReguest({
					asset: params.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
					type: params.tab === ETransactionTabsName.DEPOSIT ? 1 : 2,
				}),
			);
		} else {
			submitFilter(+location.search.slice(1) || 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, params.tab, params.subTab]);

	const definePage = () => {
		switch (params.tab) {
			case ETransactionTabsName.APARTMENT_INVEST:
				return (
					<TransactionHistoryApartmentInvest
						investmentsAll={investmentsAll?.data as Array<IApartmentInvest>}
					/>
				);
			case ETransactionTabsName.CONVERT:
				return (
					<TransactionHistoryConvert historyAll={historyAll?.data as Array<IHistoryConverts>} />
				);
			default:
				return (
					<div
						className={
							params.subTab === EParamsNameWallet.CRYPTO
								? 'table table--transactions-history table--second-type'
								: 'table table--transactions-history-fiat table--second-type'
						}
					>
						<HeaderTableHistory param={params.subTab} />
						<BodyTableHistory param={params.subTab} />
					</div>
				);
		}
	};

	return (
		<div className="admin">
			<Sidebar />

			<div className="admin__content">
				<div className="table-top-wrapper table-top-wrapper--center">
					<div className="table-title table-title--m0">
						<p>Transaction history</p>
					</div>
				</div>
				<TabsHistory />
				{definePage()}
				{historyAll?.last_page && historyAll?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={historyAll.current_page}
						numberPage={historyAll.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default TransactionHistory;
