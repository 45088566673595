import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IPlatformTokensState, ITokensBalanceResponse, ITokensListReducer } from './types';

const getPlatformTokensState = (state: IStoreState): IPlatformTokensState => state.platformTokens;

export const getTokenListSelector = createSelector(
	[getPlatformTokensState],
	(platformTokens: IPlatformTokensState): ITokensListReducer | null => platformTokens.tokensList,
);

export const getTokenLoaderSelector = createSelector(
	[getPlatformTokensState],
	(platformTokens: IPlatformTokensState): boolean => platformTokens.tokensListLoader,
);

export const getBalanceSelector = createSelector(
	[getPlatformTokensState],
	(platformTokens: IPlatformTokensState): ITokensBalanceResponse | null => platformTokens.balance,
);
