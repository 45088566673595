import { FC } from 'react';
import { Oval } from 'react-loader-spinner';

export const SmallLoader: FC = () => {
	return (
		<span className="louder-small-wrapper-custom">
			<Oval secondaryColor="grey" color="#E41472" height={20} width={20} />
		</span>
	);
};
