import ErrorMsg from 'components/ErrorMsg';
import { IPropsRowUsd } from 'components/WalletsComponents/types';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { limitsModifyReguest } from 'redux/reducers/wallets/reducer';
import { SvgCheck, SvgClose, SvgEdit } from 'ui/svg-image/SvgLimits';

export const UsdRow: FC<IPropsRowUsd> = ({ openEditUsd, handleEditBatton, limits }) => {
	const [usdMax, setUsdMax] = useState(String(limits.withdraw_without_approve_max));
	const [usdMin, setUsdMin] = useState(String(limits.withdraw_min));
	const [message, setMessage] = useState('');
	const dispatch = useDispatch();

	const handelInputs = (e: ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		target.name === 'max' &&
			(+target.value > 0 || target.value.length === 0) &&
			setUsdMax(target.value);
		target.name === 'min' &&
			(+target.value > 0 || target.value.length === 0) &&
			setUsdMin(target.value);
		setMessage('');
	};

	const submitLimits = (e: MouseEvent) => {
		if (usdMax && usdMin) {
			dispatch(
				limitsModifyReguest({
					id: 2,
					asset_id: 2,
					withdraw_min: +usdMin,
					withdraw_max: 999999999999999,
					withdraw_without_approve_max: +usdMax,
				}),
			);
			handleEditBatton(e);
		} else {
			setMessage(usdMax.length ? 'min' : 'max');
		}
	};

	const resetInputs = () => {
		setUsdMax('');
		setUsdMin('');
	};

	return openEditUsd ? (
		<>
			<div className="td">
				<p className="td-hidden-name">Withdraw min</p>
				<div className="td-edit-wrapper">
					<div className="input input--limitations  input--no-mb ">
						<div className="input-wrapper">
							<input
								onChange={handelInputs}
								name="min"
								required
								className="input-item input-item--td"
								type="text"
								placeholder="Set min withdrawal amount"
								value={
									usdMin[0] === '.'
										? `1${usdMin.replace(/[^0-9.]/, '')}`
										: usdMin.replace(/[^0-9.]{0,1}$/, '')
								}
							/>
							{(!usdMin && !usdMax && message) || message === 'min' ? (
								<ErrorMsg msg="Input field is required" />
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdraw max</p>
				<div className="td-edit-wrapper">
					<div className="input  input--limitations input--no-mb ">
						<div className="input-wrapper">
							<input
								onChange={handelInputs}
								name="max"
								required
								className="input-item input-item--td"
								type="text"
								placeholder="Set max withdrawal amount"
								value={
									usdMax[0] === '.'
										? `1${usdMax.replace(/[^0-9.]/, '')}`
										: usdMax.replace(/[^0-9.]{0,1}$/, '')
								}
							/>
							{(!usdMax && !usdMin && message) || message === 'max' ? (
								<ErrorMsg msg="Input field is required" />
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button name="usd" onClick={submitLimits} type="button">
						<SvgCheck />
					</button>
					<button
						name="usd"
						onClick={(e: MouseEvent) => {
							resetInputs();
							handleEditBatton(e);
						}}
						type="button"
					>
						<SvgClose />
					</button>
				</div>
			</div>
		</>
	) : (
		<>
			<div className="td">
				<p className="td-hidden-name">Withdraw min</p>
				<div className="td-edit-wrapper">
					<p className="td-value td-value--medium">{limits.withdraw_min}</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdraw max</p>
				<div className="td-edit-wrapper">
					<p className="td-value td-value--medium">{limits.withdraw_without_approve_max}</p>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button name="usd" onClick={handleEditBatton} type="button">
						<SvgEdit />
					</button>
				</div>
			</div>
		</>
	);
};
