import { SwiperSellPhoto } from 'components/SwiperSellPhoto';
import { FC, useState } from 'react';
import { IPropsAllPhotos } from './types';

const AllImagesShow: FC<IPropsAllPhotos> = ({ images, setOpenAllPhoto }) => {
	const [numPhoto, setNumPhote] = useState<string | null>(null);

	const toChoosePhoto = (value: string) => {
		setNumPhote(value);
	};

	return (
		<>
			<header className="header-all-image">
				<div className="container">
					<div className="header">
						<div className="header-left">
							<button
								onClick={() => setOpenAllPhoto(false)}
								className="button button--type2"
								type="button"
							>
								<span className="btn-icon">
									<svg
										className="fill"
										width="14"
										height="15"
										viewBox="0 0 14 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.25 7.49609C0.25 7.08188 0.585786 6.74609 1 6.74609H13C13.4142 6.74609 13.75 7.08188 13.75 7.49609C13.75 7.91031 13.4142 8.24609 13 8.24609H1C0.585786 8.24609 0.25 7.91031 0.25 7.49609Z"
											fill="#25252E"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.53033 0.96967C7.82322 1.26256 7.82322 1.73744 7.53033 2.03033L2.06066 7.5L7.53033 12.9697C7.82322 13.2626 7.82322 13.7374 7.53033 14.0303C7.23744 14.3232 6.76256 14.3232 6.46967 14.0303L0.46967 8.03033C0.176777 7.73744 0.176777 7.26256 0.46967 6.96967L6.46967 0.96967C6.76256 0.676777 7.23744 0.676777 7.53033 0.96967Z"
											fill="#1C1C29"
										/>
									</svg>
								</span>
								Back
							</button>
						</div>
					</div>
				</div>
			</header>
			<div className="admin">
				{numPhoto && (
					<SwiperSellPhoto images={images} setOpenAllPhoto={setOpenAllPhoto} numPhoto={numPhoto} />
				)}
				<div className="gallery-container">
					<div className="realty-photos-gallery realty-photos-gallery--type2 realty-photos-gallery--always-display">
						<div className="realty-photo-block realty-photo-block--two-third">
							<button
								onClick={() => toChoosePhoto(images[0].url)}
								type="button"
								className="realty-photo realty-photo--height-type3"
							>
								<img src={images[0].url} alt="" />
							</button>
						</div>
						{images.length && (
							<div className="realty-photo-block">
								<button
									onClick={() => toChoosePhoto(images[1].url)}
									type="button"
									className="realty-photo realty-photo--height-type4 "
								>
									<img src={images[1].url} alt="" />
								</button>
								<button
									onClick={() => toChoosePhoto(images[2].url)}
									type="button"
									className="realty-photo realty-photo--height-type4"
								>
									<img src={images[2].url} alt="" />
								</button>
							</div>
						)}
						{images.length >= 7 &&
							images
								.slice(3, images.length % 3 === 0 ? images.length - 3 : images.length)
								.map((image) => (
									<div key={image.url} className="realty-photo-block  ">
										<button
											onClick={() => toChoosePhoto(image.url)}
											type="button"
											className="realty-photo realty-photo--height-type4"
										>
											<img src={image.url} alt="" />
										</button>
									</div>
								))}
						{images.length % 3 === 0 && (
							<>
								<div className="realty-photo-block  ">
									<button
										onClick={() => toChoosePhoto(images[images.length - 3].url)}
										type="button"
										className="realty-photo realty-photo--height-type4 "
									>
										<img src={images[images.length - 3].url} alt="" />
									</button>
									<button
										onClick={() => toChoosePhoto(images[images.length - 2].url)}
										type="button"
										className="realty-photo realty-photo--height-type4"
									>
										<img src={images[images.length - 2].url} alt="" />
									</button>
								</div>

								<div className="realty-photo-block realty-photo-block--two-third">
									<button
										onClick={() => toChoosePhoto(images[images.length - 1].url)}
										type="button"
										className="realty-photo realty-photo--height-type3"
									>
										<img src={images[images.length - 1].url} alt="" />
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default AllImagesShow;
