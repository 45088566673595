import ErrorMsg from 'components/ErrorMsg';
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { postDistributeRequest } from 'redux/reducers/dividends-management/reducer';
import { IPropsDividendsTables } from '../types';
import { TdStatus } from './TdStatus';

export const DividendsTableBody: FC<IPropsDividendsTables> = ({ dividends }) => {
	const [amount, setAmount] = useState<string>('');
	const [dividendsId, setDividendsId] = useState<number>();
	const [showError, setShowError] = useState<boolean>(false);
	const dispatch = useDispatch();

	const handleInput = (event: ChangeEvent, id: number) => {
		const target = event.target as HTMLInputElement;
		setAmount(target.value);
		+target.value.replace(/\s/, '0') && setDividendsId(id);
		setShowError(false);
	};

	const sendAmount = (estate_id: number) => {
		if (amount.trim().length) {
			dispatch(
				postDistributeRequest({
					amount: +amount,
					estate_id,
				}),
			);
			setAmount('');
			setDividendsId(0);
		} else {
			setShowError(true);
		}
	};

	return (
		<div className="table-body">
			{dividends?.map((dividend) => (
				<div key={dividend.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Apartment Name</p>
						<p className="td-value td-value--medium td-value-custom-up">{dividend?.title}</p>
					</div>
					<TdStatus dividend={dividend} />
					<div className="td">
						<p className="td-hidden-name"> Real estate investors</p>
						<p className="td-value td-value--medium">{dividend?.investments_count}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Distribute</p>
						<div className="td-edit-wrapper td-edit-wrapper--distribute td-edit-wrapper--gap16">
							<div className="input input--no-mb input--small">
								<div className="input-wrapper">
									<input
										onChange={(e: ChangeEvent) => handleInput(e, dividend.id)}
										onFocus={() =>
											setTimeout(() => {
												dividend.id !== dividendsId && setAmount('');
												dividend.id !== dividendsId && setDividendsId(0);
											}, 200)
										}
										className="input-item"
										type="text"
										placeholder="Amount"
										value={
											Number(dividendsId) === dividend.id
												? String(amount).replace(/[^0-9]/, '')
												: ''
										}
									/>
								</div>
								{showError && Number(dividendsId) === dividend.id ? (
									<ErrorMsg msg="Field is required" />
								) : null}
							</div>
							<button
								disabled={Number(dividendsId) !== dividend.id}
								onClick={() => sendAmount(dividend.id)}
								className="button button--w125 button--big-height"
								type="button"
							>
								Distribute
							</button>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
