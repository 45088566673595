/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { ICurrentPage } from 'redux/reducers/dashboard/types';
import { IDashboardApi } from './types';

export const dashboardApi: IDashboardApi = {
	getBalances: () => http.get(endpoint.dashboard.BALANCES),
	getDashboardSubscriptions: (params: ICurrentPage) =>
		http.get(endpoint.dashboard.DASHBOARD, { params }),
};
