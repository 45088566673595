/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import { IPropsInvestments } from 'components/UserAcountComponents/types';
import useOnClickOutside from 'hooks/useOnClickOutside';
import moment from 'moment';
import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getZipFileRequest } from 'redux/reducers/usersManagement/reducer';
import { notificationContainer } from 'untils/notificationContainer';

export const InvestmentsTable: FC<IPropsInvestments> = ({ investments }) => {
	const [isOpenDrop, setIsOpenDrop] = useState<boolean>(false);
	const [id, setId] = useState<number | null>(null);
	const [index, setIndex] = useState<number>(0);
	const innerBorderRef = useRef([]);
	const dispatch = useDispatch();

	useOnClickOutside(innerBorderRef, () => setIsOpenDrop(false), index);

	const download = (value: number, number: number) => {
		number
			? dispatch(getZipFileRequest(value))
			: notificationContainer('There are no documents to download', 'info');
	};

	return (
		<div className="table-body">
			{investments.map((investment, i) => (
				<div key={investment.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Apartment Name</p>
						<p className="td-value td-value--medium">{investment.estate.title}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Date of invest</p>
						<p className="td-value td-value--medium">
							{moment(investment.created_at).format('DD.MM.YYYY h:mm:ss')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Invest Amount </p>
						<p className="td-value td-value--medium">
							{`${String(investment.amount_usd_invested)} USD`}
						</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Amount of token</p>
						<p className="td-value td-value--medium">{String(investment.amount_tokens || 0)}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Token status </p>
						<p
							className={`td-value td-value--medium ${
								!investment.is_locked ? 'color-invest-status-green' : 'color-invest-status'
							}`}
						>
							{investment.is_locked === 1 ? 'block' : 'unblock'}
						</p>
					</div>
					<div
						ref={(element: never) => {
							innerBorderRef.current.push(element);
						}}
						className="td td--right td--custom"
					>
						<p className="td-hidden-name">Legal documents</p>
						<div className="select select--table-select select--custom">
							<button
								onClick={() => {
									setIndex(i);
									setIsOpenDrop(!isOpenDrop);
									setId(id === investment.id ? null : investment.id);
								}}
								type="button"
								className="select__current td-value-first-letter-up"
							>
								<svg
									width="16"
									height="20"
									viewBox="0 0 16 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16 6.94C15.9896 6.84813 15.9695 6.75763 15.94 6.67V6.58C15.8919 6.47718 15.8278 6.38267 15.75 6.3L9.75 0.3C9.66734 0.222216 9.57282 0.158081 9.47 0.11H9.38L9.06 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V7C16 7 16 7 16 6.94ZM10 3.41L12.59 6H10V3.41ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H14V17Z"
										fill="#25252E"
									/>
								</svg>
							</button>
							<button
								onClick={() => download(investment.id, investment.user_documents.length)}
								type="button"
								className="button-all-loader active"
							>
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.29 11.79L11 12.09V9.50002C11 9.23481 10.8946 8.98045 10.7071 8.79292C10.5196 8.60538 10.2652 8.50002 10 8.50002C9.73478 8.50002 9.48043 8.60538 9.29289 8.79292C9.10536 8.98045 9 9.23481 9 9.50002V12.09L8.71 11.79C8.5217 11.6017 8.2663 11.4959 8 11.4959C7.7337 11.4959 7.4783 11.6017 7.29 11.79C7.1017 11.9783 6.99591 12.2337 6.99591 12.5C6.99591 12.7663 7.1017 13.0217 7.29 13.21L9.29 15.21C9.3851 15.3011 9.49725 15.3724 9.62 15.42C9.7397 15.4729 9.86913 15.5003 10 15.5003C10.1309 15.5003 10.2603 15.4729 10.38 15.42C10.5028 15.3724 10.6149 15.3011 10.71 15.21L12.71 13.21C12.8983 13.0217 13.0041 12.7663 13.0041 12.5C13.0041 12.2337 12.8983 11.9783 12.71 11.79C12.5217 11.6017 12.2663 11.4959 12 11.4959C11.7337 11.4959 11.4783 11.6017 11.29 11.79ZM17 3.50002H10.72L10.4 2.50002C10.1926 1.91325 9.80775 1.40553 9.2989 1.04718C8.79005 0.688841 8.18236 0.497623 7.56 0.500022H3C2.20435 0.500022 1.44129 0.816093 0.87868 1.3787C0.316071 1.94131 0 2.70437 0 3.50002V16.5C0 17.2957 0.316071 18.0587 0.87868 18.6213C1.44129 19.184 2.20435 19.5 3 19.5H17C17.7956 19.5 18.5587 19.184 19.1213 18.6213C19.6839 18.0587 20 17.2957 20 16.5V6.50002C20 5.70437 19.6839 4.94131 19.1213 4.3787C18.5587 3.81609 17.7956 3.50002 17 3.50002ZM18 16.5C18 16.7652 17.8946 17.0196 17.7071 17.2071C17.5196 17.3947 17.2652 17.5 17 17.5H3C2.73478 17.5 2.48043 17.3947 2.29289 17.2071C2.10536 17.0196 2 16.7652 2 16.5V3.50002C2 3.23481 2.10536 2.98045 2.29289 2.79292C2.48043 2.60538 2.73478 2.50002 3 2.50002H7.56C7.76964 2.49948 7.97416 2.56484 8.14463 2.68686C8.3151 2.80889 8.44291 2.9814 8.51 3.18002L9.05 4.82002C9.11709 5.01864 9.2449 5.19116 9.41537 5.31318C9.58584 5.4352 9.79036 5.50056 10 5.50002H17C17.2652 5.50002 17.5196 5.60538 17.7071 5.79292C17.8946 5.98045 18 6.23481 18 6.50002V16.5Z"
										fill="#E41472"
									/>
								</svg>
							</button>
						</div>
						<div
							className={classNames('select__drop select__drop--custom', {
								'select__drop--active': investment.id === id,
							})}
						>
							<div className="select__drop-scroll">
								<div className="select__drop-item">
									<ul>
										{investment.user_documents?.length ? (
											investment.user_documents.map((doc) => (
												<li className="ul-custom" key={doc.id}>
													<button
														onClick={() => {
															window.open(doc.url);
															setId(null);
														}}
														type="button"
													>
														<span>{doc.title}</span>
														<svg
															width="18"
															height="18"
															viewBox="0 0 38 38"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M35.4998 22.666C35.0136 22.666 34.5473 22.8592 34.2035 23.203C33.8597 23.5468 33.6665 24.0131 33.6665 24.4993V31.8327C33.6665 32.3189 33.4733 32.7852 33.1295 33.129C32.7857 33.4729 32.3194 33.666 31.8332 33.666H6.1665C5.68027 33.666 5.21396 33.4729 4.87014 33.129C4.52632 32.7852 4.33317 32.3189 4.33317 31.8327V24.4993C4.33317 24.0131 4.14002 23.5468 3.7962 23.203C3.45238 22.8592 2.98607 22.666 2.49984 22.666C2.01361 22.666 1.54729 22.8592 1.20347 23.203C0.859658 23.5468 0.666504 24.0131 0.666504 24.4993V31.8327C0.666504 33.2914 1.24597 34.6903 2.27742 35.7218C3.30887 36.7532 4.70781 37.3327 6.1665 37.3327H31.8332C33.2919 37.3327 34.6908 36.7532 35.7223 35.7218C36.7537 34.6903 37.3332 33.2914 37.3332 31.8327V24.4993C37.3332 24.0131 37.14 23.5468 36.7962 23.203C36.4524 22.8592 35.9861 22.666 35.4998 22.666ZM17.6982 25.801C17.8725 25.9679 18.0781 26.0988 18.3032 26.186C18.5226 26.283 18.7599 26.3331 18.9998 26.3331C19.2398 26.3331 19.4771 26.283 19.6965 26.186C19.9215 26.0988 20.1271 25.9679 20.3015 25.801L27.6348 18.4677C27.9801 18.1225 28.174 17.6542 28.174 17.166C28.174 16.6778 27.9801 16.2096 27.6348 15.8643C27.2896 15.5191 26.8214 15.3252 26.3332 15.3252C25.8449 15.3252 25.3767 15.5191 25.0315 15.8643L20.8332 20.081V2.49935C20.8332 2.01312 20.64 1.5468 20.2962 1.20299C19.9524 0.85917 19.4861 0.666016 18.9998 0.666016C18.5136 0.666016 18.0473 0.85917 17.7035 1.20299C17.3597 1.5468 17.1665 2.01312 17.1665 2.49935V20.081L12.9682 15.8643C12.7972 15.6934 12.5943 15.5578 12.371 15.4653C12.1476 15.3728 11.9082 15.3252 11.6665 15.3252C11.4248 15.3252 11.1854 15.3728 10.962 15.4653C10.7387 15.5578 10.5358 15.6934 10.3648 15.8643C10.1939 16.0353 10.0583 16.2382 9.96579 16.4616C9.87328 16.6849 9.82567 16.9243 9.82567 17.166C9.82567 17.4078 9.87328 17.6471 9.96579 17.8705C10.0583 18.0938 10.1939 18.2967 10.3648 18.4677L17.6982 25.801Z"
																fill="#E41472"
															/>
														</svg>
													</button>
												</li>
											))
										) : (
											<li className="ul-custom">
												<button onClick={() => setId(null)} type="button">
													Documents missing
												</button>
											</li>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
