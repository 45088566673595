import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { resetEmailRequest } from 'redux/reducers/settings/reducer';
import queryString from 'query-string';
import { authInitState } from 'redux/reducers/auth/reducer';

const EmailChangeSuccess: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const queryPar = queryString.parse(location.search);

	useEffect(() => {
		dispatch(resetEmailRequest({ code: String(queryPar.code) }));
	}, [dispatch, queryPar]);

	const goLogin = () => {
		dispatch(authInitState());
		history.push('/');
	};

	return (
		<section className="email-change">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="email-change__img"
			>
				<path
					d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
					fill="#31BDAA"
				/>
				<path
					d="M7.75 12L10.58 14.83L16.25 9.17"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			<img className="email-change__img" src="" alt="" />
			<h3 className="email-change__title">Email change success</h3>
			<p className="email-change__subtitle">Login with your new email</p>
			<button onClick={goLogin} className="email-change__button" type="button">
				Login
			</button>
		</section>
	);
};

export default EmailChangeSuccess;
