import React, { FC } from 'react';
import { IApartmentInvest } from 'redux/reducers/wallets/types';

interface IPropsInvest {
	investmentsAll: Array<IApartmentInvest>;
}

export const TransactionHistoryApartmentInvest: FC<IPropsInvest> = ({ investmentsAll }) => {
	return (
		<div className="admin__content">
			<div className="table table--transactions-history-apartment table--second-type">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p className="td-name">Apartment ID </p>
						</div>
						<div className="td">
							<p className="td-name">Apartment Name</p>
						</div>
						<div className="td">
							<p className="td-name">User ID</p>
						</div>
						<div className="td td--center">
							<p className="td-name">Amount of token</p>
						</div>
						<div className="td">
							<p className="td-name">Paid</p>
						</div>
						<div className="td td--center">
							<p className="td-name">Fee</p>
						</div>
						<div className="td td--right">
							<p className="td-name">Status</p>
						</div>
					</div>
				</div>
				<div className="table-body">
					{investmentsAll?.map((investment) => (
						<div key={investment.id} className="tr">
							<div className="td">
								<p className="td-hidden-name">Apartment ID </p>
								<p className="td-value td-value--medium">{investment?.id}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">Apartment Name</p>
								<p className="td-value td-value--medium">{investment?.estate.title}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">User ID</p>
								<p className="td-value td-value--medium red">{investment?.user_id}</p>
							</div>
							<div className="td td--center">
								<p className="td-hidden-name">Amount of token</p>
								<p className="td-value td-value--medium">{investment?.amount_tokens}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">Paid</p>
								<p className="td-value td-value--medium">{`${investment?.amount_usd_invested} USD`}</p>
							</div>
							<div className="td td--center">
								<p className="td-hidden-name">Fee</p>
								<p className="td-value td-value--medium">{investment?.amount_fee}</p>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Status</p>
								<p
									className={`td-value td-value-first-letter-up td-value--medium ${
										investment?.status ? 'red' : 'green'
									}`}
								>
									{investment?.status ? 'Outdated' : 'Active'}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
