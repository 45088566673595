/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from 'untils/notificationContainer';
import { IBalancesResponse, ICurrentPage, ISubscriptionsResponse } from './types';
import {
	dashboardInitState,
	getBalancesRequest,
	getBalancesSuccess,
	getDashboardSubscriptionsRequest,
	getDashboardSubscriptionsSuccess,
} from './reducer';

function* getBalancesWorker() {
	try {
		yield put(showLoading());
		const response: { data: IBalancesResponse } = yield call(api.dashboardApi.getBalances);
		yield put(getBalancesSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(dashboardInitState());
	}
}

function* getDashboardSubscriptionsWorker(action: PayloadAction<ICurrentPage>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: ISubscriptionsResponse } = yield call(
			api.dashboardApi.getDashboardSubscriptions,
			payload,
		);
		yield put(getDashboardSubscriptionsSuccess(response.data.subscriptions));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(dashboardInitState());
	}
}

export function* dashboardSaga() {
	yield takeEvery(getBalancesRequest.type, getBalancesWorker);
	yield takeEvery(getDashboardSubscriptionsRequest.type, getDashboardSubscriptionsWorker);
}
