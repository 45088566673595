import React, { FC } from 'react';
import { EParamsNameWallet } from 'services/constants/list-links-sidebar';

export const HeaderTableHistory: FC<{ param: string }> = ({ param }) => {
	return param === EParamsNameWallet.CRYPTO ? (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">ID</p>
				</div>
				<div className="td">
					<p className="td-name">User ID</p>
				</div>
				<div className="td">
					<p className="td-name">Time and date</p>
				</div>
				<div className="td">
					<p className="td-name">Asset</p>
				</div>
				<div className="td td--center">
					<p className="td-name">Network</p>
				</div>
				<div className="td td--center">
					<p className="td-name">Fee</p>
				</div>
				<div className="td td--center">
					<p className="td-name">Amount</p>
				</div>
				<div className="td">
					<p className="td-name">Destination</p>
				</div>
				<div className="td">
					<p className="td-name">Status</p>
				</div>
				<div className="td">
					<p className="td-name">TxID</p>
				</div>
			</div>
		</div>
	) : (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">ID</p>
				</div>
				<div className="td">
					<p className="td-name">Time and date</p>
				</div>
				<div className="td">
					<p className="td-name">Currency</p>
				</div>
				<div className="td">
					<p className="td-name">Payment method</p>
				</div>
				<div className="td">
					<p className="td-name">Indicated amount</p>
				</div>
				<div className="td td--center">
					<p className="td-name">Fee</p>
				</div>
				<div className="td td--center">
					<p className="td-name">Amount</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Status</p>
				</div>
			</div>
		</div>
	);
};
