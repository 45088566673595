import { FC, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useSelector } from 'react-redux';
import { getAuth } from 'redux/reducers/auth/selectors';
import { SvgShowPass } from 'ui/svg-image/SvgLogin';
import ErrorMsg from 'components/ErrorMsg';
import { SvgTimer } from 'ui/svg-image/SvgTimer';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { getOpen2Fa } from 'redux/reducers/settings/selectors';
import { Timer } from 'components/Timer';
import { password, email } from '../../../untils/formValidation';
import { ILoginForm, ILoginSubmitValue } from './types';

export const LoginForm: FC<ILoginForm> = ({ loginSubmit, sms2Fa, resetTimer }) => {
	const loginData = useSelector(getAuth);
	const [isShowPass, setIsShowPass] = useState(false);
	const open2Fa = useSelector(getOpen2Fa);
	const [seconds, setSeconts] = useState<number>(90);

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const initialValues = {
		email: loginData.loginEmail || '',
		password: loginData.loginPassword || '',
		totp: '',
	};

	const validationSchema = yup.object().shape({
		email,
		password,
	});

	return (
		<div className="authorization-form__body">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values: ILoginSubmitValue, { setSubmitting }) => {
					loginSubmit(values);
					setSubmitting(false);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, values, errors }) => {
					return (
						<Form>
							<div className="form__body">
								<div className="form-group">
									<div className="input">
										<div className="input-wrapper">
											<Field
												className="input-item"
												type="email"
												name="email"
												required
												placeholder="Email address"
											/>
										</div>
										{errors?.email ? <ErrorMsg msg={errors.email} /> : null}
									</div>
									<div className="input input--no-mb ">
										<div className="input-wrapper">
											<Field
												className="input-item input-item--right-icon"
												title="Password"
												type={isShowPass ? 'text' : 'password'}
												name="password"
												required
												placeholder="Password"
											/>
											<button onClick={handlePassDisplay} type="button" className="show-pass">
												<SvgShowPass />
											</button>
										</div>
										{errors?.password ? <ErrorMsg msg={errors.password} /> : null}
									</div>
									<div className="save-password">
										<Link to={ELinksName.RESET_PASSWORD} className="link link--big link--fw-600">
											Reset password
										</Link>
									</div>
								</div>
								{open2Fa && (
									<>
										<div className="form-group">
											<p className="block-small-title block-small-title--ta-center ">
												2FA-Authorization
											</p>
											<p className="form-subtitle">Enter 2fa code</p>
										</div>
										<div className="form-group">
											<div className="input">
												<div className="input-wrapper">
													<Field
														className="input-item input-item--pr-125 "
														type="text"
														name="totp"
														placeholder="Enter 2fa code"
														disabled={!seconds}
													/>
													{sms2Fa && (
														<>
															<span className="input-icon-text">
																{seconds && sms2Fa === 'sms_2fa_enabled' ? (
																	<Timer seconds={seconds} setSeconts={setSeconts} />
																) : null}
															</span>

															<div className="input-icon input-icon--right input-icon--color">
																<SvgTimer />
															</div>
														</>
													)}
												</div>
											</div>
										</div>
									</>
								)}
							</div>
							<div className="form-footer">
								<button
									onClick={() => resetTimer(setSeconts)}
									disabled={!(isValid && values.email && values.password) || isSubmitting}
									type="submit"
									className="button button--long"
								>
									Log In
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
