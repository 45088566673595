import { spawn } from 'redux-saga/effects';
import { authSaga } from 'redux/reducers/auth/saga';
import { settingsSaga } from 'redux/reducers/settings/saga';
import { walletsSaga } from 'redux/reducers/wallets/saga';
import { propertyManagementSaga } from 'redux/reducers/propertyManagement/saga';
import { sellManagementSaga } from 'redux/reducers/sellManagement/saga';
import { usersManagementSaga } from 'redux/reducers/usersManagement/saga';
import { platformTokensSaga } from 'redux/reducers/platform-tokens/saga';
import { dividendsManagementSaga } from 'redux/reducers/dividends-management/saga';
import { feeManagementSaga } from 'redux/reducers/fee-management/saga';
import { dashboardSaga } from 'redux/reducers/dashboard/saga';
import { interestRequestsSaga } from 'redux/reducers/interestRequests/saga';

export default function* rootSagas() {
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(sellManagementSaga);
	yield spawn(walletsSaga);
	yield spawn(propertyManagementSaga);
	yield spawn(usersManagementSaga);
	yield spawn(platformTokensSaga);
	yield spawn(dividendsManagementSaga);
	yield spawn(feeManagementSaga);
	yield spawn(dashboardSaga);
	yield spawn(interestRequestsSaga);
}
