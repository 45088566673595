import { ChangeEvent, FC, useState } from 'react';
import ErrorMsg from 'components/ErrorMsg';
import { Field, Form, Formik } from 'formik';
import yup from 'untils/capsLockValidate';
import { ICreatePasswordFormData } from 'redux/reducers/settings/types';
import { SvgShowPass } from 'ui/svg-image/SvgLogin';
import { password, new_password, old_password } from 'untils/formValidation';
import { useDispatch, useSelector } from 'react-redux';
import { checkPassRequest } from 'redux/reducers/settings/reducer';
import { getCheckPass } from 'redux/reducers/settings/selectors';
import { IPropsChangePassForm } from './types';

export const ChangePassForm: FC<IPropsChangePassForm> = ({ changePassFormSubmit }) => {
	const [isShowPassOld, setIsShowPassOld] = useState(false);
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowPassConfirm, setIsShowPassConfirm] = useState(false);
	let amountTimeoutId: ReturnType<typeof setTimeout>;
	const checkPass = useSelector(getCheckPass);
	const dispatch = useDispatch();

	const initialValues = {
		new_password: '',
		password: '',
		old_password: '',
	};

	const validationSchema = yup.object().shape({
		new_password,
		password,
		old_password,
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ICreatePasswordFormData, { setSubmitting, resetForm }) => {
				changePassFormSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors, values, setFieldValue }) => {
				const heandleInput = (e: ChangeEvent) => {
					const terget = e.target as HTMLInputElement;
					setFieldValue('old_password', terget.value);

					clearTimeout(amountTimeoutId);

					amountTimeoutId = setTimeout(() => {
						terget.value.length >= 8 &&
							dispatch(
								checkPassRequest({
									password: terget.value,
								}),
							);
					}, 500);
				};

				return (
					<Form className="admin-profile__right">
						<div className="input">
							<p className="input-name">Old password</p>
							<div className="input-wrapper">
								<input
									onChange={heandleInput}
									type={isShowPassOld ? 'text' : 'password'}
									className="input-item input-item--right-icon"
									name="old_password"
									placeholder="Old password"
									value={values.old_password}
								/>
								<button
									onClick={() => setIsShowPassOld(!isShowPassOld)}
									type="button"
									className="show-pass"
								>
									<SvgShowPass />
								</button>
							</div>
							{errors.old_password ? <ErrorMsg msg={errors.old_password} /> : null}
							{!errors.old_password &&
							!checkPass?.success &&
							checkPass?.success !== null &&
							checkPass?.success !== undefined ? (
								<ErrorMsg msg="Incorrect old password!" />
							) : null}
						</div>
						<div className="input input--mb24">
							<p className="input-name">Confirm password</p>
							<div className="input-wrapper">
								<Field
									className="input-item input-item--right-icon"
									type={isShowPassConfirm ? 'text' : 'password'}
									name="password"
									placeholder="Confirm password"
									disabled={
										(!checkPass?.success && !!values.old_password.length) ||
										!(checkPass?.success && values.old_password.length > 7)
									}
								/>
								<button
									onClick={() => setIsShowPassConfirm(!isShowPassConfirm)}
									type="button"
									className="show-pass"
								>
									<SvgShowPass />
								</button>
							</div>
							{errors.password && checkPass?.success && !errors.old_password ? (
								<ErrorMsg msg={errors.password} />
							) : null}
						</div>
						<div className="input input--mb24">
							<p className="input-name">New password</p>
							<div className="input-wrapper">
								<Field
									className="input-item input-item--right-icon"
									type={isShowPass ? 'text' : 'password'}
									name="new_password"
									placeholder="New password"
									disabled={
										(!checkPass?.success && !!values.old_password.length) ||
										!(checkPass?.success && values.old_password.length > 7)
									}
								/>
								<button
									onClick={() => setIsShowPass(!isShowPass)}
									type="button"
									className="show-pass"
								>
									<SvgShowPass />
								</button>
							</div>
							{errors.new_password && checkPass?.success && !errors.old_password ? (
								<ErrorMsg msg={errors.new_password} />
							) : null}
						</div>
						<div className="form-group form-group--more-mt">
							<button
								className="button button--long"
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
							>
								Save
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
