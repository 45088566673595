import classNames from 'classnames';
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { PendingWithdrawalsBody } from 'components/PendingWithdrawalsComponents/PendingWithdrawalsBody';
import { PendingWithdrawalsHeader } from 'components/PendingWithdrawalsComponents/PendingWithdrawalsHeader';
import PendingWithdrawalManualHeader from 'components/PendingWithdrawalsComponents/PendingWithdrawalsManualHeader';
import { Sidebar } from 'components/Sidebar';
import { DashboardLimitation } from 'components/WalletsComponents/DashboardLimitation';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getTransactionHistoryAdminReguest } from 'redux/reducers/wallets/reducer';
import {
	getTransactionHistotyAdmin,
	getTransactionHistotyAdminLoader,
} from 'redux/reducers/wallets/selectors';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';

const PendingWithdrawals: FC = () => {
	const params: { tab: string; subTab: string } = useParams();
	const transactionHistory = useSelector(getTransactionHistotyAdmin);
	const transactionLoader = useSelector(getTransactionHistotyAdminLoader);
	const dispatch = useDispatch();

	const submitFilter = (value: number) => {
		dispatch(
			getTransactionHistoryAdminReguest({
				status: 'waiting_admin',
				asset: params.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
				type: params.tab === ETransactionTabsName.DEPOSIT ? 1 : 2,
				current_page: value,
			}),
		);
	};

	useEffect(() => {
		if (!params.tab) {
			dispatch(
				getTransactionHistoryAdminReguest({
					status: 'waiting_admin',
					asset: params.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
					type: params.tab === ETransactionTabsName.DEPOSIT ? 1 : 2,
				}),
			);
		} else {
			submitFilter(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, params.tab, params.subTab]);

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<DashboardLimitation />
				<div className="table-top-wrapper table-top-wrapper--center ">
					<div className="table-title table-title--m0">
						<p>Pending Withdrawals</p>
					</div>
				</div>
				<div className="table-top-wrapper table-top-wrapper--center table-top-wrapper--type2 ">
					<div className="switcher switcher--smallest">
						<Link
							to={`${ELinksName.WALLETS_PENDING_WITHDRAWALS}/${EParamsNameWallet.WITHDRAW}/${EParamsNameWallet.CRYPTO}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: params.subTab === EParamsNameWallet.CRYPTO,
							})}
							type="button"
						>
							Crypto
						</Link>
						<Link
							to={`${ELinksName.WALLETS_PENDING_WITHDRAWALS}/${EParamsNameWallet.WITHDRAW}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: params.subTab === EParamsNameWallet.FIAT || !params.subTab,
							})}
							type="button"
						>
							Fiat
						</Link>
					</div>
					<div className="switcher switcher--smaller">
						<Link
							to={`${ELinksName.WALLETS_LIMITATION}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: params.tab === EParamsNameWallet.LIMITATION,
							})}
							type="button"
						>
							Limitations
						</Link>
						<Link
							to={`${ELinksName.WALLETS_PENDING_WITHDRAWALS}/${EParamsNameWallet.WITHDRAW}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: params.tab === EParamsNameWallet.WITHDRAW || !params.tab,
							})}
							type="button"
						>
							Withdraw
						</Link>
						<Link
							to={`${ELinksName.WALLETS_PENDING_WITHDRAWALS}/${EParamsNameWallet.MANUAL_WITHDRAW}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: params.tab === EParamsNameWallet.MANUAL_WITHDRAW,
							})}
							type="button"
						>
							Manual Withdraw
						</Link>
					</div>
				</div>
				{transactionLoader ? (
					<Loader />
				) : (
					<>
						<div
							className={`table table--${
								params.tab === EParamsNameWallet.MANUAL_WITHDRAW
									? 'pending-withdraw-crypto-manual'
									: 'pending-withdraw-crypto'
							}  table--second-type`}
						>
							{params.tab === EParamsNameWallet.MANUAL_WITHDRAW ? (
								<PendingWithdrawalManualHeader />
							) : (
								<PendingWithdrawalsHeader />
							)}
							<PendingWithdrawalsBody
								transactionsHistory={transactionHistory?.data}
								manual={params.tab === EParamsNameWallet.MANUAL_WITHDRAW}
							/>
						</div>
						{transactionHistory?.last_page && transactionHistory?.last_page > 1 && (
							<Pagination
								request={submitFilter}
								currentPage={transactionHistory.current_page}
								numberPage={transactionHistory.last_page}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PendingWithdrawals;
