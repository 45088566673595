import { FC, MouseEvent, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	ELinksName,
	EParamsNameWallet,
	listLinksSidebar,
} from 'services/constants/list-links-sidebar';
import { SvgDashboard } from 'ui/svg-image/SvgSidebar';
import classNames from 'classnames';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';

export const Sidebar: FC = () => {
	const { pathname } = useLocation();
	// const param: { tab: string } = useParams();
	const [openDropDown, setOpenDropDown] = useState(false);
	const [openWalletDrop, setOpenWalletDrop] = useState(false);

	const handleButton = (e: MouseEvent, link?: string) => {
		if (ELinksName.WALLETS === link) {
			e.preventDefault();
			setOpenWalletDrop(!openWalletDrop);
		} else {
			setOpenDropDown(!openDropDown);
			setOpenWalletDrop(false);
		}
	};

	return (
		<div className="admin__bar">
			<div className="select select--sidebar-nav">
				<button
					onClick={(e: MouseEvent) => handleButton(e)}
					type="button"
					className="select__current"
				>
					<span className="select__small-nav">
						{pathname === '/'
							? 'Dashboard'
							: pathname
									.slice(1, 1 + +pathname.slice(1).indexOf('/') || pathname.length)
									.replaceAll('-', ' ')}
					</span>
					<span className="select__current-arrow">
						<svg
							width="14"
							height="8"
							viewBox="0 0 14 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
								fill="#25252E"
							/>
						</svg>
					</span>
				</button>
				<div
					className={classNames('select__drop', {
						'select__drop--active': openDropDown,
					})}
				>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								<li
									className={classNames({
										active: pathname === ELinksName.HOME,
									})}
								>
									<Link to="/">
										<span className="sidebar-nav-icon">
											<SvgDashboard />
										</span>
										Dashboard
									</Link>
								</li>
								{listLinksSidebar.map((link) => (
									<li
										key={link.id}
										className={classNames({
											active:
												pathname.includes(link.link) ||
												(link.link.includes(pathname.slice(1, pathname.slice(1).indexOf('/'))) &&
													pathname[pathname.lastIndexOf('/') + 1]) ||
												(openWalletDrop && ELinksName.WALLETS === link.link),
										})}
									>
										<Link onClick={(e: MouseEvent) => handleButton(e, link.link)} to={link.link}>
											<span className="sidebar-nav-icon">
												<link.svg />
											</span>
											<span>{link.linkName}</span>
											{link?.svgArrow && (
												<span className="select__wallet-arrow">
													<link.svgArrow />
												</span>
											)}
										</Link>
										{openWalletDrop && ELinksName.WALLETS === link.link && (
											<div className="nav-drop nav-drop--type2 active">
												<div className="nav-drop__drop-scroll">
													<div className="nav-drop__drop-item">
														<ul>
															<li>
																<Link to={ELinksName.WALLETS_LIMITATION}>Limitations</Link>
															</li>
															<li>
																<Link
																	to={`${ELinksName.WALLETS_TRANSATION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsNameWallet.FIAT}`}
																>
																	Transactions history
																</Link>
															</li>
															<li>
																<Link to={ELinksName.WALLETS_PENDING_WITHDRAWALS}>
																	Pending withdrawals
																</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
										)}
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
