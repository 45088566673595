import BankInfoPopUp from 'components/PopUpBankInfo';
import React, { useState } from 'react';

const WithdrawManualItem = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			{isOpen ? <BankInfoPopUp closeModal={() => setIsOpen(false)} /> : ''}
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">User id</p>
					<p className="td-value td-value--medium red  ">052921</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Date &amp; Time</p>
					<p className="td-value td-value--medium ">03.05.2020 16:21:17</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Amount</p>
					<p className="td-value td-value--medium   ">2000.00 USD</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Bank Info</p>
					<div>
						<button type="button" className="link link--bigger" onClick={() => setIsOpen(true)}>
							Show
						</button>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Action</p>
					<div className="table-buttons table-buttons--text-btns">
						<button type="button" className="table-buttons__text-confirm">
							Confirm
						</button>
						<button type="button" className="table-buttons__text-cancel">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default WithdrawManualItem;
