/* eslint-disable react-hooks/exhaustive-deps */
import { InvestBodyTable } from 'components/InvestorsComponents/InvestBodyTable';
import { InvestDetails } from 'components/InvestorsComponents/InvestDetails';
import { InvestHeaderTable } from 'components/InvestorsComponents/InvestHeaderTable';
import { InvestSearch } from 'components/InvestorsComponents/InvestSearch';
import { Pagination } from 'components/Pagination';
import { Sidebar } from 'components/Sidebar';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
	getInvestorsRequest,
	getInvestorsSuccess,
} from 'redux/reducers/propertyManagement/reducer';
import { getInvestors } from 'redux/reducers/propertyManagement/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { SvgBackArrow } from 'ui/svg-image/SvgPreviewProperty';

const Investors: FC = () => {
	const param: { id: string } = useParams();
	const investors = useSelector(getInvestors);
	const dispatch = useDispatch();
	const [search, setSearch] = useState<string>('');
	const history = useHistory();

	// useEffect(() => {
	// 	dispatch(getInvestorsRequest({ id: param.id }));
	// }, [dispatch]);

	const submitFilter = (value?: number) => {
		dispatch(
			getInvestorsRequest(
				search
					? { current_page: value || +history.location.search.slice(1), id: param.id, name: search }
					: { id: param.id, current_page: value || +history.location.search.slice(1) },
			),
		);
	};

	useEffect(() => {
		dispatch(getInvestorsSuccess(null));
		history.push({
			search: String(1),
		});
		const timeOutId = setTimeout(() => submitFilter(+history.location.search.slice(1) || 1), 500);
		return () => clearTimeout(timeOutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	const searchInput = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setSearch(target.value);
	};

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<Link to={ELinksName.PROPERTY_MANAGEMENT} className="back-link back-link--mb16">
					<span className="back-link__arrow">
						<SvgBackArrow />
					</span>
					Back to property management
				</Link>
				<p className="block-title block-title--smaller">Investors</p>
				{investors && (
					<InvestDetails
						sumInvest={investors?.sum}
						countInvestors={investors?.count}
						estate={investors?.estate}
					/>
				)}
				<InvestSearch searchInput={searchInput} search={search} />
				<div className="table table--investors table--second-type">
					<InvestHeaderTable />
					{investors?.investments.data ? (
						<InvestBodyTable investors={investors.investments.data} />
					) : (
						'No investors'
					)}
				</div>
				{investors?.investments?.last_page && investors?.investments?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={investors.investments.current_page}
						numberPage={investors.investments.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default Investors;
