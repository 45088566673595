/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { api, responseErrors } from 'services';
import { notificationContainer } from 'untils/notificationContainer';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	addFundsToUserRequest,
	addFundsToUserSuccess,
	deleteUserRequest,
	deleteUserSuccess,
	getBlockedRequest,
	getBlockedSuccess,
	getDividendsUserRequest,
	getDividendsUserSuccess,
	getInvestmentsRequest,
	getInvestmentsSuccess,
	getOrdersUserRequest,
	getOrdersUserSuccess,
	getTransactionsHistoryConvertUserRequest,
	getTransactionsHistoryConvertUserSuccess,
	getTransactionsHistoryUserRequest,
	getTransactionsHistoryUserSuccess,
	getUserManagementRequest,
	getUserManagementSuccess,
	getUsersManagementListRequest,
	getUsersManagementListSuccess,
	getZipFileRequest,
	getZipFileSuccess,
	usersManagementInitState,
} from './reducer';
import {
	IAddFundsToUserRequest,
	IAddFundsToUserResponse,
	IBlockUserRequest,
	IDeleteUserRequest,
	IDownloadZipResponse,
	IInvestmentResponse,
	IOrdersResponse,
	IPaginationRequest,
	ITransactionHistoryUserResponse,
	ITransactionsHistoryConvert,
	IUserDividendsResponse,
	IUsersManagement,
	IUsersManagementListResponse,
} from './types';
import { IDevidendsReq, ITransactionReq, ITransctionsHistoryRequest } from '../wallets/types';

function* getUsersManagementListWorker(action: PayloadAction<IPaginationRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IUsersManagementListResponse } = yield call(
			api.usersManagement.getUsersManagementList,
			payload,
		);
		yield put(getUsersManagementListSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield usersManagementInitState();
	}
}

function* getBkockedWorker(action: PayloadAction<IBlockUserRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: { user: IUsersManagement } } = yield call(
			api.usersManagement.postBlockUser,
			payload,
		);
		notificationContainer(
			payload.blocked === 1 ? 'The user is blocked' : 'The user is unlocked',
			'success',
		);
		yield put(getBlockedSuccess(response.data.user));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield usersManagementInitState();
	}
}

function* deleteUserWorker(action: PayloadAction<IDeleteUserRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const respose: { data: { success: boolean; error: string } } = yield call(
			api.usersManagement.deleteUser,
			payload.id,
		);
		if (respose.data.success) {
			yield put(deleteUserSuccess(payload.id));
			yield payload.isClosePopUp(null, true);
			payload.history && payload.history?.push(ELinksName.USERS_MANAGEMENT);
			notificationContainer('User deleted success', 'info');
		}
		if (!respose.data.success) {
			yield payload.isClosePopUp(null, payload.id);
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getUserWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IUsersManagement } = yield call(
			api.usersManagement.getUserManagement,
			payload,
		);
		yield put(getUserManagementSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getInvestmentsWorker(action: PayloadAction<IPaginationRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IInvestmentResponse } = yield call(
			api.usersManagement.getInvestments,
			payload,
		);
		yield put(getInvestmentsSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTransactionsHistoryWorker(action: PayloadAction<ITransactionReq>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: ITransactionHistoryUserResponse } = yield call(
			api.usersManagement.getTransactionsHistory,
			payload,
		);
		yield put(getTransactionsHistoryUserSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTransactionsHistoryConvertsWorker(action: PayloadAction<ITransctionsHistoryRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: ITransactionsHistoryConvert } = yield call(
			api.usersManagement.getTransactionsHistoryConverts,
			payload,
		);
		yield put(getTransactionsHistoryConvertUserSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getOrdersWorker(action: PayloadAction<ITransactionReq>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IOrdersResponse } = yield call(api.usersManagement.getOrders, payload);
		yield put(getOrdersUserSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getDevidendsWorker(action: PayloadAction<IDevidendsReq>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IUserDividendsResponse } = yield call(
			api.usersManagement.getDevidends,
			payload,
		);

		yield put(getDividendsUserSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(usersManagementInitState());
	}
}

function* getZipFileWorker(action: PayloadAction<number>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IDownloadZipResponse } = yield call(
			api.usersManagement.getZipFile,
			String(payload),
		);

		yield put(getZipFileSuccess(response.data));
		yield window.open(response.data.path);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* addFundsToUserWorker(action: PayloadAction<IAddFundsToUserRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IAddFundsToUserResponse } = yield call(
			api.usersManagement.addFundsToUser,
			payload,
		);

		notificationContainer('Funds have been added to the account', 'info');
		yield put(addFundsToUserSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* usersManagementSaga() {
	yield takeEvery(getUsersManagementListRequest.type, getUsersManagementListWorker);
	yield takeEvery(getBlockedRequest.type, getBkockedWorker);
	yield takeEvery(deleteUserRequest.type, deleteUserWorker);
	yield takeEvery(getUserManagementRequest.type, getUserWorker);
	yield takeEvery(getInvestmentsRequest.type, getInvestmentsWorker);
	yield takeEvery(getTransactionsHistoryUserRequest.type, getTransactionsHistoryWorker);
	yield takeEvery(
		getTransactionsHistoryConvertUserRequest.type,
		getTransactionsHistoryConvertsWorker,
	);
	yield takeEvery(getOrdersUserRequest.type, getOrdersWorker);
	yield takeEvery(getDividendsUserRequest.type, getDevidendsWorker);
	yield takeEvery(getZipFileRequest.type, getZipFileWorker);
	yield takeEvery(addFundsToUserRequest.type, addFundsToUserWorker);
}
