import { IApi } from './types';
import { auth } from './auth';
import { settings } from './settings';
import { wallets } from './wallets';
import { sellProperty } from './sell-property';
import { propertyManagement } from './property-management';
import { usersManagement } from './users-management';
import { platformTokens } from './tokens';
import { dividendsManagement } from './dividends-management';
import { feeManagement } from './fee-management';
import { dashboardApi } from './dashboard';
import { interestRequests } from './interest-requests';

const api: IApi = {
	auth,
	settings,
	sellProperty,
	wallets,
	propertyManagement,
	usersManagement,
	platformTokens,
	dividendsManagement,
	feeManagement,
	dashboardApi,
	interestRequests,
};

export { api };
