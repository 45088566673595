import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName, ETabsDividends } from 'services/constants/list-links-sidebar';

export const DividendsTabs: FC = () => {
	const params: { tab: string } = useParams();

	return (
		<div className="table-top-wrapper table-top-wrapper--center">
			<div className="table-title table-title--m0">
				<p>
					{params.tab === ETabsDividends.MANAGEMENT
						? 'Dividends management'
						: 'History of dividends distribution'}
				</p>
			</div>
			<div className="switcher switcher--smaller">
				<Link
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === ETabsDividends.MANAGEMENT || !params.tab,
					})}
					to={`${ELinksName.DIVIDENDS_MANAGEMENT}/${ETabsDividends.MANAGEMENT}`}
				>
					Management
				</Link>
				<Link
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === ETabsDividends.HISTORY,
					})}
					to={`${ELinksName.DIVIDENDS_MANAGEMENT}/${ETabsDividends.HISTORY}`}
				>
					History
				</Link>
			</div>
		</div>
	);
};
