import classNames from 'classnames';
import { FC, MouseEvent } from 'react';
import { IPropsDividendsDrop } from '../types';

export const DividendsDrop: FC<IPropsDividendsDrop> = ({
	arrayRentStatus,
	isOpenDrop,
	isCloseDrop,
}) => {
	return (
		<div
			className={classNames('select__drop', {
				'select__drop--active': isOpenDrop,
			})}
		>
			<div className="select__drop-scroll">
				<div className="select__drop-item">
					<ul>
						{arrayRentStatus.map((status) => (
							<li key={status.text}>
								<button
									onClick={(e: MouseEvent) => {
										isCloseDrop(e);
									}}
									name={status.name}
									type="button"
								>
									{status.text}
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};
