import { FC } from 'react';

export const SvgTimer: FC = () => (
	<svg
		className="fill"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.75 13.5C2.75 8.39139 6.89139 4.25 12 4.25C17.1086 4.25 21.25 8.39139 21.25 13.5C21.25 18.6086 17.1086 22.75 12 22.75C6.89139 22.75 2.75 18.6086 2.75 13.5ZM12 5.75C7.71981 5.75 4.25 9.21981 4.25 13.5C4.25 17.7802 7.71981 21.25 12 21.25C16.2802 21.25 19.75 17.7802 19.75 13.5C19.75 9.21981 16.2802 5.75 12 5.75Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.75 2C7.75 1.58579 8.08579 1.25 8.5 1.25H15.5C15.9142 1.25 16.25 1.58579 16.25 2C16.25 2.41421 15.9142 2.75 15.5 2.75H8.5C8.08579 2.75 7.75 2.41421 7.75 2Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L18.0303 7.03033C17.7374 7.32322 17.2626 7.32322 16.9697 7.03033C16.6768 6.73744 16.6768 6.26256 16.9697 5.96967L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V9C11.25 8.58579 11.5858 8.25 12 8.25Z"
			fill="#E41472"
		/>
	</svg>
);
