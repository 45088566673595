import { IPropsPreviewProperty } from 'components/PropertyManagementComponents/types';
import moment from 'moment';
import numeral from 'numeral';
import { FC } from 'react';
import {
	SvgAmountOfTokens,
	SvgIconTotalPrice,
	SvgTokenPrice,
} from 'ui/svg-image/SvgPreviewProperty';

export const ImportantInformation: FC<IPropsPreviewProperty> = ({ values }) => {
	return (
		<div className="realty-block">
			<p className="info-title">Important information:</p>
			<div className="realty-block__content">
				<div className="realty-info-box">
					<div className="realty-info-box__item realty-info-box__item-custom">
						<div className="realty-info">
							<p className="realty-info__name">
								<span className="realty-info__name-icon">
									<SvgIconTotalPrice />
								</span>
								Purchase Price
							</p>
							<p className="realty-info__value">{`$${String(
								numeral(values?.price).format('0,0').replaceAll(',', ' '),
							)}`}</p>
						</div>
						<div className="realty-info">
							<p className="realty-info__name">
								<span className="realty-info__name-icon">
									<SvgAmountOfTokens />
								</span>
								Gross Monthly Rent
							</p>
							<p className="realty-info__value">{`$${String(
								numeral(values?.expected_profit || 0)
									.format('0,0')
									.replaceAll(',', ' '),
							)}`}</p>
						</div>
						<div className="realty-info">
							<p className="realty-info__name">
								<span className="realty-info__name-icon">
									<SvgTokenPrice />
								</span>
								Estimated first dividend date
							</p>
							<p className="realty-info__value">
								{values?.date_expected_dividends
									? moment(values?.date_expected_dividends).format('ll')
									: '-'}
							</p>
						</div>
					</div>
					<div className="realty-info-box__item">
						<div className="realty-info">
							<p className="realty-info__name">Issued tokens</p>
							<p className="realty-info__value">
								{numeral(values?.tokens_number).format('0,0').replaceAll(',', ' ')}
							</p>
						</div>
						<div className="realty-info">
							<p className="realty-info__name realty-info__name--short">Token Price</p>
							<p className="realty-info__value">{`${String(100)}$`}</p>
						</div>
						<div className="realty-info">
							<p className="realty-info__name realty-info-custom">Tokens Available</p>
							<p className="realty-info__value realty-info-custom realty-info-text-custom">
								{`${String(4)} (token №)`}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
