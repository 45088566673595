import classNames from 'classnames';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC, MouseEvent, useState } from 'react';
import { ISellPropertisRequest } from 'redux/reducers/sellManagement/types';

interface IProps {
	submitFilter: (value?: number, calendar?: ISellPropertisRequest, status?: string) => void;
	calendarData: ISellPropertisRequest | undefined;
}
export const SellTableHeader: FC<IProps> = ({ submitFilter, calendarData }) => {
	const [dropDawn, setDropDawn] = useState(false);
	const { innerBorderRef } = useOnOutsideClick(() => setDropDawn(false));

	const handleButons = (e: MouseEvent) => {
		const target = e.target as HTMLButtonElement;
		submitFilter(1, calendarData, target.name);
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">Request ID</p>
				</div>
				<div className="td">
					<p className="td-name">Name</p>
				</div>
				<div className="td">
					<p className="td-name">Email</p>
				</div>
				<div className="td">
					<p className="td-name">Date</p>
				</div>
				<div className="td">
					<p className="td-name">Phone number</p>
				</div>
				<div className="td">
					<div className="select select--td-filter select-z-index">
						<div
							onClick={() => setDropDawn(!dropDawn)}
							ref={innerBorderRef}
							className="select__current"
						>
							Status
							<span className="select__current-arrow">
								<svg
									width="14"
									height="8"
									viewBox="0 0 14 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
										fill="#25252E"
									/>
								</svg>
							</span>
						</div>
						<div
							className={classNames('select__drop', {
								'select__drop--active': dropDawn,
							})}
						>
							<div className="select__drop-scroll">
								<div className="select__drop-item">
									<ul>
										<li>
											<button onClick={handleButons} type="button">
												All
											</button>
										</li>
										<li>
											<button name="0" onClick={handleButons} type="button">
												New
											</button>
										</li>
										<li>
											<button name="1" onClick={handleButons} type="button">
												Confirmed
											</button>
										</li>
										<li>
											<button name="2" onClick={handleButons} type="button">
												Cancelled
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-name">Actions</p>
				</div>
			</div>
		</div>
	);
};
