import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	ELinksName,
	EParamsNameWallet,
	EParamsUserAcount,
} from 'services/constants/list-links-sidebar';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';

export const TransactionSwitchers: FC = () => {
	const params: { tab: string; id: string; subTabLeft: string; subTab: string } = useParams();
	return (
		<div className="transaction-switchers">
			<div className="switcher switcher--3items">
				<Link
					to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY}/${params.id}/${ETransactionTabsName.DEPOSIT}/${params.subTab}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.subTabLeft === ETransactionTabsName.DEPOSIT,
					})}
				>
					Deposit
				</Link>
				<Link
					to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY}/${params.id}/${ETransactionTabsName.WITHDRAWL}/${params.subTab}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.subTabLeft === ETransactionTabsName.WITHDRAWL,
					})}
				>
					Withdrawal
				</Link>
				<Link
					to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY}/${params.id}/${ETransactionTabsName.CONVERT}/${params.subTab}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.subTabLeft === ETransactionTabsName.CONVERT,
					})}
				>
					Convert
				</Link>
			</div>
			{params.subTabLeft !== ETransactionTabsName.CONVERT && (
				<div className="switcher switcher--2items">
					<Link
						to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY}/${params.id}/${params.subTabLeft}/${EParamsNameWallet.CRYPTO}`}
						className={classNames('switcher__item switcher__item--cnter', {
							active: params.subTab === EParamsNameWallet.CRYPTO,
						})}
					>
						Crypto
					</Link>
					<Link
						to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY}/${params.id}/${params.subTabLeft}/${EParamsNameWallet.FIAT}`}
						className={classNames('switcher__item switcher__item--cnter', {
							active: params.subTab === EParamsNameWallet.FIAT,
						})}
					>
						Fiat
					</Link>
				</div>
			)}
		</div>
	);
};
