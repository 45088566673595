/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeeManagement } from 'pages/Dashboard/FeeManagement';
import { IFeeManagementRequest, IFeeManagementState } from './types';

export const initialState: IFeeManagementState = {
	feeManagements: null,
	feeManagementsLoader: false,
};

const feeManagement = createSlice({
	name: '@@feeManagement',
	initialState,
	reducers: {
		getFeeManagementRequest: (state) => {
			const getFeeManagementState = state;
			getFeeManagementState.feeManagementsLoader = true;
		},

		getFeeManagementSuccess: (state, action: PayloadAction<IFeeManagementRequest>) => {
			const { payload } = action;
			const getFeeManagementState = state;

			getFeeManagementState.feeManagementsLoader = false;
			getFeeManagementState.feeManagements = payload;
		},

		postFeeManagementRequest: (state, action: PayloadAction<IFeeManagement>) => {
			const postFeeManagementState = state;
			postFeeManagementState.feeManagementsLoader = true;
		},

		postFeeManagementSuccess: (state, action: PayloadAction<IFeeManagementRequest>) => {
			const { payload } = action;
			const postFeeManagementState = state;
			postFeeManagementState.feeManagementsLoader = false;
			postFeeManagementState.feeManagements = payload;
		},

		feeManagementInitState: (state) => {
			const propertyManagementInitState = state;
			propertyManagementInitState.feeManagementsLoader = false;
		},
	},
});

export default feeManagement.reducer;
export const {
	getFeeManagementRequest,
	getFeeManagementSuccess,
	feeManagementInitState,
	postFeeManagementRequest,
	postFeeManagementSuccess,
} = feeManagement.actions;
