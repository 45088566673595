import { ChangeEvent, FC, useState } from 'react';
import parse from 'html-react-parser';
import CopyToClipboard from 'react-copy-to-clipboard';

import { notificationContainer } from 'untils/notificationContainer';
import { IProps2FaActiveGoogle } from './types';

export const TwoFaAuthActiveGogle: FC<IProps2FaActiveGoogle> = ({
	twoFaGoogle,
	setOpen2FaGoogle,
	handleGoogle2faSubmit,
}) => {
	const [code, setCode] = useState('');

	const heandleInput = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setCode(target.value);
	};

	const handleAddressCopy = () => {
		notificationContainer('The secret code has been copied successfully!', 'info');
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--bigger">
					<button
						onClick={() => setOpen2FaGoogle(false)}
						type="button"
						className="popup-close popup-close--type2"
					>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L17.5303 16.4697C17.8232 16.7626 17.8232 17.2374 17.5303 17.5303C17.2374 17.8232 16.7626 17.8232 16.4697 17.5303L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z"
								fill="#25252E"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M17.5303 0.46967C17.8232 0.762563 17.8232 1.23744 17.5303 1.53033L1.53033 17.5303C1.23744 17.8232 0.762563 17.8232 0.46967 17.5303C0.176777 17.2374 0.176777 16.7626 0.46967 16.4697L16.4697 0.46967C16.7626 0.176777 17.2374 0.176777 17.5303 0.46967Z"
								fill="#25252E"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-title popup-title--center">Turn on 2FA-Authentication</p>
						<div className="popup-text popup-text--mt-8 popup-text--center">
							<p>Scan the QR code using Google Authenticator</p>
						</div>
					</div>
					<div className="popup-body">
						<div className="qr">{parse(twoFaGoogle.QR_Image)}</div>
						<div className="input ">
							<p className="input-name">Secret code</p>
							<div className="input-wrapper">
								<input
									className="input-item input-item--pr-125 "
									type="text"
									placeholder="Code"
									defaultValue={twoFaGoogle.secret}
									disabled
								/>
								<CopyToClipboard text={twoFaGoogle.secret} onCopy={handleAddressCopy}>
									<button type="button" className="input-action">
										Copy
									</button>
								</CopyToClipboard>
							</div>
						</div>
						<div className="input input--mb-0">
							<p className="input-name">Enter your 2FA-Authentication code</p>
							<div className="input-wrapper">
								<input
									onChange={heandleInput}
									className="input-item"
									type="text"
									placeholder="Code"
									value={code}
								/>
							</div>
						</div>
					</div>
					<div className="popup-footer">
						<button
							onClick={() => handleGoogle2faSubmit(code)}
							type="button"
							className="button button--full-width"
							disabled={code.length !== 6}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
