export const statusSellProperty: { [char: string]: string } = {
	'0': 'New',
	'1': 'Confirmed',
	'2': 'Cancelled',
};

export const statusPropertyM: { [char: string]: string } = {
	'0': 'Unpublished',
	'1': 'Comming soon',
	'2': 'Live ',
	'3': 'Sold out',
	'4': 'Unpublished',
};

export const rentStatusList: { [char: string]: string } = {
	'0': 'Not rented',
	'1': 'Rented',
};

export const orderType: { [char: string]: string } = {
	'1': 'Sell',
	'2': 'Buy',
};

export const orderStatus: { [char: string]: string } = {
	'1': 'Waiting',
	'2': 'Sold',
	'3': 'Error',
};

export const chooseRealEstateType = [
	'House',
	'Townhome',
	'Multifamily',
	'Condo',
	'Apartment',
	'Commercial',
];

export const arrayRentStatus: Array<{ [char: string]: string }> = [
	{ text: 'All Status' },
	{ name: '0', text: 'Not rented' },
	{ name: '1', text: 'Rented' },
];

export const stateList = [
	'Alabama',
	'Alaska',
	'Arizona',
	'Arkansas',
	'Bali',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'Florida',
	'Georgia',
	'Hawaii',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Pennsylvania',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
	'Other',
];

export enum EDataPicker {
	LISTING = 'listing',
	LAUNCH = 'launch',
	UNLOCK = 'unlock',
}

export const byStatusPrice = [
	{ name: 'From high - to low', sort: 'desc' },
	{ name: 'From low - to high', sort: 'asc' },
];

export const byStatus = ['Unpublished', 'Comming soon', 'Live', 'Sold out', 'All'];

export enum EStatusUserManagement {
	VERIFIED = 'Verified',
	UNVERIFIED = 'Unverified',
}

export enum EStatusNumberUserManagement {
	VERIFIED = 2,
	UNVERIFIED = 1,
}

export const statusPropertyNumber: { [char: string]: number } = {
	Unpublished: 0,
	'Comming soon': 1,
	Live: 2,
	'Sold out': 3,
};
