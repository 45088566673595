/* eslint-disable no-nested-ternary */
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { HeaderTableHistory } from 'components/TrasactionHistoryComponents/HeaderTableHistory';
import { TransactionHistoryConvert } from 'components/TrasactionHistoryComponents/TransactionHistoryConvert';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
	getTransactionsHistoryConvertUserRequest,
	getTransactionsHistoryUserRequest,
} from 'redux/reducers/usersManagement/reducer';
import {
	getTransactionsHistoryUserSelector,
	getTransactionsHistoyConvertSelector,
	getUsersManagementListLoader,
} from 'redux/reducers/usersManagement/selectors';
import { IHistoryConverts } from 'redux/reducers/wallets/types';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import {
	ETransactionTabsName,
	filterTransactionHistory,
} from 'services/constants/transaction-history-tabs';
import { SvgArrowBackSmall } from 'ui/svg-image/SvgArrowBackSmall';
import { BodyTableHistoryAssets } from './AccountHistoryTableAssets';
import { TransactionSwitchers } from './TransactionSwitchers';

export const AccountTransactionHistory: FC = () => {
	const dispatch = useDispatch();
	const transactions = useSelector(getTransactionsHistoryUserSelector);
	const converts = useSelector(getTransactionsHistoyConvertSelector);
	const loader = useSelector(getUsersManagementListLoader);
	const params: { id: string; tab: string; subTab: string; subTabLeft: string } = useParams();
	const { location } = useHistory();
	const paginationHistory =
		params.subTabLeft === ETransactionTabsName.CONVERT ? converts : transactions;

	const submitFilter = (value: number) => {
		filterTransactionHistory.includes(params.subTabLeft) &&
			dispatch(
				getTransactionsHistoryUserRequest({
					params: {
						current_page: value || +location.search.slice(1),
						asset_id: params.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
						type: params.subTabLeft === ETransactionTabsName.DEPOSIT ? 1 : 2,
					},
					id: +params.id,
				}),
			);
		params.subTabLeft === ETransactionTabsName.CONVERT &&
			dispatch(
				getTransactionsHistoryConvertUserRequest({
					current_page: value || +location.search.slice(1),
					asset: params.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
					id: +params.id,
				}),
			);
	};

	useEffect(() => {
		submitFilter(+location.search.slice(1) || 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.subTab, params.subTabLeft]);

	return (
		<div className="admin__content">
			<Link to={ELinksName.USERS_MANAGEMENT} className="back-link back-link--mb20">
				<span className="back-link__arrow">
					<SvgArrowBackSmall />
				</span>
				Back to user management
			</Link>
			<div className="table-title table-title--mb50 table-title--pt10">
				<p>Transaction history</p>
			</div>
			<TransactionSwitchers />
			<div
				className={
					params.subTab === EParamsNameWallet.CRYPTO
						? 'table table--transactions-history table--second-type'
						: 'table table--transactions-history-fiat table--second-type'
				}
			>
				{params.subTabLeft !== ETransactionTabsName.CONVERT && (
					<HeaderTableHistory param={params.subTab} />
				)}
				{loader ? (
					<Loader />
				) : params.subTabLeft === ETransactionTabsName.CONVERT ? (
					converts && (
						<TransactionHistoryConvert historyAll={converts.data as Array<IHistoryConverts>} />
					)
				) : (
					transactions && <BodyTableHistoryAssets historyAll={transactions} param={params.subTab} />
				)}
			</div>
			{paginationHistory?.last_page && paginationHistory?.last_page > 1 && (
				<Pagination
					request={submitFilter}
					currentPage={paginationHistory.current_page}
					numberPage={paginationHistory.last_page}
				/>
			)}
		</div>
	);
};
