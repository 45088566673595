import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	ICountriesResponse,
	IInvestors,
	IProperty,
	IPropertyListResponse,
	IPropertyManagementState,
	IStatesResponse,
} from './types';

const getPropertyManagementState = (state: IStoreState): IPropertyManagementState =>
	state.propertyManagement;

export const getPropertyList = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): IPropertyListResponse | null =>
		propertyManagement.propertyList,
);

export const getPropertyLoader = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): boolean => propertyManagement.propertyListLoader,
);

export const getProfitLoader = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): boolean => propertyManagement.editProfitLoader,
);

export const getProperty = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): IProperty | null => propertyManagement.property,
);

export const getIdProperty = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): number | null =>
		propertyManagement.createIdPropertty,
);

export const getSavePropertyLoader = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): boolean => propertyManagement.editPropertyLoader,
);

export const getPublishPropertyLoader = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): boolean =>
		propertyManagement.publishPropertyLoader,
);

export const getTokenPrice = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): number | null => propertyManagement.tokenPrice,
);

export const getInvestors = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): IInvestors | null => propertyManagement.investors,
);

export const getCountriesSelector = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): Array<ICountriesResponse> | null =>
		propertyManagement.countries,
);

export const getStatesSelector = createSelector(
	[getPropertyManagementState],
	(propertyManagement: IPropertyManagementState): Array<IStatesResponse> | null =>
		propertyManagement.states,
);
