import moment from 'moment';
import { IPropsTransactionHistoryBody } from 'pages/Dashboard/Wallets/types';
import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { changeStatusRequest } from 'redux/reducers/wallets/reducer';
import WithdrawManualItem from 'components/PendingWithdrawalsComponents/WithdrawManualItem/index';

export const PendingWithdrawalsBody: FC<IPropsTransactionHistoryBody> = ({
	transactionsHistory,
	manual,
}) => {
	const dispatch = useDispatch();

	const changeStatus = (event: MouseEvent) => {
		const target = event.target as HTMLButtonElement;

		dispatch(
			changeStatusRequest({
				payment: +target.id,
				status: target.name,
			}),
		);
	};
	if (manual) {
		return (
			<div className="table-body">
				<WithdrawManualItem />
				<WithdrawManualItem />
			</div>
		);
	}

	return (
		<div className="table-body">
			{transactionsHistory?.length ? (
				transactionsHistory?.map((history) => (
					<div key={history.id} className="tr">
						<div className="td">
							<p className="td-hidden-name">Withdrawal ID</p>
							<p className="td-value td-value--medium red  ">{history.id}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">User id</p>
							<p className="td-value td-value--medium red  ">{history.user_id}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Currency</p>
							<p className="td-value td-value--medium td-hidden-name-upper">{history.asset.name}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Network</p>
							<p className="td-value td-value--medium  ">Algorand</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Amount</p>
							<p className="td-value td-value--medium">{history.amount}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Fee</p>
							<p className="td-value td-value--medium">{history.amount_fee}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Created</p>
							<p className="td-value td-value--medium ">
								{moment(history.created_at).format('DD.MM.YY h:mm:ss')}
							</p>
						</div>
						<div className="td td--right">
							<p className="td-hidden-name">Action</p>
							<div className="table-buttons table-buttons--text-btns">
								<button
									onClick={changeStatus}
									name="admin_check"
									id={String(history.id)}
									type="button"
									className="table-buttons__text-confirm"
								>
									Confirm
								</button>
								<button
									onClick={changeStatus}
									id={String(history.id)}
									name="canceled"
									type="button"
									className="table-buttons__text-cancel"
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				))
			) : (
				<span>Pending withdrawals not found</span>
			)}
		</div>
	);
};
