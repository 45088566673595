/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	IAddFundsToUserRequest,
	IBlockUserRequest,
	IPaginationRequest,
} from 'redux/reducers/usersManagement/types';
import {
	IDevidendsReq,
	ITransactionReq,
	ITransctionsHistoryRequest,
} from 'redux/reducers/wallets/types';
import { IUsersManagementApi } from './types';

export const usersManagement: IUsersManagementApi = {
	getUsersManagementList: (params: IPaginationRequest) =>
		http.get(endpoint.usersManagement.USER_MANAGEMENT_LIST, { params }),
	postBlockUser: (payload: IBlockUserRequest) =>
		http.post(endpoint.usersManagement.USER_MANAGEMENT_BLOCK, payload),
	deleteUser: (payload: number) =>
		http.delete(endpoint.usersManagement.USER_MANAGEMENT_DELETE(payload)),
	getUserManagement: (params: string) => http.get(endpoint.usersManagement.USER_MANAGEMENT(params)),
	getInvestments: (params: IPaginationRequest) =>
		http.get(endpoint.usersManagement.INVESTMENTS(params), { params }),
	getTransactionsHistory: (payload: ITransactionReq) => {
		const { params, id } = payload;
		return http.get(endpoint.usersManagement.TRANSACTION_HISTORY(id), { params });
	},
	getTransactionsHistoryConverts: (params: ITransctionsHistoryRequest) =>
		http.get(endpoint.usersManagement.TRANSACTION_HISTORY_CONVERTS(params), { params }),
	getOrders: (payload: ITransactionReq) => {
		const { params, id } = payload;
		return http.get(endpoint.usersManagement.ORDERS_USER(id as number), { params });
	},
	getDevidends: (payload: IDevidendsReq) => {
		const { params, id } = payload;
		return http.get(endpoint.usersManagement.DEVIDENDS_USER(id as number), { params });
	},
	getZipFile: (payload: string) => http.get(endpoint.usersManagement.GET_ZIP_FILE(payload)),
	addFundsToUser: (payload: IAddFundsToUserRequest) =>
		http.post(endpoint.usersManagement.ADD_FUNDS_TO_USER, payload),
};
