import { Calendar } from 'components/Calendar';
import { Pagination } from 'components/Pagination';
import { SellTableBody } from 'components/SellYourPropertyComponents/SellTableBody';
import { SellTableHeader } from 'components/SellYourPropertyComponents/SellTableHeader';
import { Sidebar } from 'components/Sidebar';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAllSellPropertyRequest } from 'redux/reducers/sellManagement/reducer';
import { getAllSell } from 'redux/reducers/sellManagement/selectors';
import { ISellPropertisRequest } from 'redux/reducers/sellManagement/types';

const SellYourPropery: FC = () => {
	const dispatch = useDispatch();
	const allSellProperty = useSelector(getAllSell);
	const history = useHistory();
	const [calendarData, setCalendarData] = useState<ISellPropertisRequest>();

	const submitFilter = (value?: number, calendar?: ISellPropertisRequest, status?: string) => {
		calendar && setCalendarData(calendar);
		calendar?.date_from === null && setCalendarData(undefined);
		const data: ISellPropertisRequest = {
			current_page: value,
			...(calendar || calendarData),
		};

		if (status || status === '0') data.status = +status;
		dispatch(getAllSellPropertyRequest(data));
	};

	useEffect(() => {
		dispatch(getAllSellPropertyRequest({ current_page: +history.location.search.slice(1) || 1 }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<div className="admin">
			<Sidebar />

			<div className="admin__content">
				<div className="table-top-wrapper table-top-wrapper--center table-title--flex">
					<div className="table-title table-title--m0 table-title--flex">
						<p>Sell Request</p>
						<Calendar submitFilter={submitFilter} />
					</div>
				</div>
				<div className="table table--orders-management table--second-type">
					<SellTableHeader submitFilter={submitFilter} calendarData={calendarData} />
					<SellTableBody allSellProperty={allSellProperty} />
				</div>
				{allSellProperty?.last_page && allSellProperty?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={allSellProperty.current_page}
						numberPage={allSellProperty.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default SellYourPropery;
