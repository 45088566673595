import { FC } from 'react';

export const FeeManagementHeader: FC = () => {
	return (
		<div className="table-header table-body-custom">
			<div className="tr">
				<div className="td">
					<p className="td-name">Currency</p>
				</div>
				<div className="td">
					<p className="td-name">Buy Tokens</p>
				</div>
				<div className="td">
					<p className="td-name">Sell Tokens</p>
				</div>
				<div className="td">
					<p className="td-name">Convert</p>
				</div>
				<div className="td">
					<p className="td-name">Deposit</p>
				</div>
				<div className="td">
					<p className="td-name">Withdraw</p>
				</div>
				<div className="td">
					<p className="td-name">Invest</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Action</p>
				</div>
			</div>
		</div>
	);
};
