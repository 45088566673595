import { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addFundsToUserRequest } from '../../../redux/reducers/usersManagement/reducer';
import { getUsersManagementListLoader } from '../../../redux/reducers/usersManagement/selectors';
import { Loader } from '../../Loader';

interface IAddFundsToAccountProps {
	userId: number;
}

interface IAddFundsToAccountValues {
	amount: number;
}

export const AddFundsToAccount: FC<IAddFundsToAccountProps> = ({
	userId,
}: IAddFundsToAccountProps) => {
	const dispatch = useDispatch();
	const loader = useSelector(getUsersManagementListLoader);
	const initialValues: IAddFundsToAccountValues = {
		amount: 0,
	};
	const onSubmit = ({ amount }: IAddFundsToAccountValues) => {
		dispatch(addFundsToUserRequest({ user_id: userId, amount }));
	};

	return (
		<Formik onSubmit={onSubmit} initialValues={initialValues}>
			<Form className="user-form">
				{loader ? (
					<Loader />
				) : (
					<>
						<p className="block-subtitle block-subtitle--type2 block-subtitle--ta-left">
							Add funds to account
						</p>
						<div className="user-form__body">
							<div className="input">
								<p className="input-name">Amount</p>
								<div className="input-wrapper">
									<Field
										type="number"
										placeholder="Enter amount"
										name="amount"
										required
										className="input-item"
									/>
									<span className="input-coin">USD</span>
								</div>
							</div>
						</div>

						<div className="form-footer">
							<button className="button button--long" type="submit">
								Confirm
							</button>
						</div>
					</>
				)}
			</Form>
		</Formik>
	);
};
