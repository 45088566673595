import { FC } from 'react';

export const HeaderOrders: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">ID</p>
				</div>
				<div className="td">
					<p className="td-name">Apartment Name</p>
				</div>
				<div className="td">
					<p className="td-name">Created</p>
				</div>
				<div className="td">
					<p className="td-name">Asset</p>
				</div>
				<div className="td">
					<p className="td-name">Quantity</p>
				</div>
				<div className="td">
					<p className="td-name">Quantity left</p>
				</div>
				<div className="td">
					<p className="td-name">Type</p>
				</div>
				<div className="td td--center">
					<p className="td-name">Fee buyer</p>
				</div>
				<div className="td td--center">
					<p className="td-name">Fee seller</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Status</p>
				</div>
			</div>
		</div>
	);
};
