import { IPropsPreviewProperty } from 'components/PropertyManagementComponents/types';
import { FC, useState } from 'react';

export const Overview: FC<IPropsPreviewProperty> = ({ values }) => {
	const [showText, setShowText] = useState<boolean>(false);

	return (
		<div className="realty-block realty-block--type2">
			<p className="info-title">Overview:</p>
			<div className="realty-block__content">
				<div className="realty-overview">
					<div
						className={`realty-overview__text realty-overview__${
							showText ? 'text-height-full' : 'text-height'
						}`}
					>
						{values.description}
					</div>
					<div className="realty-overview__more">
						<div className="realty-overview__more">
							{values.description.length > 291 &&
								(showText ? (
									<button
										onClick={() => setShowText(!showText)}
										type="button"
										className="link link--fw-600 link--bigger"
									>
										Hide text
									</button>
								) : (
									<button
										onClick={() => setShowText(!showText)}
										type="button"
										className="link link--fw-600 link--bigger"
									>
										Read more
									</button>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

/* <div className="realty-block realty-block--type2">
							<p className="info-title">Overview:</p>
							<div className="realty-block__content">
								<div className="realty-overview">
									<div className="realty-overview__text">
										<p>
											The Dunlap Hollow A-Frame is a new build that will be completed in mid-March
											2021. We are offering prebooking now with reservation dates beginning April 1,
											2021. The A-Frame sleeps up to 10 guests with 3 bedrooms and a picturesque
											loft filled with windows that sleeps 4. Here is a list of the beds in each
											room
										</p>
										<div className="realty-overview__text-hidden">
											<h4>The space</h4>
											<p>
												Henningsvær is in the heart of Lofoten - and for us Guardhouse Island is the
												heart of Henningsvær. Papa got a deed on the island in 1895 and started fish
												farming, where fish was bought and processed in all variants - Fresh, salted
												and smoked fish were the products, as well as crane and other by-products.
												My great-grandfather won a prize in Paris in 1905 for best medicine!
												<br />
											</p>
											<h4>Guest access</h4>
											<p>
												Nybryggen is the pier from about 1945 where there were rudder arches and
												saltfish production at berth, and was furnished with apartment around 1985
												with adjacent terrace and the island as patio.
												<br />
												The apartment has a base area of about 55 m2, furnished in simple but cozy
												style. There are 2 bedrooms, one with double bed and one with 3 beds, on
												either side of the bathroom.
												<br />
												Just outside the apartment there is a large room with several tables and
												benches, this has been used for collections and works well both with few and
												many people if you want to gather in a rudder arch environment. We have
												collected some photographs from Henningsvær and the island as well as old
												objects from the operation of the island, so that you get an insight into a
												past time.
												<br />
												There are two private residential houses on the island with a garden where
												common neighborly considerations apply, besides which you can freely explore
												the island with, among other things, the sea side, Storhaugen with 360
												degree views, the lake root with fishing and bathing area, as well as many
												other pleasant places.
											</p>
										</div>
									</div>
									<div className="realty-overview__more">
										<button type="button" className="link link--fw-600 link--bigger">
											Read more
										</button>
									</div>
								</div>
							</div>
						</div> */
