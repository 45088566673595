/* eslint-disable @typescript-eslint/no-explicit-any */
import ErrorMsg from 'components/ErrorMsg';
import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import { EDataPicker } from 'services/constants/status';

export interface IProps {
	handleBlur: any;
	errors: IValuesDate;
	values: IValuesDate;
	dateOfMonth: (d: Date, name?: string) => void;
}

export interface IValuesDate {
	date_listing: string | Date;
	date_launch: string | Date;
	date_token_unlock: string | Date;
}

export const NewPropertyDate: FC<IProps> = ({ dateOfMonth, handleBlur, errors, values }) => {
	return (
		<div className="new-property-date">
			<div className="new-property-date-item">
				<div className="new-property-date-item__title">
					<p>Add Date of Listing</p>
				</div>
				<div className="new-property-date-item__contetn">
					<ReactDatePicker
						name="date_listing"
						onBlur={handleBlur}
						value={String(values.date_listing || '')}
						calendarStartDay={1}
						selected={values.date_listing as Date}
						onChange={(date: Date) => dateOfMonth(date, EDataPicker.LISTING)}
						inline
						dropdownMode="select"
						showMonthDropdown
						showYearDropdown
						isClearable
					/>
					{errors?.date_listing ? <ErrorMsg msg={errors?.date_listing as string} /> : null}
				</div>
			</div>
			<div className="new-property-date-item">
				<div className="new-property-date-item__title">
					<p>Add Launch Date</p>
				</div>
				<div className="new-property-date-item__contetn">
					<ReactDatePicker
						name="date_launch"
						onBlur={handleBlur}
						value={String(values.date_launch || '')}
						calendarStartDay={1}
						selected={values.date_launch as Date}
						onChange={(date: Date) => dateOfMonth(date, EDataPicker.LAUNCH)}
						inline
						dropdownMode="select"
						showMonthDropdown
						showYearDropdown
						isClearable
					/>
					{errors?.date_launch ? <ErrorMsg msg={errors?.date_launch as string} /> : null}
				</div>
			</div>
			<div className="new-property-date-item">
				<div className="new-property-date-item__title">
					<p>Add token unblock date</p>
				</div>
				<div className="new-property-date-item__contetn">
					<ReactDatePicker
						name="date_token_unlock"
						onBlur={handleBlur}
						value={String(values.date_token_unlock || '')}
						calendarStartDay={1}
						selected={values.date_token_unlock as Date}
						onChange={(date: Date) => dateOfMonth(date, EDataPicker.UNLOCK)}
						inline
						dropdownMode="select"
						showMonthDropdown
						showYearDropdown
						isClearable
					/>
					{errors?.date_token_unlock ? (
						<ErrorMsg msg={errors?.date_token_unlock as string} />
					) : null}
				</div>
			</div>
		</div>
	);
};
