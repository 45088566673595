import React, { FC } from 'react';

export const SvgUpload: FC = () => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.44 7.90039C19.04 8.21039 20.51 10.0604 20.51 14.1104V14.2404C20.51 18.7104 18.72 20.5004 14.25 20.5004H7.73998C3.26998 20.5004 1.47998 18.7104 1.47998 14.2404V14.1104C1.47998 10.0904 2.92998 8.24039 6.46998 7.91039"
			stroke="#9E9E9E"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11 13.9991V2.61914"
			stroke="#9E9E9E"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.3499 4.85L10.9999 1.5L7.6499 4.85"
			stroke="#9E9E9E"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgDelete: FC = () => (
	<svg
		className="stroke"
		width="20"
		height="22"
		viewBox="0 0 20 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19 4.98047C15.67 4.65047 12.32 4.48047 8.98 4.48047C7 4.48047 5.02 4.58047 3.04 4.78047L1 4.98047"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.5 3.97L6.72 2.66C6.88 1.71 7 1 8.69 1H11.31C13 1 13.13 1.75 13.28 2.67L13.5 3.97"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.8499 8.14062L16.1999 18.2106C16.0899 19.7806 15.9999 21.0006 13.2099 21.0006H6.7899C3.9999 21.0006 3.9099 19.7806 3.7999 18.2106L3.1499 8.14062"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.33008 15.5H11.6601"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.5 11.5H12.5"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgClose: FC = () => (
	<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.552 15.9478L15.4478 10.052"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.4478 15.9478L9.552 10.052"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.37492 23.4166H15.6249C20.8333 23.4166 22.9166 21.3333 22.9166 16.1249V9.87492C22.9166 4.66659 20.8333 2.58325 15.6249 2.58325H9.37492C4.16659 2.58325 2.08325 4.66659 2.08325 9.87492V16.1249C2.08325 21.3333 4.16659 23.4166 9.37492 23.4166Z"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgCheck: FC = () => (
	<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.37492 23.4166H15.6249C20.8333 23.4166 22.9166 21.3333 22.9166 16.1249V9.87492C22.9166 4.66659 20.8333 2.58325 15.6249 2.58325H9.37492C4.16659 2.58325 2.08325 4.66659 2.08325 9.87492V16.1249C2.08325 21.3333 4.16659 23.4166 9.37492 23.4166Z"
			stroke="#25A353"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.073 12.9999L11.0209 15.9478L16.9272 10.052"
			stroke="#25A353"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
