import { FC } from 'react';

export const DevidendsHistoryHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">Apartment Name</p>
				</div>
				<div className="td">
					<p className="td-name">Status</p>
				</div>
				<div className="td">
					<p className="td-name"> Distributed to № of investors</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Amount distributed</p>
				</div>
			</div>
		</div>
	);
};
