import { Loader } from 'components/Loader';
import moment from 'moment';
import numeral from 'numeral';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTransactionHistotyAdminLoader } from 'redux/reducers/wallets/selectors';
import { IHistoryConverts } from 'redux/reducers/wallets/types';
import {
	ELinksName,
	EParamsNameWallet,
	EParamsUserAcount,
} from 'services/constants/list-links-sidebar';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';

export const TransactionHistoryConvert: FC<{ historyAll: Array<IHistoryConverts> | undefined }> = ({
	historyAll,
}) => {
	const loader = useSelector(getTransactionHistotyAdminLoader);

	return (
		<div className="table table--transactions-history-convert table--second-type">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p className="td-name">ID </p>
					</div>
					<div className="td">
						<p className="td-name">User ID</p>
					</div>
					<div className="td">
						<p className="td-name">Time and date</p>
					</div>
					<div className="td td--center">
						<p className="td-name">Fee</p>
					</div>
					<div className="td">
						<p className="td-name">From</p>
					</div>
					<div className="td">
						<p className="td-name">To</p>
					</div>
					<div className="td td--center">
						<p className="td-name">Convert rate</p>
					</div>
					<div className="td td--center">
						<p className="td-name">Amount (from)</p>
					</div>
					<div className="td td--center">
						<p className="td-name">Amount (to)</p>
					</div>
				</div>
			</div>
			<div className="table-body">
				{loader ? (
					<Loader />
				) : (
					historyAll?.map((history) => (
						<div key={history.id} className="tr">
							<div className="td">
								<p className="td-hidden-name">ID </p>
								<p className="td-value td-value--medium">{history.id}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">User ID</p>

								<Link
									to={String(
										history.user_id > 0
											? `${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USER_INFO}/${String(
													history.user_id,
											  )}`
											: `${ELinksName.WALLETS_TRANSATION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsNameWallet.FIAT}`,
									)}
									className="td-value td-value--medium red"
								>
									{history.user_id}
								</Link>
							</div>
							<div className="td">
								<p className="td-hidden-name">Time and date</p>
								<p className="td-value td-value--medium ">
									{moment(history.created_at).format('DD.MM.YY h:mm:ss')}
								</p>
							</div>
							<div className="td td--center">
								<p className="td-hidden-name">Fee</p>
								<p className="td-value td-value--medium">{history.amount_fee}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">From</p>
								<p className="td-value td-hidden-name-upper td-value--medium">
									{history.asset_id_from === 1 ? 'USDC' : 'USD'}
								</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">To</p>
								<p className="td-value td-hidden-name-upper td-value--medium">
									{history.asset_id_to === 1 ? 'USDC' : 'USD'}
								</p>
							</div>
							<div className="td td--center">
								<p className="td-hidden-name">Convert rate</p>
								<p className="td-value td-value--medium ">
									{(history.rate && history.rate.toFixed(6)) || 0}
								</p>
							</div>
							<div className="td td--center">
								<p className="td-hidden-name">Amount (from)</p>
								<p className="td-value td-value--medium ">{history.amount_from.toFixed()}</p>
							</div>
							<div className="td td--center">
								<p className="td-hidden-name">Amount (to)</p>
								<p className="td-value td-value--medium ">
									{numeral(history.amount_to).format('0,0.00').replaceAll(',', ' ')}
								</p>
							</div>
						</div>
					))
				)}
			</div>
		</div>
	);
};
