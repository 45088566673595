import { FC } from 'react';

export const SvgClose: FC = () => (
	<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.552 15.9478L15.4478 10.052"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.4478 15.9478L9.552 10.052"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.37492 23.4166H15.6249C20.8333 23.4166 22.9166 21.3333 22.9166 16.1249V9.87492C22.9166 4.66659 20.8333 2.58325 15.6249 2.58325H9.37492C4.16659 2.58325 2.08325 4.66659 2.08325 9.87492V16.1249C2.08325 21.3333 4.16659 23.4166 9.37492 23.4166Z"
			stroke="#E41472"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgSave: FC = () => (
	<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.37492 23.4166H15.6249C20.8333 23.4166 22.9166 21.3333 22.9166 16.1249V9.87492C22.9166 4.66659 20.8333 2.58325 15.6249 2.58325H9.37492C4.16659 2.58325 2.08325 4.66659 2.08325 9.87492V16.1249C2.08325 21.3333 4.16659 23.4166 9.37492 23.4166Z"
			stroke="#25A353"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.073 12.9999L11.0209 15.9478L16.9272 10.052"
			stroke="#25A353"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
