import { FC } from 'react';
import L from 'i18n-react';
import parse from 'html-react-parser';

import { IToastifyComponent } from './types';
// ==========================================:
const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps }) => {
	const start = message.indexOf('@') + 1;
	const end = message.indexOf(',');
	const email = message.slice(start, end);

	return (
		<div className="toastify-component">
			<div className="notification__info">
				<div className="notification__title">
					<p>
						{toastProps?.type === 'error' && String(L.translate('error'))}
						{toastProps?.type === 'success' && String(L.translate('successful'))}
						{toastProps?.type === 'info' && String(L.translate('information'))}
					</p>
				</div>
				<div className="notification__text">
					<p>{parse(String(L.translate(message)))}</p>
					{message.includes('@') && (
						<a className="button button--width-auto" href={`https://${email}`}>
							Email
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default ToastifyComponent;
