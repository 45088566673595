import classNames from 'classnames';
import { PopUpDelete } from 'components/UsersManagementComponents/PopUpDelete';
import { PopUpNotDelete } from 'components/UsersManagementComponents/PopUpNotDelete';
import { PopUpSuccess } from 'components/UsersManagementComponents/PopUpSuccess';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import moment from 'moment';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { deleteUserRequest, getBlockedRequest } from 'redux/reducers/usersManagement/reducer';
import { getUserManagement } from 'redux/reducers/usersManagement/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { SvgArrowBackSmall } from 'ui/svg-image/SvgArrowBackSmall';
import numeral from 'numeral';
import wallet from '../../../assets/images/wallet.svg';
import DocumentPopUp from '../DocumentPopup';
import { AddFundsToAccount } from './AddFundsToAccount';

export const FirstTabUserAcount: FC = () => {
	const userInfo = useSelector(getUserManagement);
	const [openDropDown, setOpenDropDown] = useState(false);
	const { innerBorderRef } = useOnOutsideClick(() => setOpenDropDown(false));
	const [isVisible, setIsVisible] = useState<number | null>(null);
	const [isClosePopUp, setIsClosePopUp] = useState<boolean | number>(false);
	const [showFullImage, setShowFullImage] = useState<{ name: string; path: string } | null>(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const d1 = moment(moment(userInfo?.created_at).format('YYYY-MM-DD'));
	const d2 = moment();
	const days = d2.diff(d1, 'days');

	const handleButton = (id: number | null, value?: boolean | number) => {
		setIsVisible(id);
		typeof value === 'boolean' && value && setIsClosePopUp(value);
		typeof value !== 'boolean' && value && setIsClosePopUp(value);
	};

	const closeAllPopUp = () => {
		setIsClosePopUp(false);
	};

	const removeUser = (id: number) => {
		dispatch(deleteUserRequest({ id, isClosePopUp: handleButton, history }));
	};

	const blockedUser = (blocked: number, id: number) => {
		dispatch(
			getBlockedRequest({
				blocked,
				user_id: id,
			}),
		);
		setIsClosePopUp(false);
	};

	const handleKycItemClick = (item: { name: string; path: string }) => {
		setOpenDropDown(!openDropDown);
		setShowFullImage({ path: item.path, name: item.name });
	};

	return (
		userInfo && (
			<>
				{showFullImage && (
					<DocumentPopUp
						path={showFullImage.path}
						name={showFullImage.name}
						closeModal={() => setShowFullImage(null)}
					/>
				)}
				<div className="admin__content">
					<div className="account">
						<div className="account__left">
							<div className="account-form">
								<Link to={ELinksName.USERS_MANAGEMENT} className="back-link back-link--mb20">
									<span className="back-link__arrow">
										<SvgArrowBackSmall />
									</span>
									Back to user management
								</Link>
								<div className="table-title table-title--mb18">
									<p>User account</p>
								</div>
								<div className="input">
									<p className="input-name">First and last name</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{`${userInfo?.name || ''} ${userInfo?.surname || ''}`}</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">Date of birth</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>
												{moment(new Date(userInfo?.dob || '') || '').format('MM.DD.YYYY') || ''}
											</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">Country</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{userInfo?.country || ''}</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">Address</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{userInfo?.address_line1 || ''}</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">Apartment, suite, etc</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{userInfo?.address_line2 || ''}</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">City</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{userInfo?.city || ''}</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">State/Province</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{userInfo?.state || ''}</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">ZIP/Postal code</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{userInfo?.zip_code || ''}</span>
										</div>
									</div>
								</div>

								<div className="input">
									<p className="input-name">Email</p>
									<div className="input-wrapper">
										<div className="input-item input-item-center">
											<span>{userInfo?.email || ''}</span>
										</div>
									</div>
								</div>
								<div className="select-block">
									<p className="select-block__name">KYC info</p>
									<div ref={innerBorderRef} className="select select--big">
										<button
											onClick={() => setOpenDropDown(!openDropDown)}
											type="button"
											className={`select__current select__current${
												userInfo.status === 3 ? '--green' : '--red'
											}`}
										>
											{userInfo.status === 3 ? 'Verified' : 'Unverified'}
											<span className="select__current-arrow select__current-arrow--w25">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M5.30711 9.21299C5.4232 8.93273 5.69668 8.75 6.00002 8.75H18C18.3034 8.75 18.5768 8.93273 18.6929 9.21299C18.809 9.49324 18.7449 9.81583 18.5304 10.0303L12.5304 16.0303C12.2375 16.3232 11.7626 16.3232 11.4697 16.0303L5.46969 10.0303C5.25519 9.81583 5.19103 9.49324 5.30711 9.21299ZM7.81068 10.25L12 14.4393L16.1894 10.25H7.81068Z"
														fill="#25252E"
													/>
												</svg>
											</span>
										</button>
										<div
											className={classNames('select__drop', {
												'select__drop--active': openDropDown,
											})}
										>
											<div className="select__drop-scroll">
												<div className="select__drop-item">
													<ul>
														{userInfo?.kyc_documents?.map((doc) => {
															return (
																<li key={doc.path}>
																	<button
																		onClick={() =>
																			handleKycItemClick({ path: doc.path, name: doc.name })
																		}
																		type="button"
																	>
																		{doc.name}
																	</button>
																</li>
															);
														})}
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="account__right">
							<div className="new-user">
								<div className="new-user__info">
									<h3 className="new-user__title">
										{days === 0
											? 'New User'
											: `Last login: ${moment(userInfo.last_activity || '').format(
													'DD.MM.YY h:mm:ss',
											  )}`}
									</h3>
									<p className="new-user__date">
										{`sign up date: ${moment(userInfo.created_at || '').format(
											'DD.MM.YY h:mm:ss',
										)}`}
									</p>
								</div>
								<div className="new-user__wallet">
									<span className="new-user__wallet-icon">
										<img src={wallet} alt="" />
									</span>
									<p className="new-user__wallet-title">Funds user owns:</p>
									<p className="new-user__wallet-number">
										{userInfo?.balances.length
											? `${numeral(userInfo?.balances[0].total_balance)
													.format('0,0.00')
													.replaceAll(',', ' ')} USDC`
											: '0 USDC'}
									</p>
									<p className="new-user__wallet-number">
										{userInfo?.balances.length
											? `${numeral(userInfo?.balances[userInfo.balances.length - 1].total_balance)
													.format('0,0.00')
													.replaceAll(',', ' ')} USD`
											: '0 USD'}
									</p>
								</div>

								<AddFundsToAccount userId={userInfo?.id} />

								<div className="new-user__actions-group">
									<div className="new-user__actions">
										<p className="new-user__actions-title">Actions</p>
										<div className="table-buttons table-buttons--center table-buttons--bigger">
											<button
												onClick={() => userInfo.blocked === 0 && blockedUser(1, userInfo.id)}
												type="button"
												className="active"
											>
												<svg
													width="24"
													height="25"
													viewBox="0 0 24 25"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M12 13.5C11.7348 13.5 11.4804 13.6054 11.2929 13.7929C11.1054 13.9804 11 14.2348 11 14.5V17.5C11 17.7652 11.1054 18.0196 11.2929 18.2071C11.4804 18.3946 11.7348 18.5 12 18.5C12.2652 18.5 12.5196 18.3946 12.7071 18.2071C12.8946 18.0196 13 17.7652 13 17.5V14.5C13 14.2348 12.8946 13.9804 12.7071 13.7929C12.5196 13.6054 12.2652 13.5 12 13.5ZM17 9.5V7.5C17 6.17392 16.4732 4.90215 15.5355 3.96447C14.5979 3.02678 13.3261 2.5 12 2.5C10.6739 2.5 9.40215 3.02678 8.46447 3.96447C7.52678 4.90215 7 6.17392 7 7.5V9.5C6.20435 9.5 5.44129 9.81607 4.87868 10.3787C4.31607 10.9413 4 11.7044 4 12.5V19.5C4 20.2956 4.31607 21.0587 4.87868 21.6213C5.44129 22.1839 6.20435 22.5 7 22.5H17C17.7956 22.5 18.5587 22.1839 19.1213 21.6213C19.6839 21.0587 20 20.2956 20 19.5V12.5C20 11.7044 19.6839 10.9413 19.1213 10.3787C18.5587 9.81607 17.7956 9.5 17 9.5ZM9 7.5C9 6.70435 9.31607 5.94129 9.87868 5.37868C10.4413 4.81607 11.2044 4.5 12 4.5C12.7956 4.5 13.5587 4.81607 14.1213 5.37868C14.6839 5.94129 15 6.70435 15 7.5V9.5H9V7.5ZM18 19.5C18 19.7652 17.8946 20.0196 17.7071 20.2071C17.5196 20.3946 17.2652 20.5 17 20.5H7C6.73478 20.5 6.48043 20.3946 6.29289 20.2071C6.10536 20.0196 6 19.7652 6 19.5V12.5C6 12.2348 6.10536 11.9804 6.29289 11.7929C6.48043 11.6054 6.73478 11.5 7 11.5H17C17.2652 11.5 17.5196 11.6054 17.7071 11.7929C17.8946 11.9804 18 12.2348 18 12.5V19.5Z"
														fill={userInfo.blocked ? '#C51918' : '#000000'}
													/>
												</svg>
											</button>
											<button
												onClick={() => userInfo.blocked === 1 && blockedUser(0, userInfo.id)}
												type="button"
												className="active"
											>
												<svg
													width="24"
													height="25"
													viewBox="0 0 24 25"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M12 13.5009C11.7348 13.5009 11.4804 13.6063 11.2929 13.7938C11.1054 13.9813 11 14.2357 11 14.5009V17.5009C11 17.7661 11.1054 18.0205 11.2929 18.208C11.4804 18.3955 11.7348 18.5009 12 18.5009C12.2652 18.5009 12.5196 18.3955 12.7071 18.208C12.8946 18.0205 13 17.7661 13 17.5009V14.5009C13 14.2357 12.8946 13.9813 12.7071 13.7938C12.5196 13.6063 12.2652 13.5009 12 13.5009ZM17 9.5009H9V7.5009C8.99854 6.90708 9.17334 6.3262 9.50226 5.8318C9.83118 5.3374 10.2994 4.95174 10.8477 4.72366C11.3959 4.49557 11.9996 4.43532 12.5821 4.55054C13.1646 4.66576 13.6999 4.95127 14.12 5.3709C14.4959 5.75496 14.7649 6.23072 14.9 6.7509C14.9328 6.87828 14.9904 6.99795 15.0695 7.10307C15.1486 7.20819 15.2476 7.29671 15.3609 7.36357C15.4742 7.43043 15.5995 7.47432 15.7298 7.49273C15.86 7.51115 15.9926 7.50373 16.12 7.4709C16.2474 7.43807 16.3671 7.38047 16.4722 7.30139C16.5773 7.22231 16.6658 7.1233 16.7327 7.01001C16.7995 6.89673 16.8434 6.77138 16.8618 6.64113C16.8802 6.51088 16.8728 6.37828 16.84 6.2509C16.6122 5.3857 16.1603 4.59583 15.53 3.9609C14.8302 3.26326 13.9393 2.78863 12.97 2.59694C12.0006 2.40525 10.9961 2.50509 10.0835 2.88385C9.17078 3.26262 8.3908 3.90333 7.84201 4.72507C7.29321 5.54681 7.00021 6.51275 7 7.5009V9.5009C6.20435 9.5009 5.44129 9.81697 4.87868 10.3796C4.31607 10.9422 4 11.7052 4 12.5009V19.5009C4 20.2965 4.31607 21.0596 4.87868 21.6222C5.44129 22.1848 6.20435 22.5009 7 22.5009H17C17.7956 22.5009 18.5587 22.1848 19.1213 21.6222C19.6839 21.0596 20 20.2965 20 19.5009V12.5009C20 11.7052 19.6839 10.9422 19.1213 10.3796C18.5587 9.81697 17.7956 9.5009 17 9.5009ZM18 19.5009C18 19.7661 17.8946 20.0205 17.7071 20.208C17.5196 20.3955 17.2652 20.5009 17 20.5009H7C6.73478 20.5009 6.48043 20.3955 6.29289 20.208C6.10536 20.0205 6 19.7661 6 19.5009V12.5009C6 12.2357 6.10536 11.9813 6.29289 11.7938C6.48043 11.6063 6.73478 11.5009 7 11.5009H17C17.2652 11.5009 17.5196 11.6063 17.7071 11.7938C17.8946 11.9813 18 12.2357 18 12.5009V19.5009Z"
														fill={userInfo.blocked ? '#000000' : '#25A353'}
													/>
												</svg>
											</button>
											<button
												onClick={() => handleButton(userInfo.id)}
												type="button"
												className="active"
											>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z"
														fill="#000000"
													/>
												</svg>
											</button>
										</div>
									</div>
									<div className="new-user__actions">
										<p className="new-user__actions-title">Whitelist</p>
										<div className="table-buttons table-buttons--center table-buttons--bigger">
											<button type="button" className="active">
												<svg
													width="25"
													height="25"
													viewBox="0 0 25 25"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M12.7144 2.5C10.7365 2.5 8.80315 3.08649 7.15866 4.1853C5.51417 5.28412 4.23244 6.8459 3.47556 8.67317C2.71869 10.5004 2.52065 12.5111 2.90651 14.4509C3.29236 16.3907 4.24477 18.1725 5.64329 19.5711C7.04182 20.9696 8.82365 21.922 10.7635 22.3079C12.7033 22.6937 14.7139 22.4957 16.5412 21.7388C18.3685 20.9819 19.9302 19.7002 21.0291 18.0557C22.1279 16.4112 22.7144 14.4778 22.7144 12.5C22.7144 11.1868 22.4557 9.88642 21.9532 8.67317C21.4506 7.45991 20.714 6.35752 19.7854 5.42893C18.8568 4.50035 17.7545 3.76375 16.5412 3.2612C15.3279 2.75866 14.0276 2.5 12.7144 2.5ZM12.7144 20.5C11.1321 20.5 9.58539 20.0308 8.2698 19.1518C6.9542 18.2727 5.92882 17.0233 5.32332 15.5615C4.71782 14.0997 4.5594 12.4911 4.86808 10.9393C5.17676 9.38743 5.93869 7.96197 7.05751 6.84315C8.17633 5.72433 9.60179 4.9624 11.1536 4.65372C12.7055 4.34504 14.314 4.50346 15.7758 5.10896C17.2376 5.71447 18.4871 6.73984 19.3661 8.05544C20.2452 9.37103 20.7144 10.9177 20.7144 12.5C20.7144 14.6217 19.8715 16.6566 18.3712 18.1569C16.8709 19.6571 14.8361 20.5 12.7144 20.5ZM16.7144 11.5H13.7144V8.5C13.7144 8.23478 13.609 7.98043 13.4215 7.79289C13.2339 7.60536 12.9796 7.5 12.7144 7.5C12.4491 7.5 12.1948 7.60536 12.0073 7.79289C11.8197 7.98043 11.7144 8.23478 11.7144 8.5V11.5H8.71436C8.44914 11.5 8.19479 11.6054 8.00725 11.7929C7.81972 11.9804 7.71436 12.2348 7.71436 12.5C7.71436 12.7652 7.81972 13.0196 8.00725 13.2071C8.19479 13.3946 8.44914 13.5 8.71436 13.5H11.7144V16.5C11.7144 16.7652 11.8197 17.0196 12.0073 17.2071C12.1948 17.3946 12.4491 17.5 12.7144 17.5C12.9796 17.5 13.2339 17.3946 13.4215 17.2071C13.609 17.0196 13.7144 16.7652 13.7144 16.5V13.5H16.7144C16.9796 13.5 17.2339 13.3946 17.4215 13.2071C17.609 13.0196 17.7144 12.7652 17.7144 12.5C17.7144 12.2348 17.609 11.9804 17.4215 11.7929C17.2339 11.6054 16.9796 11.5 16.7144 11.5Z"
														fill="#25A353"
													/>
												</svg>
											</button>
											<button type="button" className="active">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z"
														fill="#000000"
													/>
												</svg>
											</button>
										</div>
									</div>
								</div>
								<div className="new-user__actions">
									<p className="new-user__actions-title new-user__actions-title--mb20">
										Turn on verified status
									</p>
									<div className="user-checkbox">
										<label>
											<input className="hidden" type="checkbox" />
											<span className="checkbox-item" />
										</label>
										<p className="user-checkbox__status">Unverified</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					{isVisible && (
						<PopUpDelete
							isVisible={isVisible}
							handleButton={handleButton}
							removeUser={removeUser}
						/>
					)}
					{typeof isClosePopUp === 'boolean' && isClosePopUp && (
						<PopUpSuccess isClosePopUp={setIsClosePopUp} />
					)}
					{typeof isClosePopUp !== 'boolean' && isClosePopUp && (
						<PopUpNotDelete
							isClosePopUp={isClosePopUp}
							blockedUser={blockedUser}
							closeAllPopUp={closeAllPopUp}
						/>
					)}
				</div>
			</>
		)
	);
};
