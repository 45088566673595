import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	I2faSmsRequest,
	IChangeEmailRequest,
	ICheckPassRequest,
	ICreatePasswordFormData,
	IDisable2faPayload,
	IEditUserRequest,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	IGetSmsRequest,
	ISmsCodeResponse,
} from 'redux/reducers/settings/types';
import { ISettingsApi } from './types';

export const settings: ISettingsApi = {
	editUser: (payload: IEditUserRequest) => http.post(endpoint.settings.EDIT_USER, payload),
	changeEmail: (payload: IChangeEmailRequest) => http.post(endpoint.settings.CHANGE_EMAIL, payload),
	changePassword: (payload) =>
		http.post<ICreatePasswordFormData>(endpoint.settings.CHANGE_PASSWORD, payload),
	resetEmail: (payload) => http.post(endpoint.settings.EMAIL_RESET, payload),

	generate2faKey: () =>
		http
			.get<IGenerate2faKeyResponse>(endpoint.settings.GENERATE_2FA_KEY)
			.then((response) => response.data),

	enable2fa: (payload) => http.post<IEnable2faPayload>(endpoint.settings.ENABLE_2FA, payload),

	disable2fa: (payload) => http.post<IDisable2faPayload>(endpoint.settings.DISABLE_2FA, payload),

	enable2faSms: (payload: I2faSmsRequest) =>
		http.post<IEnable2faPayload>(endpoint.settings.ENABLE_SMS_2FA, payload),

	disable2faSms: (payload: I2faSmsRequest) =>
		http.post<IDisable2faPayload>(endpoint.settings.DISABLE_SMS_2FA, payload),

	getSmsCode: (payload: IGetSmsRequest) =>
		http.post<ISmsCodeResponse>(endpoint.settings.GET_SMS_CODE, payload),

	getSmsCodePhone: () => http.post<ISmsCodeResponse>(endpoint.settings.GET_SMS_CODE_PHONE),

	getSmsCode2faLogin: (payload: IGetSmsRequest) =>
		http.post<ISmsCodeResponse>(endpoint.settings.GET_SMS_CODE_LOGIN, payload),
	checkPassword: (payload: ICheckPassRequest) =>
		http.post(endpoint.settings.CHECK_PASSWORD, payload),
};
