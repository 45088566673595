import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IDividendsHistory,
	IDividendsManagementState,
	IDividendsResponse,
	IDividendsSum,
} from './types';

const getDividendsManagementState = (state: IStoreState): IDividendsManagementState =>
	state.dividendsManagement;

export const getDevidends = createSelector(
	[getDividendsManagementState],
	(dividendsManagement: IDividendsManagementState): IDividendsResponse | null =>
		dividendsManagement.dividends,
);

export const getDevidendsLoader = createSelector(
	[getDividendsManagementState],
	(dividendsManagement: IDividendsManagementState): boolean => dividendsManagement.dividendsLoader,
);

export const getDividendsSumSelector = createSelector(
	[getDividendsManagementState],
	(dividendsManagement: IDividendsManagementState): IDividendsSum | null =>
		dividendsManagement.dividendsSum,
);

export const getDevidendsHistory = createSelector(
	[getDividendsManagementState],
	(dividendsManagement: IDividendsManagementState): IDividendsHistory | null =>
		dividendsManagement.dividendsHistory,
);
