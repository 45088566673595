/* eslint-disable for-direction */
/* eslint-disable no-plusplus */
export const createArray = (start: number, end: number) => {
	const array = [];

	for (let i = end; i >= start; i--) {
		array.push(i);
	}

	return array;
};
