import { FC } from 'react';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { getLoader } from 'redux/reducers/wallets/selectors';
import { IPropsPopUpError } from '../types';

export const PopUpError: FC<IPropsPopUpError> = ({ dataForm, closePopUp, submitDepositFiat }) => {
	const loader = useSelector(getLoader);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--type2 popup--huge popup--center popup--height-500">
					<button onClick={closePopUp} className="popup__close" type="button">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								strokeWidth="5"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#25252E"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#25252E"
							/>
						</svg>
					</button>
					<div className="popup-body">
						<div className="popup-alert">
							<p className="popup-title">Transaction failed to process</p>
							<div className="button-wrapper">
								<button
									onClick={() => submitDepositFiat(dataForm)}
									className="button button--long"
									type="button"
								>
									{loader ? (
										<div className="louder-wrapper-custom">
											<Oval secondaryColor="grey" color="#E41472" height={30} width={30} />
										</div>
									) : (
										'Try again'
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
