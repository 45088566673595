/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, MouseEvent, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { validationSchema } from 'untils/formValidation';
import { IPropertyRequest } from 'redux/reducers/propertyManagement/types';
import { EDataPicker } from 'services/constants/status';
import { useHistory, useLocation, useParams } from 'react-router';
import moment from 'moment';
import ErrorMsg from 'components/ErrorMsg';
import { PopUpAddDocument } from 'components/PopUpAddDocument';
import { PopUpCreateDocument } from 'components/PopUpCreateDocument';
import { useDispatch, useSelector } from 'react-redux';
import {
	getIdProperty,
	getPublishPropertyLoader,
	getSavePropertyLoader,
} from 'redux/reducers/propertyManagement/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { Link } from 'react-router-dom';
import { PreviewProperty } from 'components/PropertyManagementComponents/PreviewProperty';
import { publishBtn } from 'services/constants/name-button';
import { ImagesAll } from 'components/PropertyManagementComponents/PreviewProperty/PhotosGallery/ImagesAll';
import {
	getPropertySuccess,
	postUnpublishRequest,
} from 'redux/reducers/propertyManagement/reducer';
import { IPropertyForm } from './types';
import { SmallLoader } from '../../SmallLoader';
import { PhotosGallery } from './PhotosGallery';
import { AddDescription, IValues } from './AddDescription';
import { IValuesDate, NewPropertyDate } from './NewPropertyDate';
import { IValuesPropertyAddress, NewPropertyAddress } from './NewPropertyAddress';
import { IValuesPropertyDividends, PropertyDividends } from './PropertyDividends';
// import { DropDownType } from './DropDownType';
// import { AddPrice } from './AddPrice';

export const PropertyForm: FC<IPropertyForm> = ({
	propertySubmit,
	property,
	handleSave,
	unpublish,
}) => {
	const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);
	const [dropType, setDropType] = useState(false);
	const idProperty = useSelector(getIdProperty);
	const dispatch = useDispatch();
	const [dropState, setDropState] = useState(false);
	const [dropCounries, setDropCountries] = useState(false);
	const { pathname } = useLocation();
	const param: { id: string } = useParams();
	const [images, setImages] = useState<Array<{ [key: string]: string | number }>>([]);
	const [documents, setDocuments] = useState<Array<{ [key: string]: string | number }>>([]);
	const [size, setSize] = useState<number>(0);
	const [amount, setAmount] = useState<number>(0);
	const [count, setCount] = useState<number>(0);
	const [imgNumber, setImgNumber] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isOpenYear, setIsOpenYear] = useState<boolean>(false);
	const [addDocFirstPopUp, setAddDocFirstPopUp] = useState(false);
	const [addDocSecondPopUp, setAddDocSecondPopUp] = useState(false);
	const [namesDoc, setNamesDoc] = useState<Array<string>>([]);
	const [base64Docs, setBase64Docs] = useState<Array<string>>([]);
	const saveLoader = useSelector(getSavePropertyLoader);
	const publishLoader = useSelector(getPublishPropertyLoader);
	const [check, setCheck] = useState<string>('');
	const [indexOld, setIndexOld] = useState<number | null>(null);
	const history = useHistory();

	const isOpenPopUpFirstDoc = () => {
		setAddDocFirstPopUp(!addDocFirstPopUp);
	};

	const isOpenPopUpSecondDoc = () => {
		setAddDocFirstPopUp(false);
		setAddDocSecondPopUp(!addDocSecondPopUp);
	};

	const cleanUp = () => {
		setNamesDoc([]);
		setBase64Docs([]);
	};

	const onUnpublish = () => {
		property?.id &&
			dispatch(
				postUnpublishRequest({
					params: {
						estate_id: property.id,
						status: 4,
					},
					history,
				}),
			);
	};

	const handleClick = (e: MouseEvent) => {
		e.preventDefault();
		setIsOpen(!isOpen);
	};

	const handleClickYearBuild = (e: MouseEvent) => {
		e.preventDefault();
		setIsOpenYear(!isOpenYear);
	};

	const changeName = (name: string, i: number) => {
		setNamesDoc(
			namesDoc.map((doc, index) => {
				if (index === i) return name;
				return doc;
			}),
		);
	};

	const initialValues = {
		bathroom_number: Number(property?.bathroom_number) || '',
		bedroom_number: property?.bedroom_number || '',
		city: property?.city || '',
		country: property?.country || '',
		date_expected_dividends:
			(property?.date_expected_dividends && new Date(property?.date_expected_dividends)) || '',
		date_launch: (property?.date_launch && new Date(property?.date_launch || '')) || '',
		date_listing: (property?.date_listing && new Date(property?.date_listing || '')) || '',
		date_token_unlock:
			(property?.date_token_unlock && new Date(property?.date_token_unlock || '')) || '',
		description: property?.description || '',
		dividends_period: property?.dividends_period || '',
		documents: [],
		expected_expenses: Number(property?.expected_expenses) || '',
		expected_profit: Number(property?.expected_profit) || '',
		id: param.id || idProperty || '',
		images: null,
		index: property?.index || '',
		lattitude: property?.lattitude || '',
		llc_series: property?.llc_series || '',
		longitude: property?.longitude || '',
		lot_size: property?.lot_size || '',
		price: Number(property?.price) || '',
		square_meters: Number(property?.square_meters) || '',
		state: property?.state || '',
		street: property?.street || '',
		ticker_symbol: property?.ticker_symbol || '',
		title: property?.title || '',
		token_sales_scale: property?.token_sales_scale || 0,
		tokens_number: Number(property?.tokens_number) || '',
		type: property?.type || '',
		year_built: property?.year_built || '',
	};

	useEffect(() => {
		// pathname.includes('post-new-property') && dispatch(createPropertyRequest());

		setImages(
			property?.images.map((img) => {
				return { url: img.url, name: img.name, id: img.id, sort: img.sort, thumb: img.thumb };
			}) || [],
		);
		setDocuments(property?.documents || []);
	}, [property, dispatch]);

	if (Math.ceil(count / (amount / 100)) === 100) {
		setAmount(0);
		setCount(0);
	}

	useEffect(() => {
		// dispatch(getTokenPriceRequest());

		return () => {
			dispatch(getPropertySuccess(null));
		};
	}, []);

	let submitAction: string | undefined;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IPropertyRequest, actions) => {
				const data = {
					...values,
					date_listing: values.date_listing
						? moment(values.date_listing as Date).format('YY-MM-DD')
						: null,
					documents,
					dividends_period: Number(values.dividends_period),
					title: values?.title || '',
					ticker_symbol: values?.ticker_symbol || '',
					llc_series: values?.llc_series || '',
					bedroom_number: values?.bedroom_number || '',
					bathroom_number: Number(values?.bathroom_number) || '',
					square_meters: Number(values?.square_meters) || '',
					description: values?.description.replace(/\n/g, '<br/>') || '',
					price: Number(values?.price) || '',
					tokens_number: Number(values?.tokens_number) || '',
					type: values?.type || '',
					country: values?.country || '',
					city: values?.city || '',
					state: values.country.toLowerCase() === 'united states of america' ? values?.state : '',
					index: values?.index || '',
					lattitude: values?.lattitude || '',
					longitude: values?.longitude || '',
					id: param.id || idProperty || '',
				};

				if (values.date_token_unlock) {
					data.date_token_unlock = moment(values.date_token_unlock as Date).format('YYYY-MM-DD');
				} else {
					delete data.date_token_unlock;
				}

				if (values.year_built) {
					data.year_built = moment(values.year_built as Date).format('YYYY-MM-DD');
				} else {
					delete data.year_built;
				}

				if (values.date_expected_dividends) {
					data.date_expected_dividends = moment(values.date_expected_dividends as Date).format(
						'YY-MM-DD',
					);
				} else {
					delete data.date_expected_dividends;
				}

				if (values.date_launch) {
					data.date_launch = moment(values.date_launch as Date).format('YY-MM-DD');
				} else {
					delete data.date_launch;
				}

				if (values.street) {
					data.street = values.street;
				} else {
					delete data.street;
				}

				if (values.expected_profit) {
					data.expected_profit = Number(values?.expected_profit);
				} else {
					delete data.expected_profit;
				}

				if (values.expected_expenses) {
					data.expected_expenses = Number(values?.expected_expenses);
				} else {
					delete data.expected_expenses;
				}

				propertySubmit(data, images, submitAction);
				actions.setSubmitting(false);
				// resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({
				handleSubmit,
				isSubmitting,
				isValid,
				dirty,
				values,
				setFieldValue,
				handleBlur,
				errors,
			}) => {
				const openModal = (e: MouseEvent) => {
					const target = e.currentTarget as HTMLButtonElement;
					setDropType(!dropType);
					if (target.name) {
						setFieldValue('type', +target.name);
					}
				};

				const openModalState = (e: MouseEvent) => {
					const target = e.currentTarget as HTMLButtonElement;
					!target.name && setDropState(!dropState);
					target.name && setFieldValue('state', target.name);
				};

				const openModalCountries = (e: MouseEvent) => {
					const target = e.currentTarget as HTMLButtonElement;
					!target.name && setDropCountries(!dropCounries);
					target.name && setFieldValue('country', target.name);
				};

				const inputFile = (filesData: FileList) => {
					let file;
					setFieldValue('images', []);
					setFieldValue('images', filesData);
					setAmount(filesData.length);
					// eslint-disable-next-line no-plusplus
					for (let i = 0; i < filesData.length; i++) {
						const reader = new FileReader();
						file = filesData[i];

						const numberSize: number = file.size / 1048576;
						setSize((prev) => numberSize + prev);
						reader.onload = () => {
							if (!images.find((img) => img.url === (reader.result as string))) {
								setImages((prev) => [
									...prev,
									{
										file: reader.result as string,
										url: reader.result as string,
										main_image: 0,
									},
								]);
							}
							setCount((prev) => prev + 1);
						};
						reader.readAsDataURL(file);
					}
				};

				const docFile = (filesData: FileList) => {
					let file;
					setFieldValue('documents', []);
					setFieldValue('documents', filesData);
					for (let i = 0; i < filesData.length; i += 1) {
						const nameKey: File = filesData[i];
						file = filesData[i];

						setNamesDoc((prev) => [...prev, nameKey.name]);

						const reader = new FileReader();
						reader.onload = () => {
							setBase64Docs((prev) => [...prev, reader.result as string]);
						};
						// @ts-ignore
						reader.readAsDataURL(file);
					}
				};

				const removeDoc = (index: number) => {
					setBase64Docs(base64Docs.filter((doc, i) => i !== index));
					setNamesDoc(namesDoc.filter((doc, i) => i !== index));
					// !base64Docs.length && setAddDocSecondPopUp(false);
					base64Docs.length === 1 && setAddDocSecondPopUp(false);
					setAddDocFirstPopUp(true);
				};

				const docFileAll = () => {
					// if (!documents.find((el) => base64Docs.find((doc) => el.file === doc))) {
					base64Docs.forEach((doc, index) => {
						setDocuments((prev) => [
							...prev,
							{ title: namesDoc[index], file: doc, need_signature: 0, need_confirm: 1 },
						]);
					});
					// }

					setBase64Docs([]);
					setNamesDoc([]);
					setAddDocSecondPopUp(false);
				};

				const removeImage = (value: string, num: number) => {
					const fileListArr = Array.from(String(values.images));
					setFieldValue(
						'images',
						fileListArr.filter((file, index) => index !== num),
					);
					setImages(
						images.filter((img) => {
							return img.url !== value;
						}),
					);
					setFieldValue('images', []);
				};

				const removeDocument = (id: number) => {
					setDocuments(
						documents.filter((doc, index) => {
							return index !== id;
						}),
					);
					setFieldValue(
						'documents',
						documents.filter((doc, index) => {
							return index !== id;
						}),
					);
				};

				const dateOfMonth = (d: Date, name?: string) => {
					EDataPicker.LISTING === name && setFieldValue('date_listing', d);
					EDataPicker.LAUNCH === name && setFieldValue('date_launch', d);
					EDataPicker.UNLOCK === name && setFieldValue('date_token_unlock', d);
				};

				const dateDividends = (d: Date) => {
					setFieldValue('date_expected_dividends', d);
					setIsOpen(!isOpen);
				};

				const onYearBuilt = (d: Date) => {
					setFieldValue('year_built', d);
					setIsOpenYear(!isOpenYear);
				};

				const addedSignature = (name: string, index: number) => {
					((indexOld !== index && check === name) || check !== name) &&
						setDocuments(
							documents.map((doc, i) => {
								if (i === index && (check || doc[name] === 0)) {
									return {
										...doc,
										[name]: doc[name] === 0 ? 1 : 1,
										[name === 'need_signature' ? 'need_confirm' : 'need_signature']: 0,
									};
								}
								return doc;
							}),
						);
					setCheck(name);
					setIndexOld(index);
				};

				const onSave = () => {
					const data: IPropertyRequest = {
						...values,
						date_listing: values.date_listing
							? moment(values.date_listing as Date).format('YY-MM-DD')
							: null,
						documents,
						dividends_period: Number(values.dividends_period),
						title: values?.title || '',
						ticker_symbol: values?.ticker_symbol || '',
						llc_series: values?.llc_series || '',
						bedroom_number: values?.bedroom_number || '',
						bathroom_number: Number(values?.bathroom_number) || '',
						square_meters: Number(values?.square_meters) || '',
						description: values?.description || '',
						price: Number(values?.price) || '',
						tokens_number: Number(values?.tokens_number) || '',
						type: values?.type || '',
						country: values?.country || '',
						city: values?.city || '',
						state: values.country.toLowerCase() === 'united states of america' ? values?.state : '',
						index: values?.index || '',
						lattitude: values?.lattitude || '',
						longitude: values?.longitude || '',
						id: param.id || idProperty || '',
						images,
					};

					if (values.date_token_unlock) {
						data.date_token_unlock = moment(values.date_token_unlock as Date).format('YYYY-MM-DD');
					} else {
						delete data.date_token_unlock;
					}

					if (values.year_built) {
						data.year_built = moment(values.year_built as Date).format('YYYY-MM-DD');
					} else {
						delete data.year_built;
					}

					if (values.date_expected_dividends) {
						data.date_expected_dividends = moment(values.date_expected_dividends as Date).format(
							'YY-MM-DD',
						);
					} else {
						delete data.date_expected_dividends;
					}

					if (values.date_launch) {
						data.date_launch = moment(values.date_launch as Date).format('YY-MM-DD');
					} else {
						delete data.date_launch;
					}

					if (values.street) {
						data.street = values.street;
					} else {
						delete data.street;
					}

					if (values.expected_profit) {
						data.expected_profit = Number(values?.expected_profit);
					} else {
						delete data.expected_profit;
					}

					if (values.expected_expenses) {
						data.expected_expenses = Number(values?.expected_expenses);
					} else {
						delete data.expected_expenses;
					}
					handleSave(data, images);
				};

				return isOpenPreview ? (
					<PreviewProperty
						documents={documents}
						setIsOpenPreview={setIsOpenPreview}
						values={values}
						images={images}
					/>
				) : (
					<Form className="new-property">
						{imgNumber ? (
							<ImagesAll
								setImages={setImages}
								images={images as { [key: string]: string }[]}
								setOpenAllPhoto={setImgNumber}
							/>
						) : (
							<>
								<div className="new-property__title">
									{pathname.includes('edit-property') ? 'Edit property' : 'Post new property'}
								</div>
								<div className="new-property-info">
									<div className="property-input">
										<div className="input">
											<div className="input-wrapper">
												<Field
													name="title"
													required
													maxLength={50}
													className="input-item input-custom-pading"
													type="text"
													placeholder="Enter the title of real estate"
													value={values.title}
												/>
												<span className="input-coin input-lenght">{`${values.title.length}/50`}</span>
											</div>
											{errors?.title ? <ErrorMsg msg={errors.title} /> : null}
										</div>
									</div>

									<div className="property-input">
										<div className="input">
											<div className="input-name">Ticker Symbol</div>
											<div className="input-wrapper">
												<Field
													name="ticker_symbol"
													maxLength={50}
													className="input-item input-custom-pading"
													type="text"
													placeholder="Enter the Ticker Symbol"
													value={values.ticker_symbol}
												/>
											</div>
											{errors?.ticker_symbol ? <ErrorMsg msg={errors.ticker_symbol} /> : null}
										</div>
									</div>

									<div className="property-input">
										<div className="input">
											<div className="input-name">LLC Series Number and Name</div>
											<div className="input-wrapper">
												<Field
													name="llc_series"
													maxLength={50}
													className="input-item input-custom-pading"
													type="text"
													placeholder="Enter the LLC Series Number and Name"
													value={values.llc_series}
												/>
											</div>
											{errors?.llc_series ? <ErrorMsg msg={errors.llc_series} /> : null}
										</div>
									</div>
									<PhotosGallery
										count={count}
										amount={amount}
										inputFile={inputFile}
										images={images}
										size={size}
										setImgNumber={setImgNumber}
										removeImage={removeImage}
									/>
									<div className="category-title">
										<p className="category-title__item">Add description</p>
									</div>
									<AddDescription
										values={values as IValues}
										errors={errors as IValues}
										openModal={openModal}
										setDropType={setDropType}
										dropType={dropType}
										onYearBuilt={onYearBuilt}
										isOpenYear={isOpenYear}
										handleBlur={handleBlur}
										handleClick={handleClickYearBuild}
									/>
								</div>
								<NewPropertyDate
									handleBlur={handleBlur}
									errors={errors as IValuesDate}
									values={values as IValuesDate}
									dateOfMonth={dateOfMonth}
								/>

								<div className="new-property-info">
									<div className="category-title">
										<p className="category-title__item">Tokens sales scale (in %)</p>
									</div>
									<div className="property-input-grid">
										<div className="input-group">
											<div className="input">
												<div className="input-name">Token path</div>
												<div className="input-wrapper">
													<Field
														name="token_sales_scale"
														className="input-item"
														type="range"
														min={0}
														max={100}
														placeholder="Enter token path"
														value={+values.token_sales_scale || 0}
													/>
													<span>{+values.token_sales_scale || 0}%</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="new-property-info">
									<div className="category-title">
										<p className="category-title__item">Add price</p>
									</div>
									<div className="property-input-grid">
										<div className="input-group">
											<div className="input">
												<div className="input-name">Total price of the real estate</div>
												<div className="input-wrapper">
													<Field
														name="price"
														className="input-item"
														type="text"
														placeholder="Enter total price of the real estate"
														value={+values.price || ''}
													/>
													<span className="input-coin">USD</span>
												</div>
												{errors?.price ? <ErrorMsg msg={errors?.price} /> : null}
											</div>
											<div className="input">
												<div className="input-name">Amount of tokens will be issued</div>
												<div className="input-wrapper">
													<Field
														name="tokens_number"
														className="input-item"
														type="text"
														placeholder="Amount of tokens will be issued"
														value={values.price && (+values.price / 100 || '')}
													/>
												</div>
												{/* {errors?.tokens_number ? <ErrorMsg msg={errors?.tokens_number} /> : null} */}
											</div>
										</div>
									</div>
									{/* <AddPrice /> */}
								</div>
								{/* <DropDownType
									setDropType={setDropType}
									dropType={dropType}
									values={values.type as number}
									openModal={openModal}
									errors={errors.type}
								/> */}
								<NewPropertyAddress
									values={values as IValuesPropertyAddress}
									errors={errors as IValuesPropertyAddress}
									setDropState={setDropState}
									setDropCountries={setDropCountries}
									openModalState={openModalState}
									openModalCountries={openModalCountries}
									dropState={dropState}
									dropCounries={dropCounries}
								/>
								<PropertyDividends
									handleClick={handleClick}
									dateDividends={dateDividends}
									errors={errors as IValuesPropertyDividends}
									values={values as IValuesPropertyDividends}
									isOpen={isOpen}
									handleBlur={handleBlur}
								/>
								<div className="legal-documents">
									<div className="category-title">
										<p className="category-title__item">Legal documents</p>
										<button
											onClick={isOpenPopUpFirstDoc}
											type="button"
											className="button button--long button--container"
										>
											+ add more documents
										</button>
									</div>
									<div className="legal-documents-list">
										{documents.map((doc, index) => (
											<div
												key={String(index) + String(doc.file)}
												className="legal-documents-list-item"
											>
												<button
													onClick={() => removeDocument(index)}
													type="button"
													className="legal-documents-list__link legal-documents-list__link-custom"
													title={String(doc.title)}
												>
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z"
															fill="#C51918"
														/>
													</svg>
													{String(doc.title).length >= 30
														? `${String(doc.title).slice(0, 30)} ...`
														: doc.title}
												</button>
												<div className="radio">
													<label className="radio__label">
														<Field
															onClick={() => addedSignature('need_signature', index)}
															className="hidden"
															name={String(doc.file) + String(index)}
															type="radio"
															checked={doc.need_signature}
														/>
														<span className="radio__text">Need signature</span>
														<span className="radio__item" />
													</label>
												</div>
												<div className="radio radio--position-right">
													<label className="radio__label">
														<Field
															onClick={() => addedSignature('need_confirm', index)}
															className="hidden"
															name={String(index) + String(doc.file)}
															type="checkbox"
															checked={doc.need_confirm}
														/>
														<span className="radio__text">Needs to view and confirm</span>
														<span className="radio__item" />
													</label>
												</div>
											</div>
										))}
									</div>
									<div className="legal-documents-button">
										<Link
											to={ELinksName.PROPERTY_MANAGEMENT}
											className="button button--full-width button--type7"
										>
											Cancel
										</Link>
										<button
											onClick={() => setIsOpenPreview(true)}
											disabled={
												!(isValid && dirty) ||
												isSubmitting ||
												(values.country.toLowerCase() === 'united states of america' &&
													!values.state)
											}
											type="button"
											className={`button button--full-width ${
												!(isValid && dirty) || isSubmitting ? 'button--type7' : 'button--type4'
											}`}
										>
											Preview
										</button>
										<button
											name={publishBtn.save}
											// disabled={!(isValid && dirty) || isSubmitting}
											type="button"
											className="button button--full-width"
											onClick={() => {
												submitAction = publishBtn.save;
												onSave();
											}}
										>
											{saveLoader ? <SmallLoader /> : 'Save'}
										</button>
										{unpublish && (
											<button
												onClick={onUnpublish}
												type="button"
												className="button button--full-width button--type2"
											>
												Unpublish
											</button>
										)}

										<button
											onClick={() => {
												submitAction = publishBtn.publish;
												handleSubmit();
											}}
											name={publishBtn.publish}
											disabled={
												!isValid ||
												isSubmitting ||
												(values.country.toLowerCase() === 'united states of america' &&
													!values.state)
											}
											type="button"
											className="button button--full-width button--type3"
										>
											{publishLoader ? <SmallLoader /> : 'Save and Publish'}
										</button>
									</div>
								</div>
								{addDocFirstPopUp && (
									<PopUpAddDocument
										docFile={docFile}
										base64Doc={base64Docs}
										nameDoc={namesDoc}
										isOpenPopUpFirstDoc={isOpenPopUpFirstDoc}
										isOpenPopUpSecondDoc={isOpenPopUpSecondDoc}
										changeName={changeName}
										setNameDoc={setNamesDoc}
										cleanUp={cleanUp}
									/>
								)}

								{addDocSecondPopUp && (
									<PopUpCreateDocument
										docFileAll={docFileAll}
										nameDoc={namesDoc}
										isOpenPopUpSecondDoc={isOpenPopUpSecondDoc}
										changeName={changeName}
										removeDoc={removeDoc}
										cleanUp={cleanUp}
									/>
								)}
							</>
						)}
					</Form>
				);
			}}
		</Formik>
	);
};
