import { IPropsPreviewProperty } from 'components/PropertyManagementComponents/types';
import { FC } from 'react';
import { chooseRealEstateType } from 'services/constants/status';
import {
	SvgBathrooms,
	SvgBedrooms,
	SvgRealEstate,
	SvgSquareMeters,
} from 'ui/svg-image/SvgPreviewProperty';
import wallet from '../../../../assets/images/wallet.svg';

export const PropertyFeatures: FC<IPropsPreviewProperty> = ({ values }) => {
	return (
		<div className="realty-block">
			<p className="info-title">Property Features:</p>
			<div className="realty-block__content">
				<div className="realty-features-box">
					<div className="features-list">
						<div className="features-list__group">
							<div className="realty-features">
								<div className="realty-features__icon">
									<SvgRealEstate />
								</div>
								<div className="realty-features__info">
									<p className="realty-features__name">Property type:</p>
									<p className="realty-features__value">{chooseRealEstateType[+values.type - 1]}</p>
								</div>
							</div>
							<div className="realty-features">
								<div className="realty-features__icon">
									<SvgBedrooms />
								</div>
								<div className="realty-features__info">
									<p className="realty-features__name realty-features__name--mob">
										Amount of bedrooms
									</p>
									<p className="realty-features__value realty-features__value--extra">
										{`${String(values.bedroom_number)} bedrooms`}
									</p>
								</div>
							</div>

							<div className="realty-features">
								<div className="realty-features__icon">
									<SvgSquareMeters />
								</div>
								<div className="realty-features__info">
									<p className="realty-features__name realty-features__name--mob">
										Number of square meters
									</p>
									<p className="realty-features__value realty-features__value--extra">
										{`${String(values.square_meters)} Sqft`}
									</p>
								</div>
							</div>

							<div className="realty-features">
								<div className="realty-features__icon">
									<SvgBathrooms />
								</div>
								<div className="realty-features__info">
									<p className="realty-features__name realty-features__name--mob">
										Amount of bathrooms
									</p>
									<p className="realty-features__value realty-features__value--extra">
										{`${String(values.bathroom_number)} bathrooms`}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="realty-invest">
						<div className="token-to-invest">
							<span className="token-to-invest__icon">
								<img src={wallet} alt="" />
							</span>
							<div className="token-to-invest__info">
								<p className="token-to-invest__title">Tokens you own:</p>
								<p className="token-to-invest__value">{`${String(0)} (token №${String(
									values?.id || '',
								)})`}</p>
							</div>
						</div>
						<div className="realty-invest__btn">
							<button type="button" className="button button--long">
								Invest now
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
