import { SmallLoader } from 'components/SmallLoader';
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { editPofitRequest } from 'redux/reducers/propertyManagement/reducer';
import { getProfitLoader } from 'redux/reducers/propertyManagement/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { statusPropertyM } from 'services/constants/status';
import { colorsStatusProperty } from 'services/constants/status-colors';
import { SvgCheck, SvgClose } from 'ui/svg-image/SvgPropertyManagement';
import { IPropsBodyTable } from './types';

export const BodyTable: FC<IPropsBodyTable> = ({ listProperty, openPopup }) => {
	const [profit, setProfit] = useState('');
	const [price, setPrice] = useState('');
	const [profitId, setProfitID] = useState('');
	const [loaderId, setLoaderID] = useState('');
	const dispatch = useDispatch();
	const profitLoader = useSelector(getProfitLoader);

	const isOpen = (value?: string, id?: string, oldPrice?: string) => {
		setProfit(value || '');
		setPrice(oldPrice || '');
		setProfitID(id || '');
		id && setLoaderID(id || '');
	};

	const handleInput = (e: ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		target.name === 'profit' ? setProfit(target.value) : setPrice(target.value);
	};

	const resetInput = () => {
		setProfit('');
		setPrice('');
	};

	const handleInputs = () => {
		dispatch(
			editPofitRequest({
				profit: +profit,
				price: +price,
				estate_id: +profitId,
			}),
		);
		isOpen();
		resetInput();
	};

	return (
		<div className="table-body">
			{listProperty.map((property) => (
				<div key={property.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Photo</p>
						<div className="table-property-photo">
							{property?.images?.length ? <img src={property.images[0].thumb} alt="" /> : '-'}
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Item name</p>
						<p className="td-value td-value--medium">
							<Link to={`${ELinksName.EDIT_PROPERTY}/${String(property.id)}`}>
								{(property.title && property.title[0].toUpperCase() + property.title.slice(1)) ||
									'-'}
							</Link>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Status</p>
						<p className={`td-value td-value--medium ${colorsStatusProperty[property.status]}`}>
							{statusPropertyM[property.status]}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Item ID </p>
						<p className="td-value td-value--medium red">{property.id}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Item price</p>
						{+profitId === property?.id ? (
							<div className="input">
								<div className="input-wrapper">
									<input
										className="input-item input-item--td"
										type="text"
										name="price"
										placeholder="Set price"
										onChange={handleInput}
										value={
											price[0] === '.'
												? `0${String(+price).replace(/[^0-9.]/, '')}`
												: String(+price).replace(/[^0-9.]{0,1}$/, '')
										}
									/>
								</div>
							</div>
						) : (
							<div className="td-value td-value--medium">
								{property.price ? (
									<span>
										{profitLoader && +loaderId === property?.id ? (
											<SmallLoader />
										) : (
											`${String(+property.price)} USD`
										)}
									</span>
								) : (
									<span className="grey">Set price</span>
								)}
							</div>
						)}
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Request</p>
						<p className="td-value td-value--medium td-value--blue-link">
							<Link to={`${ELinksName.INTEREST_REQUEST}/${property?.id}`} className="">
								{property?.interest_requests_count}
							</Link>
						</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Investors</p>
						<Link
							to={`${ELinksName.INVESTORS}/${property?.id}`}
							className="td-value td-value--medium"
						>
							{String(property.investments_count)}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Investment</p>
						<p className="td-value td-value--medium">
							{`${String(property.investments_sum_amount_usd_invested || 0)} USD`}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Profit</p>
						{+profitId === property?.id ? (
							<div className="input">
								<div className="input-wrapper">
									<input
										className="input-item input-item--td"
										type="text"
										placeholder="Set price"
										onChange={handleInput}
										value={
											profit[0] === '.'
												? `0${String(profit).replace(/[^0-9.]/, '')}`
												: String(profit).replace(/[^0-9.]{0,1}$/, '')
										}
										name="profit"
									/>
								</div>
							</div>
						) : (
							<div className="td-value td-value--medium">
								{property.expected_profit ? (
									<span>
										{profitLoader && +loaderId === property?.id ? (
											<SmallLoader />
										) : (
											property.expected_profit
										)}
									</span>
								) : (
									<span className="grey">Set price</span>
								)}
							</div>
						)}
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons">
							{+profitId === property?.id ? (
								<>
									<button onClick={handleInputs} type="button">
										<SvgCheck />
									</button>
									<button
										onClick={() => {
											isOpen();
											resetInput();
										}}
										type="button"
									>
										<SvgClose />
									</button>
								</>
							) : (
								<button
									onClick={() =>
										isOpen(
											String(property.expected_profit),
											String(property.id),
											String(property.price),
										)
									}
									type="button"
								>
									<svg
										width="25"
										height="25"
										viewBox="0 0 25 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M5.20825 18.7501H9.62492C9.76201 18.7509 9.89791 18.7246 10.0248 18.6728C10.1517 18.6209 10.2672 18.5446 10.3645 18.448L17.5728 11.2293L20.5312 8.33343C20.6288 8.2366 20.7063 8.12139 20.7592 7.99445C20.8121 7.86751 20.8393 7.73136 20.8393 7.59385C20.8393 7.45634 20.8121 7.32018 20.7592 7.19325C20.7063 7.06631 20.6288 6.9511 20.5312 6.85427L16.1145 2.38552C16.0177 2.28788 15.9025 2.21039 15.7755 2.1575C15.6486 2.10462 15.5124 2.07739 15.3749 2.07739C15.2374 2.07739 15.1013 2.10462 14.9743 2.1575C14.8474 2.21039 14.7322 2.28788 14.6353 2.38552L11.6978 5.33343L4.46867 12.5522C4.37213 12.6495 4.29574 12.7649 4.24391 12.8919C4.19207 13.0188 4.16579 13.1547 4.16659 13.2918V17.7084C4.16659 17.9847 4.27633 18.2497 4.47168 18.445C4.66703 18.6404 4.93198 18.7501 5.20825 18.7501ZM15.3749 4.59385L18.3228 7.54177L16.8437 9.02093L13.8958 6.07302L15.3749 4.59385ZM6.24992 13.7188L12.427 7.54177L15.3749 10.4897L9.19783 16.6668H6.24992V13.7188ZM21.8749 20.8334H3.12492C2.84865 20.8334 2.5837 20.9432 2.38835 21.1385C2.193 21.3339 2.08325 21.5988 2.08325 21.8751C2.08325 22.1514 2.193 22.4163 2.38835 22.6117C2.5837 22.807 2.84865 22.9168 3.12492 22.9168H21.8749C22.1512 22.9168 22.4161 22.807 22.6115 22.6117C22.8068 22.4163 22.9166 22.1514 22.9166 21.8751C22.9166 21.5988 22.8068 21.3339 22.6115 21.1385C22.4161 20.9432 22.1512 20.8334 21.8749 20.8334Z"
											fill="#E41472"
										/>
									</svg>
								</button>
							)}
							<button onClick={() => openPopup(property.id)} type="button" className="active">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z"
										fill="#9E9E9E"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
