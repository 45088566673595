import { PropertyForm } from 'components/Forms/PropertyForm';
import { Sidebar } from 'components/Sidebar';
import { Uploading } from 'components/Uploading';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
	editPropertyRequest,
	publishPropertyRequest,
} from 'redux/reducers/propertyManagement/reducer';
import { IPropertyRequest } from 'redux/reducers/propertyManagement/types';
import { publishBtn } from 'services/constants/name-button';

const PostNewProperty: FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [count, setCount] = useState<number>(0);
	const [amount, setAmount] = useState<number>(0);

	const handleIPropertySubmit = (
		values: IPropertyRequest,
		images: Array<{ [key: string]: string | number }>,
		submitAction: string | undefined,
	) => {
		setIsUploading(true);
		setAmount(images.filter((img) => !img.id).length);

		// publishBtn.save === submitAction &&
		// 	dispatch(
		// 		editPropertyRequest({
		// 			data: { estate: values },
		// 			history,
		// 			images,
		// 			count: setCount,
		// 			setIsUploading,
		// 		}),
		// 	);

		publishBtn.publish === submitAction &&
			dispatch(
				publishPropertyRequest({
					data: { estate: values },
					history,
					images,
					count: setCount,
					setIsUploading,
				}),
			);
	};

	const handleSave = (
		values: IPropertyRequest,
		images: Array<{ [key: string]: string | number }>,
	) => {
		setIsUploading(true);
		setAmount(images.filter((img) => !img.id).length);
		dispatch(
			editPropertyRequest({
				data: { estate: values },
				history,
				images,
				count: setCount,
				setIsUploading,
			}),
		);
	};

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				{isUploading && <Uploading amount={amount} count={count} />}
				<PropertyForm propertySubmit={handleIPropertySubmit} handleSave={handleSave} />
			</div>
		</div>
	);
};

export default PostNewProperty;
