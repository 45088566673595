/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	I2faSmsPayload,
	IChangeEmailRequest,
	ICheckPassRequest,
	ICheckPassResponse,
	ICreatePasswordFormData,
	IDisable2faPayload,
	IEditUserRequest,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	IGetSmsLoginRequest,
	IGetSmsRequest,
	IInitialState,
	IResetEmailRequest,
	ISmsCodeResponse,
} from './types';

export const initialState: IInitialState = {
	editUser: false,
	data2fa: null,
	account2faLoader: false,
	displayDisable2faForm: false,
	smsCodeLoader: false,
	timerStart: false,
	account2faSmsPhoneLoader: false,
	enable2faSmsPhone: false,
	disable2faSmsPhone: false,
	smsCodeLoginLoader: false,
	open2FaPage: false,
	changeEmailLoader: false,
	resetEmailLoader: false,
	openModalEmail: '',
	open2FaSms: null,
	sms_2fa: null,
	checkPassLoader: false,
	checkPass: null,
	openTimer: null,
	open2FaSmsModal: false,
	open2FaGoogle: false,
};

const settings = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		editUserRequest: (state, action: PayloadAction<IEditUserRequest>) => {
			const editUserState = state;
			editUserState.editUser = true;
		},

		editUserSuccess: (state) => {
			const editUserState = state;
			editUserState.editUser = false;
		},

		changeEmailRequest: (state, action: PayloadAction<IChangeEmailRequest>) => {
			const changeEmailLoaderState = state;
			changeEmailLoaderState.editUser = true;
		},

		changeEmailSuccess: (state, action: PayloadAction<string>) => {
			const { payload } = action;

			const changeEmailLoaderState = state;
			changeEmailLoaderState.editUser = false;
			changeEmailLoaderState.openModalEmail = payload;
		},

		resetEmailRequest: (state, action: PayloadAction<IResetEmailRequest>) => {
			const changeEmailLoaderState = state;
			changeEmailLoaderState.editUser = true;
		},

		generate2faKeyRequest: (state) => {
			const enable2faState = state;

			enable2faState.account2faLoader = true;
			enable2faState.sms_2fa = '';
		},
		generate2faKeySuccess: (state, action: PayloadAction<IGenerate2faKeyResponse>) => {
			const { payload } = action;
			const enable2faState = state;

			enable2faState.account2faLoader = false;
			enable2faState.data2fa = payload;
		},

		enable2faRequest: (state, action: PayloadAction<IEnable2faPayload>) => {
			const enable2faState = state;

			enable2faState.account2faLoader = true;
		},

		enable2faSuccess: (state) => {
			const enable2faState = state;

			enable2faState.account2faLoader = false;
			enable2faState.displayDisable2faForm = false;
		},

		disable2faRequest: (state, action: PayloadAction<IDisable2faPayload>) => {
			const disable2faState = state;

			disable2faState.account2faLoader = true;
		},

		disable2faSuccess: (state) => {
			const disable2faState = state;

			disable2faState.account2faLoader = false;
			disable2faState.data2fa = null;
		},

		enable2faSmsPhoneRequest: (state, action: PayloadAction<I2faSmsPayload>) => {
			const enable2faState = state;

			enable2faState.account2faSmsPhoneLoader = true;
		},

		enable2faSmsPhoneSuccess: (state) => {
			const enable2faState = state;

			enable2faState.account2faSmsPhoneLoader = false;
			enable2faState.enable2faSmsPhone = true;
		},

		disable2faSmsPhoneRequest: (state, action: PayloadAction<I2faSmsPayload>) => {
			const disable2faState = state;

			disable2faState.account2faSmsPhoneLoader = true;
		},

		disable2faSmsPhoneSuccess: (state) => {
			const disable2faState = state;

			disable2faState.account2faSmsPhoneLoader = false;
			disable2faState.disable2faSmsPhone = true;
		},

		getSmsCodeRequest: (state, action: PayloadAction<IGetSmsRequest>) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoader = true;
		},

		getSmsCodePhoneRequest: (state) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoader = true;
		},

		getSmsCodeSuccess: (state) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoader = false;
			smsCodeState.timerStart = true;
		},

		getSmsCode2faLoginRequest: (state, action: PayloadAction<IGetSmsLoginRequest>) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoginLoader = true;
		},

		getSmsCode2faLoginSuccess: (state) => {
			const smsCodeState = state;

			smsCodeState.smsCodeLoginLoader = false;
		},

		open2FaPageSuccess: (state, action: PayloadAction<boolean>) => {
			const open2FaPageState = state;
			const { payload } = action;
			open2FaPageState.open2FaPage = payload;
		},

		sms2FaSuccess: (state, action: PayloadAction<{ sms_2fa_enabled: string | null }>) => {
			const sms2FaState = state;
			const { payload } = action;

			if (Object.keys(payload).toString() === 'sms_2fa_enabled') {
				sms2FaState.sms_2fa = Object.keys(payload).toString();
			}
		},

		open2FaSmsSuccess: (state, action: PayloadAction<string | null>) => {
			const open2FaPageState = state;
			const { payload } = action;
			open2FaPageState.open2FaSms = payload;
		},

		checkPassRequest: (state, action: PayloadAction<ICheckPassRequest>) => {
			const checkPassState = state;

			checkPassState.checkPassLoader = true;
		},

		checkPassSuccess: (state, action: PayloadAction<ICheckPassResponse>) => {
			const checkPassState = state;
			const { payload } = action;

			checkPassState.checkPassLoader = false;
			checkPassState.checkPass = payload;
		},

		openTimerSuccess: (state, action: PayloadAction<ISmsCodeResponse | null>) => {
			const checkPassState = state;
			const { payload } = action;

			checkPassState.checkPassLoader = false;
			checkPassState.openTimer = payload;
		},

		openPopUpSms2FaSuccess: (state, action: PayloadAction<boolean>) => {
			const checkPassState = state;
			const { payload } = action;

			checkPassState.open2FaSmsModal = payload;
		},

		openPopUpGoogle2FaSuccess: (state, action: PayloadAction<boolean>) => {
			const checkPassState = state;
			const { payload } = action;

			checkPassState.open2FaGoogle = payload;
		},

		changeUserPassRequest: (state, action: PayloadAction<ICreatePasswordFormData>) => {},

		settingsInitState: () => initialState,
	},
});

export default settings.reducer;
export const {
	editUserRequest,
	editUserSuccess,
	generate2faKeyRequest,
	generate2faKeySuccess,
	settingsInitState,
	disable2faSuccess,
	disable2faRequest,
	enable2faSuccess,
	enable2faRequest,
	changeUserPassRequest,
	getSmsCodeRequest,
	getSmsCodeSuccess,
	enable2faSmsPhoneRequest,
	enable2faSmsPhoneSuccess,
	disable2faSmsPhoneRequest,
	disable2faSmsPhoneSuccess,
	getSmsCode2faLoginRequest,
	getSmsCode2faLoginSuccess,
	open2FaPageSuccess,
	changeEmailRequest,
	changeEmailSuccess,
	resetEmailRequest,
	open2FaSmsSuccess,
	sms2FaSuccess,
	checkPassRequest,
	checkPassSuccess,
	openTimerSuccess,
	getSmsCodePhoneRequest,
	openPopUpSms2FaSuccess,
	openPopUpGoogle2FaSuccess,
} = settings.actions;
