import { AdminProfile } from 'components/SettingsComponent/AdminProfile';
import { TwoFaAuth } from 'components/SettingsComponent/TwoFaAuth';
import { Sidebar } from 'components/Sidebar';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ELinkSettings, ELinksName } from 'services/constants/list-links-sidebar';
import classNames from 'classnames';
import { PopupConfirmEmail } from 'components/PopupConfirmEmail';
import { useSelector } from 'react-redux';
import { getOpen2FaGoogle, getOpen2FaSms, getOpenModal } from 'redux/reducers/settings/selectors';
import { PopUp2FaOn } from 'components/PopUp2FaOn';
import { PopUp2FaOff } from 'components/PopUp2FaOff';

const Settings: FC = () => {
	const openModal = useSelector(getOpenModal);
	const param: { tab: string } = useParams();
	const [open2FaGoogle, setOpen2FaGoogle] = useState(false);
	const [open2FaPhone, setOpen2FaPhone] = useState(false);
	const open2FaSms = useSelector(getOpen2FaSms);
	const openGoogle = useSelector(getOpen2FaGoogle);

	return (
		<div className="admin">
			{(open2FaGoogle || open2FaPhone) && open2FaSms && (
				<PopUp2FaOn setOpen2FaGoogle={setOpen2FaGoogle} />
			)}
			{(!open2FaGoogle || !open2FaPhone) && openGoogle && <PopUp2FaOff />}
			<Sidebar />
			{openModal && <PopupConfirmEmail />}
			<div className="admin__content">
				<div className="table-top-wrapper table-top-wrapper--center">
					<div className="table-title table-title--m0">
						<p>Admin profile</p>
					</div>
					<div className="switcher switcher--smaller">
						<Link
							className={classNames('switcher__item switcher__item--center', {
								active:
									param.tab === ELinkSettings.PROFILE ||
									(!param.tab && param.tab !== ELinkSettings.PROFILE),
							})}
							to={`${ELinksName.SETTINGS}/profile`}
						>
							Change password
						</Link>
						<Link
							className={classNames('switcher__item switcher__item--center', {
								active: param.tab === ELinkSettings.TWOFAAUTH,
							})}
							to={`${ELinksName.SETTINGS}/two-fa-auth`}
						>
							2FA-Authentication
						</Link>
					</div>
				</div>
				{param.tab === 'two-fa-auth' ? (
					<TwoFaAuth
						open2FaGoogle={open2FaGoogle}
						setOpen2FaGoogle={setOpen2FaGoogle}
						setOpen2FaPhone={setOpen2FaPhone}
						open2FaPhone={open2FaPhone}
					/>
				) : (
					<AdminProfile />
				)}
			</div>
		</div>
	);
};

export default Settings;
