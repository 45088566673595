import ErrorMsg from 'components/ErrorMsg';
import { Field, Form, Formik } from 'formik';
import { ChangeEvent, FC } from 'react';
import { ISubmitWithdrawUsdc } from 'redux/reducers/wallets/types';
import yup from 'untils/capsLockValidate';
import { address } from 'untils/formValidation';
import { notificationContainer } from 'untils/notificationContainer';
import { IPropsWitndrowUsdc } from './types';

export const WithdrawUsdcForm: FC<IPropsWitndrowUsdc> = ({ submitWithdrawUsdc }) => {
	const amount = yup
		.number()
		.typeError('Amount must be a number type')
		.required('Amount is a required field');

	const initialValues = {
		address: '',
		amount: '',
	};

	const validationSchema = yup.object().shape({
		address,
		amount,
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ISubmitWithdrawUsdc, { setSubmitting, resetForm }) => {
				submitWithdrawUsdc({
					payment_system: 'circle',
					type: 2,
					amount: +values.amount,
					payerData: [
						{
							address: values.address,
						},
					],
				});
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors, setFieldValue, values }) => {
				const clearForm = () => {
					setFieldValue('address', '');
					setFieldValue('amount', '');
				};
				const heandleInput = (e: ChangeEvent) => {
					const terget = e.target as HTMLInputElement;
					if (
						terget.value.slice(terget.value.indexOf('.') + 1).length > 2 &&
						terget.value.includes('.')
					) {
						notificationContainer(
							'After the comma, you can enter no more than two characters',
							'info',
						);
					} else {
						(+terget.value > 0 || terget.value.length === 0) &&
							setFieldValue('amount', terget.value);
					}
				};

				return (
					<Form className="operation-form">
						<div className="form-group ">
							<div className="input input--mb-0 ">
								<p className="input-name ">Wallet address:</p>
								<div className="input-wrapper">
									<Field
										name="address"
										className="input-item  "
										type="text"
										placeholder="Enter the address of your wallet "
									/>
								</div>
							</div>
							{errors.address ? <ErrorMsg msg={errors.address} /> : null}
						</div>
						<div className="form-group ">
							<div className="input input--mb-0 ">
								<p className="input-name ">Amount to withdraw:</p>
								<div className="input-wrapper">
									<input
										onChange={heandleInput}
										name="amount"
										className="input-item input-item--pr-125 "
										type="text"
										placeholder="Enter amount"
										value={
											values.amount === '.'
												? `1${String(values.amount).replace(/[^0-9.]/, '')}`
												: String(values.amount).replace(/[^0-9.]/, '')
										}
									/>
									<span className="input-coin">USDC</span>
								</div>
							</div>
							{errors.amount ? <ErrorMsg msg={errors.amount} /> : null}
						</div>
						<div className="form-footer form-footer--mob-column form-footer--more-mt">
							<button
								onClick={clearForm}
								type="button"
								className="button button--type7 button--full-width"
							>
								Cancel
							</button>
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="button  button--full-width"
							>
								Withdraw
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
