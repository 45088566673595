import { FC } from 'react';

export const SvgCalendar: FC = () => (
	<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5 1.75V3.625"
			stroke="#4062E1"
			strokeWidth="1.2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 1.75V3.625"
			stroke="#4062E1"
			strokeWidth="1.2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.1875 6.18127H12.8125"
			stroke="#4062E1"
			strokeWidth="1.2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.125 5.8125V11.125C13.125 13 12.1875 14.25 10 14.25H5C2.8125 14.25 1.875 13 1.875 11.125V5.8125C1.875 3.9375 2.8125 2.6875 5 2.6875H10C12.1875 2.6875 13.125 3.9375 13.125 5.8125Z"
			stroke="#4062E1"
			strokeWidth="1.2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.49706 9.0625H7.50267"
			stroke="#4062E1"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.18407 9.0625H5.18968"
			stroke="#4062E1"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.18407 10.9375H5.18968"
			stroke="#4062E1"
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgSearch: FC = () => (
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z"
			stroke="#1D1D1D"
			strokeOpacity="0.4"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.75 13.75L12.5 12.5"
			stroke="#1D1D1D"
			strokeOpacity="0.4"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgCalendarSell: FC = () => (
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.25 4.5C1.25 4.08579 1.58579 3.75 2 3.75H22C22.4142 3.75 22.75 4.08579 22.75 4.5V22.5C22.75 22.9142 22.4142 23.25 22 23.25H2C1.58579 23.25 1.25 22.9142 1.25 22.5V4.5ZM2.75 5.25V21.75H21.25V5.25H2.75Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.25 10.5C13.25 10.0858 13.5858 9.75 14 9.75H18C18.4142 9.75 18.75 10.0858 18.75 10.5V17.5C18.75 17.9142 18.4142 18.25 18 18.25H14C13.5858 18.25 13.25 17.9142 13.25 17.5V10.5ZM14.75 11.25V16.75H17.25V11.25H14.75Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.5 1.75C8.91421 1.75 9.25 2.08579 9.25 2.5V6.5C9.25 6.91421 8.91421 7.25 8.5 7.25C8.08579 7.25 7.75 6.91421 7.75 6.5V2.5C7.75 2.08579 8.08579 1.75 8.5 1.75Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.5 1.75C15.9142 1.75 16.25 2.08579 16.25 2.5V6.5C16.25 6.91421 15.9142 7.25 15.5 7.25C15.0858 7.25 14.75 6.91421 14.75 6.5V2.5C14.75 2.08579 15.0858 1.75 15.5 1.75Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.25 10.5C5.25 10.0858 5.58579 9.75 6 9.75H10C10.4142 9.75 10.75 10.0858 10.75 10.5V17.5C10.75 17.9142 10.4142 18.25 10 18.25H6C5.58579 18.25 5.25 17.9142 5.25 17.5C5.25 17.0858 5.58579 16.75 6 16.75H9.25V11.25H6C5.58579 11.25 5.25 10.9142 5.25 10.5Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.25 14C6.25 13.5858 6.58579 13.25 7 13.25H10C10.4142 13.25 10.75 13.5858 10.75 14C10.75 14.4142 10.4142 14.75 10 14.75H7C6.58579 14.75 6.25 14.4142 6.25 14Z"
			fill="#E41472"
		/>
	</svg>
);

export const SvgResetCaledar: FC<{ resetCalendar: () => void }> = ({ resetCalendar }) => (
	<svg
		onClick={resetCalendar}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
			fill="#25252E"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
			fill="#25252E"
		/>
	</svg>
);

// <button class="popup__close" type="button">

//         </button>
