import ErrorMsg from 'components/ErrorMsg';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { IEditUserRequest } from 'redux/reducers/settings/types';
import yup from 'untils/capsLockValidate';
import { surname, name } from '../../../untils/formValidation';
import { IPropsChangeName } from './types';

export const ChangeNameForm: FC<IPropsChangeName> = ({ editUserSubmit }) => {
	const userData = useSelector(getUserData);
	const initialValues = {
		name: userData?.name || '',
		surname: userData?.surname || '',
	};

	const validationSchema = yup.object().shape({
		name,
		surname,
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IEditUserRequest, { resetForm, setSubmitting }) => {
				editUserSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors }) => {
				return (
					<Form className="formik-form">
						<div className="input">
							<p className="input-name">Name</p>
							<div className="input-wrapper">
								<Field name="name" type="text" className="input-item" placeholder="Your name" />
							</div>
							{errors.name ? <ErrorMsg msg={errors.name} /> : null}
						</div>
						<div className="input">
							<p className="input-name">Surname</p>
							<div className="input-wrapper">
								<Field
									name="surname"
									type="text"
									className="input-item"
									placeholder="Your surname"
								/>
							</div>
							{errors.surname ? <ErrorMsg msg={errors.surname} /> : null}
						</div>
						<div className="form-group form-group--more-mt">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								className="button button--long"
								type="submit"
							>
								Save
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
