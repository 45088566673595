/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import ErrorMsg from 'components/ErrorMsg';
import { Field } from 'formik';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import moment from 'moment';
import { FC, MouseEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import { chooseRealEstateType } from 'services/constants/status';

export interface IProps {
	errors: IValues;
	values: IValues;
	openModal: (event: MouseEvent) => void;
	setDropType: (value: boolean) => void;
	dropType: boolean;
	isOpenYear: boolean;
	onYearBuilt: (d: Date) => void;
	handleBlur: any;
	handleClick: (e: MouseEvent) => void;
}

export interface IValues {
	bedroom_number: string;
	bathroom_number: string;
	square_meters: string;
	description: string;
	type: string;
	year_built: string | Date;
	lot_size: number | string;
}

export const AddDescription: FC<IProps> = ({
	errors,
	values,
	setDropType,
	dropType,
	openModal,
	isOpenYear,
	onYearBuilt,
	handleBlur,
	handleClick,
}) => {
	const { innerBorderRef } = useOnOutsideClick(() => {
		setDropType(false);
	});

	return (
		<div className="property-input-grid">
			<div className="input-group">
				<div className="input-name">Property type</div>
				<div ref={innerBorderRef} className="select select--type3 input">
					<div onClick={openModal} className="select__current">
						{chooseRealEstateType[Number(values.type) - 1] || (
							<span className="select-name-custom">Property type</span>
						)}
						<span className="select__current-arrow">
							<svg
								width="14"
								height="8"
								viewBox="0 0 14 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
									fill="#25252E"
								/>
							</svg>
						</span>
					</div>
					<div
						className={classNames('select__drop', {
							'select__drop--active': dropType,
						})}
					>
						<div className="select__drop-scroll">
							<div className="select__drop-item">
								<ul>
									{chooseRealEstateType.map((estateType, index) => (
										<li key={estateType}>
											<button onClick={openModal} name={String(index + 1)} type="button">
												{estateType}
											</button>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
				{errors.type ? <ErrorMsg msg={errors.type} /> : null}
				{Number(values.type) !== 4 && Number(values.type) !== 5 ? (
					<div className="input input-top-custom">
						<div className="input-name">Lot size</div>
						<div className="input-wrapper">
							<Field
								name="lot_size"
								value={+values.lot_size || ''}
								className="input-item"
								type="text"
								placeholder="Lot size"
							/>
							<span className="input-coin"> sqft </span>
						</div>
					</div>
				) : (
					''
				)}
				<div className="input">
					<div className="input-name">Year built</div>
					<div className="select--type3 ">
						<div className="input-wrapper input-wrapper-year-custom">
							<button
								onClick={handleClick}
								type="button"
								name="year_built"
								className="input-item input-item-custom-button"
								placeholder="Year built"
							>
								<span className="input-item-custom">
									{values.year_built ? (
										moment(values.year_built).format('YYYY')
									) : (
										<span className="input-item-light-custom">Year built</span>
									)}
								</span>
							</button>
							<div className="block-inline-custom block-inline-year">
								{isOpenYear && (
									<div className="block-position-calendar-custom">
										<ReactDatePicker
											name="year_built"
											onBlur={handleBlur}
											value={String(values.year_built || '')}
											selected={values.year_built ? new Date(values.year_built) : new Date()}
											onChange={(date: Date) => onYearBuilt(date)}
											showYearPicker
											dateFormat="yyyy"
											yearItemNumber={10}
											inline
										/>
									</div>
								)}
								{/* {errors?.year_built ? <ErrorMsg msg={errors?.year_built as string} /> : null} */}
							</div>
						</div>
					</div>
				</div>

				<div className="input">
					<div className="input-name">Living space</div>
					<div className="input-wrapper">
						<Field
							name="square_meters"
							className="input-item"
							type="text"
							placeholder="Living space"
							value={+values.square_meters || ''}
						/>
						<span className="input-coin">sqft</span>
					</div>
					{errors?.square_meters ? <ErrorMsg msg={errors.square_meters} /> : null}
				</div>
				<div className="input">
					<div className="input-name">Bedrooms</div>
					<div className="input-wrapper">
						<Field
							name="bedroom_number"
							className="input-item"
							type="text"
							placeholder="Bedrooms"
							value={values.bedroom_number}
						/>
					</div>
					{errors?.bedroom_number ? <ErrorMsg msg={errors.bedroom_number} /> : null}
				</div>
				<div className="input">
					<div className="input-name">Bathrooms</div>
					<div className="input-wrapper">
						<Field
							name="bathroom_number"
							className="input-item"
							type="text"
							placeholder="Bathrooms"
							value={+values.bathroom_number || ''}
						/>
					</div>
					{errors?.bathroom_number ? <ErrorMsg msg={errors.bathroom_number} /> : null}
				</div>
			</div>
			<div className="property-textarea">
				<div className="input">
					<div className="input-name">About the property</div>
					<div className="input-wrapper">
						<div className="textarea">
							<Field
								component="textarea"
								name="description"
								placeholder="About the property"
								value={values.description.replaceAll('<br/>', '\n') || ''}
							/>
						</div>
						{errors?.description ? <ErrorMsg msg={errors.description} /> : null}
					</div>
				</div>
			</div>
		</div>
	);
};
