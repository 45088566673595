import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IApartmentInvestResponse,
	IBalancesLimitsResponse,
	ICreateUsdcResponse,
	IHistoryConvertsResponse,
	ITransctionsHistoryResponse,
	IWalletsStor,
	TLimits,
} from './types';

const getWalletsState = (state: IStoreState): IWalletsStor => state.wallets;

export const getBalancesLimits = createSelector(
	[getWalletsState],
	(wallets: IWalletsStor): null | IBalancesLimitsResponse => wallets.balancesLimits,
);

export const getProfit = createSelector(
	[getWalletsState],
	(wallets: IWalletsStor): { profit: string | number } | null => wallets?.balancesProfit,
);

export const getQrUsdc = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): ICreateUsdcResponse | null => walletSlice.usdcQr,
);

export const getTransactionHistotyAdmin = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): ITransctionsHistoryResponse | null =>
		walletSlice.transactionsHistoryAdmin,
);

export const getTransactionHistotyAdminLoader = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): boolean => walletSlice.transactionsHistoryAdminLoader,
);

export const getTransactionHistotyAll = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): ITransctionsHistoryResponse | null =>
		walletSlice.transactionsHistoryAll,
);

export const getInvestmentsAll = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): IApartmentInvestResponse | null => walletSlice.investmentsAll,
);

export const getHistoryConverts = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): IHistoryConvertsResponse | null => walletSlice.historyConverts,
);

export const getLoader = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): boolean => walletSlice.walletUsdLoader,
);

export const getLimits = createSelector(
	[getWalletsState],
	(walletSlice: IWalletsStor): null | TLimits => walletSlice.limitations,
);
