export const colorsStatus: { [key: string]: string } = {
	error: 'red',
	completed: 'green',
	canceled: 'orange',
	processing: 'grey',
};

export const colorsStatusProperty: { [key: string]: string } = {
	0: 'red',
	2: 'green',
	1: 'orange',
	3: 'grey',
	4: 'red',
};
