/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBalancesResponse, ICurrentPage, IDashboardState, ISubscriptions } from './types';

export const initialState: IDashboardState = {
	balances: null,
	subscriptionsList: null,
	balancesLoader: false,
};

const dashboard = createSlice({
	name: '@@dashboard',
	initialState,
	reducers: {
		getBalancesRequest: (state) => {
			const getBalancesState = state;
			getBalancesState.balancesLoader = true;
		},

		getBalancesSuccess: (state, action: PayloadAction<IBalancesResponse>) => {
			const { payload } = action;
			const getBalancesState = state;

			getBalancesState.balancesLoader = false;
			getBalancesState.balances = payload;
		},

		getDashboardSubscriptionsRequest: (state, action: PayloadAction<ICurrentPage>) => {
			const getSubscriptionsState = state;
			getSubscriptionsState.balancesLoader = true;
		},

		getDashboardSubscriptionsSuccess: (state, action: PayloadAction<ISubscriptions>) => {
			const { payload } = action;
			const getSubscriptionsState = state;

			getSubscriptionsState.balancesLoader = false;
			getSubscriptionsState.subscriptionsList = payload;
		},

		dashboardInitState: (state) => {
			const propertyManagementInitState = state;
			propertyManagementInitState.balancesLoader = false;
		},
	},
});

export default dashboard.reducer;
export const {
	getBalancesRequest,
	getBalancesSuccess,
	dashboardInitState,
	getDashboardSubscriptionsRequest,
	getDashboardSubscriptionsSuccess,
} = dashboard.actions;
