/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IDividendsManagementApi } from './types';

export const dividendsManagement: IDividendsManagementApi = {
	getDividends: (params) => http.get(endpoint.dividendsManagement.DIVIDENDS, { params }),
	postRentStatus: (payload) => http.post(endpoint.dividendsManagement.CHANGE_RENT_STATUS, payload),
	postDistribute: (payload) => http.post(endpoint.dividendsManagement.DISTRIBUTE, payload),
	getDividendsSum: () => http.get(endpoint.dividendsManagement.DIVIDENDS_SUM),
	getDividendsHistory: (params) =>
		http.get(endpoint.dividendsManagement.DIVIDENDS_HISTORY, { params }),
};
