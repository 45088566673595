import { BodyTable } from 'components/InterestRequestComponent/BodyTable';
import { HeaderTable } from 'components/InterestRequestComponent/HeaderTable';
import { InfoRequest } from 'components/InterestRequestComponent/InfoRequest';
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { Sidebar } from 'components/Sidebar';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getInterestRequest, getInterestSuccess } from 'redux/reducers/interestRequests/reducer';
import {
	getLoaderRequestSelector,
	getUserInterestListSelector,
} from 'redux/reducers/interestRequests/selectors';

const InterestRequest: FC = () => {
	const { location } = useHistory();
	const [currentPage, setCurrentPage] = useState(+location.search.slice(1) || 1);
	const [search, setSearch] = useState('');
	const requests = useSelector(getUserInterestListSelector);
	const loader = useSelector(getLoaderRequestSelector);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	const handleSearchDebounce = (newSearch: string) => {
		setSearch(newSearch);
		setCurrentPage(1);
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	useEffect(() => {
		dispatch(getInterestRequest({ params: { search, current_page: currentPage }, id }));
		return () => {
			dispatch(getInterestSuccess(null));
		};
	}, [dispatch, id, search, currentPage]);

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				{requests && (
					<InfoRequest
						sum={requests?.sum[0]}
						estate={requests.estate}
						handleSearchDebounce={handleSearchDebounce}
						search={search}
					/>
				)}
				<div className="table table--interest table--second-type">
					<HeaderTable />
					{loader ? (
						<Loader />
					) : (
						requests && <BodyTable requests={requests?.interest_request.data} />
					)}
				</div>

				{requests?.interest_request?.last_page && requests?.interest_request?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={requests.interest_request.current_page}
						numberPage={requests.interest_request.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default InterestRequest;
