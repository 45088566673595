import { isAuth, isGuest } from 'routes/authMiddlewares';
import Login from 'pages/Auth/Login';
import ResetPassword from 'pages/Auth/ResetPassword';
import CreateNewPassword from 'pages/Auth/CreateNewPassword';
import Settings from 'pages/Dashboard/Settings';
import EmailChangeSuccess from 'pages/Dashboard/EmailChangeSuccess';
import PropertyManagement from 'pages/Dashboard/PropertyManagement';
import PlatformTokens from 'pages/Dashboard/PlatformTokens';
import TransactionHistory from 'pages/Dashboard/TransactionHistory';
import UsersManagement from 'pages/Dashboard/UsersManagement';
import SellYourPropery from 'pages/Dashboard/SellYourPropery';
import FeeManagement from 'pages/Dashboard/FeeManagement';
import DividendsManagement from 'pages/Dashboard/DividendsManagement';
import EditOrder from 'pages/Dashboard/EditOrder';
import Limitations from 'pages/Dashboard/Wallets/Limitations';
import WalletsTransactionsHistory from 'pages/Dashboard/Wallets/WalletsTransactionsHistory';
import PendingWithdrawals from 'pages/Dashboard/Wallets/PendingWithdrawals';
import PostNewProperty from 'pages/Dashboard/PostNewProperty';
import EditProperty from 'pages/Dashboard/EditProperty';
import Deposit from 'pages/Dashboard/Wallets/Deposit';
import Withdraw from 'pages/Dashboard/Wallets/Withdraw';
import UsersAccount from 'pages/Dashboard/UsersAccount';
import Investors from 'pages/Dashboard/Investors';
import InterestRequest from 'pages/Dashboard/InterestRequest';
import { ELinksName, EParams } from 'services/constants/list-links-sidebar';
import { Home } from 'pages/Dashboard/Home';
import { IRouteItem } from './types';

const navList = {
	login: {
		path: ELinksName.LOGIN,
		component: isGuest(Login),
	},

	resetPassword: {
		path: ELinksName.RESET_PASSWORD,
		component: isGuest(ResetPassword),
	},

	newPassword: {
		path: ELinksName.NEW_PASSWORD,
		component: isGuest(CreateNewPassword),
	},

	home: {
		path: ELinksName.HOME,
		component: isAuth(Home),
	},

	settings: {
		path: ELinksName.SETTINGS + EParams.TAB,
		component: isAuth(Settings),
	},

	emailChangeSuccess: {
		path: ELinksName.EMAIL_RESET,
		component: isAuth(EmailChangeSuccess),
	},

	propertyManagement: {
		path: ELinksName.PROPERTY_MANAGEMENT,
		component: isAuth(PropertyManagement),
	},

	platformTokens: {
		path: ELinksName.PLATFORM_TOKEN,
		component: isAuth(PlatformTokens),
	},

	transactionHistory: {
		path: ELinksName.TRANSATION_HISTORY + EParams.TAB + EParams.SUBTAB,
		component: isAuth(TransactionHistory),
	},

	usersManagement: {
		path: ELinksName.USERS_MANAGEMENT,
		component: isAuth(UsersManagement),
	},

	sellYourPropery: {
		path: ELinksName.SELL_YOUR_PROPERTY,
		component: isAuth(SellYourPropery),
	},

	feeManagement: {
		path: ELinksName.FEE_MANAGMENT,
		component: isAuth(FeeManagement),
	},

	dividendsManagement: {
		path: ELinksName.DIVIDENDS_MANAGEMENT + EParams.TAB,
		component: isAuth(DividendsManagement),
	},

	editOrder: {
		path: ELinksName.SELL_REQUEST + EParams.ID,
		component: isAuth(EditOrder),
	},

	postNewProperty: {
		path: ELinksName.POST_NEW_PROPERTY,
		component: isAuth(PostNewProperty),
	},

	editProperty: {
		path: ELinksName.EDIT_PROPERTY + EParams.ID,
		component: isAuth(EditProperty),
	},

	limitations: {
		path: ELinksName.WALLETS_LIMITATION,
		component: isAuth(Limitations),
	},

	walletsTransactionsHistory: {
		path: ELinksName.WALLETS_TRANSATION_HISTORY + EParams.TAB + EParams.SUBTAB,
		component: isAuth(WalletsTransactionsHistory),
	},

	pendingWithdrawals: {
		path: ELinksName.WALLETS_PENDING_WITHDRAWALS + EParams.TAB + EParams.SUBTAB,
		component: isAuth(PendingWithdrawals),
	},

	deposit: {
		path: ELinksName.DEPOSIT + EParams.TAB + EParams.ID,
		component: isAuth(Deposit),
	},

	withdraw: {
		path: ELinksName.WITHDRAW + EParams.TAB + EParams.ID,
		component: isAuth(Withdraw),
	},

	usersAccount: {
		path: ELinksName.USER_ACCOUNT + EParams.TAB + EParams.ID + EParams.SUBTAB_LEFT + EParams.SUBTAB,
		component: isAuth(UsersAccount),
	},

	investors: {
		path: ELinksName.INVESTORS + EParams.ID,
		component: isAuth(Investors),
	},

	interestRequest: {
		path: ELinksName.INTEREST_REQUEST + EParams.ID,
		component: isAuth(InterestRequest),
	},
};

// ==========================================:
const routesList: Array<IRouteItem> = [];

Object.keys(navList).forEach((item) => routesList.push(navList[item as keyof typeof navList]));

export { navList, routesList };
