import { Sidebar } from 'components/Sidebar';
import { DashboardLimitation } from 'components/WalletsComponents/DashboardLimitation';
import { HeaderLimitation } from 'components/WalletsComponents/HeaderLimitation';
import { HeaderTableLimitation } from 'components/WalletsComponents/HeaderTableLimitation';
import { TableBodyLimitation } from 'components/WalletsComponents/TableBodyLimitation';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLimitationsReguest } from 'redux/reducers/wallets/reducer';

const Limitations: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getLimitationsReguest());
	}, [dispatch]);

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<DashboardLimitation />
				<HeaderLimitation />
				<div className="table table--limitations table--second-type">
					<HeaderTableLimitation />
					<TableBodyLimitation />
				</div>
			</div>
		</div>
	);
};

export default Limitations;
