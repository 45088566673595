import {
	SvgDividendsManagement,
	SvgFeeManagement,
	SvgPlatformTokens,
	SvgPropertyManagement,
	SvgSellYourProperty,
	SvgTransactionHistory,
	SvgUsersManagement,
	SvgWallets,
	SvgWalletsArrow,
} from 'ui/svg-image/SvgSidebar';
import { ETransactionTabsName } from './transaction-history-tabs';

export enum ELinksName {
	HOME = '/',
	LOGIN = '/login',
	RESET_PASSWORD = '/reset-password',
	NEW_PASSWORD = '/admin/password/recover',
	SETTINGS = '/settings',
	EMAIL_RESET = '/admin/profile/email/reset',
	PROPERTY_MANAGEMENT = '/property-management',
	WALLETS = '/wallets',
	PLATFORM_TOKEN = '/platform-tokens',
	TRANSATION_HISTORY = '/transaction-history',
	USERS_MANAGEMENT = '/users-management',
	SELL_YOUR_PROPERTY = '/sell-your-property',
	FEE_MANAGMENT = '/fee-management',
	DIVIDENDS_MANAGEMENT = '/dividends-management',
	SELL_REQUEST = '/sell-request',
	WALLETS_LIMITATION = '/wallets-limitations',
	WALLETS_TRANSATION_HISTORY = '/wallets-transactions-history',
	WALLETS_PENDING_WITHDRAWALS = '/wallets-pending-withdrawals',
	POST_NEW_PROPERTY = '/post-new-property',
	EDIT_PROPERTY = '/edit-property',
	DEPOSIT = '/deposit',
	WITHDRAW = '/withdraw',
	USER_ACCOUNT = '/users-account',
	INVESTORS = '/investors',
	INTEREST_REQUEST = '/interest-requests',
}

export enum EParams {
	TAB = '/:tab?',
	SUBTAB = '/:subTab?',
	SUBTAB_LEFT = '/:subTabLeft?',
	ID = '/:id?',
	USER_ID = '/:userId?',
}

export enum ELinkSettings {
	PROFILE = 'profile',
	TWOFAAUTH = 'two-fa-auth',
}

export enum ETabsDividends {
	MANAGEMENT = 'management',
	HISTORY = 'history',
}

export enum EParamsNameWallet {
	FIAT = 'fiat',
	CRYPTO = 'crypto',
	ADD_CARD = 'add-card',
	LIMITATION = 'limitations',
	WITHDRAW = 'withdraw',
	MANUAL_WITHDRAW = 'manual_withdraw',
	DEPOSIT = 'deposit',
}

export enum EParamsUserAcount {
	USER_INFO = 'user-info',
	INVESTMENTS = 'list-of-investments',
	DIVIDENDS_DISTRIBUTED = 'dividends-distributed',
	ORDERS_HISTORY = 'orders-history',
	USERS_ACCOUNT_TRANSATION_HISTORY = 'transaction-history',
}

export const listLinksSidebar = [
	{
		link: ELinksName.PROPERTY_MANAGEMENT,
		linkName: 'Property management',
		svg: SvgPropertyManagement,
		id: 2,
	},
	{
		link: ELinksName.WALLETS,
		linkName: 'Wallets',
		svg: SvgWallets,
		id: 3,
		svgArrow: SvgWalletsArrow,
	},
	{ link: ELinksName.PLATFORM_TOKEN, linkName: 'Platform tokens', svg: SvgPlatformTokens, id: 4 },
	{
		link: `${ELinksName.TRANSATION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsNameWallet.CRYPTO}`,
		linkName: 'Transaction History',
		svg: SvgTransactionHistory,
		id: 5,
	},
	{
		link: ELinksName.USERS_MANAGEMENT,
		linkName: 'Users management',
		svg: SvgUsersManagement,
		id: 6,
	},
	{
		link: ELinksName.SELL_YOUR_PROPERTY,
		linkName: 'Sell your property',
		svg: SvgSellYourProperty,
		id: 7,
	},
	{ link: ELinksName.FEE_MANAGMENT, linkName: 'Fee management', svg: SvgFeeManagement, id: 8 },
	{
		link: ELinksName.DIVIDENDS_MANAGEMENT,
		linkName: 'Dividends management',
		svg: SvgDividendsManagement,
		id: 9,
	},
];
