/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from 'untils/notificationContainer';
import {
	IEditPriceRequest,
	ITokensBalanceResponse,
	ITokensListRequest,
	ITokensListResponse,
} from './types';
import {
	editPriceRequest,
	editPriceSuccess,
	getBalanceRequest,
	getBalanceSuccess,
	getTokensListRequest,
	getTokensListSuccess,
	tokensInitState,
} from './reducer';

function* getTokenListWorker(action: PayloadAction<ITokensListRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: ITokensListResponse } = yield call(
			api.platformTokens.getListTokens,
			payload,
		);
		yield put(getTokensListSuccess(response.data.tokens));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(tokensInitState());
	}
}

function* getBalanceWorker() {
	try {
		yield put(showLoading());
		const response: { data: ITokensBalanceResponse } = yield call(
			api.platformTokens.getBalanceTokens,
		);
		yield put(getBalanceSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(tokensInitState());
	}
}

function* editTokenPriceWorker(action: PayloadAction<IEditPriceRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.platformTokens.editPrice, payload.params);
		yield put(editPriceSuccess({ id: payload.id, price: payload.params.price }));
		notificationContainer('The price has been successfully changed', 'info');
		// yield payload.clearId(null);
		// yield payload.clearText('');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(tokensInitState());
	}
}

export function* platformTokensSaga() {
	yield takeEvery(getTokensListRequest.type, getTokenListWorker);
	yield takeEvery(editPriceRequest.type, editTokenPriceWorker);
	yield takeEvery(getBalanceRequest.type, getBalanceWorker);
}
