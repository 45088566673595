/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { ICreateNewPasswordPayload } from 'components/Forms/CreateNewPassForm/type';
import { notificationContainer } from '../../../untils/notificationContainer';
import {
	loginRequest,
	loginSuccess,
	logoutRequest,
	authInitState,
	forgotPasswordSuccess,
	forgotPasswordRequest,
	createNewPasswordRequest,
	createNewPasswordSuccess,
} from './reducer';
import {
	IAdminAuthUserResponseData,
	ILoginRequestPayload,
	IAdminLogoutData,
	IForgotPasswordPayload,
} from './types';
import { open2FaPageSuccess, sms2FaSuccess } from '../settings/reducer';

// =============================================================:
function* loginRequestWorker(action: PayloadAction<ILoginRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IAdminAuthUserResponseData = yield call(api.auth.login, payload);
		yield put(sms2FaSuccess(response));
		yield put(open2FaPageSuccess(true));
		yield put(loginSuccess(response));
	} catch (error) {
		if (error instanceof Error) {
			if (error.message.includes(`Cannot read properties of undefined (reading 'email')`)) {
				notificationContainer(String(L.translate(`Enter the two-factor code`)), 'info');
				yield put(open2FaPageSuccess(true));
			}
		}

		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(String(L.translate(`Errors.${code}`)), 'info');
				yield put(authInitState());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* logoutRequestWorker(action: PayloadAction<IAdminLogoutData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.history.push('');
	} catch (error) {
		if (axios.isAxiosError(error)) responseErrors(error);
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* forgotPasswordRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.forgotPassword, payload);
		yield put(forgotPasswordSuccess());

		notificationContainer(
			String(
				`Please check your email <span>${payload.field},</span> we have sent a link to reset password.`,
			),
			'success',
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* createNewPasswordWorker(action: PayloadAction<ICreateNewPasswordPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.auth.newPassword, payload.data);
		yield put(createNewPasswordSuccess());
		payload.history.push('/login');

		notificationContainer(String('Your password has been successfully changed!'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(forgotPasswordRequest.type, forgotPasswordRequestWorker);
	yield takeEvery(createNewPasswordRequest.type, createNewPasswordWorker);
}
