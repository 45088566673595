/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getUserData } from 'redux/reducers/auth/selectors';

import {
	disable2faRequest,
	enable2faRequest,
	generate2faKeyRequest,
} from 'redux/reducers/settings/reducer';
import { get2faData } from 'redux/reducers/settings/selectors';
import { TwoFaAuthActiveGogle } from './TwoFaAuthActiveGogle';
import { TwoFaAuthDisabledGoogle } from './TwoFaAuthDisabledGogle';
import { TwoFaAuthPhone } from './TwoFaAuthPhone';
import { IPropsTwoFaAuth } from './types';

export const TwoFaAuth: FC<IPropsTwoFaAuth> = ({
	open2FaGoogle,
	setOpen2FaGoogle,
	setOpen2FaPhone,
	open2FaPhone,
}) => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);

	const twoFaGoogle = useSelector(get2faData);
	const history = useHistory();

	const handleToggle = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;

		if (target.name === '2fa-google') {
			setOpen2FaGoogle(true);
			!userData?.google2fa_enabled && dispatch(generate2faKeyRequest());
		} else {
			setOpen2FaPhone(true);
		}
	};

	const handleGoogle2faSubmit = (value: string) => {
		!userData?.google2fa_enabled
			? dispatch(
					enable2faRequest({
						totp: value,
						history,
					}),
			  )
			: dispatch(
					disable2faRequest({
						totp: value,
						history,
					}),
			  );
	};

	useEffect(() => {
		return () => {
			setOpen2FaPhone(false);
			setOpen2FaGoogle(false);
		};
	}, []);

	return !open2FaPhone ? (
		<div className="tfa-wrapper">
			{open2FaGoogle && userData?.google2fa_enabled ? (
				<TwoFaAuthDisabledGoogle
					open2FaGoogle={open2FaGoogle}
					handleGoogle2faSubmit={handleGoogle2faSubmit}
				/>
			) : (
				<div className="autofication-2fa">
					<p className="tfa-info__title">2FA-Authentication</p>
					<p className="autofication-2fa__description">Choose your way of authentication</p>
					<div className="autofication-2fa__item">
						<p>Turn on Google Authenticator </p>
						<div className="user-checkbox user-checkbox--black">
							<label>
								<input
									name="2fa-google"
									onChange={handleToggle}
									type="checkbox"
									className="hidden"
									disabled={!!userData?.sms_2fa_enabled || false}
									checked={!!userData?.google2fa_enabled || false}
								/>
								<span className="checkbox-item checkbox-item--small checkbox-item-grey " />
							</label>
						</div>
					</div>
					<div className="autofication-2fa__item">
						<p>Turn on phone verification </p>
						<div className="user-checkbox user-checkbox--black">
							<label>
								<input
									onChange={handleToggle}
									type="checkbox"
									className="hidden"
									disabled={!!userData?.google2fa_enabled || false}
									checked={!!userData?.sms_2fa_enabled || false}
								/>
								<span className="checkbox-item checkbox-item--small checkbox-item-grey" />
							</label>
						</div>
					</div>
				</div>
			)}
			{open2FaGoogle && twoFaGoogle && !userData?.google2fa_enabled && (
				<TwoFaAuthActiveGogle
					handleGoogle2faSubmit={handleGoogle2faSubmit}
					twoFaGoogle={twoFaGoogle}
					setOpen2FaGoogle={setOpen2FaGoogle}
				/>
			)}
		</div>
	) : (
		<TwoFaAuthPhone
			userData={userData}
			open2FaPhone={open2FaPhone}
			setOpen2FaPhone={setOpen2FaPhone}
		/>
	);
};
