import ErrorMsg from 'components/ErrorMsg';
import { Timer } from 'components/Timer';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from 'redux/reducers/auth/selectors';
import {
	disable2faSmsPhoneRequest,
	enable2faSmsPhoneRequest,
	getSmsCodePhoneRequest,
	getSmsCodeRequest,
	open2FaPageSuccess,
	openTimerSuccess,
} from 'redux/reducers/settings/reducer';
import { getOpenTimer } from 'redux/reducers/settings/selectors';
import { useHistory } from 'react-router';
import { SvgTimer } from 'ui/svg-image/SvgTimer';
import { IProps2FaSms } from './types';

export const TwoFaAuthPhone: FC<IProps2FaSms> = ({ userData, setOpen2FaPhone }) => {
	const [seconds, setSeconts] = useState<number>(90);
	const [phone, setPhone] = useState<string>(userData?.phone?.replace('+', '') || '');
	const [smsCode, setSmsCode] = useState('');
	const token = useSelector(getToken);
	const dispatch = useDispatch();
	const history = useHistory();
	const openTimer = useSelector(getOpenTimer);

	const heandleInputPhone = (value: string) => {
		setPhone(value);
	};

	const heandleInputSmsCode = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setSmsCode(target.value);
	};

	const getSmsCode = () => {
		setSeconts(90);
		userData?.phone
			? dispatch(getSmsCodePhoneRequest())
			: dispatch(getSmsCodeRequest({ phone: `+${phone}` }));
	};

	const on2FaSms = () => {
		!userData?.sms_2fa_enabled
			? dispatch(
					enable2faSmsPhoneRequest({
						data: {
							token,
							sms_code: smsCode,
						},
						history,
						setOpen2FaPhone,
					}),
			  )
			: dispatch(
					disable2faSmsPhoneRequest({
						data: {
							token,
							sms_code: smsCode,
						},
						history,
						setOpen2FaPhone,
					}),
			  );
		setSmsCode('');
		dispatch(open2FaPageSuccess(false));
	};

	if (seconds === 0) {
		dispatch(openTimerSuccess(null));
		setSeconts(90);
	}

	useEffect(() => {
		return () => {
			dispatch(openTimerSuccess(null));
		};
	}, [dispatch]);

	return (
		<div className="tfa-wrapper">
			<div className="tfa-info">
				<p className="tfa-info__title">{`Turn-${
					userData?.sms_2fa_enabled ? 'off' : 'on'
				} 2FA-Authentication`}</p>
				<p className="tfa-info__description">
					{userData?.sms_2fa_enabled
						? 'To disable phone 2fa enter verification code sent by sms'
						: 'Enter your phone number and the verification code sent to it.'}
				</p>
			</div>
			<div className="phone-block">
				<div className="input">
					<p className="input-name">Phone number</p>
					<div className="input-wrapper">
						<PhoneInput
							inputClass="input-item input-item--code-select2"
							placeholder="Phone number"
							country="us"
							value={phone}
							onChange={heandleInputPhone}
							disabled={!!userData?.phone}
						/>
						<span className="select__current-arrow">
							<svg
								width="14"
								height="8"
								viewBox="0 0 14 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
									fill="#25252E"
								/>
							</svg>
						</span>
					</div>
					{!isValidPhoneNumber(`+${phone}` || '') && phone && (
						<ErrorMsg msg="Wrong phone number format" />
					)}
				</div>
				<div className="tfa-code__btn">
					<button
						onClick={getSmsCode}
						disabled={!isValidPhoneNumber(`+${phone}` || '') || openTimer?.success}
						type="button"
						className="button button--bigger-height button--full-width"
					>
						Get Code
					</button>
				</div>
			</div>

			<div className="input input--mb-0">
				<p className="input-name">Phone verification code</p>
				<div className="input-wrapper">
					<input
						onChange={heandleInputSmsCode}
						value={smsCode}
						className="input-item input-item--pr-125 "
						type="text"
						placeholder="Phone verification code"
						disabled={!seconds}
					/>
					{openTimer && (
						<>
							<span className="input-icon-text">
								{seconds && openTimer ? <Timer seconds={seconds} setSeconts={setSeconts} /> : null}
							</span>

							<div className="input-icon input-icon--right input-icon--color">
								<SvgTimer />
							</div>
						</>
					)}
				</div>
			</div>
			<div className="form-footer form-footer--end">
				<button
					onClick={on2FaSms}
					disabled={smsCode.length !== 5}
					type="button"
					className="button button--long button--mob-full-width"
				>
					Confirm
				</button>
			</div>
		</div>
	);
};
