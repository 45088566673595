import { ForgotPassForm } from 'components/Forms/ForgotPassForm';
import { IForgotPassSubmitValue } from 'components/Forms/ForgotPassForm/type';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgotPasswordRequest } from 'redux/reducers/auth/reducer';
import { ELinksName } from 'services/constants/list-links-sidebar';
import logo from '../../../assets/images/logo.svg';

const ResetPassword: FC = () => {
	const dispatch = useDispatch();

	const handleSubmitEmail = (values: IForgotPassSubmitValue) => {
		dispatch(forgotPasswordRequest(values));
	};
	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<div className="form">
						<div className="form__header">
							<Link to={ELinksName.LOGIN} className="back-to">
								<span className="back-to__arrow">
									<svg
										className="fill"
										width="33"
										height="32"
										viewBox="0 0 33 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M3.36597 16C3.36597 15.4477 3.81368 15 4.36597 15H28.366C28.9182 15 29.366 15.4477 29.366 16C29.366 16.5523 28.9182 17 28.366 17H4.36597C3.81368 17 3.36597 16.5523 3.36597 16Z"
											fill="#E41472"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M13.0731 7.29289C13.4636 7.68342 13.4636 8.31658 13.0731 8.70711L5.78018 16L13.0731 23.2929C13.4636 23.6834 13.4636 24.3166 13.0731 24.7071C12.6825 25.0976 12.0494 25.0976 11.6588 24.7071L3.65886 16.7071C3.26834 16.3166 3.26834 15.6834 3.65886 15.2929L11.6588 7.29289C12.0494 6.90237 12.6825 6.90237 13.0731 7.29289Z"
											fill="#E41472"
										/>
									</svg>
								</span>
								Back
							</Link>
							<div className="form-logo">
								<img src={logo} alt="logo" />
							</div>
							<p className="form-title form-title--center">Reset password</p>
							<div className="form-subtitle">
								<p>Please enter your email address in order to get the reset password link</p>
							</div>
						</div>
						<ForgotPassForm emailSubmit={handleSubmitEmail} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default ResetPassword;
