/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IStatusRequest } from 'redux/reducers/sellManagement/types';
import { ISellPropertyApi } from './types';

export const sellProperty: ISellPropertyApi = {
	getSellProperty: (params) => http.get(endpoint.sellProperty.GET_ALL_SELL_PROPERTY, { params }),
	deleteSellProperty: (payload: number) =>
		http.delete(endpoint.sellProperty.DELETE_PROPERTY(payload)),
	showProperty: (payload: number) => http.get(endpoint.sellProperty.SHOW_SELL_PROPERTY(payload)),
	statusSellProperty: (payload: IStatusRequest) =>
		http.post(endpoint.sellProperty.STATUS_SELL_PROPERTY(payload)),
};
