import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IFeeManagementRequest, IFeeManagementState } from './types';

const getFeeManagementState = (state: IStoreState): IFeeManagementState => state.feeManagement;

export const getFeeManagementSelector = createSelector(
	[getFeeManagementState],
	(feeManagement: IFeeManagementState): IFeeManagementRequest | null =>
		feeManagement.feeManagements,
);

export const getFeeManagementLoaderSelector = createSelector(
	[getFeeManagementState],
	(feeManagement: IFeeManagementState): boolean => feeManagement.feeManagementsLoader,
);
