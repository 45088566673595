const PendingWithdrawalManualHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">User id</p>
				</div>
				<div className="td">
					<p className="td-name">Date &amp; Time</p>
				</div>
				<div className="td">
					<p className="td-name">Amount</p>
				</div>
				<div className="td">
					<p className="td-name">Bank Info</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Action</p>
				</div>
			</div>
		</div>
	);
};

export default PendingWithdrawalManualHeader;
