import { AccountTransactionHistory } from 'components/UserAcountComponents/AccountTransactionHistory';
import { DividendsDistributed } from 'components/UserAcountComponents/DividendsDistributed';
import { FirstTabUserAcount } from 'components/UserAcountComponents/FirstTabUserAcount';
import { Investments } from 'components/UserAcountComponents/Investments';
import { LeftSidebarAcount } from 'components/UserAcountComponents/LeftSidebarAcount';
import { OrdersHistory } from 'components/UserAcountComponents/OrdersHistory';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { getUserManagementRequest } from 'redux/reducers/usersManagement/reducer';
import { EParamsUserAcount } from 'services/constants/list-links-sidebar';

const UsersAccount: FC = () => {
	const params: { tab: string; id: string } = useParams();
	const { pathname } = useLocation();
	const [openDrop, setOpenDrop] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUserManagementRequest(params.id));
	}, [dispatch, params.id]);

	const addButton = () => {
		setOpenDrop(!openDrop);
	};

	const definePage = () => {
		switch (params.tab) {
			case EParamsUserAcount.INVESTMENTS:
				return <Investments />;
			case EParamsUserAcount.DIVIDENDS_DISTRIBUTED:
				return <DividendsDistributed />;
			case EParamsUserAcount.ORDERS_HISTORY:
				return <OrdersHistory />;
			case EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY:
				return <AccountTransactionHistory />;
			default:
				return <FirstTabUserAcount />;
		}
	};

	return (
		<section className="admin-section">
			<div className="container">
				<div className="admin">
					<div className="admin__bar admin__bar--small">
						<div className="select select--sidebar-nav">
							<button
								onClick={() => setOpenDrop(!openDrop)}
								type="button"
								className="select__current"
							>
								<span className="select__current-text-custom">
									{params.tab === 'user-info'
										? pathname.slice(1, pathname.slice(1).indexOf('/')).replaceAll('-', ' ')
										: params.tab.replaceAll('-', ' ')}
								</span>

								<span className="select__current-arrow">
									<svg
										width="14"
										height="8"
										viewBox="0 0 14 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
											fill="#25252E"
										/>
									</svg>
								</span>
							</button>
							<LeftSidebarAcount addButton={addButton} openDrop={openDrop} />
						</div>
					</div>
					{definePage()}
				</div>
			</div>
		</section>
	);
};

export default UsersAccount;
