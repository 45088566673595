import React, { FC, useEffect } from 'react';

interface IDetailsProps {
	closeModal: () => void;
}

const BankInfoPopUp: FC<IDetailsProps> = ({ closeModal }) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--type2 popup--bigger">
					<p className="popup-title">Bank Info</p>
					<div className="popup-body">
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Amount</p>
							<p className="order-option__value">Recieve 95.00 USD</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Beneficiary name </p>
							<p className="order-option__value">Dillan Smith</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Beneficiary account number</p>
							<p className="order-option__value">165959</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Bank name</p>
							<p className="order-option__value">Privat Bank</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Bank Address</p>
							<p className="order-option__value">Main Street, Hackensack, New Jersey 07601</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">SWIFT/BIC code</p>
							<p className="order-option__value">CHASUS33XXX.</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Beneficiary address line 1</p>
							<p className="order-option__value">Main Street, Hackensack, New Jersey 07601</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Beneficiary address line 2</p>
							<p className="order-option__value">Main Street, Hackensack, New Jersey 07601</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Account number</p>
							<p className="order-option__value">1568659</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Intermediary bank </p>
							<p className="order-option__value">1110 0002 5</p>
						</div>
						<div className="order-option order-option--mob-column">
							<p className="order-option__name">Intermediary ABA code </p>
							<p className="order-option__value">1110 0002 5</p>
						</div>
					</div>
					<div className="popup-footer popup-footer--more-mt popup-footer--mob-column">
						<button
							type="button"
							className="button button--type7 button--full-width"
							onClick={closeModal}
						>
							Cancel
						</button>
						<button type="button" className="button button--full-width ">
							Confirm
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BankInfoPopUp;
