import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	ICheckPassResponse,
	IGenerate2faKeyResponse,
	IInitialState,
	ISmsCodeResponse,
} from './types';

const getSettingsState = (state: IStoreState): IInitialState => state.settings;

export const get2faData = createSelector(
	[getSettingsState],
	(settings: IInitialState): null | IGenerate2faKeyResponse => settings.data2fa,
);

export const getOpen2Fa = createSelector(
	[getSettingsState],
	(settings: IInitialState): boolean => settings.open2FaPage,
);

export const getOpenModal = createSelector(
	[getSettingsState],
	(settings: IInitialState): string => settings.openModalEmail,
);

export const getSms2Fa = createSelector(
	[getSettingsState],
	(settings: IInitialState): string | null => settings.sms_2fa,
);

export const getCheckPass = createSelector(
	[getSettingsState],
	(settings: IInitialState): ICheckPassResponse | null => settings.checkPass,
);

export const getOpenTimer = createSelector(
	[getSettingsState],
	(settings: IInitialState): ISmsCodeResponse | null => settings.openTimer,
);

export const getOpen2FaSms = createSelector(
	[getSettingsState],
	(settings: IInitialState): boolean => settings.open2FaSmsModal,
);

export const getOpen2FaGoogle = createSelector(
	[getSettingsState],
	(settings: IInitialState): boolean => settings.open2FaGoogle,
);
