/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from 'untils/notificationContainer';
import { IFeeManagement } from 'pages/Dashboard/FeeManagement';
import { IFeeManagementRequest } from './types';
import {
	feeManagementInitState,
	getFeeManagementRequest,
	getFeeManagementSuccess,
	postFeeManagementRequest,
	postFeeManagementSuccess,
} from './reducer';

function* getFeeManagementWorker() {
	try {
		yield put(showLoading());
		const response: { data: IFeeManagementRequest } = yield call(
			api.feeManagement.getFeeManagement,
		);
		yield put(getFeeManagementSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(feeManagementInitState());
	}
}

function* postFeeManagementWorker(action: PayloadAction<IFeeManagement>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: IFeeManagementRequest } = yield call(
			api.feeManagement.postFeeManagement,
			payload,
		);
		yield put(postFeeManagementSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(feeManagementInitState());
	}
}

export function* feeManagementSaga() {
	yield takeEvery(getFeeManagementRequest.type, getFeeManagementWorker);
	yield takeEvery(postFeeManagementRequest.type, postFeeManagementWorker);
}
