/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from 'untils/notificationContainer';
import {
	IDistributeRequest,
	IDividendsHistory,
	IDividendsHistoryRequest,
	IDividendsRequest,
	IDividendsResponse,
	IDividendsSum,
	IStatusRequest,
} from './types';
import {
	dividendsManagementInitState,
	getDividendHistoryRequest,
	getDividendsHistorySuccess,
	getDividendsRequest,
	getDividendsSuccess,
	getDividendsSumRequest,
	getDividendsSumSuccess,
	postDistributeRequest,
	postStatusRequest,
	postStatusSuccess,
} from './reducer';

function* getDevidendsWorker(action: PayloadAction<IDividendsRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: { dividends: IDividendsResponse } } = yield call(
			api.dividendsManagement.getDividends,
			payload,
		);

		yield put(getDividendsSuccess(response.data.dividends));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(dividendsManagementInitState());
		yield put(hideLoading());
	}
}

function* postStatusWorker(action: PayloadAction<IStatusRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.dividendsManagement.postRentStatus, payload);

		yield put(postStatusSuccess(payload));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(dividendsManagementInitState());
		yield put(hideLoading());
	}
}

function* postDistributeWorker(action: PayloadAction<IDistributeRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.dividendsManagement.postDistribute, payload);
		yield notificationContainer('Distribution was successful', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(dividendsManagementInitState());
		yield put(hideLoading());
	}
}

function* getDividendsSumWorker() {
	try {
		yield put(showLoading());
		const response: { data: IDividendsSum } = yield call(api.dividendsManagement.getDividendsSum);
		yield put(getDividendsSumSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getDividendsHistoryWorker(action: PayloadAction<IDividendsHistoryRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: { dividends: IDividendsHistory } } = yield call(
			api.dividendsManagement.getDividendsHistory,
			payload,
		);
		yield put(getDividendsHistorySuccess(response.data.dividends));
	} catch (error) {
		yield put(getDividendsHistorySuccess(null));
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(dividendsManagementInitState());
		yield put(hideLoading());
	}
}

export function* dividendsManagementSaga() {
	yield takeEvery(getDividendsRequest.type, getDevidendsWorker);
	yield takeEvery(postStatusRequest.type, postStatusWorker);
	yield takeEvery(postDistributeRequest.type, postDistributeWorker);
	yield takeEvery(getDividendsSumRequest.type, getDividendsSumWorker);
	yield takeEvery(getDividendHistoryRequest.type, getDividendsHistoryWorker);
}
