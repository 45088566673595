import { FC } from 'react';

export const PlatformTokensHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">Name of property</p>
				</div>
				<div className="td">
					<p className="td-name">Date of issue token</p>
				</div>
				<div className="td">
					<p className="td-name">Price of property</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Amount of tokens</p>
				</div>
			</div>
		</div>
	);
};
