import { FC, useEffect } from 'react';
import { IPropsPopUpNotDelete } from '../types';

export const PopUpNotDelete: FC<IPropsPopUpNotDelete> = ({
	isClosePopUp,
	blockedUser,
	closeAllPopUp,
}) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--type2 popup--bigger">
					<p className="popup-title">
						Account cannot be deleted, user have available funds, you can block user
					</p>
					<div className="popup-footer popup-footer--more-mt popup-footer--mob-column">
						<button
							onClick={() => closeAllPopUp()}
							type="button"
							className="button button--type7 button--full-width"
						>
							Cancel
						</button>
						<button
							onClick={() => blockedUser(1, Number(isClosePopUp))}
							type="button"
							className="button button--full-width"
						>
							Block user
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
