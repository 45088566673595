import classNames from 'classnames';
import { ListPhotos } from 'components/ListPhotos';
import { FC, MouseEvent } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { statusPropertyRequest } from 'redux/reducers/sellManagement/reducer';
import { SvgSellPropertyShowImg } from 'ui/svg-image/SvgSellProperty';
import { IPropsFileSubmit } from './types';

export const FormEditOrder: FC<IPropsFileSubmit> = ({ setOpenAllPhoto, sellProperty }) => {
	const dispatch = useDispatch();
	const param: { id: string } = useParams();

	const status = (event: MouseEvent) => {
		const target = event.target as HTMLButtonElement;
		dispatch(statusPropertyRequest({ property_id: +param.id, status: +target.name }));
	};

	return (
		<form className="sell-form">
			<p className="info-title info-title--left info-title--fw-600">Description</p>
			<div className="form-group">
				<div className="block-step">
					<span className="block-step__icon">
						<svg
							width="24"
							height="16"
							viewBox="0 0 24 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M6.36735 0.5H8.32653L1.95918 15.5H0L6.36735 0.5Z" fill="#E41472" />
							<path d="M14.2043 0.5H16.1634L9.7961 15.5H7.83691L14.2043 0.5Z" fill="#E41472" />
							<path d="M22.0412 0.5H24.0004L17.633 15.5H15.6738L22.0412 0.5Z" fill="#E41472" />
						</svg>
					</span>
					1
				</div>
				<div className="input">
					<p className="input-name">Provide a description of the real estate</p>
					<div className="input-wrapper">
						<div className="textarea textarea-default">
							<div className="">{sellProperty.description}</div>
						</div>
					</div>
				</div>
			</div>
			<p className="info-title info-title--left info-title--fw-600">Media</p>
			<div className="form-group">
				<div className="block-step">
					<span className="block-step__icon">
						<svg
							width="24"
							height="16"
							viewBox="0 0 24 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M6.36735 0.5H8.32653L1.95918 15.5H0L6.36735 0.5Z" fill="#E41472" />
							<path d="M14.2043 0.5H16.1634L9.7961 15.5H7.83691L14.2043 0.5Z" fill="#E41472" />
							<path d="M22.0412 0.5H24.0004L17.633 15.5H15.6738L22.0412 0.5Z" fill="#E41472" />
						</svg>
					</span>
					2
				</div>
				<div className="form-item">
					<p className="input-name">Attach media (Photos, till 50 pcs)</p>
					<div className="upload-gallery">
						{sellProperty.property_images.slice(0, 6).map((image, index) => (
							<div key={String(image.url) + String(index)} className="uploaded-item">
								<div className="uploaded-photo">
									{sellProperty.property_images?.length <= 6 || Number(index) + 1 < 6 ? (
										<ListPhotos image={String(image.url)} />
									) : (
										<>
											<img src={image.url} alt="" />
											<button
												onClick={() => setOpenAllPhoto(true)}
												type="button"
												className="upload-action"
											>
												<span className="upload-action__icon">
													<SvgSellPropertyShowImg />
												</span>
												Show more
											</button>
										</>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="form-box">
				<p className="info-title info-title--fw-600">Provide contact information:</p>
				<div className="form-group">
					<div className="block-step">
						<span className="block-step__icon">
							<svg
								width="24"
								height="16"
								viewBox="0 0 24 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.36735 0.5H8.32653L1.95918 15.5H0L6.36735 0.5Z" fill="#E41472" />
								<path d="M14.2043 0.5H16.1634L9.7961 15.5H7.83691L14.2043 0.5Z" fill="#E41472" />
								<path d="M22.0412 0.5H24.0004L17.633 15.5H15.6738L22.0412 0.5Z" fill="#E41472" />
							</svg>
						</span>
						3
					</div>
					<div className="input">
						<p className="input-name">Name</p>
						<div className="input-wrapper">
							<div className="input-item input-item-center">{sellProperty.user_name}</div>
						</div>
					</div>
					<div className="input">
						<p className="input-name">Email</p>
						<div className="input-wrapper">
							<div className="input-item input-item-center">{sellProperty.email}</div>
						</div>
					</div>
					<div className="input">
						<p className="input-name">Phone number</p>
						<div className="input-wrapper">
							<PhoneInput
								disabled
								value={sellProperty.phone}
								placeholder="Phone number"
								country="us"
							/>
							<span className="select__current-arrow">
								<svg
									width="14"
									height="8"
									viewBox="0 0 14 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
										fill="#25252E"
									/>
								</svg>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="form-footer form-footer--more-mt">
				<button
					onClick={status}
					name="2"
					type="button"
					disabled={sellProperty.status === 2 || sellProperty.status === 1}
					className={classNames('button-custom', {
						'button-custom--color': sellProperty.status === 2,
					})}
				>
					Decline
				</button>
				<button
					onClick={status}
					name="1"
					type="button"
					className={classNames('button-custom', {
						'button-custom--color': sellProperty.status === 1,
					})}
					disabled={sellProperty.status === 1 || sellProperty.status === 2}
				>
					Accepted
				</button>
			</div>
			{/* <Link to="/" onClick={development} className="link link--type2 link--mt16">
				Create the offer
			</Link> */}
		</form>
	);
};
