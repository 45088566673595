import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { ETypePopUp } from 'services/constants/type-pop-up';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'untils/notificationContainer';
import {
	changeStatusRequest,
	changeStatusSuccess,
	createUsdcWalletRequest,
	createUsdcWalletSuccess,
	createUsdWalletRequest,
	createUsdWalletSuccess,
	getBalancesLimitsReguest,
	getBalancesLimitsSuccess,
	getHistoryConvertsReguest,
	getHistoryConvertsSuccess,
	getInvestmentsAllReguest,
	getInvestmentsAllSuccess,
	getLimitationsReguest,
	getLimitationsSuccess,
	getProfitLimitsReguest,
	getProfitLimitsSuccess,
	getTransactionHistoryAdminReguest,
	getTransactionHistoryAdminSuccess,
	getTransactionHistoryAllReguest,
	getTransactionHistoryAllSuccess,
	limitsModifyReguest,
	walletsInitState,
	withdrawUsdcWalletRequest,
	withdrawUsdcWalletSuccess,
} from './reducer';
import {
	IChangeStatusRequest,
	ICreateUsdcRequest,
	ICreateUsdcResponse,
	IHistoryConvertsResponse,
	ILimitsModifyReguest,
	ISagaDepFitReq,
	ITransctionsHistoryRequest,
	ITransctionsHistoryResponse,
	IWithdrawUsdcRequest,
	TLimits,
} from './types';

function* getBalancesLimitsWorker() {
	try {
		yield put(showLoading());
		const response: AxiosResponse = yield call(api.wallets.getBalancesLimits);

		yield put(getBalancesLimitsSuccess(response.data[0]));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getProfitLimitsWorker() {
	try {
		yield put(showLoading());
		const response: AxiosResponse = yield call(api.wallets.getProfitLimits);

		yield put(getProfitLimitsSuccess(response.data[0]));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* limitsModifyWorker(action: PayloadAction<ILimitsModifyReguest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.wallets.postLimitsModifyReguest, payload);
		const response: { data: { limits: TLimits } } = yield call(api.wallets.getLimitations);

		yield put(getLimitationsSuccess(response.data.limits));
		notificationContainer('Limits have been changed successfully', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createUsdWalletWorker(action: PayloadAction<ISagaDepFitReq>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.wallets.createWallet, payload.data);
		yield put(createUsdWalletSuccess());
		payload.setPopUp(true);
	} catch (error) {
		payload.setPopUp(false);
		yield put(walletsInitState());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createUsdcWalletWorker(action: PayloadAction<ICreateUsdcRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: ICreateUsdcResponse } = yield call(
			api.wallets.createWalletUsdc,
			payload,
		);

		yield put(createUsdcWalletSuccess(response.data));
	} catch (error) {
		yield put(walletsInitState());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* withdrawUsdcWalletWorker(action: PayloadAction<IWithdrawUsdcRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.wallets.createWalletUsdc, payload.data);
		yield put(withdrawUsdcWalletSuccess());
		payload.openPopUp(ETypePopUp.SUBMIT);
	} catch (error) {
		yield put(walletsInitState());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getLimitationsWorker() {
	try {
		const response: { data: { limits: TLimits } } = yield call(api.wallets.getLimitations);

		yield put(getLimitationsSuccess(response.data.limits));
	} catch (error) {
		yield put(walletsInitState());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTransactionsHistoryAdminWorker(action: PayloadAction<ITransctionsHistoryRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: ITransctionsHistoryResponse } = yield call(
			api.wallets.getTransactionHistory,
			payload,
		);
		yield put(getTransactionHistoryAdminSuccess(response.data));
	} catch (error) {
		yield put(walletsInitState());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTransactionsHistoryAllWorker(action: PayloadAction<ITransctionsHistoryRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: ITransctionsHistoryResponse } = yield call(
			api.wallets.getTransactionHistoryAll,
			payload,
		);
		yield put(getTransactionHistoryAllSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getHistoryConvertsWorker(action: PayloadAction<IHistoryConvertsResponse>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IHistoryConvertsResponse } = yield call(
			api.wallets.getHistoryConverts,
			payload,
		);
		yield put(getHistoryConvertsSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getInvestmentsAllWorker(action: PayloadAction<ITransctionsHistoryRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: AxiosResponse = yield call(api.wallets.getInvestmentsAll, payload);
		yield put(getInvestmentsAllSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* changeStatusWorker(action: PayloadAction<IChangeStatusRequest>) {
	const { payload } = action;

	try {
		yield call(api.wallets.changeStatus, payload);
		notificationContainer('', 'success');
		yield put(changeStatusSuccess(+payload.payment));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

export function* walletsSaga() {
	yield takeEvery(getBalancesLimitsReguest.type, getBalancesLimitsWorker);
	yield takeEvery(limitsModifyReguest.type, limitsModifyWorker);
	yield takeEvery(createUsdWalletRequest.type, createUsdWalletWorker);
	yield takeEvery(createUsdcWalletRequest.type, createUsdcWalletWorker);
	yield takeEvery(withdrawUsdcWalletRequest.type, withdrawUsdcWalletWorker);
	yield takeEvery(getProfitLimitsReguest.type, getProfitLimitsWorker);
	yield takeEvery(getTransactionHistoryAdminReguest.type, getTransactionsHistoryAdminWorker);
	yield takeEvery(getTransactionHistoryAllReguest.type, getTransactionsHistoryAllWorker);
	yield takeEvery(getHistoryConvertsReguest.type, getHistoryConvertsWorker);
	yield takeEvery(getInvestmentsAllReguest.type, getInvestmentsAllWorker);
	yield takeEvery(getLimitationsReguest.type, getLimitationsWorker);
	yield takeEvery(changeStatusRequest.type, changeStatusWorker);
}
