import { FC } from 'react';

interface IProps {
	count: number;
	amount: number;
}

export const Uploading: FC<IProps> = ({ amount, count }) => {
	return (
		<section className="uploader-wrapper">
			<div className="item-loader">
				<p className="item-loader__upload-text">{`Uploading images... ${Math.ceil(
					amount && count ? count / (amount / 100) : 0,
				)}%`}</p>
				<div className="item-loader__item">
					<span
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						style={{ width: `${amount && count ? count / (amount / 100) || 0 : 0}%` }}
					/>
				</div>
			</div>
		</section>
	);
};
