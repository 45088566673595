// import classNames from 'classnames';
import ErrorMsg from 'components/ErrorMsg';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoader } from 'redux/reducers/wallets/selectors';
import { IFormDepositFiatInitialValues } from 'redux/reducers/wallets/types';
// import { ELinksName, EParamsNameWallet } from 'services/constants/listLinks';
// import { SvgArrowBack } from 'ui/svg-image/SvgArrowBack';
import yup from 'untils/capsLockValidate';
import { cvc, exp_month, nameOnCard, number } from '../../../../../untils/formValidation';
import { IPropsAddCard } from '../../types';

export const AddCard: FC<IPropsAddCard> = ({ dataDepositeFiat, submit }) => {
	const param: { tab: string; id: string } = useParams();
	const loader = useSelector(getLoader);

	const clearNumber = (value: number | string) => {
		return String(value).replace(/\D+/g, '');
	};

	const initialValues = {
		number: '',
		exp_month: '',
		exp_year: '',
		cvc: '',
		nameOnCard: '',
	};

	const validationSchema = yup.object().shape({
		number,
		cvc,
		nameOnCard,
		exp_month,
	});

	const formatExpirationDate = (value: number | string) => {
		const clearValue = clearNumber(value);

		if (clearValue.length >= 3) {
			return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
		}

		return clearValue;
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IFormDepositFiatInitialValues, { setSubmitting, resetForm }) => {
				submit({
					payment_system: 'stripe',
					type: dataDepositeFiat.type,
					amount: +param.id,
					currency: 'usd',
					message: 'Test stripe api',
					payerData: {
						...values,
						exp_month: values.exp_month.slice(0, 2),
						exp_year: values.exp_month.slice(3),
					},
				});
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors, values }) => {
				return (
					<Form className="profile__content">
						<div className="profile-wrapper">
							<div className="profile-header profile-header--type2">
								{/* <div className="profile-header__group">
									<Link
										to={`${ELinksName.CURRENT_BALANCE}/${EParamsNameWallet.DEPOSIT_FIAT}`}
										className="back-to back-to--type2 back-to--start back-to--mb-16"
									>
										<span className="back-to__arrow">
											<SvgArrowBack />
										</span>
										Back to user deposit
									</Link>
									<p className="block-title block-title--smaller ">Bank card</p>
								</div> */}
							</div>
							<div className="operation-form">
								<p className="info-title info-title--fw-600">Enter your card information</p>
								<div className="form-group form-group--more-mt">
									<div className="input ">
										<p className="input-name">Cardholder</p>
										<div className="input-wrapper">
											<Field
												name="nameOnCard"
												className="input-item"
												type="text"
												placeholder="Enter your Name and Last name"
												value={values.nameOnCard}
											/>
										</div>
										{errors?.nameOnCard ? <ErrorMsg msg={errors.nameOnCard} /> : null}
									</div>
									<div className="input ">
										<p className="input-name">Card number</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												type="text"
												placeholder="Enter your card number"
												name="number"
												maxLength={19}
												value={values.number
													.replace(/\s/g, '')
													.replace(/(\d{4})/g, '$1 ')
													.trim()}
											/>
										</div>
										{errors?.number ? <ErrorMsg msg={errors.number} /> : null}
									</div>
									<div className="card-row">
										<div className="input input--mb-0">
											<p className="input-name">Expiry date</p>
											<div className="input-wrapper">
												<Field
													name="exp_month"
													className="input-item"
													type="text"
													placeholder="MM/YY"
													maxLength={5}
													value={formatExpirationDate(values.exp_month)}
												/>
											</div>
											{errors?.exp_month ? <ErrorMsg msg={errors.exp_month} /> : null}
										</div>
										<div className="input input--mb-0">
											<p className="input-name">Security code</p>
											<div className="input-wrapper">
												<Field
													name="cvc"
													className="input-item"
													type="text"
													placeholder="CVV"
													maxLength={4}
												/>
											</div>
											{errors?.cvc ? <ErrorMsg msg={errors.cvc} /> : null}
										</div>
									</div>
								</div>
								{/*                     
                    <!-- -
                    <div class="paymernt-method">
                      <p class="paymernt-method__name">
                        Payment method: <span>**** 4578</span>
                      </p>
                      <button class="link link--bigger">
                        Add new card
                      </button>
                    </div>
                    --> */}
								<div className="form-footer form-footer--end form-footer--more-mt">
									<button
										disabled={!(isValid && dirty) || isSubmitting}
										type="submit"
										className="button button--long button--mob-full-width"
									>
										{loader ? (
											<div className="louder-wrapper-custom">
												<Oval secondaryColor="grey" color="#E41472" height={30} width={30} />
											</div>
										) : (
											'Deposit'
										)}
									</button>
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
