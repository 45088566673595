import { FC, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLimits } from 'redux/reducers/wallets/selectors';
import { UsdcRow } from './UsdcRow';
import { UsdRow } from './UsdRow';

export const TableBodyLimitation: FC = () => {
	const [openEditUsdc, setOpenEditUsdc] = useState(false);
	const [openEditUsd, setOpenEditUsd] = useState(false);
	const limits = useSelector(getLimits);

	const handleEditBatton = (e: MouseEvent) => {
		const target = e.currentTarget as HTMLButtonElement;
		target.name === 'usdc' ? setOpenEditUsdc(!openEditUsdc) : setOpenEditUsd(!openEditUsd);
	};

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<p className="td-value td-value--size2 td-value--fw600">USDC</p>
				</div>
				{limits && (
					<UsdcRow
						openEditUsdc={openEditUsdc}
						handleEditBatton={handleEditBatton}
						limits={limits[0]}
					/>
				)}
			</div>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<p className="td-value td-value--size2 td-value--fw600">USD</p>
				</div>
				{limits && (
					<UsdRow
						openEditUsd={openEditUsd}
						handleEditBatton={handleEditBatton}
						limits={limits[1]}
					/>
				)}
			</div>
		</div>
	);
};
