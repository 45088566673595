import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';

export const HeaderLimitation: FC = () => {
	const params: { tab: string } = useParams();
	return (
		<div className="table-top-wrapper table-top-wrapper--center table-top-wrapper--mb24">
			<div className="table-title table-title--m0">
				<p>Limitations</p>
			</div>
			<div className="switcher switcher--smaller">
				<Link
					to={`${ELinksName.WALLETS_LIMITATION}/${EParamsNameWallet.LIMITATION}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === EParamsNameWallet.CRYPTO || !params.tab,
					})}
					type="button"
				>
					Limitations
				</Link>
				<Link
					to={`${ELinksName.WALLETS_PENDING_WITHDRAWALS}/${EParamsNameWallet.WITHDRAW}/${EParamsNameWallet.FIAT}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === EParamsNameWallet.CRYPTO,
					})}
					type="button"
				>
					Withdraw
				</Link>
			</div>
		</div>
	);
};
