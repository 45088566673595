/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useState } from 'react';

interface IProps {
	data: {
		price: number;
		setText: (value: string) => void;
	};
}

export const InputForPrice: FC<IProps> = ({ data }) => {
	const [textInput, setTextInput] = useState<string>(String(data.price));

	const handleInput = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		!Number.isNaN(+target.value) && setTextInput(target.value);
		!Number.isNaN(+target.value) && data.setText(String(target.value));
	};

	useEffect(() => {
		data.setText(String(data.price));
	}, []);

	return (
		<input
			onChange={handleInput}
			className="input-item input-item--td"
			type="text"
			placeholder="Set price"
			value={
				textInput[0] === '.'
					? `0${String(textInput).replace(/[^0-9.]/, '')}`
					: String(textInput).replace(/[^0-9.]{0,1}$/, '')
			}
		/>
	);
};
