import { IPropsDividendsTable } from 'components/UserAcountComponents/types';
import moment from 'moment';
import numeral from 'numeral';
import { FC } from 'react';

export const DividendsTableBody: FC<IPropsDividendsTable> = ({ dividends }) => {
	return (
		<div className="table-body">
			{dividends.map((dividend) => (
				<div key={dividend.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Apartment Name</p>
						<p className="td-value td-value--medium td-value-custom-up">{dividend.estate.title}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Date of payment</p>
						<p className="td-value td-value--medium">
							{moment(dividend.date).format('DD.MM.YY h:mm:ss')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">January</p>
						<p className="td-value td-value--medium">
							{numeral(dividend.amount).format('0,0.00').replaceAll(',', ' ')}
						</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">2021</p>
						<p className="td-value td-value--medium">
							{numeral(dividend.amount).format('0,0.00').replaceAll(',', ' ')}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};
