import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { field } from '../../../untils/formValidation';
import { IForgotPassSubmitValue, IForgotPasswordForm } from './type';
import yup from '../../../untils/capsLockValidate';

export const ForgotPassForm: FC<IForgotPasswordForm> = ({ emailSubmit }) => {
	const initialValues = {
		field: '',
	};

	const validationSchema = yup.object().shape({
		field,
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IForgotPassSubmitValue, { resetForm, setSubmitting }) => {
				emailSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<Form>
						<div className="form__body">
							<div className="form-group">
								<div className="input">
									<div className="input-wrapper">
										<Field
											type="email"
											placeholder="Your email"
											name="field"
											required
											className="input-item"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="form-footer">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="button button--long"
							>
								Submit
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
