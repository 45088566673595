/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IFeeManagement } from 'pages/Dashboard/FeeManagement';
import { IFeeManagementApi } from './types';

export const feeManagement: IFeeManagementApi = {
	getFeeManagement: () => http.get(endpoint.feeManagement.FEE_MANAGEMENT),
	postFeeManagement: (payload: IFeeManagement) =>
		http.post(endpoint.feeManagement.FEE_MANAGEMENT, payload),
};
