import ErrorMsg from 'components/ErrorMsg';
import { Field, Form, Formik } from 'formik';
import { IFeeManagement } from 'pages/Dashboard/FeeManagement';
import { FC, useState } from 'react';
import { ICommissionsRequest } from 'redux/reducers/fee-management/types';
import { SvgCheck, SvgClose, SvgEdit } from 'ui/svg-image/SvgFeeManagement';
import { validationFeeSchema } from 'untils/formValidation';

interface IPropsFeeManagement {
	feeSubmit: (value: IFeeManagement) => void;
	commission: ICommissionsRequest;
}

export const FeeManagementTable: FC<IPropsFeeManagement> = ({ feeSubmit, commission }) => {
	const [commisionId, setCommisionId] = useState<number | null>(null);

	const initialValues = {
		asset_id: commission.asset_id,
		buy_token: commission.buy_token,
		sell_token: commission.sell_token,
		convert: commission.convert,
		deposit: commission.deposit,
		withdrawal: commission.withdrawal,
		invest: commission.invest,
	};

	return (
		<Formik
			key={commission.id}
			initialValues={initialValues}
			validationSchema={validationFeeSchema}
			onSubmit={(values: IFeeManagement, { setSubmitting }) => {
				const data = {
					...values,
					asset_id: Number(commisionId),
				};
				feeSubmit(data);
				setSubmitting(false);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, values, errors, dirty, resetForm }) => {
				return (
					<Form className="table-body table-body-custom">
						<div className="tr">
							<div className="td">
								<p className="td-hidden-name">Currency</p>
								<p className="td-value td-value-center td-value--medium td-value--fw-600">
									{commission.asset.name || 'Set the %'}
								</p>
							</div>
							<div className="td">
								{commisionId === commission.id ? (
									<div className="input">
										<div className="input-wrapper">
											<Field
												className="input-item input-item--td"
												type="text"
												placeholder="Set price %"
												value={values.buy_token}
												name="buy_token"
											/>
										</div>
										{errors.buy_token ? (
											<ErrorMsg msg={errors.buy_token.replace('_', ' ')} />
										) : null}
									</div>
								) : (
									<>
										<p className="td-hidden-name">Buy Tokens</p>
										<p className="td-value td-value--medium grey">
											{`${commission.buy_token}%` || 'Set the %'}
										</p>
									</>
								)}
							</div>
							<div className="td">
								{commisionId === commission.id ? (
									<div className="input">
										<div className="input-wrapper">
											<Field
												className="input-item input-item--td"
												type="text"
												placeholder="Set price %"
												value={values.sell_token}
												name="sell_token"
											/>
										</div>
										{errors.sell_token ? (
											<ErrorMsg msg={errors.sell_token.replace('_', ' ')} />
										) : null}
									</div>
								) : (
									<>
										<p className="td-hidden-name">Sell Tokens</p>
										<p className="td-value td-value--medium grey">
											{`${commission.sell_token}%` || 'Set the %'}
										</p>
									</>
								)}
							</div>
							<div className="td">
								{commisionId === commission.id ? (
									<div className="input">
										<div className="input-wrapper">
											<Field
												className="input-item input-item--td"
												type="text"
												placeholder="Set price %"
												value={values.convert}
												name="convert"
											/>
										</div>
										{errors.convert ? <ErrorMsg msg={errors.convert.replace('_', ' ')} /> : null}
									</div>
								) : (
									<>
										<p className="td-hidden-name">Convert</p>
										<p className="td-value td-value--medium grey">
											{`${commission.convert}%` || 'Set the %'}
										</p>
									</>
								)}
							</div>
							<div className="td">
								{commisionId === commission.id ? (
									<div className="input">
										<div className="input-wrapper">
											<Field
												className="input-item input-item--td"
												type="text"
												placeholder="Set price %"
												value={values.deposit}
												name="deposit"
											/>
										</div>
										{errors.deposit ? <ErrorMsg msg={errors.deposit.replace('_', ' ')} /> : null}
									</div>
								) : (
									<>
										<p className="td-hidden-name">Deposit</p>
										<p className="td-value td-value--medium grey">
											{`${commission.deposit}%` || 'Set the %'}
										</p>
									</>
								)}
							</div>
							<div className="td">
								{commisionId === commission.id ? (
									<div className="input">
										<div className="input-wrapper">
											<Field
												className="input-item input-item--td"
												type="text"
												placeholder="Set price %"
												value={values.withdrawal}
												name="withdrawal"
											/>
										</div>
										{errors.withdrawal ? (
											<ErrorMsg msg={errors.withdrawal.replace('_', ' ')} />
										) : null}
									</div>
								) : (
									<>
										<p className="td-hidden-name">Withdraw</p>
										<p className="td-value td-value--medium grey">
											{`${commission.withdrawal}%` || 'Set the %'}
										</p>
									</>
								)}
							</div>
							<div className="td">
								{commisionId === commission.id ? (
									<div className="input">
										<div className="input-wrapper">
											<Field
												className="input-item input-item--td"
												type="text"
												placeholder="Set price %"
												value={values.invest}
												name="invest"
											/>
										</div>
										{errors.invest ? <ErrorMsg msg={errors.invest.replace('_', ' ')} /> : null}
									</div>
								) : (
									<>
										<p className="td-hidden-name">Invest</p>
										<p className="td-value td-value--medium grey">
											{`${commission.invest}%` || 'Set the %'}
										</p>
									</>
								)}
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Actions</p>
								<div className="table-buttons">
									{commisionId === commission.id ? (
										<>
											<button disabled={!(isValid && dirty) || isSubmitting} type="submit">
												<SvgClose />
											</button>
											<button
												onClick={() => {
													resetForm();
													setCommisionId(null);
												}}
												type="button"
											>
												<SvgCheck />
											</button>
										</>
									) : (
										<button
											onClick={() => setCommisionId(commission.id)}
											type="button"
											className="active"
										>
											<SvgEdit />
										</button>
									)}
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
