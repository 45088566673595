import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IInterestRequest } from 'redux/reducers/interestRequests/types';
import { IInterestRequestsApi } from './types';

export const interestRequests: IInterestRequestsApi = {
	getUsersInterestList: (payload: IInterestRequest) => {
		const { params, id } = payload;
		return http.get(endpoint.interestRequests.GET_LIST_INTEREST(id), { params });
	},
};
