/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInterestRequest, IInterestRequestsState, IInterestResponse } from './types';

export const initialState: IInterestRequestsState = {
	userInterestList: null,
	loader: false,
};

const interestRequests = createSlice({
	name: '@@interestRequests',
	initialState,
	reducers: {
		getInterestRequest: (state, action: PayloadAction<IInterestRequest>) => {
			const editUserState = state;
			editUserState.loader = true;
		},

		getInterestSuccess: (state, action: PayloadAction<IInterestResponse | null>) => {
			const editUserState = state;
			const { payload } = action;

			editUserState.loader = false;
			editUserState.userInterestList = payload;
		},

		getInterestState: () => initialState,
	},
});

export default interestRequests.reducer;
export const { getInterestRequest, getInterestSuccess } = interestRequests.actions;
