import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	IChangeStatusRequest,
	ICreateUsdcRequest,
	IDepositeFiatRequest,
	ILimitsModifyReguest,
	ITransctionsHistoryRequest,
} from 'redux/reducers/wallets/types';
import { IWalletsApi } from './types';

export const wallets: IWalletsApi = {
	getBalancesLimits: () => http.get(endpoint.wallets.GET_BALANCES),
	getProfitLimits: () => http.get(endpoint.wallets.GET_PROFIT),
	getTransactionHistory: (params: ITransctionsHistoryRequest) =>
		http.get(endpoint.wallets.GET_HISTORY, { params }),
	getTransactionHistoryAll: (params: ITransctionsHistoryRequest) =>
		http.get(endpoint.wallets.GET_HISTORY_ALL, { params }),
	getInvestmentsAll: (params: ITransctionsHistoryRequest) =>
		http.get(endpoint.wallets.GET_APARTMENT_INVEST, { params }),
	getHistoryConverts: (params: ITransctionsHistoryRequest) =>
		http.get(endpoint.wallets.GET_HISTORY_CONVERTS, { params }),
	postLimitsModifyReguest: (payload: ILimitsModifyReguest) =>
		http.post(endpoint.wallets.MODIFY_LIMITS, payload),
	createWallet: (payload: IDepositeFiatRequest) =>
		http.post(endpoint.wallets.CREATE_WALLET, payload),
	createWalletUsdc: (payload: ICreateUsdcRequest) =>
		http.post(endpoint.wallets.CREATE_WALLET, payload),
	getLimitations: () => http.get(endpoint.wallets.GET_LIMITS),
	changeStatus: (params: IChangeStatusRequest) => http.get(endpoint.wallets.CHANGE_STATUS(params)),
};
