import { Pagination } from 'components/Pagination';
import { Sidebar } from 'components/Sidebar';
import { DashboardLimitation } from 'components/WalletsComponents/DashboardLimitation';
import { TransactionsHistoryHeader } from 'components/WalletsComponents/TransactionsHistoryHeader';
import { TransactionsHistoryTableBody } from 'components/WalletsComponents/TransactionsHistoryTableBody';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionHistoryAdminReguest } from 'redux/reducers/wallets/reducer';
import {
	getTransactionHistotyAdmin,
	getTransactionHistotyAdminLoader,
} from 'redux/reducers/wallets/selectors';
import { Loader } from 'components/Loader';
import { useParams } from 'react-router';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import { TabsHistory } from 'components/TrasactionHistoryComponents/TabsHistory';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';

const WalletsTransactionsHistory: FC = () => {
	const dispatch = useDispatch();
	const param: { tab: string; subTab: string } = useParams();

	const transactionHistory = useSelector(getTransactionHistotyAdmin);
	const transactionLoader = useSelector(getTransactionHistotyAdminLoader);

	const submitFilter = (value: number) => {
		dispatch(
			getTransactionHistoryAdminReguest({
				asset: param.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
				type: param.tab === ETransactionTabsName.DEPOSIT ? 1 : 2,
				current_page: value,
			}),
		);
	};

	useEffect(() => {
		if (!param.tab) {
			dispatch(
				getTransactionHistoryAdminReguest({
					asset: param.subTab === EParamsNameWallet.CRYPTO ? 1 : 2,
					type: param.tab === ETransactionTabsName.DEPOSIT ? 1 : 2,
				}),
			);
		} else {
			submitFilter(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, param.tab, param.subTab]);

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<DashboardLimitation />
				<div className="table-top-wrapper table-top-wrapper--center table-top-wrapper--mb24">
					<div className="table-title table-title--m0">
						<p>Transactions history list of Admin</p>
					</div>
				</div>
				<div className="switcher-wrapper">
					<div className="switcher switcher--smaller">
						<Link
							to={`${ELinksName.WALLETS_TRANSATION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: param.tab === ETransactionTabsName.DEPOSIT || !param.tab,
							})}
						>
							Deposit
						</Link>
						<Link
							to={`${ELinksName.WALLETS_TRANSATION_HISTORY}/${ETransactionTabsName.WITHDRAWL}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: param.tab === ETransactionTabsName.WITHDRAWL,
							})}
						>
							Withdrawal
						</Link>
					</div>

					<div className="switcher switcher--last switcher--smaller">
						<Link
							to={`${ELinksName.WALLETS_TRANSATION_HISTORY}/${param.tab}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: param.subTab === EParamsNameWallet.FIAT || !param.subTab,
							})}
							type="button"
						>
							Fiat
						</Link>
						<Link
							to={`${ELinksName.WALLETS_TRANSATION_HISTORY}/${param.tab}/${EParamsNameWallet.CRYPTO}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: param.subTab === EParamsNameWallet.CRYPTO,
							})}
							type="button"
						>
							Crypto
						</Link>
					</div>
				</div>

				<div
					className={
						param.subTab === EParamsNameWallet.CRYPTO
							? 'table table--transactions-history table--second-type'
							: 'table table--transactions-history-fiat table--second-type'
					}
				>
					<TransactionsHistoryHeader />
					{transactionLoader ? (
						<Loader />
					) : (
						<>
							<TransactionsHistoryTableBody transactionsHistory={transactionHistory?.data} />
							{transactionHistory?.last_page && transactionHistory?.last_page > 1 && (
								<Pagination
									request={submitFilter}
									currentPage={transactionHistory.current_page}
									numberPage={transactionHistory.last_page}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default WalletsTransactionsHistory;
