/* eslint-disable no-useless-escape */
import numeral from 'numeral';
import { FC } from 'react';
import { IInterest } from 'redux/reducers/interestRequests/types';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';

interface IProps {
	requests: Array<IInterest>;
}

export const BodyTable: FC<IProps> = ({ requests }) => {
	const onParsePhoneNumber = (phone: string) => {
		const result = parsePhoneNumber(phone);

		return result?.formatInternational();
	};

	return (
		<div className="table-body">
			{requests.map((request) => (
				<div key={request.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Request ID </p>
						<p className="td-value td-value--medium">{request.id}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Request date </p>
						<p className="td-value td-value--medium">
							{moment(request.created_at).format('DD.MM.YY HH:mm:ss')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Number of tokens </p>
						<p className="td-value td-value--medium">
							{numeral(request.amount_of_tokens).format('0,0').replaceAll(',', ' ')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Total amount</p>
						<p className="td-value td-value--medium">{`${String(
							numeral(request.total_sum_in_dollars).format('0,0').replaceAll(',', ' '),
						)} USD`}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Phone number</p>
						<p className="td-value td-value--medium td-value-phone-custom">
							{onParsePhoneNumber(request.phone)}
						</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Email</p>
						<p className="td-value td-value--medium">{request.email}</p>
					</div>
				</div>
			))}
		</div>
	);
};
