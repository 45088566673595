/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IEditPriceRequest,
	IPlatformTokensState,
	ITokensBalanceResponse,
	ITokensListReducer,
	ITokensListRequest,
} from './types';

export const initialState: IPlatformTokensState = {
	tokensList: null,
	balance: null,
	tokensListLoader: false,
};

const platformTokens = createSlice({
	name: '@@platformTokens',
	initialState,
	reducers: {
		getTokensListRequest: (state, action: PayloadAction<ITokensListRequest>) => {
			const getTokensListState = state;
			getTokensListState.tokensListLoader = true;
		},

		getTokensListSuccess: (state, action: PayloadAction<ITokensListReducer>) => {
			const { payload } = action;
			const getTokensListState = state;

			getTokensListState.tokensListLoader = false;
			getTokensListState.tokensList = payload;
		},

		getBalanceRequest: (state) => {},

		getBalanceSuccess: (state, action: PayloadAction<ITokensBalanceResponse>) => {
			const { payload } = action;
			const getBalanceState = state;
			getBalanceState.balance = payload;
		},

		editPriceRequest: (state, action: PayloadAction<IEditPriceRequest>) => {
			const getTokensListState = state;
			getTokensListState.tokensListLoader = true;
		},

		editPriceSuccess: (state, action: PayloadAction<{ id: number; price: number }>) => {
			const { payload } = action;
			const getTokensListState = state;
			getTokensListState.tokensListLoader = false;
			if (getTokensListState.tokensList?.data) {
				getTokensListState.tokensList.data.forEach((token) => {
					if (token.id === payload.id) token.price = payload.price;
				});
			}
		},

		tokensInitState: (state) => {
			const propertyManagementInitState = state;
			propertyManagementInitState.tokensListLoader = false;
		},
	},
});

export default platformTokens.reducer;
export const {
	getTokensListRequest,
	getTokensListSuccess,
	tokensInitState,
	editPriceRequest,
	editPriceSuccess,
	getBalanceRequest,
	getBalanceSuccess,
} = platformTokens.actions;
