import { FC } from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { getCommisionCalculate } from 'redux/reducers/wallets/selectors';
import { IWithdrawUsdcRequest } from 'redux/reducers/wallets/types';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';

export const WithdrawalRequestSubmittedPopUp: FC<{ withdrawData: IWithdrawUsdcRequest | null }> = ({
	withdrawData,
}) => {
	// const commisionCalculate = useSelector(getCommisionCalculate);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--type2 popup--bigger">
					<div className="popup-header">
						<div className="success-icon success-icon-ctnter-custom">
							<svg
								width="21"
								height="21"
								viewBox="0 0 21 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.5 20.5C16 20.5 20.5 16 20.5 10.5C20.5 5 16 0.5 10.5 0.5C5 0.5 0.5 5 0.5 10.5C0.5 16 5 20.5 10.5 20.5Z"
									fill="#31BDAA"
								/>
								<path
									d="M6.25 10.4999L9.08 13.3299L14.75 7.66992"
									stroke="white"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<p className="popup-title popup-title--center popup-title--mt-24">
							Withdrawal request submitted
						</p>
					</div>

					<div className="popup-body">
						<div className="order-ooptions ">
							<div className="order-option order-option--type2 order-option--mob-column">
								<p className="order-option__name order-option__name--color2">Amount</p>
								<p className="order-option__value">
									{`Recieve ${String(withdrawData?.data.amount)} USDC`}{' '}
									<span className="br br--on-mob" />
									{/* {`(Network fee ${String(commisionCalculate?.commission)} USDC)`} */}
								</p>
							</div>
							<div className="order-option order-option--type2 order-option--mob-column">
								<p className="order-option__name order-option__name--color2">Address</p>
								<p className="order-option__value order-option__value-custom order-option__value--padding">
									{withdrawData?.data.payerData[0].address}
								</p>
							</div>
						</div>
					</div>
					<div className="popup-footer popup-footer--more-mt popup-footer--mob-column">
						<Link
							to={`${ELinksName.WALLETS_TRANSATION_HISTORY}/${ETransactionTabsName.WITHDRAWL}/${EParamsNameWallet.CRYPTO}`}
							className="button button--full-width "
						>
							View withdrawal history
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
