import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import L from 'i18n-react';

import { notificationContainer } from 'untils/notificationContainer';
import { E2FaMassage } from 'services/constants/twoFaMassage';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	changeEmailRequest,
	changeEmailSuccess,
	changeUserPassRequest,
	checkPassRequest,
	checkPassSuccess,
	disable2faRequest,
	disable2faSmsPhoneRequest,
	disable2faSmsPhoneSuccess,
	disable2faSuccess,
	editUserRequest,
	editUserSuccess,
	enable2faRequest,
	enable2faSmsPhoneRequest,
	enable2faSmsPhoneSuccess,
	enable2faSuccess,
	generate2faKeyRequest,
	generate2faKeySuccess,
	getSmsCode2faLoginRequest,
	getSmsCode2faLoginSuccess,
	getSmsCodePhoneRequest,
	getSmsCodeRequest,
	open2FaSmsSuccess,
	openPopUpGoogle2FaSuccess,
	openPopUpSms2FaSuccess,
	openTimerSuccess,
	resetEmailRequest,
	settingsInitState,
} from './reducer';
import {
	I2faSmsPayload,
	IChangeEmailRequest,
	ICheckPassRequest,
	ICheckPassSagaResponse,
	ICreatePasswordFormData,
	IDisable2faPayload,
	IEditUserRequest,
	IEditUserResponse,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	IGetSmsRequest,
	IResetEmailRequest,
	ISmsCodeResponse,
	ITotpResponse,
} from './types';
import { authInitState, rewriteStatus, sms2faEnabledSuccess, userSuccess } from '../auth/reducer';

function* editUserWorker(action: PayloadAction<IEditUserRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IEditUserResponse = yield call(api.settings.editUser, payload);
		yield put(editUserSuccess());
		yield put(userSuccess(response.data.user));
		notificationContainer(String(L.translate(`Data has been changed`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* changeEmailWorker(action: PayloadAction<IChangeEmailRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.settings.changeEmail, payload);
		yield put(changeEmailSuccess(payload.email));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* changeUserPassWorker(action: PayloadAction<ICreatePasswordFormData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.changePassword, payload);
		yield put(authInitState());
		notificationContainer(String('Your password has been successfully changed!'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* resetEmailWorker(action: PayloadAction<IResetEmailRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.resetEmail, payload);
		notificationContainer(String('Your email has been successfully changed!'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* generate2faKeyWorker() {
	try {
		yield put(showLoading());
		const response: IGenerate2faKeyResponse = yield call(api.settings.generate2faKey);
		yield put(generate2faKeySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* enable2faWorker(action: PayloadAction<IEnable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.enable2fa, payload);
		yield put(enable2faSuccess());
		yield put(openPopUpSms2FaSuccess(true));

		// console.log('response');
		yield put(rewriteStatus(1));
		// notificationContainer(String('Google 2fa successfully activated!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/two-fa-auth`);

		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* disable2faWorker(action: PayloadAction<IDisable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.disable2fa, payload);
		yield put(disable2faSuccess());
		yield put(openPopUpGoogle2FaSuccess(true));

		// console.log(response);
		yield put(rewriteStatus(0));
		// notificationContainer(String('Google 2fa successfully turned off!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/two-fa-auth`);

		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getSmsCodeWorker(action: PayloadAction<IGetSmsRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: ISmsCodeResponse } = yield call(api.settings.getSmsCode, payload);
		yield put(openTimerSuccess(response.data));
		yield put(open2FaSmsSuccess(E2FaMassage.SMS));
		notificationContainer(String('A code has been sent to your phone number'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getSmsCodePhoneWorker() {
	try {
		yield put(showLoading());
		const response: { data: ISmsCodeResponse } = yield call(api.settings.getSmsCodePhone);
		yield put(openTimerSuccess(response.data));
		yield put(open2FaSmsSuccess(E2FaMassage.SMS));
		notificationContainer(String('A code has been sent to your phone number'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getSmsCode2FaLoginWorker(action: PayloadAction<IGetSmsRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.getSmsCode2faLogin, payload);
		yield put(getSmsCode2faLoginSuccess());
		notificationContainer(String('A code has been sent to your phone number'), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* enable2faSmsWorker(action: PayloadAction<I2faSmsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITotpResponse = yield call(api.settings.enable2faSms, payload.data);
		yield put(enable2faSmsPhoneSuccess());
		yield put(userSuccess(response.data.user));
		payload.setOpen2FaPhone(false);
		yield put(openPopUpSms2FaSuccess(true));

		// notificationContainer(String('Sms 2fa successfully activated!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/two-fa-auth`);

		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* disable2faSmsWorker(action: PayloadAction<I2faSmsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.disable2faSms, payload.data);
		yield put(disable2faSmsPhoneSuccess());
		yield put(sms2faEnabledSuccess(false));
		payload.setOpen2FaPhone(false);
		yield put(openPopUpGoogle2FaSuccess(true));

		// notificationContainer(String('Sms 2fa successfully deactivated!'), 'success');
		payload.history.push(`${ELinksName.SETTINGS}/two-fa-auth`);
		// yield put(authInitState());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* checkUserPassWorker(action: PayloadAction<ICheckPassRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICheckPassSagaResponse = yield call(api.settings.checkPassword, payload);
		yield put(checkPassSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* settingsSaga() {
	yield takeEvery(editUserRequest.type, editUserWorker);
	yield takeEvery(changeEmailRequest.type, changeEmailWorker);
	yield takeEvery(changeUserPassRequest.type, changeUserPassWorker);
	yield takeEvery(resetEmailRequest.type, resetEmailWorker);
	yield takeEvery(generate2faKeyRequest.type, generate2faKeyWorker);
	yield takeEvery(enable2faRequest.type, enable2faWorker);
	yield takeEvery(disable2faRequest.type, disable2faWorker);
	yield takeEvery(getSmsCodeRequest.type, getSmsCodeWorker);
	yield takeEvery(getSmsCode2faLoginRequest.type, getSmsCode2FaLoginWorker);
	yield takeEvery(enable2faSmsPhoneRequest.type, enable2faSmsWorker);
	yield takeEvery(disable2faSmsPhoneRequest.type, disable2faSmsWorker);
	yield takeEvery(getSmsCodePhoneRequest.type, getSmsCodePhoneWorker);
	yield takeEvery(checkPassRequest.type, checkUserPassWorker);
}
