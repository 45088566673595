import { Loader } from 'components/Loader';
import { IPropsAccountHistoryTable } from 'components/UserAcountComponents/types';
import moment from 'moment';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { getTransactionHistotyAdminLoader } from 'redux/reducers/wallets/selectors';
import { EParamsNameWallet } from 'services/constants/list-links-sidebar';
import { colorsStatus } from 'services/constants/status-colors';
import { notificationContainer } from 'untils/notificationContainer';

export const BodyTableHistoryAssets: FC<IPropsAccountHistoryTable> = ({ historyAll, param }) => {
	const loader = useSelector(getTransactionHistotyAdminLoader);

	const handleDestination = () => {
		notificationContainer('Destination id copied successfully!', 'info');
	};

	const handleTransaction = () => {
		notificationContainer('Transaction id copied successfully!', 'info');
	};

	return param === EParamsNameWallet.CRYPTO ? (
		<div className="table-body">
			{loader ? (
				<Loader />
			) : (
				historyAll.data.map((history) => (
					<div key={history.id} className="tr">
						<div className="td">
							<p className="td-hidden-name">ID</p>
							<p className="td-value td-value--medium">{history.id}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">User ID</p>
							<p className="td-value td-value--medium red">{history.user_id}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Time and date</p>
							<p className="td-value td-value--medium">
								{moment(history.created_at).format('DD.MM.YY h:mm:ss')}
							</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Asset</p>
							<p className="td-value td-hidden-name-upper td-value--medium">
								{history.asset_id === 1 ? 'USDC' : 'USD'}
							</p>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name">Network</p>
							<p className="td-value td-value--medium">Algorand</p>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name">Fee</p>
							<p className="td-value td-value--medium">{history.amount_fee}</p>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name">Amount</p>
							<p className="td-value td-value--medium">{history.amount}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Destination</p>
							<div className="td-wrapper">
								<p className="td-value td-value--medium">
									{history?.payment_id && history?.payment_id.slice(0, 6)}...
								</p>
								<CopyToClipboard text={history?.payment_id || ''} onCopy={handleDestination}>
									<button type="button">Copy</button>
								</CopyToClipboard>
							</div>
						</div>
						<div className="td">
							<p className="td-hidden-name">Status</p>
							<p
								className={`td-value td-value-first-letter-up td-value--medium td-status--${history?.status.toLowerCase()}`}
							>
								{history.status.replaceAll('_', ' ')}
							</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">TxID</p>
							<div className="td-wrapper">
								<p className="td-value td-value--medium">
									{history?.transaction_id && history?.transaction_id?.slice(0, 6)}...
								</p>
								<CopyToClipboard text={history.transaction_id || ''} onCopy={handleTransaction}>
									<button type="button">Copy</button>
								</CopyToClipboard>
							</div>
						</div>
					</div>
				))
			)}
		</div>
	) : (
		<div className="table-body">
			{loader ? (
				<Loader />
			) : (
				historyAll?.data.map((history) => (
					<div key={history.id} className="tr">
						<div className="td">
							<p className="td-hidden-name">ID</p>
							<p className="td-value td-value--medium">{history.id}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Time and date</p>
							<p className="td-value td-value--medium">
								{moment(history.created_at).format('DD.MM.YY h:mm:ss')}
							</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Currency</p>
							<p className="td-value td-hidden-name-upper td-value--medium">USD</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Payment method</p>
							<p className="td-value td-value-flex-start td-value-first-letter-up td-value--medium">
								Stripe
							</p>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name">Indicated amount</p>
							<p className="td-value td-value-flex-start td-value--medium">
								{history.indicated_amount}
							</p>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name ">Fee</p>
							<p className="td-value td-value--medium">{history.amount_fee}</p>
						</div>
						<div className="td td--center">
							<p className="td-hidden-name">Amount</p>
							<p className="td-value td-value--medium">{history.amount}</p>
						</div>
						<div className="td td--right">
							<p className="td-hidden-name">Status</p>
							<p
								className={`td-value td-value-first-letter-up td-value--medium ${
									colorsStatus[history.status]
								}`}
							>
								{history.status.replaceAll('_', ' ')}
							</p>
						</div>
					</div>
				))
			)}
		</div>
	);
};
