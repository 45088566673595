/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ICountriesResponse,
	IEditProfitRequest,
	IEditRequest,
	IEstate,
	IInvestors,
	IInvestorsRequest,
	IProperty,
	IPropertyListResponse,
	IPropertyManagementState,
	IPropertyPaginationRequest,
	IPublishRequest,
	IStatesResponse,
	IUnpublishRequste,
} from './types';

export const initialState: IPropertyManagementState = {
	propertyList: null,
	propertyListLoader: false,
	editProfitLoader: false,
	property: null,
	createIdPropertty: null,
	editPropertyLoader: false,
	publishPropertyLoader: false,
	tokenPrice: null,
	investors: null,
	countries: null,
	states: null,
};

const propertyManagement = createSlice({
	name: '@@propertyManagement',
	initialState,
	reducers: {
		getPropertyListRequest: (state, action: PayloadAction<IPropertyPaginationRequest>) => {
			const getPropertyListState = state;
			getPropertyListState.propertyListLoader = true;
		},

		getPropertyListSuccess: (state, action: PayloadAction<IPropertyListResponse>) => {
			const { payload } = action;
			const getPropertyListState = state;

			getPropertyListState.propertyListLoader = false;
			getPropertyListState.propertyList = payload;
		},

		getPropertyRequest: (state, action: PayloadAction<string>) => {
			const getPropertyListState = state;
			getPropertyListState.propertyListLoader = true;
		},

		getPropertySuccess: (state, action: PayloadAction<IProperty | null>) => {
			const { payload } = action;
			const getPropertyListState = state;

			getPropertyListState.propertyListLoader = false;
			getPropertyListState.property = payload;
		},

		editPropertyRequest: (state, action: PayloadAction<IEditRequest>) => {
			const getPropertyListState = state;
			getPropertyListState.editPropertyLoader = true;
		},

		editPropertySuccess: (state) => {
			const getPropertyListState = state;
			getPropertyListState.editPropertyLoader = false;
		},

		editPofitRequest: (state, action: PayloadAction<IEditProfitRequest>) => {
			const editPofitState = state;
			editPofitState.editProfitLoader = true;
		},

		editProfitSuccess: (state, action: PayloadAction<IEstate>) => {
			const { payload } = action;
			const editPofitState = state;
			editPofitState.editProfitLoader = false;
			editPofitState.propertyList?.data.forEach((profit, index) => {
				if (payload.id === profit.id) {
					if (editPofitState.propertyList) {
						editPofitState.propertyList.data[index].price = payload.price;
						editPofitState.propertyList.data[index].expected_profit = payload.expected_profit;
						editPofitState.propertyList.data[index].expected_expenses = payload.expected_expenses;
					}
				}
			});
		},

		createPropertyRequest: (state) => {
			const getPropertyListState = state;
			getPropertyListState.propertyListLoader = true;
		},

		createPropertySuccess: (state, action: PayloadAction<number>) => {
			const getPropertyListState = state;
			const { payload } = action;
			getPropertyListState.propertyListLoader = false;
			getPropertyListState.createIdPropertty = payload;
		},

		publishPropertyRequest: (state, action: PayloadAction<IPublishRequest>) => {
			const getpublishPropertyState = state;
			getpublishPropertyState.publishPropertyLoader = true;
		},

		publishPropertyResponse: (state) => {
			const getpublishPropertyState = state;
			getpublishPropertyState.publishPropertyLoader = false;
		},

		deletePropertyRequest: (state, action: PayloadAction<{ id: number }>) => {},

		deletePropertySuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const getPropertyListState = state;

			if (getPropertyListState.propertyList?.data)
				getPropertyListState.propertyList.data = getPropertyListState.propertyList?.data.filter(
					(property) => property.id !== payload,
				);
		},

		getTokenPriceRequest: (state) => {},

		getTokenPriceSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const tokenPriceState = state;
			tokenPriceState.tokenPrice = payload;
		},

		getInvestorsRequest: (state, action: PayloadAction<IInvestorsRequest>) => {},

		getInvestorsSuccess: (state, action: PayloadAction<IInvestors | null>) => {
			const { payload } = action;
			const tokenPriceState = state;
			tokenPriceState.investors = payload;
		},

		getCountriesRequest: (state) => {},

		getCountriesSuccess: (state, action: PayloadAction<Array<ICountriesResponse> | null>) => {
			const { payload } = action;
			const countriesState = state;
			countriesState.countries = payload;
		},

		getStatesRequest: (state) => {},

		getStatesSuccess: (state, action: PayloadAction<Array<IStatesResponse>>) => {
			const { payload } = action;
			const statesUsaState = state;
			statesUsaState.states = payload;
		},

		postUnpublishRequest: (state, action: PayloadAction<IUnpublishRequste>) => {},

		// postUnpublishSuccess: (state, action: PayloadAction<Array<ICountriesResponse> | null>) => {
		// 	const { payload } = action;
		// 	const countriesState = state;
		// 	countriesState.countries = payload;
		// },

		propertyManagementInitState: (state) => {
			const propertyManagementInitState = state;
			propertyManagementInitState.propertyListLoader = false;
			propertyManagementInitState.editProfitLoader = false;
			propertyManagementInitState.editPropertyLoader = false;
			propertyManagementInitState.publishPropertyLoader = false;
			propertyManagementInitState.publishPropertyLoader = false;
		},
	},
});

export default propertyManagement.reducer;
export const {
	getPropertyListRequest,
	getPropertyListSuccess,
	propertyManagementInitState,
	getPropertyRequest,
	getPropertySuccess,
	editPropertyRequest,
	editPropertySuccess,
	createPropertyRequest,
	createPropertySuccess,
	deletePropertyRequest,
	deletePropertySuccess,
	editPofitRequest,
	editProfitSuccess,
	publishPropertyRequest,
	publishPropertyResponse,
	getTokenPriceRequest,
	getTokenPriceSuccess,
	getInvestorsRequest,
	getInvestorsSuccess,
	getCountriesRequest,
	getCountriesSuccess,
	postUnpublishRequest,
	getStatesRequest,
	getStatesSuccess,
	// postUnpublishSuccess,
} = propertyManagement.actions;
