import { FC, useEffect } from 'react';
import { IPropsPopUpDelete } from '../types';

export const PopUpDelete: FC<IPropsPopUpDelete> = ({ isVisible, handleButton, removeUser }) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--type2 popup--bigger">
					<p className="popup-title">{`Are you sure you want to delete user ID ${isVisible}?`}</p>
					<div className="popup-footer popup-footer--more-mt popup-footer--mob-column">
						<button
							onClick={() => handleButton(null)}
							type="button"
							className="button button--type7 button--full-width"
						>
							Cancel
						</button>
						<button
							onClick={() => removeUser(isVisible)}
							type="button"
							className="button button--full-width"
						>
							Delete
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
