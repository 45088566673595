import React, { FC } from 'react';

export const PendingWithdrawalsHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">Withdrawal ID</p>
				</div>
				<div className="td">
					<p className="td-name">User id</p>
				</div>
				<div className="td">
					<p className="td-name">Currency</p>
				</div>
				<div className="td">
					<p className="td-name">Network</p>
				</div>
				<div className="td">
					<p className="td-name">Amount</p>
				</div>
				<div className="td">
					<p className="td-name">Fee</p>
				</div>
				<div className="td">
					<p className="td-name">Created</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Action</p>
				</div>
			</div>
		</div>
	);
};
