import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IInvestmentsData } from 'redux/reducers/dividends-management/types';
import { ELinksName, EParamsUserAcount } from 'services/constants/list-links-sidebar';

interface IProps {
	investors: Array<IInvestmentsData>;
}

export const InvestBodyTable: FC<IProps> = ({ investors }) => {
	return (
		<div className="table-body">
			{investors.map((investor) => (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name"># </p>
						<p className="td-value td-value--medium">1</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Full Name </p>
						<Link
							to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USER_INFO}/${String(
								investor.user.id,
							)}`}
							className="link link--decoration link--type3 link--bigger"
						>
							{`${String(investor?.user?.name || '')} ${String(investor?.user?.surname || '-')}`}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Investment</p>
						<p className="td-value td-value--medium">{`${String(
							investor.amount_usd_invested,
						)} USD`}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Tokens amount</p>
						<p className="td-value td-value--medium">{investor.amount_tokens}</p>
					</div>
				</div>
			))}
		</div>
	);
};
