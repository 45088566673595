/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateNewPasswordPayload } from 'components/Forms/CreateNewPassForm/type';
import { IEditUserResponse } from '../settings/types';
import {
	IAdminAuthUserResponseData,
	ILoginRequestPayload,
	IAdminLogoutData,
	IAuthStore,
	IForgotPasswordPayload,
	IUserData,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	accessToken: null,
	isAuthenticated: false,
	loginLoader: false,
	adminLogoutLoader: false,
	email: null,
	loginEmail: null,
	loginPassword: null,
	remember: false,
	userData: null,
	forgotPasswordLoader: false,
	createNewPasswordLoader: false,
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginRequestPayload>) => {
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
		},

		loginSuccess: (state, action: PayloadAction<IAdminAuthUserResponseData>) => {
			const { payload } = action;
			const loginState = state;

			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.userData = payload.user_data;
			loginState.email = payload.user_data.email;
			loginState.loginEmail = payload.user_data.email;
		},

		logoutRequest: (state, action: PayloadAction<IAdminLogoutData>) => {
			const logoutState = state;

			logoutState.adminLogoutLoader = true;
		},

		forgotPasswordRequest: (state, action: PayloadAction<IForgotPasswordPayload>) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = true;
		},

		forgotPasswordSuccess: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = false;
		},

		createNewPasswordRequest: (state, action: PayloadAction<ICreateNewPasswordPayload>) => {
			const createNewPasswordState = state;

			createNewPasswordState.createNewPasswordLoader = true;
		},

		createNewPasswordSuccess: (state) => {
			const createNewPasswordState = state;

			createNewPasswordState.createNewPasswordLoader = false;
		},

		userSuccess: (state, action: PayloadAction<IUserData>) => {
			const userState = state;
			const { payload } = action;
			userState.userData = payload;
		},

		sms2faEnabledSuccess: (state, action: PayloadAction<boolean>) => {
			const userState = state;
			const { payload } = action;
			if (userState.userData?.sms_2fa_enabled) {
				userState.userData.sms_2fa_enabled = payload;
				userState.userData.phone = '';
			}
		},

		rewriteStatus: (state, action: PayloadAction<number>) => {
			const userState = state;
			const { payload } = action;
			if (userState.userData) userState.userData.google2fa_enabled = payload;
		},

		authInitState: (state) => {
			const authInitStateState = state;

			if (authInitStateState.remember) {
				authInitStateState.isAuthenticated = false;
				authInitStateState.accessToken = null;
				authInitStateState.adminLogoutLoader = false;
				authInitStateState.loginLoader = false;
			} else {
				return initialState;
			}
		},
	},
});

export default auth.reducer;
export const {
	loginRequest,
	loginSuccess,
	logoutRequest,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	authInitState,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	userSuccess,
	rewriteStatus,
	sms2faEnabledSuccess,
} = auth.actions;
