import { FC, useEffect } from 'react';

interface IPropsTimer {
	seconds: number;
	setSeconts: (num: number) => void;
}

export const Timer: FC<IPropsTimer> = ({ seconds, setSeconts }) => {
	useEffect(() => {
		const tick = (num: number) => setSeconts(seconds - num);

		const interval = setInterval(() => tick(1), 1000);

		const cleanup = () => {
			clearInterval(interval);
		};
		return cleanup;
	});
	return <div>{!!seconds && `${seconds}sec`}</div>;
};
