/* eslint-disable no-restricted-globals */
import { FC } from 'react';

interface IDocPopUpProps {
	path: string;
	name: string;
	closeModal: () => void;
}

const DocumentPopUp: FC<IDocPopUpProps> = ({ path, name, closeModal }) => {
	const handlerDowload = () => {
		const attachment = `${location.protocol}${location.hostname}/storage/${path}`;
		// const attachment = `${location.protocol}//brickfi-dev.corp.merehead.xyz/storage/${path}`;
		const link = document.createElement('a');
		link.href = attachment;
		link.target = '_blank';
		link.download = attachment;
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	return (
		<div className="popup-window popup-window--doc">
			<div className="popup-window__inside">
				<div className="doc-panel-box">
					<div className="container">
						<div className="doc-panel">
							<button className="doc-panel__back" type="button" onClick={closeModal}>
								<svg
									width="22"
									height="24"
									viewBox="0 0 22 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M20.1668 10.1664H6.2518L12.3018 4.13469C12.647 3.78947 12.841 3.32124 12.841 2.83302C12.841 2.3448 12.647 1.87658 12.3018 1.53136C11.9566 1.18613 11.4884 0.992188 11.0001 0.992188C10.5119 0.992188 10.0437 1.18613 9.69847 1.53136L0.531802 10.698C0.364894 10.8724 0.234058 11.078 0.146802 11.303C-0.0365643 11.7494 -0.0365643 12.25 0.146802 12.6964C0.234058 12.9214 0.364894 13.127 0.531802 13.3014L9.69847 22.468C9.8689 22.6399 10.0717 22.7762 10.2951 22.8693C10.5185 22.9624 10.7581 23.0103 11.0001 23.0103C11.2422 23.0103 11.4818 22.9624 11.7052 22.8693C11.9286 22.7762 12.1314 22.6399 12.3018 22.468C12.4736 22.2976 12.61 22.0948 12.7031 21.8714C12.7962 21.648 12.8441 21.4084 12.8441 21.1664C12.8441 20.9243 12.7962 20.6847 12.7031 20.4613C12.61 20.2379 12.4736 20.0351 12.3018 19.8647L6.2518 13.833H20.1668C20.653 13.833 21.1193 13.6399 21.4632 13.2961C21.807 12.9522 22.0001 12.4859 22.0001 11.9997C22.0001 11.5135 21.807 11.0471 21.4632 10.7033C21.1193 10.3595 20.653 10.1664 20.1668 10.1664Z"
										fill="#E41472"
									/>
								</svg>
							</button>
							<p className="doc-panel__title">{name} </p>
							<button className="doc-panel__action" type="button" onClick={handlerDowload}>
								<svg
									width="38"
									height="38"
									viewBox="0 0 38 38"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M35.4998 22.666C35.0136 22.666 34.5473 22.8592 34.2035 23.203C33.8597 23.5468 33.6665 24.0131 33.6665 24.4993V31.8327C33.6665 32.3189 33.4733 32.7852 33.1295 33.129C32.7857 33.4729 32.3194 33.666 31.8332 33.666H6.1665C5.68027 33.666 5.21396 33.4729 4.87014 33.129C4.52632 32.7852 4.33317 32.3189 4.33317 31.8327V24.4993C4.33317 24.0131 4.14002 23.5468 3.7962 23.203C3.45238 22.8592 2.98607 22.666 2.49984 22.666C2.01361 22.666 1.54729 22.8592 1.20347 23.203C0.859658 23.5468 0.666504 24.0131 0.666504 24.4993V31.8327C0.666504 33.2914 1.24597 34.6903 2.27742 35.7218C3.30887 36.7532 4.70781 37.3327 6.1665 37.3327H31.8332C33.2919 37.3327 34.6908 36.7532 35.7223 35.7218C36.7537 34.6903 37.3332 33.2914 37.3332 31.8327V24.4993C37.3332 24.0131 37.14 23.5468 36.7962 23.203C36.4524 22.8592 35.9861 22.666 35.4998 22.666ZM17.6982 25.801C17.8725 25.9679 18.0781 26.0988 18.3032 26.186C18.5226 26.283 18.7599 26.3331 18.9998 26.3331C19.2398 26.3331 19.4771 26.283 19.6965 26.186C19.9215 26.0988 20.1271 25.9679 20.3015 25.801L27.6348 18.4677C27.9801 18.1225 28.174 17.6542 28.174 17.166C28.174 16.6778 27.9801 16.2096 27.6348 15.8643C27.2896 15.5191 26.8214 15.3252 26.3332 15.3252C25.8449 15.3252 25.3767 15.5191 25.0315 15.8643L20.8332 20.081V2.49935C20.8332 2.01312 20.64 1.5468 20.2962 1.20299C19.9524 0.85917 19.4861 0.666016 18.9998 0.666016C18.5136 0.666016 18.0473 0.85917 17.7035 1.20299C17.3597 1.5468 17.1665 2.01312 17.1665 2.49935V20.081L12.9682 15.8643C12.7972 15.6934 12.5943 15.5578 12.371 15.4653C12.1476 15.3728 11.9082 15.3252 11.6665 15.3252C11.4248 15.3252 11.1854 15.3728 10.962 15.4653C10.7387 15.5578 10.5358 15.6934 10.3648 15.8643C10.1939 16.0353 10.0583 16.2382 9.96579 16.4616C9.87328 16.6849 9.82567 16.9243 9.82567 17.166C9.82567 17.4078 9.87328 17.6471 9.96579 17.8705C10.0583 18.0938 10.1939 18.2967 10.3648 18.4677L17.6982 25.801Z"
										fill="#E41472"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div className="doc-frame">
					{/* <img src={`${location.protocol}${location.hostname}/storage/${path}`} alt={name} /> */}
					<img
						src={`${location.protocol}//brickfi-dev.corp.merehead.xyz/storage/${path}`}
						alt="passport"
					/>
				</div>
			</div>
		</div>
	);
};

export default DocumentPopUp;
