import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useOnClickOutside(ref: any, handler: (event: Event) => void, index = 0) {
	useEffect(() => {
		const listener = (event: Event) => {
			if (!ref?.current || ref?.current[index]?.contains(event.target)) {
				return;
			}
			handler(event);
		};

		document.addEventListener(`mousedown`, listener);
		document.addEventListener(`touchstart`, listener);

		return () => {
			document.removeEventListener(`mousedown`, listener);
			document.removeEventListener(`touchstart`, listener);
		};
	}, [ref, handler, index]);
}

export default useOnClickOutside;
