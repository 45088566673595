import { FC } from 'react';

export const SvgArrowBackSmall: FC = () => {
	return (
		<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.36597 16C3.36597 15.4477 3.81368 15 4.36597 15H28.366C28.9182 15 29.366 15.4477 29.366 16C29.366 16.5523 28.9182 17 28.366 17H4.36597C3.81368 17 3.36597 16.5523 3.36597 16Z"
				fill="#E41472"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.0729 7.29289C13.4635 7.68342 13.4635 8.31658 13.0729 8.70711L5.78006 16L13.0729 23.2929C13.4635 23.6834 13.4635 24.3166 13.0729 24.7071C12.6824 25.0976 12.0492 25.0976 11.6587 24.7071L3.65874 16.7071C3.26821 16.3166 3.26821 15.6834 3.65874 15.2929L11.6587 7.29289C12.0492 6.90237 12.6824 6.90237 13.0729 7.29289Z"
				fill="#E41472"
			/>
		</svg>
	);
};
