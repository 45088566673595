import { Balances } from 'components/DashboardComponents/Balances';
import { Sidebar } from 'components/Sidebar';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalancesRequest } from 'redux/reducers/dashboard/reducer';
import { getBalancesSelector } from 'redux/reducers/dashboard/selectors';

export const Home: FC = () => {
	const dispatch = useDispatch();
	const balances = useSelector(getBalancesSelector);
	useEffect(() => {
		dispatch(getBalancesRequest());
	}, [dispatch]);

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">{balances && <Balances balances={balances[0]} />}</div>
		</div>
	);
};
