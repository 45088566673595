import { TdStatus } from 'components/DividendsManagementComponents/DividendsTableBody/TdStatus';
import { IPropsDevidendsHistoryBody } from 'components/DividendsManagementComponents/types';
import numeral from 'numeral';
import { FC } from 'react';

export const DevidendsHistoryBody: FC<IPropsDevidendsHistoryBody> = ({ devidendsHistory }) => {
	return (
		<div className="table-body">
			{devidendsHistory.map((history) => (
				<div key={history.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Apartment Name</p>
						<p className="td-value td-value--medium td-value-custom-up">{history.title}</p>
					</div>
					<div className="td ">
						{/* <p className="td-hidden-name">Status</p> */}
						<TdStatus dividend={history} />
					</div>
					<div className="td">
						<p className="td-hidden-name"> Distributed to № of investors</p>
						<p className="td-value td-value--medium">{history.investments_count}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Distribute</p>
						<p className="td-value td-value--medium">
							{numeral(history.dividends_sum_amount).format('0,0.00').replaceAll(',', ' ') || 0}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};
