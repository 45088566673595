import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IBalancesResponse, IDashboardState, ISubscriptions } from './types';

const getDashboardtState = (state: IStoreState): IDashboardState => state.dashboard;

export const getBalancesSelector = createSelector(
	[getDashboardtState],
	(dashboard: IDashboardState): IBalancesResponse | null => dashboard.balances,
);

export const getSubscriptionsSelector = createSelector(
	[getDashboardtState],
	(dashboard: IDashboardState): ISubscriptions | null => dashboard.subscriptionsList,
);
