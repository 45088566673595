/* eslint-disable react-hooks/exhaustive-deps */
import { FC, MouseEvent, useEffect, useState } from 'react';
import { TableFilter } from 'components/PropertyManagementComponents/TableFilter';
import { Sidebar } from 'components/Sidebar';
import { BodyTable } from 'components/PropertyManagementComponents/BodyTable';
import { HeaderTable } from 'components/PropertyManagementComponents/HeaderTable';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyListRequest } from 'redux/reducers/propertyManagement/reducer';
import { getPropertyList, getPropertyLoader } from 'redux/reducers/propertyManagement/selectors';
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { RemovePropertyPopup } from 'components/RemovePropertyPopup';
import { useHistory } from 'react-router';
import { IPropertyPaginationRequest } from 'redux/reducers/propertyManagement/types';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { byStatus, statusPropertyNumber } from 'services/constants/status';
import classNames from 'classnames';

const PropertyManagement: FC = () => {
	const dispatch = useDispatch();
	const listProperty = useSelector(getPropertyList);
	const listLoader = useSelector(getPropertyLoader);
	const [popup, setPopup] = useState<number | null>(null);
	const [sortPrice, setSortPrice] = useState<string>('');
	const [status, setStatus] = useState<number | null>(null);
	const { location } = useHistory();
	const [isOpenStaus, setIsOpenStatus] = useState(false);
	const { innerBorderRef } = useOnOutsideClick(() => setIsOpenStatus(false));

	const submitFilter = (value: number) => {
		const data: IPropertyPaginationRequest = {
			current_page: value || +location.search.slice(1),
		};

		if (sortPrice && sortPrice !== 'Reset') data.sort_price = sortPrice;
		if (status || status === 0) data.status = status;

		dispatch(getPropertyListRequest(data));
	};

	const sortProperty = (sort: { status?: number; sort_price?: string }) => {
		sort.sort_price && setSortPrice(sort.sort_price);
		sort.status || sort.status === 0 ? setStatus(sort.status) : setStatus(null);
	};

	const openPopup = (value: number | null) => {
		setPopup(value);
	};

	useEffect(() => {
		!sortPrice &&
			!status &&
			status !== 0 &&
			dispatch(getPropertyListRequest({ current_page: +location.search.slice(1) || 1 }));
		(sortPrice || status || status === 0) &&
			submitFilter(+location.search ? +location.search.slice(1) : 1);
	}, [dispatch, sortPrice, status]);

	const handleButton = () => {
		setIsOpenStatus(!isOpenStaus);
	};

	const isStatus = (e: MouseEvent) => {
		const target = e.target as HTMLButtonElement;
		sortProperty({ status: +target.name });
		handleButton();
	};

	return (
		<div className="admin" style={{ position: 'relative' }}>
			<Sidebar />
			{popup && <RemovePropertyPopup popup={popup} openPopup={openPopup} />}
			<div className="admin__content">
				<TableFilter sortPrice={sortPrice} sortProperty={sortProperty} />
				<div className="table table--property-management table--second-type">
					<HeaderTable isStatus={isStatus} />
					<div className="table-header-hide">
						<div ref={innerBorderRef} className="select select--table-select">
							<button onClick={handleButton} type="button" className="select__current">
								Status
								<span className="select__current-arrow">
									<svg
										width="15"
										height="8"
										viewBox="0 0 15 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.64012 0.712987C0.756206 0.432732 1.02968 0.25 1.33303 0.25H13.333C13.6364 0.25 13.9099 0.432732 14.0259 0.712987C14.142 0.993243 14.0779 1.31583 13.8634 1.53033L7.86336 7.53033C7.57047 7.82322 7.09559 7.82322 6.8027 7.53033L0.8027 1.53033C0.588201 1.31583 0.524034 0.993243 0.64012 0.712987ZM3.14369 1.75L7.33303 5.93934L11.5224 1.75H3.14369Z"
											fill="#25252E"
										/>
									</svg>
								</span>
							</button>
							<div
								className={classNames('select__drop', {
									'select__drop--active': isOpenStaus,
								})}
							>
								<div className="select__drop-scroll">
									<div className="select__drop-item">
										<ul>
											{byStatus.map((element) => (
												<li key={element}>
													<button
														onClick={isStatus}
														name={String(statusPropertyNumber[element])}
														type="button"
													>
														{element}
													</button>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					{listLoader ? (
						<Loader />
					) : (
						listProperty && <BodyTable listProperty={listProperty.data} openPopup={openPopup} />
					)}
				</div>
				{listProperty?.last_page && listProperty?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={listProperty.current_page}
						numberPage={listProperty.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default PropertyManagement;
