import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { PlatformTokensBody } from 'components/PlatformTokensComponents/PlatformTokensBody';
import { PlatformTokensDashboard } from 'components/PlatformTokensComponents/PlatformTokensDashboard';
import { PlatformTokensHeader } from 'components/PlatformTokensComponents/PlatformTokensHeader';
import { Sidebar } from 'components/Sidebar';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getBalanceRequest, getTokensListRequest } from 'redux/reducers/platform-tokens/reducer';
import {
	getBalanceSelector,
	getTokenListSelector,
	getTokenLoaderSelector,
} from 'redux/reducers/platform-tokens/selectors';

const PlatformTokens: FC = () => {
	const dispatch = useDispatch();
	const tokens = useSelector(getTokenListSelector);
	const loader = useSelector(getTokenLoaderSelector);
	const history = useHistory();
	const balance = useSelector(getBalanceSelector);

	const submitFilter = (value: number) => {
		dispatch(
			getTokensListRequest({
				current_page: value,
			}),
		);
	};

	useEffect(() => {
		dispatch(getTokensListRequest({ current_page: +history.location.search.slice(1) || 1 }));
		dispatch(getBalanceRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<div className="admin">
			<Sidebar />

			<div className="admin__content">
				<div className="table-top-wrapper table-top-wrapper--center">
					<div className="table-title table-title--m0">
						<p>Platform tokens</p>
					</div>
				</div>
				{balance && <PlatformTokensDashboard balance={balance} />}
				<div className="table table--platform-tokens table--second-type">
					<PlatformTokensHeader />
					{loader ? <Loader /> : tokens?.data && <PlatformTokensBody tokens={tokens.data} />}
				</div>
				{tokens?.last_page && tokens?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={tokens.current_page}
						numberPage={tokens.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default PlatformTokens;
