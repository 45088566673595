/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { api, responseErrors } from 'services';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { notificationContainer } from 'untils/notificationContainer';
import {
	createPropertyRequest,
	createPropertySuccess,
	deletePropertyRequest,
	deletePropertySuccess,
	editPofitRequest,
	editProfitSuccess,
	editPropertyRequest,
	getCountriesRequest,
	getCountriesSuccess,
	getInvestorsRequest,
	getInvestorsSuccess,
	getPropertyListRequest,
	getPropertyListSuccess,
	getPropertyRequest,
	getPropertySuccess,
	getStatesRequest,
	getStatesSuccess,
	getTokenPriceRequest,
	getTokenPriceSuccess,
	postUnpublishRequest,
	propertyManagementInitState,
	publishPropertyRequest,
	publishPropertyResponse,
} from './reducer';
import {
	ICountriesResponse,
	IEditProfitRequest,
	IEditRequest,
	IInvestors,
	IInvestorsRequest,
	IProfitResponse,
	IProperty,
	IPropertyListResponse,
	IPropertyPaginationRequest,
	IPublishRequest,
	IStatesResponse,
	IUnpublishRequste,
} from './types';

function* getListPropertytWorker(action: PayloadAction<IPropertyPaginationRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IPropertyListResponse } = yield call(
			api.propertyManagement.getListProperty,
			payload,
		);
		yield put(getPropertyListSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* getPropertytWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: {
			data: {
				estate: IProperty;
			};
		} = yield call(api.propertyManagement.getProperty, payload);
		yield put(getPropertySuccess(response.data.estate));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* editPropertytWorker(action: PayloadAction<IEditRequest>) {
	const { payload } = action;
	const { data } = payload;
	const isDatabase = payload.images.filter((img) => img.id || img.name);
	if (isDatabase) data.estate.images = isDatabase;
	try {
		yield put(showLoading());
		const response: { data: { estate: IProperty } } = yield call(
			api.propertyManagement.editProperty,
			data,
		);
		let files = [];

		for (let i = 0; Number(i) < payload.images.length; i++) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (!payload.images[Number(i)].id && payload.images[Number(i)].file) {
				files.push(payload.images[Number(i)]);
			}
			if (files.length) {
				yield call(api.propertyManagement.addPictures, {
					images: [payload.images[Number(i)]] as Array<{ [key: string]: string | number }>,
					estate_id: response.data.estate.id,
				});
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				yield payload.count((prev) => +prev + 1);
				files = [];
			}
		}
		yield notificationContainer(
			payload.history.location.pathname.includes(ELinksName.POST_NEW_PROPERTY)
				? 'New property is created'
				: 'Change added successfully',
			'info',
		);
		yield payload.history.push(ELinksName.PROPERTY_MANAGEMENT);
	} catch (error) {
		payload.setIsUploading(false);
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* publishPropertytWorker(action: PayloadAction<IPublishRequest>) {
	const { payload } = action;
	const { data } = payload;
	const isDatabase = payload.images.filter((img) => img.id);
	if (isDatabase) data.estate.images = isDatabase;

	try {
		const response: { data: { estate: IProperty } } = yield call(
			api.propertyManagement.publishProperty,
			payload,
		);
		yield put(publishPropertyResponse());
		let files = [];

		for (let i = 0; Number(i) < payload.images.length; i++) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (!payload.images[Number(i)].id) {
				files.push(payload.images[Number(i)]);
			}
			if (files.length) {
				yield call(api.propertyManagement.addPictures, {
					images: [payload.images[Number(i)]] as Array<{ [key: string]: string | number }>,
					estate_id: response.data.estate.id,
				});
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				yield payload.count((prev) => +prev + 1);
				files = [];
			}
		}

		yield notificationContainer(
			payload.history.location.pathname.includes(ELinksName.POST_NEW_PROPERTY)
				? 'New property is created'
				: 'Change added successfully',
			'info',
		);

		yield payload.history.push(ELinksName.PROPERTY_MANAGEMENT);
	} catch (error) {
		payload.setIsUploading(false);
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* createPropertytWorker() {
	try {
		yield put(showLoading());
		const response: { data: { id: number } } = yield call(api.propertyManagement.createProperty);
		yield put(createPropertySuccess(response.data.id));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* deletePropertytWorker(action: PayloadAction<{ id: number }>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.propertyManagement.deleteProperty, payload);
		yield put(deletePropertySuccess(payload.id));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* editProfitWorker(action: PayloadAction<IEditProfitRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IProfitResponse } = yield call(
			api.propertyManagement.editProfit,
			payload,
		);
		yield put(editProfitSuccess(response.data.estate));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* getTokenPriceWorker() {
	try {
		const response: { data: { id: number } } = yield call(api.propertyManagement.getTokenPrice);
		yield put(getTokenPriceSuccess(response.data.id));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
		yield put(propertyManagementInitState());
	}
}

function* getInvestorsWorker(action: PayloadAction<IInvestorsRequest>) {
	const { payload } = action;
	try {
		const response: { data: IInvestors } = yield call(api.propertyManagement.getInvestors, payload);
		yield put(getInvestorsSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(propertyManagementInitState());
	}
}

function* getCountriesWorker() {
	try {
		const response: { data: { countries: Array<ICountriesResponse> } } = yield call(
			api.propertyManagement.getCounries,
		);
		yield put(getCountriesSuccess(response.data.countries));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(propertyManagementInitState());
	}
}

function* postPublishWorker(action: PayloadAction<IUnpublishRequste>) {
	const { payload } = action;

	try {
		yield call(api.propertyManagement.postUnpublish, payload.params);
		yield notificationContainer('The post was successfully unpublish', 'info');
		yield payload.history.push(ELinksName.PROPERTY_MANAGEMENT);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(propertyManagementInitState());
	}
}

function* getStatesWorker() {
	try {
		const response: { data: { countries: Array<IStatesResponse> } } = yield call(
			api.propertyManagement.getStates,
		);
		yield put(getStatesSuccess(response.data.countries));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(propertyManagementInitState());
	}
}

export function* propertyManagementSaga() {
	yield takeEvery(getPropertyListRequest.type, getListPropertytWorker);
	yield takeEvery(getPropertyRequest.type, getPropertytWorker);
	yield takeEvery(editPropertyRequest.type, editPropertytWorker);
	yield takeEvery(createPropertyRequest.type, createPropertytWorker);
	yield takeEvery(deletePropertyRequest.type, deletePropertytWorker);
	yield takeEvery(editPofitRequest.type, editProfitWorker);
	yield takeEvery(publishPropertyRequest.type, publishPropertytWorker);
	yield takeEvery(getTokenPriceRequest.type, getTokenPriceWorker);
	yield takeEvery(getInvestorsRequest.type, getInvestorsWorker);
	yield takeEvery(getCountriesRequest.type, getCountriesWorker);
	yield takeEvery(postUnpublishRequest.type, postPublishWorker);
	yield takeEvery(getStatesRequest.type, getStatesWorker);
}
