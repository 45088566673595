import { FC, useMemo } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { IPropsPreviewProperty } from 'components/PropertyManagementComponents/types';

export const Map: FC<IPropsPreviewProperty> = ({ values }) => {
	const center = useMemo(
		() => ({ lat: +values.lattitude, lng: +values.longitude }),
		[values.lattitude, values.longitude],
	);

	return (
		<GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
			<Marker position={center} />
		</GoogleMap>
	);
};
