import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IEstate } from 'redux/reducers/propertyManagement/types';
import { ELinksName } from 'services/constants/list-links-sidebar';

interface IProps {
	countInvestors: number;
	sumInvest: number;
	estate: IEstate;
}

export const InvestDetails: FC<IProps> = ({ sumInvest, countInvestors, estate }) => {
	return (
		<div className="invest-object">
			<div className="invest-object__photo">
				<img src={estate?.images?.[0]?.url} alt="" />
			</div>
			<div className="invest-object__info">
				<Link
					to={`${ELinksName.EDIT_PROPERTY}/${estate.id}`}
					className="link link--decoration link--type3"
				>
					{estate.title}
				</Link>
				<div className="invest-object__info-list">
					<div className="dashboard-item dashboard-item--type2">
						<div className="dashboard-item__info dashboard-item__info--mb0">
							<p>Total Investors: </p>
							<span>{countInvestors}</span>
						</div>
					</div>
					<div className="dashboard-item dashboard-item--type2">
						<div className="dashboard-item__info dashboard-item__info--mb0">
							<p>Total investment: </p>
							<span>{`${sumInvest} USD`}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
