import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { SelectDrop } from './SelectDrop';

interface IProps {
	sortProperty: (sort: { status?: number; sort_price?: string }) => void;
	sortPrice: string;
}

export const TableFilter: FC<IProps> = ({ sortPrice, sortProperty }) => {
	const [openDropFilter, setOpenDropFilter] = useState(false);
	const [openDropPrice, setOpenDropPrice] = useState(false);
	const [openDropStatus, setOpenDropStatus] = useState(false);
	const { innerBorderRef } = useOnOutsideClick(() => setOpenDropFilter(false));

	const handelDrop = () => {
		setOpenDropFilter(!openDropFilter);
		setOpenDropStatus(false);
		setOpenDropPrice(false);
	};

	const handelDropStatus = () => {
		setOpenDropStatus(!openDropStatus);
	};

	const handelDropPrice = () => {
		setOpenDropPrice(!openDropPrice);
	};

	return (
		<div className="table-filter">
			<div className="table-filter-left">
				<div ref={innerBorderRef} className="select select-custom select--select-inside">
					<div onClick={handelDrop} className="select__current">
						Sort by
						<span className="select__current-arrow">
							<svg
								width="14"
								height="8"
								viewBox="0 0 14 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
									fill="#25252E"
								/>
							</svg>
						</span>
					</div>
					<SelectDrop
						openDropFilter={openDropFilter}
						handelDropStatus={handelDropStatus}
						handelDropPrice={handelDropPrice}
						openDropPrice={openDropPrice}
						openDropStatus={openDropStatus}
						closeDropFilter={setOpenDropFilter}
						sortProperty={sortProperty}
						sortPrice={sortPrice}
					/>
				</div>
			</div>
			<div className="table-filter-right">
				<Link to={ELinksName.POST_NEW_PROPERTY} className="button button--long">
					Post new property
				</Link>
			</div>
		</div>
	);
};
