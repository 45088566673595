/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormEditOrder } from 'components/Forms/FormEditOrder';
import { Sidebar } from 'components/Sidebar';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSellPropertyRequest } from 'redux/reducers/sellManagement/reducer';
import { getSellProperty } from 'redux/reducers/sellManagement/selectors';
import AllImagesShow from '../AllImagesShow';
// import { sellYourPropertyRequest } from 'redux/reducers/market/reducer';

const EditOrder: FC = () => {
	const [openAllPhoto, setOpenAllPhoto] = useState(false);
	const dispatch = useDispatch();
	const sellProperty = useSelector(getSellProperty);
	const params: { id: string; userId: string } = useParams();

	useEffect(() => {
		dispatch(getSellPropertyRequest(+params.id));
	}, [dispatch, params]);

	return openAllPhoto ? (
		sellProperty && (
			<AllImagesShow images={sellProperty?.property_images} setOpenAllPhoto={setOpenAllPhoto} />
		)
	) : (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<div className="order-manegement-request-title">
					<p>
						Sell request ID <span className="red">{params.id}</span>
					</p>
				</div>
				<div className="order-manegement-request">
					{sellProperty && (
						<FormEditOrder setOpenAllPhoto={setOpenAllPhoto} sellProperty={sellProperty} />
					)}
				</div>
			</div>
		</div>
	);
};

export default EditOrder;
