import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getInvestmentsRequest } from 'redux/reducers/usersManagement/reducer';
import { getInvestmentsSelector } from 'redux/reducers/usersManagement/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { SvgArrowBackSmall } from 'ui/svg-image/SvgArrowBackSmall';
import { InvestmentsHeader } from './InvestmentsHeader';
import { InvestmentsTable } from './InvestmentsTable';

export const Investments: FC = () => {
	const dispatch = useDispatch();
	const investments = useSelector(getInvestmentsSelector);
	const history = useHistory();
	const params: { id: string } = useParams();

	const submitFilter = (value: number) => {
		dispatch(
			getInvestmentsRequest({
				current_page: value || 1,
				id: +params.id,
			}),
		);
	};

	useEffect(() => {
		dispatch(
			getInvestmentsRequest({
				id: +params.id,
				current_page: +history.location.search.slice(1) || 1,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<div className="admin__content">
			<Link to={ELinksName.USERS_MANAGEMENT} className="back-link back-link--mb20">
				<span className="back-link__arrow">
					<SvgArrowBackSmall />
				</span>
				Back to user management
			</Link>
			<div className="table-title table-title--type-2">
				<p>List of user investments </p>
			</div>
			<div className="table table--user-account table--second-type table--border-top">
				<InvestmentsHeader />
				{!investments?.data ? <Loader /> : <InvestmentsTable investments={investments?.data} />}
			</div>
			{investments?.last_page && investments?.last_page > 1 && (
				<Pagination
					request={submitFilter}
					currentPage={investments.current_page}
					numberPage={investments.last_page}
				/>
			)}
		</div>
	);
};
