import classNames from 'classnames';
import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import {
	ETransactionTabsName,
	filterTransactionHistory,
} from 'services/constants/transaction-history-tabs';

export const TabsHistory: FC = () => {
	const params: { tab: string; subTab: string } = useParams();

	return (
		<div className="switcher-wrapper">
			<div className="switcher switcher--4items  switcher--mob-column">
				<Link
					to={`${ELinksName.TRANSATION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsNameWallet.CRYPTO}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === ETransactionTabsName.DEPOSIT || !params.tab,
					})}
				>
					Deposit
				</Link>
				<Link
					to={`${ELinksName.TRANSATION_HISTORY}/${ETransactionTabsName.WITHDRAWL}/${EParamsNameWallet.CRYPTO}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === ETransactionTabsName.WITHDRAWL,
					})}
				>
					Withdrawal
				</Link>
				<Link
					to={`${ELinksName.TRANSATION_HISTORY}/${ETransactionTabsName.APARTMENT_INVEST}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === ETransactionTabsName.APARTMENT_INVEST,
					})}
				>
					Apartment Invest
				</Link>
				<Link
					to={`${ELinksName.TRANSATION_HISTORY}/${ETransactionTabsName.CONVERT}`}
					className={classNames('switcher__item switcher__item--cnter', {
						active: params.tab === ETransactionTabsName.CONVERT,
					})}
				>
					Convert
				</Link>
			</div>
			{filterTransactionHistory.includes(params.tab) && (
				<div className="switcher switcher--smaller switcher__item--center">
					<Link
						to={`${ELinksName.TRANSATION_HISTORY}/${params.tab}/${EParamsNameWallet.CRYPTO}`}
						className={classNames('switcher__item switcher__item--cnter', {
							active: params.subTab === EParamsNameWallet.CRYPTO || !params.subTab,
						})}
					>
						Crypto
					</Link>
					<Link
						to={`${ELinksName.TRANSATION_HISTORY}/${params.tab}/${EParamsNameWallet.FIAT}`}
						className={classNames('switcher__item switcher__item--cnter', {
							active: params.subTab === EParamsNameWallet.FIAT,
						})}
					>
						Fiat
					</Link>
				</div>
			)}
		</div>
	);
};
