import { FC } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { fileTypes } from 'services/constants/file-types';
import { SvgDelete, SvgUpload } from 'ui/svg-image/SvgPropertyManagement';

export interface Props {
	count: number;
	amount: number;
	size: number;
	inputFile: (filesData: FileList) => void;
	images: Array<{ [key: string]: string | number }>;
	setImgNumber: (value: boolean) => void;
	removeImage: (value: string, num: number) => void;
}

export const PhotosGallery: FC<Props> = ({
	setImgNumber,
	count,
	amount,
	inputFile,
	images,
	size,
	removeImage,
}) => {
	return (
		<>
			<div className="category-title category-title--type2">
				<p className="category-title__item">
					Photo <small>(total: {images.length})</small>
				</p>
			</div>
			<div className="realty-photos-gallery realty-photos-gallery--always-display realty-photos-gallery--mt-16">
				<div className="controls-group">
					<div className="uploaded-item uploaded-item--type2 uploaded-item--type2">
						<label className="upload-file-box upload-file-box-custom">
							<div className="file-uploader">
								<FileUploader multiple handleChange={inputFile} name="file" types={fileTypes} />
							</div>
							<span className="upload-file upload-file--grey">
								<span className="upload-file__icon upload-file__icon--grey">
									<SvgUpload />
								</span>
								<span className="upload-file__title">Upload Files</span>
							</span>
						</label>
					</div>
					{Math.ceil(count / (amount / 100)) !== 100 && amount && count ? (
						<div className="item-loader">
							<p className="item-loader__size-text">{`File size: ${size.toFixed(1)} mb`}</p>
							<p className="item-loader__upload-text">
								{`Uploading... ${Math.ceil(count / (amount / 100))}%`}
							</p>
							<div className="item-loader__item">
								<span
									// eslint-disable-next-line @typescript-eslint/ban-ts-comment
									// @ts-ignore
									style={{ width: `${count / (amount / 100) || 0}%` }}
								/>
							</div>
						</div>
					) : null}

					<button type="button" className="rearrange-images" onClick={() => setImgNumber(true)}>
						Rearrange images
					</button>
				</div>

				{images.slice(0, 7)?.map((img, index) => (
					<div key={img.url} className="realty-photo-block realty-photo-block-max-heigth">
						<div
							onClick={() => setImgNumber(true)}
							className="realty-photo realty-photo--height-type5 realty-photo-custom"
						>
							<img src={String(img.thumb)} alt="" />
						</div>
						<button
							onClick={() => removeImage(String(img.url), index)}
							type="button"
							className="upload-action"
						>
							<span className="upload-action__icon">
								<SvgDelete />
							</span>
						</button>
					</div>
				))}
			</div>
		</>
	);
};
