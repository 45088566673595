/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from 'untils/notificationContainer';
import {
	IResponseStatus,
	ISagaSellProperty,
	ISellPropertisRequest,
	ISellSaga,
	IStatusRequest,
} from './types';
import {
	deleteSellPropertyRequest,
	deleteSellPropertySuccess,
	getAllSellPropertyRequest,
	getAllSellPropertySuccess,
	getSellPropertyRequest,
	getSellPropertySuccess,
	statusPropertyRequest,
	statusPropertySuccess,
} from './reducer';

function* allSellPropertytWorker(action: PayloadAction<ISellPropertisRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ISellSaga = yield call(api.sellProperty.getSellProperty, payload);
		yield put(getAllSellPropertySuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* showSellPropertytWorker(action: PayloadAction<number>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ISagaSellProperty = yield call(api.sellProperty.showProperty, payload);

		yield put(getSellPropertySuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* deleteSellPropertytWorker(action: PayloadAction<number>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.sellProperty.deleteSellProperty, payload);
		yield put(deleteSellPropertySuccess(payload));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* statusSellPropertytWorker(action: PayloadAction<IStatusRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: { data: IResponseStatus } = yield call(
			api.sellProperty.statusSellProperty,
			payload,
		);
		yield put(statusPropertySuccess(+response.data.status));
		notificationContainer('Status changed', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* sellManagementSaga() {
	yield takeEvery(getAllSellPropertyRequest.type, allSellPropertytWorker);
	yield takeEvery(deleteSellPropertyRequest.type, deleteSellPropertytWorker);
	yield takeEvery(getSellPropertyRequest.type, showSellPropertytWorker);
	yield takeEvery(statusPropertyRequest.type, statusSellPropertytWorker);
}
