import moment from 'moment';
import numeral from 'numeral';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { editPriceRequest } from 'redux/reducers/platform-tokens/reducer';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { InputForPrice } from 'ui/InputForPrice';
import { SvgClose, SvgSave } from 'ui/svg-image/SvgPlatformTokens';
import { IPropsTableTokens } from '../types';

export const PlatformTokensBody: FC<IPropsTableTokens> = ({ tokens }) => {
	const [text, setText] = useState('');
	const [idToken, setIdToken] = useState<number | null>(null);
	const dispatch = useDispatch();

	const sendChanges = (estate_id: number) => {
		dispatch(
			editPriceRequest({
				params: { estate_id, price: Number(text) },
				id: Number(idToken),
				clearText: setText,
				clearId: setIdToken,
			}),
		);
	};

	return (
		<div className="table-body">
			{tokens.map((token) => (
				<div key={token.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Name of property</p>
						<Link
							to={`${ELinksName.EDIT_PROPERTY}/${String(token.id)}`}
							className="td-value td-value--medium"
						>
							<span className="td-value-custom-up">
								{token.title &&
									`${token.title[0].toUpperCase() + token.title.slice(1)} 
								(№${String(token.id)})`}
							</span>
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Date of issue token</p>
						<p className="td-value td-value--medium">
							{moment(token.created_at).format('DD.MM.YY h:mm:ss')}
						</p>
					</div>
					{idToken === token.id ? (
						<div className="td">
							<p className="td-hidden-name">Price of property</p>
							<div className="td-edit-wrapper">
								<div className="input input--no-mb ">
									<div className="input-wrapper">
										<InputForPrice data={{ price: +token.price, setText }} />
									</div>
								</div>
								<div className="table-buttons">
									<button onClick={() => sendChanges(token.id)} type="button">
										<SvgSave />
									</button>
									<button
										onClick={() => {
											setText('');
											setIdToken(null);
										}}
										type="button"
									>
										<SvgClose />
									</button>
								</div>
							</div>
						</div>
					) : (
						<div className="td">
							<p className="td-hidden-name">Price of property</p>
							<div className="td-edit-wrapper">
								<p className="td-value td-value--medium">
									{`${
										idToken === token.id
											? text
											: numeral(+token.price)
													.format('0,0')
													.replaceAll(',', ' ')
									} USD`}
								</p>
								<button
									onClick={() => setIdToken(token.id)}
									className="td-edit-button"
									type="button"
								>
									<svg
										width="25"
										height="25"
										viewBox="0 0 25 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M5.20825 18.7501H9.62492C9.76201 18.7509 9.89791 18.7246 10.0248 18.6728C10.1517 18.6209 10.2672 18.5446 10.3645 18.448L17.5728 11.2293L20.5312 8.33343C20.6288 8.2366 20.7063 8.12139 20.7592 7.99445C20.8121 7.86751 20.8393 7.73136 20.8393 7.59385C20.8393 7.45634 20.8121 7.32018 20.7592 7.19325C20.7063 7.06631 20.6288 6.9511 20.5312 6.85427L16.1145 2.38552C16.0177 2.28788 15.9025 2.21039 15.7755 2.1575C15.6486 2.10462 15.5124 2.07739 15.3749 2.07739C15.2374 2.07739 15.1013 2.10462 14.9743 2.1575C14.8474 2.21039 14.7322 2.28788 14.6353 2.38552L11.6978 5.33343L4.46867 12.5522C4.37213 12.6495 4.29574 12.7649 4.24391 12.8919C4.19207 13.0188 4.16579 13.1547 4.16659 13.2918V17.7084C4.16659 17.9847 4.27633 18.2497 4.47168 18.445C4.66703 18.6404 4.93198 18.7501 5.20825 18.7501ZM15.3749 4.59385L18.3228 7.54177L16.8437 9.02093L13.8958 6.07302L15.3749 4.59385ZM6.24992 13.7188L12.427 7.54177L15.3749 10.4897L9.19783 16.6668H6.24992V13.7188ZM21.8749 20.8334H3.12492C2.84865 20.8334 2.5837 20.9432 2.38835 21.1385C2.193 21.3339 2.08325 21.5988 2.08325 21.8751C2.08325 22.1514 2.193 22.4163 2.38835 22.6117C2.5837 22.807 2.84865 22.9168 3.12492 22.9168H21.8749C22.1512 22.9168 22.4161 22.807 22.6115 22.6117C22.8068 22.4163 22.9166 22.1514 22.9166 21.8751C22.9166 21.5988 22.8068 21.3339 22.6115 21.1385C22.4161 20.9432 22.1512 20.8334 21.8749 20.8334Z"
											fill="#E41472"
										/>
									</svg>
								</button>
							</div>
						</div>
					)}
					<div className="td td--right">
						<p className="td-hidden-name">Amount of tokens</p>
						<p className="td-value td-value--medium">
							{numeral(token.tokens_sum_count).format('0,0').replaceAll(',', ' ') || 0}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};
