import classNames from 'classnames';
import { Sidebar } from 'components/Sidebar';
import { PopUpError } from 'components/WalletsComponents/PopUpError';
import { PopUpSuccess } from 'components/WalletsComponents/PopUpSuccess';
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { createUsdWalletRequest } from 'redux/reducers/wallets/reducer';
import { IDepositeFiatRequest } from 'redux/reducers/wallets/types';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';
// import { notificationContainer } from 'untils/notificationContainer';
import { AddCard } from './AddCart';
import { Crypto } from './Crypto';
import { Fiat } from './Fiat';

const Deposit: FC = () => {
	const [popUp, setPopUp] = useState<null | boolean>(null);
	const [dataForm, setDataForm] = useState<IDepositeFiatRequest>();
	const [amount, setAmount] = useState<string>('');
	const [type, setType] = useState<number>(1);
	const params: { id: string; tab: string } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const addDataToForm = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		if (target.name === 'amount') {
			// if (+target.value <= 999999) {
			setAmount(target.value);
			history.push({
				search: String(target.value),
			});
			// }

			// if (+target.value > 999999) {
			// 	notificationContainer('The amount must be less than 99999', 'info');
			// }
		} else {
			setType(+target.id);
		}
	};

	const submitDepositFiat = (value: IDepositeFiatRequest) => {
		setDataForm(value);
		dispatch(createUsdWalletRequest({ data: value, setPopUp }));
	};

	const closePopUp = () => {
		history.push(
			`${ELinksName.WALLETS_TRANSATION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsNameWallet.FIAT}`,
		);
		setPopUp(null);
	};

	const definePage = () => {
		switch (params.tab) {
			case EParamsNameWallet.CRYPTO:
				return <Crypto />;
			case EParamsNameWallet.ADD_CARD:
				return <AddCard dataDepositeFiat={{ amount, type }} submit={submitDepositFiat} />;
			default:
				return <Fiat addDataToForm={addDataToForm} amount={amount} />;
		}
	};

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<div className="profile-header profile-header--type2">
					<div className="profile-header__group">
						<p className="block-title">Deposit</p>
					</div>
					<div className="switcher switcher--smaller ">
						<Link
							to={`${ELinksName.DEPOSIT}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active:
									params.tab === EParamsNameWallet.FIAT ||
									params.tab === EParamsNameWallet.ADD_CARD ||
									!params.tab,
							})}
						>
							<span>Deposit Fiat</span>
						</Link>
						<Link
							to={`${ELinksName.DEPOSIT}/${EParamsNameWallet.CRYPTO}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: params.tab === EParamsNameWallet.CRYPTO,
							})}
						>
							<span>Deposit Crypto</span>
						</Link>
					</div>
				</div>
				{definePage()}
			</div>
			{popUp === false && dataForm && (
				<PopUpError
					dataForm={dataForm}
					closePopUp={closePopUp}
					submitDepositFiat={submitDepositFiat}
				/>
			)}
			{popUp === true && <PopUpSuccess closePopUp={closePopUp} />}
		</div>
	);
};

export default Deposit;
