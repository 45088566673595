import ErrorMsg from 'components/ErrorMsg';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { IChangeEmailRequest } from 'redux/reducers/settings/types';
import yup from 'untils/capsLockValidate';
import { email } from 'untils/formValidation';
import { IPropsChangeEmail } from './types';

export const ChangeEmailForm: FC<IPropsChangeEmail> = ({ changeImailSubmit }) => {
	const userData = useSelector(getUserData);

	const initialValues = {
		email: userData?.email || '',
	};

	const validationSchema = yup.object().shape({
		email,
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IChangeEmailRequest, { setSubmitting }) => {
				changeImailSubmit(values);
				setSubmitting(false);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors, values }) => {
				return (
					<Form className="formik-form">
						<div className="input input--mt40">
							<p className="input-name">Email</p>
							<div className="input-wrapper">
								<Field
									name="email"
									type="email"
									className="input-item"
									placeholder="Your email"
									values={values.email}
								/>
							</div>
							{errors.email ? <ErrorMsg msg={errors.email} /> : null}
						</div>
						<div className="form-group form-group--more-mt">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="button button--long"
							>
								Change email
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
