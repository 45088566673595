import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { deletePropertyRequest } from 'redux/reducers/propertyManagement/reducer';
import { deleteSellPropertyRequest } from 'redux/reducers/sellManagement/reducer';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { IPropsPopUPRemove } from './types';

export const RemovePropertyPopup: FC<IPropsPopUPRemove> = ({ popup, openPopup }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const removeProperty = () => {
		popup &&
			dispatch(
				ELinksName.PROPERTY_MANAGEMENT === pathname
					? deletePropertyRequest({ id: popup })
					: deleteSellPropertyRequest(popup),
			);
		openPopup(null, null);
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="popup-remove popup-remove-custom">
			<div className="popup-remove__wrapper">
				<h3 className="popup-remove__title">
					{ELinksName.PROPERTY_MANAGEMENT === pathname
						? 'This action is irreversible, are you sure you want to delete property?'
						: 'This action is irreversible, are you sure you want to delete Sell request?'}
				</h3>
				<div className="popup-remove__subtitle">{`Delete property Id ${String(popup)}?`}</div>
				<div className="popup-remove__btn-wrapper">
					<button
						onClick={() => openPopup(null, null)}
						type="button"
						className="popup-remove__btn-cancel"
					>
						Cancel
					</button>
					<button onClick={removeProperty} type="button" className="popup-remove__btn-remove">
						Delete
					</button>
				</div>
			</div>
		</div>
	);
};
