/* eslint-disable @typescript-eslint/no-explicit-any */
import { notificationContainer } from 'untils/notificationContainer';

// ==========================================:
const customResponseErrors = (errorMessage: string, type: string, response: any) => {
	const urlPath = response.config.url;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const params: string = urlPath?.split('/').pop()?.replace('_', '/').toUpperCase() || ' ';
	if (errorMessage === 'Pair_not_available') {
		notificationContainer(`Pair ${params}  not available. Choose another pair.`, type);
	} else {
		notificationContainer(errorMessage, type);
	}
};
export { customResponseErrors };
