/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, MouseEvent, useState } from 'react';
import DatePicker from 'react-datepicker';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';

import 'react-datepicker/dist/react-datepicker.css';

import { useHistory } from 'react-router';
import { SvgCalendarSell, SvgResetCaledar } from 'ui/svg-image/SvgCalendar';
import { ISellPropertisRequest } from 'redux/reducers/sellManagement/types';
import moment from 'moment';
import useOnOutsideClick from 'hooks/useOnOutsideClick';

export const Calendar: FC<{
	submitFilter: (value?: number, calendar?: ISellPropertisRequest, status?: string) => void;
}> = ({ submitFilter }) => {
	const history = useHistory();
	const [startDate, setStartDate] = useState<Date>();
	const [endDate, setEndDate] = useState<Date>();
	const [isOpen, setIsOpen] = useState(false);
	const { innerBorderRef } = useOnOutsideClick(() => setIsOpen(false));

	const handleClick = (e: MouseEvent) => {
		e.preventDefault();
		if (startDate && endDate) return;
		setIsOpen(!isOpen);
	};

	const range = (start: number, end: number) => {
		return new Array(end - start).fill(undefined).map((d, i) => i + start);
	};
	const years = range(2002, getYear(new Date()) + 1);
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const onChange = (dates: Array<any>) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		end &&
			submitFilter(+history.location.search.slice(1) || 1, {
				date_from: moment(start).format('YY-MM-DD'),
				date_to: moment(end).format('YY-MM-DD'),
			});

		end && setIsOpen(!isOpen);
	};

	const resetCalendar = () => {
		setStartDate(undefined);
		setEndDate(undefined);
		submitFilter(+history.location.search.slice(1) || 1, { date_from: null });
	};

	return (
		<div ref={innerBorderRef} className="wrapper-culendar-custom">
			<div>
				<button onClick={handleClick} className="calendar-button" type="button">
					{startDate ? (
						`${moment(startDate).format('DD.MM.YY')} - ${
							endDate ? moment(endDate).format('DD.MM.YY') : ''
						}`
					) : (
						<span className="select-date">Select a date</span>
					)}
					<span className="calendar-button__icon">
						{startDate && endDate ? (
							<SvgResetCaledar resetCalendar={resetCalendar} />
						) : (
							<SvgCalendarSell />
						)}
					</span>
				</button>
			</div>

			<div className="block-inline-custom">
				{isOpen && (
					<div
						className={
							history.location.pathname.includes('/sell-your-property')
								? 'block-position-custom'
								: 'block-position-custom-first'
						}
					>
						<DatePicker
							renderCustomHeader={({
								date,
								changeYear,
								changeMonth,
								decreaseMonth,
								increaseMonth,
								prevMonthButtonDisabled,
								nextMonthButtonDisabled,
								customHeaderCount,
							}) => {
								return customHeaderCount === 0 ? (
									<div className="calendar-header">
										<div className="btn-wrapper">
											<button
												className="left-arrow"
												type="button"
												onClick={decreaseMonth}
												disabled={prevMonthButtonDisabled}
											>
												{/* <span /> */}
											</button>
										</div>
										<div className="select-wrapper">
											<select
												className="select-year"
												value={months[getMonth(date)]}
												onChange={({ target: { value } }) => {
													return changeMonth(months.indexOf(value));
												}}
											>
												{months.map((option) => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>

											<select
												className="select-year"
												value={getYear(date)}
												onChange={({ target: { value } }) => changeYear(+value)}
											>
												{years.map((option) => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
										</div>

										<div className="btn-wrapper">
											<button
												className="right-arrow"
												type="button"
												onClick={increaseMonth}
												disabled={nextMonthButtonDisabled}
											>
												{/* <span /> */}
											</button>
										</div>
									</div>
								) : (
									<div className="calendar-header">
										<div className="btn-wrapper">
											<button
												className="left-arrow"
												type="button"
												onClick={decreaseMonth}
												disabled={prevMonthButtonDisabled}
											>
												{/* <span /> */}
											</button>
										</div>
										<div className="select-wrapper">
											<select
												className="select-year"
												value={months[getMonth(date) + 1]}
												onChange={({ target: { value } }) => {
													return changeMonth(months.indexOf(value));
												}}
											>
												{months.map((option) => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>

											<select
												className="select-year"
												value={getYear(date)}
												onChange={({ target: { value } }) => changeYear(+value)}
											>
												{years.map((option) => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>
										</div>

										<div className="btn-wrapper">
											<button
												className="right-arrow"
												type="button"
												onClick={increaseMonth}
												disabled={nextMonthButtonDisabled}
											>
												{/* <span /> */}
											</button>
										</div>
									</div>
								);
							}}
							onChange={onChange}
							startDate={startDate}
							endDate={endDate}
							inline
							monthsShown={2}
							selectsRange
						/>
					</div>
				)}
			</div>
		</div>
	);
};
