import { IStatusRequest } from 'redux/reducers/sellManagement/types';
import { IPaginationRequest } from 'redux/reducers/usersManagement/types';
import { IChangeStatusRequest, ITransctionsHistoryRequest } from 'redux/reducers/wallets/types';

export const endpoint = {
	auth: {
		LOGIN: '/admin/login',
		LOGOUT: '/admin/logout',
		SEND_RESET_PASSWORD: '/admin/password/send_reset_link',
		RECOVER_PASSWORD: '/admin/password/recover',
	},
	settings: {
		EDIT_USER: '/admin/profile/change/name',
		CHANGE_EMAIL: '/admin/profile/change/email',
		EMAIL_RESET: '/admin/profile/email/reset',
		CHANGE_PASSWORD: '/admin/profile/change/password',
		GENERATE_2FA_KEY: '/admin/2fa/google/generate_secret_key',
		ENABLE_2FA: '/admin/2fa/google/enable',
		DISABLE_2FA: '/admin/2fa/google/disable',
		ENABLE_SMS_2FA: '/admin/2fa/sms/enable',
		DISABLE_SMS_2FA: '/admin/2fa/sms/disable',
		GET_SMS_CODE: '/admin/2fa/sms/send',
		GET_SMS_CODE_PHONE: '/admin/2fa/send_sms',
		CHECK_PASSWORD: '/admin/password/check',

		RESEND: '/2fa/sms',
		GET_SMS_CODE_LOGIN: '/2fa/send_sms/email',
	},
	sellProperty: {
		GET_ALL_SELL_PROPERTY: `/admin/property`,
		DELETE_PROPERTY: (param: number) => `/admin/property/delete/${param}`,
		SHOW_SELL_PROPERTY: (param: number) => `/admin/property/${param}`,
		STATUS_SELL_PROPERTY: (param: IStatusRequest) =>
			`/admin/property/change_status?property_id=${String(param.property_id)}&status=${String(
				param.status,
			)}`,
	},
	wallets: {
		GET_BALANCES: '/admin/wallet/balances',
		GET_PROFIT: '/admin/wallet/profit',
		GET_HISTORY: '/admin/wallet/history',
		GET_HISTORY_ALL: '/admin/wallet/history_all',
		GET_APARTMENT_INVEST: '/admin/wallet/investments_all',
		GET_HISTORY_CONVERTS: '/admin/wallet/converts_all',
		MODIFY_LIMITS: '/admin/limits/modify',
		CREATE_WALLET: '/admin/payment/pay',
		GET_LIMITS: '/admin/limits',
		CHANGE_STATUS: (params: IChangeStatusRequest) =>
			`admin/payment/change/status/${String(params.payment)}/${String(params.status)}`,
	},
	propertyManagement: {
		GET_PROPERTY_MANAGEMENT_LIST: '/admin/estate',
		GET_PROPERTY_MANAGEMENT: (param: string) => `/admin/estate/${param}`,
		POST_CREATE_PROPERTY: '/admin/estate/create',
		PUT_EDIT_PROPERTY: '/admin/estate/save',
		PUBLISH_PROPERTY: `/admin/estate/publish`,
		DELETE_PROPERTY: (id: number) => `admin/estate/delete/${id}`,
		POST_PROFIT_PRICE: '/admin/estate/change_profit',
		ADD_PICTURES: '/admin/estate/add_images',
		GET_TOKEN_PRICE: '/admin/estate/token_price',
		GET_INVESTORS: `admin/investments/investors`,
		GET_COUNTRIES: `/auth/countries_list`,
		POST_UNPUBLISH: `/admin/estate/unpublish`,
		STATES_LIST: `/auth/states_list`,
	},
	usersManagement: {
		USER_MANAGEMENT_LIST: 'admin/user/list',
		USER_MANAGEMENT_BLOCK: 'admin/user/update_block',
		INVESTMENTS: (params: IPaginationRequest) => `/admin/user/investments/${String(params.id)}`,
		TRANSACTION_HISTORY: (id: number | undefined) => `/admin/user/transactions/${String(id)}`,
		TRANSACTION_HISTORY_CONVERTS: (params: ITransctionsHistoryRequest) =>
			`/admin/user/converts/${String(params.id)}`,
		USER_MANAGEMENT_DELETE: (id: number) => `admin/user/delete/${String(id)}`,
		USER_MANAGEMENT: (id: string) => `/admin/user/show/${id}`,
		ORDERS_USER: (id: number) => `/admin/user/orders/${String(id)}`,
		DEVIDENDS_USER: (id: number) => `/admin/user/dividends/${String(id)}`,
		EDIT_STATUS: `/admin/user/update_status`,
		GET_ZIP_FILE: (payload: string) => `/admin/user/get_documents_archive/${payload}`,
		ADD_FUNDS_TO_USER: 'admin/user/add-funds',
	},
	platformTokens: {
		TOKENS_LIST: '/admin/tokens',
		BALANCE: '/admin/tokens/sums',
		EDIT_PRICE: '/admin/estate/change_profit',
	},
	dividendsManagement: {
		DIVIDENDS: '/admin/dividends',
		CHANGE_RENT_STATUS: '/admin/dividends/change_rent_status',
		DISTRIBUTE: '/admin/dividends/distribute',
		DIVIDENDS_SUM: '/admin/dividends/sum',
		DIVIDENDS_HISTORY: '/admin/dividends/history',
	},
	feeManagement: {
		FEE_MANAGEMENT: '/admin/commision',
	},
	dashboard: {
		BALANCES: '/admin/dashboard',
		DASHBOARD: '/admin/dashboard/subscriptions',
	},
	interestRequests: {
		GET_LIST_INTEREST: (id: string) => `/admin/interest_requests/estate/${id}`,
	},
};
