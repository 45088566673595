/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import {
	ISellManagementState,
	ISellPropertisRequest,
	ISellPropertyOneResponse,
	ISellPropertyResponse,
	IStatusRequest,
} from './types';

export const initialState: ISellManagementState = {
	sellProperty: null,
	oneSellProperty: null,
	sellPropertyLoader: false,
	deleteSellPropertyLoader: false,
	statusPropertyLoader: false,
};

const sellManagement = createSlice({
	name: '@@sellProperty',
	initialState,
	reducers: {
		getAllSellPropertyRequest: (state, action: PayloadAction<ISellPropertisRequest>) => {
			const getAllSellPropertyState = state;
			getAllSellPropertyState.sellPropertyLoader = true;
		},

		getAllSellPropertySuccess: (state, action: PayloadAction<ISellPropertyResponse>) => {
			const { payload } = action;
			const getAllSellPropertyState = state;

			getAllSellPropertyState.sellPropertyLoader = false;
			getAllSellPropertyState.sellProperty = payload;
		},

		getSellPropertyRequest: (state, action: PayloadAction<number>) => {
			const getSellPropertyState = state;
			getSellPropertyState.sellPropertyLoader = true;
		},

		getSellPropertySuccess: (state, action: PayloadAction<ISellPropertyOneResponse>) => {
			const { payload } = action;
			const getSellPropertyState = state;

			getSellPropertyState.sellPropertyLoader = false;
			getSellPropertyState.oneSellProperty = payload;
		},

		statusPropertyRequest: (state, action: PayloadAction<IStatusRequest>) => {
			const statusPropertyState = state;
			statusPropertyState.statusPropertyLoader = true;
		},

		statusPropertySuccess: (state, action: PayloadAction<number>) => {
			const statusPropertyState = state;
			const { payload } = action;

			statusPropertyState.statusPropertyLoader = false;

			if (statusPropertyState.oneSellProperty) statusPropertyState.oneSellProperty.status = payload;
		},

		deleteSellPropertyRequest: (state, action: PayloadAction<number>) => {
			const deleteSellPropertyState = state;
			deleteSellPropertyState.deleteSellPropertyLoader = true;
		},

		deleteSellPropertySuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const deleteSellPropertyState = state;
			if (deleteSellPropertyState.sellProperty?.data)
				deleteSellPropertyState.sellProperty.data =
					deleteSellPropertyState.sellProperty?.data.filter((property) => property.id !== payload);
			deleteSellPropertyState.deleteSellPropertyLoader = false;
		},

		sellPropertyInitState: () => initialState,
	},
});

export default sellManagement.reducer;
export const {
	getAllSellPropertyRequest,
	getAllSellPropertySuccess,
	sellPropertyInitState,
	deleteSellPropertyRequest,
	deleteSellPropertySuccess,
	getSellPropertyRequest,
	getSellPropertySuccess,
	statusPropertyRequest,
	statusPropertySuccess,
} = sellManagement.actions;
