import { WithdrawUsdcForm } from 'components/Forms/WithdrawUsdcForm/WithdrawUsdcForm';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { withdrawUsdcWalletRequest } from 'redux/reducers/wallets/reducer';
import { IWithdrawUsdcInitialValues, IWithdrawUsdcRequest } from 'redux/reducers/wallets/types';
import { ETypePopUp } from 'services/constants/type-pop-up';
import { WithdrawalRequestSubmittedPopUp } from './WithdrawalRequestSubmitted';
import { WithdrawCryptoPopUpConfirm } from './WithdrawCryptoPopUpConfirm';

export const WithdrawCrypto: FC = () => {
	const [popUpOpen, setPopUpOpen] = useState('');
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [withdrawData, setWithdrawData] = useState<IWithdrawUsdcRequest | null>(null);

	const submitWithdrawUsdc = (values: IWithdrawUsdcInitialValues) => {
		setWithdrawData({ openPopUp: setPopUpOpen, data: values });

		if (userData?.sms_2fa_enabled || userData?.google2fa_enabled) {
			setPopUpOpen(ETypePopUp.CONFIRM);
		} else {
			dispatch(withdrawUsdcWalletRequest({ openPopUp: setPopUpOpen, data: values }));
		}
	};

	const closePopUp = () => {
		setPopUpOpen('');
	};

	useEffect(() => {
		return () => {
			setPopUpOpen('');
		};
	}, []);

	return (
		<>
			{popUpOpen === ETypePopUp.CONFIRM && (
				<WithdrawCryptoPopUpConfirm withdrawData={withdrawData} closePopUp={closePopUp} />
			)}
			{popUpOpen === ETypePopUp.SUBMIT && (
				<WithdrawalRequestSubmittedPopUp withdrawData={withdrawData} />
			)}
			<WithdrawUsdcForm submitWithdrawUsdc={submitWithdrawUsdc} />
		</>
	);
};
