/* eslint-disable @typescript-eslint/no-explicit-any */

import { DragEvent, FC, useEffect, useState } from 'react';
import { IImg, IPhoto, IProps } from './types';

export const ImagesAll: FC<IProps> = ({ images, setOpenAllPhoto, setImages }) => {
	const [photos, setPhotos] = useState<IPhoto[]>([]);
	const [currentPhoto, setCurrentPhoto] = useState<IImg | null>(null);
	const [showSave, setShowSave] = useState(false);

	useEffect(() => {
		const formattedImgs = images?.map((img, indx) => {
			return { ...img, sort: indx };
		});
		setPhotos(formattedImgs);
	}, [images]);

	useEffect(() => {
		const formedPhotos = photos.map((ph) => {
			return { url: ph.url };
		});
		const formedImages = images.map((item) => {
			return { url: item.url };
		});
		if (JSON.stringify(formedPhotos) !== JSON.stringify(formedImages)) {
			return setShowSave(true);
		}
		return setShowSave(false);
	}, [images, photos]);

	const onDragStart = (e: DragEvent<HTMLButtonElement>, item: IImg) => {
		setCurrentPhoto(item);
		(e.target as HTMLButtonElement).style.border = '2px solid grey';
	};
	const onDragLeave = (e: DragEvent<HTMLButtonElement>) => {
		(e.target as HTMLButtonElement).style.border = 'none';
	};
	const onDragEnd = (e: DragEvent<HTMLButtonElement>) => {
		(e.target as HTMLButtonElement).style.border = 'none';
	};
	const onDragOver = (e: DragEvent<HTMLButtonElement>) => {
		e.preventDefault();
		(e.target as HTMLButtonElement).style.border = '2px solid grey';
	};
	const onDrop = (e: DragEvent<HTMLButtonElement>, item: IImg) => {
		e.preventDefault();
		setPhotos(
			photos
				.map((img) => {
					if (img.id === item.id) {
						return { ...img, sort: currentPhoto?.sort };
					}
					if (img.id === currentPhoto?.id) {
						return { ...img, sort: item.sort };
					}
					return img;
				})
				.sort((a: any, b: any) => {
					if (a.sort > b.sort) {
						return 1;
					}
					return -1;
				}) as IPhoto[],
		);
		(e.target as HTMLButtonElement).style.border = 'none';
	};

	const handleSave = () => {
		const formatedPhotos = photos.map((item) => {
			const { ...image } = item;
			return image;
		});
		setImages && setImages(formatedPhotos);
		setShowSave(false);
	};

	return (
		<>
			<header className="header-all-image header-all-image-position">
				<div className="container">
					<div className="header">
						<div className="header-left">
							<button
								onClick={() => setOpenAllPhoto(false)}
								className="button button--type2"
								type="button"
							>
								<span className="btn-icon">
									<svg
										className="fill"
										width="14"
										height="15"
										viewBox="0 0 14 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.25 7.49609C0.25 7.08188 0.585786 6.74609 1 6.74609H13C13.4142 6.74609 13.75 7.08188 13.75 7.49609C13.75 7.91031 13.4142 8.24609 13 8.24609H1C0.585786 8.24609 0.25 7.91031 0.25 7.49609Z"
											fill="#25252E"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.53033 0.96967C7.82322 1.26256 7.82322 1.73744 7.53033 2.03033L2.06066 7.5L7.53033 12.9697C7.82322 13.2626 7.82322 13.7374 7.53033 14.0303C7.23744 14.3232 6.76256 14.3232 6.46967 14.0303L0.46967 8.03033C0.176777 7.73744 0.176777 7.26256 0.46967 6.96967L6.46967 0.96967C6.76256 0.676777 7.23744 0.676777 7.53033 0.96967Z"
											fill="#1C1C29"
										/>
									</svg>
								</span>
								Back
							</button>
						</div>
						{showSave && (
							<div className="header-right">
								<button onClick={handleSave} className="button button--type3" type="button">
									Save
								</button>
							</div>
						)}
					</div>
				</div>
			</header>
			<div className="admin header-all-image-position-body">
				<div className="gallery-container">
					<div className="realty-photos-gallery realty-photos-gallery--type2 realty-photos-gallery--always-display">
						{photos?.map((image) => (
							<div key={image.url} className="realty-photo-block  ">
								<button
									onDragStart={(e) => onDragStart(e, image)}
									onDragLeave={(e) => onDragLeave(e)}
									onDragEnd={(e) => onDragEnd(e)}
									onDragOver={(e) => onDragOver(e)}
									onDrop={(e) => onDrop(e, image)}
									type="button"
									className="realty-photo realty-photo--height-type4"
								>
									<img src={String(image.thumb)} alt="" />
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};
