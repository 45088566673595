import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IInvestmentResponse,
	IOrdersResponse,
	ITransactionHistoryUserResponse,
	ITransactionsHistoryConvert,
	IUserDividendsPagination,
	IUsersManagement,
	IUsersManagementListResponse,
	IUsersManagementState,
} from './types';

const getUsersManagementState = (state: IStoreState): IUsersManagementState =>
	state.usersManagement;

export const getUsersManagementList = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): IUsersManagementListResponse | null =>
		usersManagement.usersManagementList,
);

export const getUserManagement = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): IUsersManagement | null =>
		usersManagement.userManagement,
);

export const getUsersManagementListLoader = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): boolean => usersManagement.usersManagementLoader,
);

export const getBlockedLoader = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): boolean => usersManagement.userBlockLoader,
);

export const getInvestmentsSelector = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): IInvestmentResponse | null =>
		usersManagement.investments,
);

export const getTransactionsHistoryUserSelector = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): ITransactionHistoryUserResponse | null =>
		usersManagement.transactions,
);

export const getTransactionsHistoyConvertSelector = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): ITransactionsHistoryConvert | null =>
		usersManagement.converts,
);

export const getOrdersListSelector = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): IOrdersResponse | null => usersManagement.orders,
);

export const getDividendsSelector = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): IUserDividendsPagination | null =>
		usersManagement.dividends,
);

export const getDividendsSumSelector = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): string => usersManagement.dividendsSum,
);

export const getZipSelector = createSelector(
	[getUsersManagementState],
	(usersManagement: IUsersManagementState): string | null => usersManagement.zip,
);
