import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';
import auth from 'redux/reducers/auth/reducer';
import app from 'redux/reducers/app/reducer';
import settings from 'redux/reducers/settings/reducer';
import wallets from 'redux/reducers/wallets/reducer';
import propertyManagement from 'redux/reducers/propertyManagement/reducer';
import sellManagement from 'redux/reducers/sellManagement/reducer';
import usersManagement from 'redux/reducers/usersManagement/reducer';
import platformTokens from 'redux/reducers/platform-tokens/reducer';
import dividendsManagement from 'redux/reducers/dividends-management/reducer';
import feeManagement from 'redux/reducers/fee-management/reducer';
import dashboard from 'redux/reducers/dashboard/reducer';
import interestRequests from 'redux/reducers/interestRequests/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		auth,
		app,
		settings,
		sellManagement,
		wallets,
		propertyManagement,
		usersManagement,
		platformTokens,
		dividendsManagement,
		feeManagement,
		dashboard,
		interestRequests,
	});

export default createRootReducer;
