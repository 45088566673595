import { IPropsPopUp } from 'components/Forms/PropertyForm/types';
import { FC, useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { fileDoc } from 'services/constants/file-types';

export const PopUpAddDocument: FC<IPropsPopUp> = ({
	isOpenPopUpFirstDoc,
	docFile,
	base64Doc,
	isOpenPopUpSecondDoc,
	setNameDoc,
	cleanUp,
}) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--type2 popup--bigger">
					<p className="popup-title">Add new document</p>
					<div className="popup-body">
						{/* <div className="input input--mb24">
							<p className="input-name input-name--type2">Name</p>
							<div className="input-wrapper">
								<input
									disabled={!base64Doc}
									className="input-item"
									type="text"
									placeholder="Enter name of the document"
									value={nameDoc}
									onChange={changeName}
								/>
							</div>
						</div> */}
						<div className="add-document-pop">
							<div className="uploaded-item uploaded-item--type2 uploaded-item--full-width">
								<label className="upload-file-box">
									<div className="imput-file-custom">
										<FileUploader multiple handleChange={docFile} name="file" types={fileDoc} />
									</div>
									<span className="upload-file">
										<span className="upload-file__icon">
											<svg
												width="22"
												height="22"
												viewBox="0 0 22 22"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M15.44 7.90039C19.04 8.21039 20.51 10.0604 20.51 14.1104V14.2404C20.51 18.7104 18.72 20.5004 14.25 20.5004H7.73998C3.26998 20.5004 1.47998 18.7104 1.47998 14.2404V14.1104C1.47998 10.0904 2.92998 8.24039 6.46998 7.91039"
													stroke="#25252E"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M11 13.9991V2.61914"
													stroke="#25252E"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M14.3499 4.85L10.9999 1.5L7.6499 4.85"
													stroke="#25252E"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</span>
										<span className="upload-file__title">Upload Files</span>
										<span className="upload-file__text">Or Drop File</span>
									</span>
								</label>
							</div>
						</div>
					</div>
					<div className="popup-footer popup-footer--more-mt popup-footer--mob-column">
						<button
							onClick={() => {
								setNameDoc([]);
								cleanUp();
								isOpenPopUpFirstDoc();
							}}
							type="button"
							className="button button--type7 button--full-width"
						>
							Cancel
						</button>
						<button
							onClick={isOpenPopUpSecondDoc}
							disabled={!base64Doc.length}
							type="button"
							className="button button--full-width "
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
