import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { SvgUserIcon } from 'ui/svg-image/SvgUserIcon';
import logo from '../../assets/images/logo.svg';

export const Header: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userData = useSelector(getUserData);

	const logOut = () => {
		dispatch(logoutRequest({ history }));
	};

	return (
		<header>
			<div className="container">
				<div className="header">
					<div className="header-left">
						<Link to={ELinksName.HOME} className="logo">
							<img src={logo} alt="logo" />
						</Link>
					</div>
					<div className="header-right">
						<Link to={ELinksName.SETTINGS} className="button button--type2">
							<SvgUserIcon />
							{userData?.name}
						</Link>
						<button onClick={logOut} type="button" className="button">
							Log out
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};
