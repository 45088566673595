import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.scss';
import { IPropsSwiper } from './types';
import SlideNextButton from './SlideNextButton/SlideNextButton';
import SliderPreviousButton from './SliderPreviousButton/SliderPreviousButton';

export const SwiperSellPhoto: FC<IPropsSwiper> = ({ images, setOpenAllPhoto, numPhoto }) => {
	const arrayImage = images.map((img) => img.url);
	const [startImg, setStartImg] = useState(arrayImage.indexOf(numPhoto) + 1);

	const nextImg = () => {
		setStartImg((prev) => prev + 1);
	};

	const prevImg = () => {
		setStartImg((prev) => prev - 1);
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="slider-header">
					<button
						onClick={() => setOpenAllPhoto(false)}
						type="button"
						className="slider-header__close"
					>
						<svg
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L17.5303 16.9697C17.8232 17.2626 17.8232 17.7374 17.5303 18.0303C17.2374 18.3232 16.7626 18.3232 16.4697 18.0303L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M17.5303 0.96967C17.8232 1.26256 17.8232 1.73744 17.5303 2.03033L1.53033 18.0303C1.23744 18.3232 0.762563 18.3232 0.46967 18.0303C0.176777 17.7374 0.176777 17.2626 0.46967 16.9697L16.4697 0.96967C16.7626 0.676777 17.2374 0.676777 17.5303 0.96967Z"
								fill="white"
							/>
						</svg>
						Close
					</button>
					<p className="slider-header__count">{`${String(startImg)}/${String(images.length)}`}</p>
					<div className="realty-actions realty-actions--opacity">
						<div className="object-action object-action--type2">
							<svg
								width="40"
								height="40"
								viewBox="0 0 40 40"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M35.0002 23.334C34.5581 23.334 34.1342 23.5096 33.8217 23.8221C33.5091 24.1347 33.3335 24.5586 33.3335 25.0007V31.6673C33.3335 32.1093 33.1579 32.5333 32.8453 32.8458C32.5328 33.1584 32.1089 33.334 31.6668 33.334H8.3335C7.89147 33.334 7.46755 33.1584 7.15499 32.8458C6.84242 32.5333 6.66683 32.1093 6.66683 31.6673V25.0007C6.66683 24.5586 6.49123 24.1347 6.17867 23.8221C5.86611 23.5096 5.44219 23.334 5.00016 23.334C4.55814 23.334 4.13421 23.5096 3.82165 23.8221C3.50909 24.1347 3.3335 24.5586 3.3335 25.0007V31.6673C3.3335 32.9934 3.86028 34.2652 4.79796 35.2029C5.73564 36.1405 7.00741 36.6673 8.3335 36.6673H31.6668C32.9929 36.6673 34.2647 36.1405 35.2024 35.2029C36.14 34.2652 36.6668 32.9934 36.6668 31.6673V25.0007C36.6668 24.5586 36.4912 24.1347 36.1787 23.8221C35.8661 23.5096 35.4422 23.334 35.0002 23.334ZM18.8168 26.184C18.9753 26.3357 19.1622 26.4547 19.3668 26.534C19.5663 26.6222 19.782 26.6677 20.0002 26.6677C20.2183 26.6677 20.434 26.6222 20.6335 26.534C20.8381 26.4547 21.025 26.3357 21.1835 26.184L27.8502 19.5173C28.164 19.2035 28.3403 18.7778 28.3403 18.334C28.3403 17.8901 28.164 17.4645 27.8502 17.1507C27.5363 16.8368 27.1107 16.6605 26.6668 16.6605C26.223 16.6605 25.7973 16.8368 25.4835 17.1507L21.6668 20.984V5.00065C21.6668 4.55862 21.4912 4.1347 21.1787 3.82214C20.8661 3.50958 20.4422 3.33398 20.0002 3.33398C19.5581 3.33398 19.1342 3.50958 18.8217 3.82214C18.5091 4.1347 18.3335 4.55862 18.3335 5.00065V20.984L14.5168 17.1507C14.3614 16.9953 14.1769 16.872 13.9739 16.7879C13.7709 16.7038 13.5533 16.6605 13.3335 16.6605C13.1137 16.6605 12.8961 16.7038 12.6931 16.7879C12.49 16.872 12.3056 16.9953 12.1502 17.1507C11.9948 17.306 11.8715 17.4905 11.7874 17.6936C11.7033 17.8966 11.66 18.1142 11.66 18.334C11.66 18.5538 11.7033 18.7714 11.7874 18.9744C11.8715 19.1774 11.9948 19.3619 12.1502 19.5173L18.8168 26.184Z"
									fill="#E41472"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div className="photo-slider-box">
					<Swiper className="photo-slider" initialSlide={startImg - 1} slidesPerView={1}>
						<SliderPreviousButton prevImg={prevImg} startImg={startImg} />

						{images.map((image) => (
							<SwiperSlide className="photo-slide" key={image.url}>
								<div className="photo-slide">
									<img src={image.url} alt="" />
								</div>
							</SwiperSlide>
						))}
						<SlideNextButton nextImg={nextImg} startImg={startImg} numberImg={images.length} />
					</Swiper>
				</div>
			</div>
		</div>
	);
};
