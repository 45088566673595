import classNames from 'classnames';
import { IPropsSelectDrop } from 'components/PropertyManagementComponents/types';
// import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC } from 'react';
import { SubDropFilter } from '../SubDropFilter';

export const SelectDrop: FC<IPropsSelectDrop> = ({
	openDropFilter,
	handelDropPrice,
	openDropPrice,
	closeDropFilter,
	sortProperty,
	sortPrice,
}) => {
	const isClosePrice = () => {
		handelDropPrice();
		closeDropFilter(false);
	};

	return openDropFilter ? (
		<>
			<div
				className={classNames('select__drop', {
					'select__drop--active': openDropFilter,
				})}
			>
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							<li>
								<div className="select">
									<button onClick={handelDropPrice} type="button" className="select__current">
										By price
										<span className="select__current-arrow">
											<svg
												width="14"
												height="8"
												viewBox="0 0 14 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
													fill="#25252E"
												/>
											</svg>
										</span>
									</button>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			{openDropPrice && (
				<SubDropFilter
					sortProperty={sortProperty}
					openDrop={openDropPrice}
					isClose={isClosePrice}
					sortPrice={sortPrice}
				/>
			)}
		</>
	) : null;
};
