import { FC, ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { IProps2FaDisabled } from './types';

export const TwoFaAuthDisabledGoogle: FC<IProps2FaDisabled> = ({ handleGoogle2faSubmit }) => {
	const [code, setCode] = useState('');
	const userData = useSelector(getUserData);

	const heandleInput = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setCode(target.value);
	};

	return (
		<div className="tfa-wrapper">
			<div className="tfa-info">
				<p className="tfa-info__title">{`Turn-${
					userData?.google2fa_enabled ? 'off' : 'on'
				} 2FA-Authentication`}</p>
				<p className="tfa-info__description">
					Enter the verification code from the Google Authenticator to disable Google 2FA
				</p>
			</div>
			<div className="input input--mb-0">
				<div className="input-wrapper">
					<input
						onChange={heandleInput}
						className="input-item input-item--pr-125 "
						type="text"
						placeholder="Code"
						value={code}
					/>
				</div>
			</div>
			<div className="form-footer form-footer--end">
				<button
					onClick={() => handleGoogle2faSubmit(code)}
					type="button"
					className="button button--long button--mob-full-width"
					disabled={code.length !== 6}
				>
					Confirm
				</button>
			</div>
		</div>
	);
};
