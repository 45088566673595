import { Pagination } from 'components/Pagination';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getDashboardSubscriptionsRequest } from 'redux/reducers/dashboard/reducer';
import { getSubscriptionsSelector } from 'redux/reducers/dashboard/selectors';
import {
	SvgNewUsers,
	SvgProfit,
	SvgTotalInvestors,
	SvgTotalUnits,
	SvgWhitelisted,
} from 'ui/svg-image/SvgDashboard';
import numeral from 'numeral';
import { IPropsBalances } from '../types';

export const Balances: FC<IPropsBalances> = ({ balances }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const subscriptions = useSelector(getSubscriptionsSelector);

	const balancesItem = [
		{ svg: <SvgTotalUnits />, name: 'Total units:', unit: 'items', key: 'total_estates' },
		{
			svg: <SvgTotalInvestors />,
			name: 'Total investors:',
			unit: 'investors',
			key: 'total_investors',
		},
		{ svg: <SvgWhitelisted />, name: 'Whitelisted:', unit: 'users', key: 'whitelisted_users' },
		{ svg: <SvgNewUsers />, name: 'New users (24h):', unit: 'users', key: 'new_users' },
		{ svg: <SvgProfit />, name: 'Profit:', unit: 'USD', key: 'profit' },
	];

	const submitFilter = (value: number) => {
		dispatch(
			getDashboardSubscriptionsRequest({
				current_page: value,
			}),
		);
	};

	useEffect(() => {
		submitFilter(+history.location.search.slice(1) || 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="dashboard dashboard--standart dashboard--mb40 dashboard--info0">
				{balancesItem.map((balance) => (
					<div key={balance.key} className="dashboard-item">
						<div className="dashboard-item__icon">{balance.svg}</div>
						<div className="dashboard-item__info">
							<p>{balance.name}</p>
							{balance.key === 'profit' ? (
								<span>{`${String(
									numeral(balances[balance.key]).format('0,0.00').replaceAll(',', ' '),
								)} ${balance.unit}`}</span>
							) : (
								<span>{`${String(balances[balance.key])} ${balance.unit}`}</span>
							)}
						</div>
					</div>
				))}
			</div>
			<div className="table-top-wrapper table-top-wrapper--center">
				<div className="table-title table-title--m0">
					<p>Emails subscribed for mailing </p>
				</div>
			</div>
			<div className="table table--subscribed-mailing table--second-type">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p className="td-name">Email</p>
						</div>
						<div className="td">
							<p className="td-name">Created date</p>
						</div>
					</div>
				</div>
				<div className="table-body">
					{subscriptions?.data.map((subscription) => (
						<div key={subscription.id} className="tr">
							<div className="td">
								<p className="td-hidden-name">Email</p>
								<p className="td-value td-value--medium">{subscription.email}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name"> Created date</p>
								<p className="td-value td-value--medium">
									{moment(subscription.created_at).format('DD.MM.YY h:mm:ss')}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
			{subscriptions?.last_page && subscriptions?.last_page > 1 && (
				<Pagination
					request={submitFilter}
					currentPage={subscriptions.current_page}
					numberPage={subscriptions.last_page}
				/>
			)}
		</>
	);
};
