import { FC } from 'react';

export const InvestmentsHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">Apartment Name</p>
				</div>
				<div className="td">
					<p className="td-name">Date of invest</p>
				</div>
				<div className="td">
					<p className="td-name">Invest Amount </p>
				</div>
				<div className="td td--center">
					<p className="td-name">Amount of token</p>
				</div>
				<div className="td">
					<p className="td-name">Token status </p>
				</div>
				<div className="td td--right">
					<p className="td-name">Legal documents</p>
				</div>
			</div>
		</div>
	);
};
