import classNames from 'classnames';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { months } from 'moment';
import { FC, MouseEvent, useState } from 'react';
import { Year } from './Year.tsx';

interface IProps {
	selectedMonthOrYear: (event: MouseEvent) => void;
	month: string;
	year: string;
}

export const LaptopDrop: FC<IProps> = ({ year, month, selectedMonthOrYear }) => {
	const [isOpenDrop, setIsOpenDrop] = useState<boolean>(false);
	const { innerBorderRef } = useOnOutsideClick(() => setIsOpenDrop(false));

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">Apartment Name</p>
				</div>
				<div className="td">
					<p className="td-name">Date of payment</p>
				</div>
				<div onClick={() => setIsOpenDrop(!isOpenDrop)} className="td">
					<div ref={innerBorderRef} className="select select--table-select">
						<button type="button" className="select__current td-value-first-letter-up">
							{month}
							<span className="select__current-arrow">
								<svg
									width="15"
									height="8"
									viewBox="0 0 15 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.64012 0.712987C0.756206 0.432732 1.02968 0.25 1.33303 0.25H13.333C13.6364 0.25 13.9099 0.432732 14.0259 0.712987C14.142 0.993243 14.0779 1.31583 13.8634 1.53033L7.86336 7.53033C7.57047 7.82322 7.09559 7.82322 6.8027 7.53033L0.8027 1.53033C0.588201 1.31583 0.524034 0.993243 0.64012 0.712987ZM3.14369 1.75L7.33303 5.93934L11.5224 1.75H3.14369Z"
										fill="#25252E"
									/>
								</svg>
							</span>
						</button>
						<div
							className={classNames('select__drop', {
								'select__drop--active': isOpenDrop,
							})}
						>
							<div className="select__drop-scroll">
								<div className="select__drop-item">
									<ul>
										{months().map((m) => (
											<li key={m}>
												<button
													onClick={(e: MouseEvent) => selectedMonthOrYear(e)}
													name={m.toLowerCase()}
													type="button"
												>
													{m}
												</button>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Year year={year} selectedMonthOrYear={selectedMonthOrYear} />
			</div>
		</div>
	);
};
