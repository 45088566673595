import { ChangeEmailForm } from 'components/Forms/ChangeEmailForm';
import { ChangeNameForm } from 'components/Forms/ChangeNameForm';
import { ChangePassForm } from 'components/Forms/ChangePassForm';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
	changeEmailRequest,
	changeUserPassRequest,
	editUserRequest,
} from 'redux/reducers/settings/reducer';
import {
	IEditUserRequest,
	IChangeEmailRequest,
	ICreatePasswordFormData,
} from 'redux/reducers/settings/types';

export const AdminProfile: FC = () => {
	const dispatch = useDispatch();

	const editUserSubmit = (values: IEditUserRequest) => {
		dispatch(editUserRequest(values));
	};

	const changeImailSubmit = (values: IChangeEmailRequest) => {
		dispatch(changeEmailRequest(values));
	};

	const handleChangePassFormSubmit = (values: ICreatePasswordFormData) => {
		dispatch(changeUserPassRequest(values));
	};

	return (
		<div className="admin-profile">
			<div className="admin-profile__left">
				<ChangeNameForm editUserSubmit={editUserSubmit} />
				<ChangeEmailForm changeImailSubmit={changeImailSubmit} />
			</div>
			<ChangePassForm changePassFormSubmit={handleChangePassFormSubmit} />
		</div>
	);
};
