import { DividendsDrop } from 'components/DividendsManagementComponents/DividendsDrop';
import { IPropsTdStatus } from 'components/DividendsManagementComponents/types';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { postStatusRequest } from 'redux/reducers/dividends-management/reducer';
import { arrayRentStatus, rentStatusList } from 'services/constants/status';
import { SvgStatusArrow } from 'ui/svg-image/SvgStatusArrow';

export const TdStatus: FC<IPropsTdStatus> = ({ dividend }) => {
	const [rentStatus, setRentStatus] = useState<string>('');
	const [isOpenDrop, setIsOpenDrop] = useState<number | null>(null);
	const { innerBorderRef } = useOnOutsideClick(() => setIsOpenDrop(null));
	const dispatch = useDispatch();

	const isCloseDrop = (e: MouseEvent) => {
		const target = e.target as HTMLButtonElement;
		setRentStatus(target.name);
		setIsOpenDrop(null);
		dispatch(postStatusRequest({ rent_status: +target.name, estate_id: dividend.id }));
	};

	return (
		<div ref={innerBorderRef} className="td">
			<p className="td-hidden-name">Status</p>
			<div className="select select-z-index select--td-filter select--mob-right-drop select--wide-drop">
				<button
					onClick={() => setIsOpenDrop(dividend.id === isOpenDrop ? null : dividend.id)}
					type="button"
					className={`select__current ${dividend.rent_status ? 'green' : 'red'}`}
				>
					{dividend.id === isOpenDrop && rentStatus
						? rentStatusList[rentStatus]
						: rentStatusList[dividend.rent_status]}
					<span className="select__current-arrow">
						<SvgStatusArrow />
					</span>
				</button>
				{dividend.id === isOpenDrop && (
					<DividendsDrop
						arrayRentStatus={arrayRentStatus.slice(1)}
						isOpenDrop={isOpenDrop}
						isCloseDrop={isCloseDrop}
					/>
				)}
			</div>
		</div>
	);
};
