import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { Sidebar } from 'components/Sidebar';
import { PopUpDelete } from 'components/UsersManagementComponents/PopUpDelete';
import { PopUpNotDelete } from 'components/UsersManagementComponents/PopUpNotDelete';
import { PopUpSuccess } from 'components/UsersManagementComponents/PopUpSuccess';
import { UsersManagementTableBody } from 'components/UsersManagementComponents/UsersManagementTableBody';
import { UsersManagementTableHeader } from 'components/UsersManagementComponents/UsersManagementTableHeader';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
	deleteUserRequest,
	getBlockedRequest,
	getUsersManagementListRequest,
} from 'redux/reducers/usersManagement/reducer';
import {
	getUsersManagementList,
	getUsersManagementListLoader,
} from 'redux/reducers/usersManagement/selectors';

export interface ISearchUserRequest {
	search_field: string;
	search_value: number | string;
}

const UsersManagement: FC = () => {
	const dispatch = useDispatch();
	const usersManagtList = useSelector(getUsersManagementList);
	const usersManagtListLoader = useSelector(getUsersManagementListLoader);
	const [isVisible, setIsVisible] = useState<number | null>(null);
	const [isClosePopUp, setIsClosePopUp] = useState<boolean | number>(false);
	const [searchText, setSearchText] = useState<ISearchUserRequest>({
		search_field: '',
		search_value: '',
	});
	const location = useLocation();

	const handleSearch = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setSearchText({
			search_field: +target.value ? 'user_id' : 'email',
			search_value: target.value || '',
		});
	};

	const handleButton = (id: number | null, value?: boolean | number) => {
		setIsVisible(id);
		typeof value === 'boolean' && value && setIsClosePopUp(value);
		typeof value !== 'boolean' && value && setIsClosePopUp(value);
	};

	const removeUser = (id: number) => {
		dispatch(deleteUserRequest({ id, isClosePopUp: handleButton }));
	};

	const closeAllPopUp = () => {
		setIsClosePopUp(false);
	};

	const blockedUser = (blocked: number, id: number) => {
		dispatch(
			getBlockedRequest({
				blocked,
				user_id: id,
			}),
		);
		setIsClosePopUp(false);
	};

	const submitFilter = (value: number) => {
		dispatch(
			getUsersManagementListRequest({
				current_page: value,
			}),
		);
	};

	useEffect(() => {
		const timeOutId = setTimeout(
			() =>
				dispatch(
					searchText.search_value
						? getUsersManagementListRequest({
								current_page: +location.search.slice(1) || 1,
								...searchText,
						  })
						: getUsersManagementListRequest({
								current_page: +location.search.slice(1) || 1,
						  }),
				),
			500,
		);
		return () => clearTimeout(timeOutId);
	}, [searchText, dispatch, location]);

	return (
		<div className="admin">
			<Sidebar />

			<div className="admin__content">
				<div className="table-top-wrapper table-top-wrapper--center">
					<div className="table-title table-title--m0">
						<p>Users management</p>
					</div>
					<div className="input input--big-width input--no-mb">
						<div className="input-wrapper ">
							<input
								onChange={handleSearch}
								className="input-item input-item--right-icon"
								type="text"
								placeholder="Search by User ID or Name or Email"
							/>
							<button className="input-search-button" type="button">
								<svg
									width="35"
									height="35"
									viewBox="0 0 35 35"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M31.6605 29.5901L26.25 24.2235C28.3502 21.6049 29.3672 18.2812 29.0921 14.9358C28.8169 11.5903 27.2704 8.47751 24.7706 6.2373C22.2708 3.9971 19.0077 2.79982 15.6522 2.89163C12.2968 2.98345 9.10401 4.35739 6.73046 6.73095C4.3569 9.1045 2.98296 12.2973 2.89115 15.6527C2.79933 19.0082 3.99662 22.2713 6.23682 24.7711C8.47702 27.2709 11.5899 28.8174 14.9353 29.0925C18.2807 29.3677 21.6044 28.3507 24.223 26.2505L29.5896 31.6172C29.7252 31.7539 29.8865 31.8624 30.0642 31.9364C30.2419 32.0105 30.4325 32.0486 30.625 32.0486C30.8176 32.0486 31.0082 32.0105 31.1859 31.9364C31.3636 31.8624 31.5249 31.7539 31.6605 31.6172C31.9233 31.3453 32.0702 30.9819 32.0702 30.6037C32.0702 30.2255 31.9233 29.862 31.6605 29.5901V29.5901ZM16.0417 26.2505C14.0227 26.2505 12.049 25.6518 10.3703 24.5301C8.69151 23.4084 7.38308 21.8141 6.61044 19.9488C5.8378 18.0834 5.63564 16.0309 6.02953 14.0507C6.42342 12.0704 7.39567 10.2515 8.82333 8.82382C10.251 7.39616 12.0699 6.42391 14.0502 6.03002C16.0304 5.63612 18.0829 5.83828 19.9483 6.61093C21.8136 7.38357 23.4079 8.692 24.5296 10.3708C25.6513 12.0495 26.25 14.0232 26.25 16.0422C26.25 18.7496 25.1745 21.3461 23.2601 23.2606C21.3457 25.175 18.7491 26.2505 16.0417 26.2505V26.2505Z"
										fill="#9E9E9E"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div className="table table--user-management table--second-type">
					<div className="table-header">
						<UsersManagementTableHeader />
					</div>
					<div className="table-body">
						{usersManagtListLoader ? (
							<Loader />
						) : (
							usersManagtList?.data && (
								<UsersManagementTableBody
									usersManagtList={usersManagtList.data}
									handleButton={handleButton}
									blockedUser={blockedUser}
								/>
							)
						)}
					</div>
				</div>
				{usersManagtList?.last_page && usersManagtList?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={usersManagtList.current_page}
						numberPage={usersManagtList.last_page}
					/>
				)}
			</div>
			{isVisible && (
				<PopUpDelete isVisible={isVisible} handleButton={handleButton} removeUser={removeUser} />
			)}
			{typeof isClosePopUp === 'boolean' && isClosePopUp && (
				<PopUpSuccess isClosePopUp={setIsClosePopUp} />
			)}
			{typeof isClosePopUp !== 'boolean' && isClosePopUp && (
				<PopUpNotDelete
					isClosePopUp={isClosePopUp}
					blockedUser={blockedUser}
					closeAllPopUp={closeAllPopUp}
				/>
			)}
		</div>
	);
};

export default UsersManagement;
