import { FC } from 'react';
import { Pagin } from 'untils/pagination';

import { IPropsPagination } from './types';

export const Pagination: FC<IPropsPagination> = ({ currentPage, numberPage, request }) => {
	return (
		<div className="pagination-block">
			<ul className="pagination">
				<Pagin pageCount={numberPage} pageIndex={currentPage} setPageIndex={request} />
			</ul>
		</div>
	);
};
