import { languages } from 'languages';
import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { appLanguage } from 'redux/reducers/app/selectors';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { routesList } from 'routes/routesList';
import { Header } from 'components/Header';

// ==========================================:
const App: FC = () => {
	const isAuth = useSelector(getIsAuth);
	const language = useSelector(appLanguage);
	L.setTexts(languages[language]);

	return (
		<BrowserRouter>
			{isAuth && <Header />}
			<main className="main">
				<section className="admin-section">
					<div className="container">
						<Switch>
							{routesList?.map((route) => (
								<Route key={route.path} exact path={route.path} component={route.component} />
							))}
							<Redirect to="/" />
						</Switch>
					</div>
				</section>
			</main>
		</BrowserRouter>
	);
};

export default App;
