/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	IWalletsStor,
	ILimitsModifyReguest,
	IBalancesLimitsResponse,
	ISagaDepFitReq,
	ICreateUsdcRequest,
	ICreateUsdcResponse,
	IWithdrawUsdcRequest,
	ITransctionsHistoryResponse,
	ITransctionsHistoryRequest,
	IHistoryConvertsResponse,
	IApartmentInvestResponse,
	TLimits,
	IChangeStatusRequest,
} from './types';

export const initialState: IWalletsStor = {
	balancesLimits: null,
	balancesProfit: null,
	balancesLimitsLoader: false,
	walletUsdLoader: false,
	walletUsdcLoader: false,
	usdcQr: null,
	transactionsHistoryAdmin: null,
	transactionsHistoryAll: null,
	historyConverts: null,
	investmentsAll: null,
	transactionsHistoryAdminLoader: false,
	limitations: null,
};

const wallets = createSlice({
	name: '@@wallets',
	initialState,
	reducers: {
		getBalancesLimitsReguest: (state) => {
			const balancesLimitsState = state;
			balancesLimitsState.balancesLimitsLoader = true;
		},

		getBalancesLimitsSuccess: (state, action: PayloadAction<IBalancesLimitsResponse>) => {
			const { payload } = action;
			const balancesLimitsState = state;
			balancesLimitsState.balancesLimitsLoader = false;

			balancesLimitsState.balancesLimits = payload;
		},

		getProfitLimitsReguest: (state) => {
			const balancesLimitsState = state;
			balancesLimitsState.balancesLimitsLoader = true;
		},

		getProfitLimitsSuccess: (state, action: PayloadAction<{ profit: string | number }>) => {
			const { payload } = action;
			const balancesLimitsState = state;
			balancesLimitsState.balancesLimitsLoader = false;

			balancesLimitsState.balancesProfit = payload;
		},

		limitsModifyReguest: (state, action: PayloadAction<ILimitsModifyReguest>) => {},

		createUsdWalletRequest: (state, action: PayloadAction<ISagaDepFitReq>) => {
			const createUsdWalletState = state;
			createUsdWalletState.walletUsdLoader = true;
		},

		createUsdWalletSuccess: (state) => {
			const createUsdWalletState = state;
			createUsdWalletState.walletUsdLoader = false;
		},

		createUsdcWalletRequest: (state, action: PayloadAction<ICreateUsdcRequest>) => {
			const createUsdcWalletState = state;
			createUsdcWalletState.walletUsdcLoader = true;
		},

		createUsdcWalletSuccess: (state, action: PayloadAction<ICreateUsdcResponse | null>) => {
			const { payload } = action;

			const createUsdcWalletState = state;
			createUsdcWalletState.walletUsdcLoader = false;
			createUsdcWalletState.usdcQr = payload;
		},

		withdrawUsdcWalletRequest: (state, action: PayloadAction<IWithdrawUsdcRequest>) => {
			const withdrawUsdcWalletState = state;
			withdrawUsdcWalletState.walletUsdcLoader = true;
		},

		withdrawUsdcWalletSuccess: (state) => {
			const withdrawUsdcWalletState = state;
			withdrawUsdcWalletState.walletUsdcLoader = false;
		},

		getTransactionHistoryAdminReguest: (
			state,
			action: PayloadAction<ITransctionsHistoryRequest>,
		) => {
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = true;
		},

		getTransactionHistoryAdminSuccess: (
			state,
			action: PayloadAction<ITransctionsHistoryResponse>,
		) => {
			const { payload } = action;
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = false;

			transactionsHistoryState.transactionsHistoryAdmin = payload;
		},

		getLimitationsReguest: (state) => {},

		getLimitationsSuccess: (state, action: PayloadAction<TLimits>) => {
			const { payload } = action;
			const limitationsState = state;

			limitationsState.limitations = payload;
		},

		getTransactionHistoryAllReguest: (state, action: PayloadAction<ITransctionsHistoryRequest>) => {
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = true;
		},

		getTransactionHistoryAllSuccess: (
			state,
			action: PayloadAction<ITransctionsHistoryResponse>,
		) => {
			const { payload } = action;
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = false;

			transactionsHistoryState.transactionsHistoryAll = payload;
		},

		getHistoryConvertsReguest: (state, action: PayloadAction<ITransctionsHistoryRequest>) => {
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = true;
		},

		getHistoryConvertsSuccess: (state, action: PayloadAction<IHistoryConvertsResponse>) => {
			const { payload } = action;
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = false;

			transactionsHistoryState.historyConverts = payload;
		},

		getInvestmentsAllReguest: (state, action: PayloadAction<ITransctionsHistoryRequest>) => {
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = true;
		},

		getInvestmentsAllSuccess: (state, action: PayloadAction<IApartmentInvestResponse>) => {
			const { payload } = action;
			const transactionsHistoryState = state;
			transactionsHistoryState.transactionsHistoryAdminLoader = false;

			transactionsHistoryState.investmentsAll = payload;
		},

		changeStatusRequest: (state, action: PayloadAction<IChangeStatusRequest>) => {},

		changeStatusSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;

			const changeStatusState = state;
			if (changeStatusState.transactionsHistoryAdmin) {
				changeStatusState.transactionsHistoryAdmin.data =
					changeStatusState.transactionsHistoryAdmin.data.filter(
						(transaction) => transaction.id !== payload,
					);
			}
		},

		walletsInitState: (state) => {
			const loadersState = state;
			loadersState.balancesLimitsLoader = false;
			loadersState.transactionsHistoryAdminLoader = false;
			loadersState.walletUsdLoader = false;
			loadersState.walletUsdcLoader = false;
		},
	},
});

export default wallets.reducer;
export const {
	getBalancesLimitsReguest,
	getBalancesLimitsSuccess,
	limitsModifyReguest,
	createUsdWalletRequest,
	createUsdWalletSuccess,
	createUsdcWalletRequest,
	createUsdcWalletSuccess,
	withdrawUsdcWalletRequest,
	withdrawUsdcWalletSuccess,
	getProfitLimitsReguest,
	getProfitLimitsSuccess,
	getTransactionHistoryAdminReguest,
	getTransactionHistoryAdminSuccess,
	getTransactionHistoryAllReguest,
	getTransactionHistoryAllSuccess,
	getHistoryConvertsReguest,
	getHistoryConvertsSuccess,
	walletsInitState,
	getInvestmentsAllReguest,
	getInvestmentsAllSuccess,
	getLimitationsReguest,
	getLimitationsSuccess,
	changeStatusRequest,
	changeStatusSuccess,
} = wallets.actions;
