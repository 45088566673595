import { FC } from 'react';

export const HeaderTable: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">Request ID</p>
				</div>
				<div className="td">
					<p className="td-name">Request date</p>
				</div>
				<div className="td">
					<p className="td-name">Number of tokens</p>
				</div>
				<div className="td">
					<p className="td-name">Total amount</p>
				</div>
				<div className="td">
					<p className="td-name">Phone number</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Email</p>
				</div>
			</div>
		</div>
	);
};
