import { IPropsOrdersTable } from 'components/UserAcountComponents/types';
import moment from 'moment';
import { FC } from 'react';
import { orderStatus, orderType } from 'services/constants/status';

export const TableHistory: FC<IPropsOrdersTable> = ({ ordersList }) => {
	return (
		<div className="table-body">
			{ordersList.map((order) => (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">ID</p>
						<p className="td-value td-value--medium">{order.id}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Apartment Name</p>
						<p className="td-value td-value--medium">{order?.estate.title}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Created</p>
						<p className="td-value td-value--medium">
							{moment(order?.estate.created_at).format('DD.MM.YY h:mm:ss')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Asset</p>
						<p className="td-value td-value--medium td-value--medium-upper">{order.asset.name}</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Quantity </p>
						<p className="td-value td-value--medium">{order.token_price}</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Quantity left </p>
						<p className="td-value td-value--medium">{Number(order.tokens_left)}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Type</p>
						<p className="td-value td-value--medium">{orderType[order.type]}</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Fee buyer</p>
						<p className="td-value td-value--medium">{Number(order.fee_buyer)}</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Fee seller</p>
						<p className="td-value td-value--medium">{Number(order.fee_seller)}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Status</p>
						<p className="td-value td-value--medium green">{orderStatus[order.status]}</p>
					</div>
				</div>
			))}
		</div>
	);
};
