import { FC } from 'react';

export const UsersManagementTableHeader: FC = () => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-name">User ID</p>
			</div>
			<div className="td">
				<p className="td-name">Full name</p>
			</div>
			<div className="td">
				<p className="td-name">Email</p>
			</div>
			<div className="td">
				<p className="td-name">Country</p>
			</div>
			<div className="td">
				<p className="td-name">Last login</p>
			</div>
			<div className="td">
				<p className="td-name">Status</p>
			</div>
			<div className="td">
				<p className="td-name">Whitelist</p>
			</div>
			<div className="td td--right">
				<p className="td-name">Actions</p>
			</div>
		</div>
	);
};
