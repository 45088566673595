import { FC, useEffect, useState, MouseEvent } from 'react';
import { SvgCopy, SvgFavorite } from 'ui/svg-image/SvgPreviewProperty';
import { IPropsPreviewProperty } from '../types';
import { ImportantInformation } from './ImportantInformation';
import { Overview } from './Overview';
import { PhotosGallery } from './PhotosGallery';
import { ImagesAll } from './PhotosGallery/ImagesAll';
import { PropertyFeatures } from './PropertyFeatures';
import { StartMapGoogle } from './StartMapGoogle';

export const PreviewProperty: FC<IPropsPreviewProperty> = ({
	setIsOpenPreview,
	values,
	images,
	documents,
}) => {
	const [isOpenAllImg, setIsOpenAllImg] = useState<boolean>(false);
	const [onShowMap, setOnShowMap] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return isOpenAllImg ? (
		<ImagesAll images={images as { [key: string]: string }[]} setOpenAllPhoto={setIsOpenAllImg} />
	) : (
		<section className="admin-section admin-section-position">
			<section className="real-estate-section real-estate-section-custom">
				<div className="container">
					<div className="realty-wrapper realty-wrapper-custom">
						<div className="back-btn-block">
							<button
								onClick={() => setIsOpenPreview && setIsOpenPreview(false)}
								type="button"
								className="back-btn"
							>
								<span className="back-btn__arrow">
									<svg
										width="17"
										height="17"
										viewBox="0 0 17 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M15.7918 8.5H1.2085"
											stroke="#25252E"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.49967 15.7904L1.20801 8.4987L8.49967 1.20703"
											stroke="#25252E"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								Go back
							</button>
						</div>
						<div className="realty-header">
							<div className="realty-header__left">
								<h1 className="realty-name">Dunlap Hollow A-Frame</h1>
								<p className="realty-address">
									<span className="realty-address__icon">
										<svg
											width="20"
											height="23"
											viewBox="0 0 20 23"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.99891 12.9299C11.722 12.9299 13.1189 11.5331 13.1189 9.80994C13.1189 8.08681 11.722 6.68994 9.99891 6.68994C8.27578 6.68994 6.87891 8.08681 6.87891 9.80994C6.87891 11.5331 8.27578 12.9299 9.99891 12.9299Z"
												stroke="#25252E"
												strokeWidth="1.5"
											/>
											<path
												d="M1.62166 7.99C3.59166 -0.669998 16.4217 -0.659997 18.3817 8C19.5317 13.08 16.3717 17.38 13.6017 20.04C11.5917 21.98 8.41166 21.98 6.39166 20.04C3.63166 17.38 0.471662 13.07 1.62166 7.99Z"
												stroke="#25252E"
												strokeWidth="1.5"
											/>
										</svg>
									</span>
									{`${String(values.city)}, ${String(values.state)}, ${String(
										values.index,
									)}, ${String(values.country)}`}
								</p>
							</div>
							<div className="realty-header__right">
								<div className="realty-actions">
									<span className="object-label object-label--live">
										<span className="object-label__dot" />
										Live
									</span>
									<button type="button" className="object-action object-action--type2">
										<SvgCopy />
									</button>
									<button type="button" className="object-action object-action--type2">
										<SvgFavorite />
									</button>
								</div>
								{/* <p className="realty-date">
									Listed date: <span>Jun 15, 2022</span>
								</p> */}
							</div>
						</div>
						{images?.length ? (
							<PhotosGallery
								setIsOpenAllImg={setIsOpenAllImg}
								images={images as { [key: string]: string }[]}
							/>
						) : null}
						<PropertyFeatures setOnShowMap={setOnShowMap} onShowMap={onShowMap} values={values} />
						<ImportantInformation values={values} />
						<Overview values={values} />

						<div className="realty-map">
							<StartMapGoogle values={values} />
						</div>

						<div className="realty-block">
							<div className="realty-block__header">
								<p className="info-title">You can place an order to Sell or Buy token</p>
								<div className="switcher switcher--fixed-size">
									<button className="switcher__item active" type="button">
										Buy
									</button>
									<button className="switcher__item" type="button">
										Sell
									</button>
								</div>
								<button type="button" className="button button--medium button--type4">
									<span className="btn-icon">
										<svg
											className="fill"
											width="25"
											height="24"
											viewBox="0 0 25 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M12.5313 4.25C12.9455 4.25054 13.2809 4.58677 13.2803 5.00098L13.262 19.001C13.2614 19.4152 12.9252 19.7505 12.511 19.75C12.0968 19.7495 11.7615 19.4132 11.762 18.999L11.7803 4.99902C11.7809 4.5848 12.1171 4.24946 12.5313 4.25Z"
												fill="#E41472"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M4.75 12C4.75 11.5858 5.08579 11.25 5.5 11.25H19.5C19.9142 11.25 20.25 11.5858 20.25 12C20.25 12.4142 19.9142 12.75 19.5 12.75H5.5C5.08579 12.75 4.75 12.4142 4.75 12Z"
												fill="#E41472"
											/>
										</svg>
									</span>
									New sell order
								</button>
							</div>
						</div>
						{documents?.length ? (
							<div className="realty-block">
								<div className="realty-block__header realty-block__header--type2">
									<p className="info-title">Documents:</p>
								</div>
								<div className="realty-block__content">
									<div className="realty-doc-list">
										{documents?.map((document) => (
											<div key={document.id} className="realty-doc realty-doc-custom">
												<span className="realty-doc__icon">
													<svg
														width="18"
														height="23"
														viewBox="0 0 18 23"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M10 1.5H3C2.46957 1.5 1.96086 1.71071 1.58579 2.08579C1.21071 2.46086 1 2.96957 1 3.5V19.5C1 20.0304 1.21071 20.5391 1.58579 20.9142C1.96086 21.2893 2.46957 21.5 3 21.5H15C15.5304 21.5 16.0391 21.2893 16.4142 20.9142C16.7893 20.5391 17 20.0304 17 19.5V8.5L10 1.5Z"
															stroke="#3D58DB"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M10 1.5V8.5H17"
															stroke="#3D58DB"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
												<a
													className="link-documents-custom"
													href="/"
													onClick={(event: MouseEvent) => event.preventDefault()}
												>
													{document.title}
												</a>
											</div>
										))}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</section>
		</section>
	);
};
