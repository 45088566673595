import { FC } from 'react';

export const WithdrawFiat: FC = () => {
	return (
		<div className="operation-form">
			<div className="form-group ">
				<div className="input">
					<p className="input-name ">PayPal ID email</p>
					<div className="input-wrapper">
						<input className="input-item " type="text" placeholder="Enter your email" />
					</div>
				</div>
			</div>
			<div className="form-footer form-footer--end">
				<button type="button" className="button  button--width-150">
					Сontinue
				</button>
			</div>
		</div>
	);
};
