import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openPopUpGoogle2FaSuccess } from 'redux/reducers/settings/reducer';

export const PopUp2FaOff: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--smaller">
					<button
						onClick={() => {
							dispatch(openPopUpGoogle2FaSuccess(false));
						}}
						className="popup__close"
						type="button"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#25252E"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#25252E"
							/>
						</svg>
					</button>
					<div className="popup-success-icon popup-success-icon--small">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
								fill="#31BDAA"
							/>
							<path
								d="M7.75 12L10.58 14.83L16.25 9.17"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<p className="popup-title popup-title--small popup-title--center popup-title--mb16">
						2FA-Authentication turned off
					</p>
					<div className="popup-body">
						<p className="popup-text popup-text--center">
							We recommend turning this function on, as this will raise the security level of your
							account
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
