import { FC } from 'react';
import { SvgShowAll } from 'ui/svg-image/SvgPreviewProperty';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, EffectFade, Navigation } from 'swiper';

interface IProps {
	images: { [key: string]: string }[];
	setIsOpenAllImg: (value: boolean) => void;
}

export const PhotosGallery: FC<IProps> = ({ images, setIsOpenAllImg }) => {
	return (
		<div className="realty-photos">
			<div className="realty-photos-gallery">
				<div className="realty-photo-block realty-photo-block--half">
					<div className="realty-photo realty-photo--height-type2">
						<img src={images[0].url} alt="" />
					</div>
					{images.length > 5 && (
						<div onClick={() => setIsOpenAllImg(true)} className="all-photos">
							<div className="button button--full-width all-photos__btn">
								<span className="btn-icon btn-icon--width-24">
									<SvgShowAll />
								</span>
								Show all photos
							</div>
						</div>
					)}
				</div>
				<div className="realty-photo-block realty-photo-block--half realty-photo-block--two-row">
					{images.slice(1, 5).map((img) => (
						<div key={img.url || img.file} className="realty-photo  ">
							<img src={img.url} alt="" />
						</div>
					))}
				</div>
			</div>
			<div className="show-mob">
				<div className="card-slider-box card-slider-box--realty-page">
					<div className="swiper-container">
						<Swiper
							modules={[Pagination, EffectFade, Navigation]}
							pagination={{
								el: '.swiper-pagination',
								type: 'bullets',
								clickable: true,
								dynamicBullets: true,
							}}
							navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
							effect="fade"
							speed={800}
							slidesPerView={1}
							loop={false}
							spaceBetween={24}
							className="swiper-container swiper-container-0 swiper-container-fade swiper-container-initialized swiper-container-horizontal"
						>
							{images?.length ? (
								images?.map((img) => (
									<SwiperSlide key={img.id} className="swiper-wrapper swiper-wrapper-custom">
										<div className="swiper-slide">
											<div className="card-slider-item">
												<img src={img.url} alt="" />
											</div>
										</div>
									</SwiperSlide>
								))
							) : (
								<div className="swiper-slide swiper-slide--center">
									<div className="card-slider-item swiper-slide--center">
										<span>No pictures</span>
									</div>
								</div>
							)}
							<div className="swiper-pagination" />
						</Swiper>
					</div>
					<div className="slider-dots" />
				</div>
			</div>
		</div>
	);
};
