/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import ErrorMsg from 'components/ErrorMsg';
import { Field } from 'formik';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatesRequest } from 'redux/reducers/propertyManagement/reducer';
import { getStatesSelector } from 'redux/reducers/propertyManagement/selectors';
// import { stateList } from 'services/constants/status';
import { CountiesPopup } from './CountiesPopup';

interface IProps {
	values: IValuesPropertyAddress;
	errors: IValuesPropertyAddress;
	openModalState: (e: React.MouseEvent) => void;
	dropState: boolean;
	setDropState: (value: boolean) => void;
	dropCounries: boolean;
	setDropCountries: (value: boolean) => void;
	openModalCountries: (e: React.MouseEvent) => void;
}

export interface IValuesPropertyAddress {
	country: string;
	street: string;
	city: string;
	state: string;
	index: string;
	lattitude: string;
	longitude: string;
}

export const NewPropertyAddress: FC<IProps> = ({
	openModalState,
	values,
	errors,
	dropState,
	setDropState,
	dropCounries,
	setDropCountries,
	openModalCountries,
}) => {
	const dispatch = useDispatch();
	const stateList = useSelector(getStatesSelector);
	const { innerBorderRef } = useOnOutsideClick(() => {
		setDropState(false);
	});

	useEffect(() => {
		dispatch(getStatesRequest());
	}, [dispatch]);

	return (
		<div className="new-property-address">
			<div className="category-title">
				<p className="category-title__item">Add the property address </p>
			</div>
			<div className="new-property-address-grid">
				<CountiesPopup
					dropCounries={dropCounries}
					setDropCountries={setDropCountries}
					openModalCountries={openModalCountries}
					values={values}
					errors={errors}
				/>
				{/* <div className="input">
					<div className="input-wrapper">
						<Field
							name="country"
							className="input-item"
							type="text"
							placeholder="Country"
							value={values.country || ''}
						/>
					</div>
					{errors?.country ? <ErrorMsg msg={errors?.country} /> : null}
				</div> */}
				<div className="input">
					<div className="input-wrapper">
						<Field
							name="street"
							className="input-item"
							type="text"
							placeholder="Street"
							value={values.street || ''}
						/>
					</div>
					{errors?.street ? <ErrorMsg msg={errors?.street} /> : null}
				</div>
				<div className="input">
					<div className="input-wrapper">
						<Field
							name="city"
							className="input-item"
							type="text"
							placeholder="City"
							value={values.city || ''}
						/>
					</div>
					{errors?.city ? <ErrorMsg msg={errors?.city} /> : null}
				</div>

				{values.country.toLowerCase() === 'united states of america' && (
					<div>
						<div className="select select--type3">
							<div onClick={openModalState} ref={innerBorderRef} className="select__current">
								{values.state || 'State'}
								<span className="select__current-arrow">
									<svg
										width="14"
										height="8"
										viewBox="0 0 14 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
											fill="#25252E"
										/>
									</svg>
								</span>
							</div>
							<div
								className={classNames('select__drop', {
									'select__drop--active': dropState,
								})}
							>
								<div className="select__drop-scroll">
									<div className="select__drop-item">
										<ul>
											{stateList?.length &&
												stateList.map((el) => (
													<li key={el.name}>
														<button onClick={openModalState} name={el.name} type="button">
															{el.name}
														</button>
													</li>
												))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						{!values.state ? <ErrorMsg msg="This field is required to publish" /> : null}
					</div>
				)}
				<div className="input">
					<div className="input-wrapper">
						<Field
							name="index"
							className="input-item"
							type="text"
							placeholder="Index"
							value={values.index || ''}
						/>
					</div>
					{errors?.index ? <ErrorMsg msg={errors?.index} /> : null}
				</div>
				<div className="input">
					<div className="input-wrapper">
						<Field
							name="lattitude"
							className="input-item"
							type="text"
							placeholder="49°4′4.8″N"
							value={values.lattitude || ''}
						/>
					</div>
					{errors?.lattitude ? <ErrorMsg msg={errors?.lattitude} /> : null}
				</div>
				<div className="input">
					<div className="input-wrapper">
						<Field
							name="longitude"
							className="input-item"
							type="text"
							placeholder="33°25′13.4″E"
							value={values.longitude || ''}
						/>
					</div>
					{errors?.longitude ? <ErrorMsg msg={errors?.longitude} /> : null}
				</div>
			</div>
		</div>
	);
};
