import moment from 'moment';
import { IPropsTransactionHistoryBody } from 'pages/Dashboard/Wallets/types';
import { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
	ELinksName,
	EParamsNameWallet,
	EParamsUserAcount,
} from 'services/constants/list-links-sidebar';
import { colorsStatus } from 'services/constants/status-colors';
import { ETransactionTabsName } from 'services/constants/transaction-history-tabs';
import { notificationContainer } from 'untils/notificationContainer';

export const TransactionsHistoryTableBody: FC<IPropsTransactionHistoryBody> = ({
	transactionsHistory,
}) => {
	const param: { subTab: string } = useParams();
	const handleDestination = () => {
		notificationContainer('Destination id copied successfully!', 'info');
	};

	const handleTransaction = () => {
		notificationContainer('Transaction id copied successfully!', 'info');
	};

	return param.subTab === EParamsNameWallet.CRYPTO ? (
		<div className="table-body">
			{transactionsHistory?.map((transactionHistory) => (
				<div key={transactionHistory.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">ID</p>
						<p className="td-value td-value--medium">{String(transactionHistory.id)}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">User ID</p>
						<p className="td-value td-value--medium red">
							<Link
								to={String(
									transactionHistory.user_id > 0
										? `${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USER_INFO}/${String(
												transactionHistory.user_id,
										  )}`
										: `${ELinksName.WALLETS_TRANSATION_HISTORY}/${ETransactionTabsName.DEPOSIT}/${EParamsNameWallet.FIAT}`,
								)}
								className="td-value td-value--medium red"
							>
								{transactionHistory.user_id}
							</Link>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Time and date</p>
						<p className="td-value td-value--medium">
							{moment(transactionHistory.created_at).format('DD.MM.YY h:mm:ss')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Asset</p>
						<p className="td-value td-hidden-name-upper td-value--medium">
							{transactionHistory.asset.name}
						</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Network</p>
						<p className="td-value td-value--medium">Algorand</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Fee</p>
						<p className="td-value td-value--medium">{transactionHistory.amount_fee}</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Amount</p>
						<p className="td-value td-value--medium">{transactionHistory.amount}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Destination</p>
						<div className="td-wrapper">
							{transactionHistory?.payment_id ? (
								<>
									<p className="td-value td-value--medium">
										{transactionHistory?.payment_id && transactionHistory?.payment_id?.slice(0, 6)}
										...
									</p>
									<CopyToClipboard
										text={transactionHistory?.payment_id || ''}
										onCopy={handleDestination}
									>
										<button type="button">Copy</button>
									</CopyToClipboard>
								</>
							) : (
								'-'
							)}
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Status</p>
						<p
							className={`td-value td-value-first-letter-up td-value--medium td-status--${transactionHistory?.status.toLowerCase()}`}
						>
							{transactionHistory.status.replaceAll('_', ' ')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">TxID</p>
						<div className="td-wrapper">
							{transactionHistory?.transaction_id ? (
								<>
									<p className="td-value td-value--medium">
										{transactionHistory?.transaction_id &&
											transactionHistory?.transaction_id?.slice(0, 6)}
										...
									</p>
									<CopyToClipboard
										text={transactionHistory.transaction_id || ''}
										onCopy={handleTransaction}
									>
										<button type="button">Copy</button>
									</CopyToClipboard>
								</>
							) : (
								'-'
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	) : (
		<div className="table-body">
			{transactionsHistory?.map((history) => (
				<div key={history.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">ID</p>
						<p className="td-value td-value--medium">{history.id}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Time and date</p>
						<p className="td-value td-value--medium">
							{moment(history.created_at).format('DD.MM.YY h:mm:ss')}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<p className="td-value td-hidden-name-upper td-value--medium">{history.asset.name}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Payment method</p>
						<p className="td-value td-value-flex-start td-value-first-letter-up td-value--medium">
							{history?.payment_system?.name}
						</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Indicated amount</p>
						<p className="td-value td-value-flex-start td-value--medium">
							{history.indicated_amount}
						</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name ">Fee</p>
						<p className="td-value td-value--medium">{history.amount_fee}</p>
					</div>
					<div className="td td--center">
						<p className="td-hidden-name">Amount</p>
						<p className="td-value td-value--medium">{history.amount}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Status</p>
						<p
							className={`td-value td-value-first-letter-up td-value--medium ${
								colorsStatus[history.status]
							}`}
						>
							{history.status.replaceAll('_', ' ')}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};
