import { FC } from 'react';

export const SvgUserIcon: FC = () => (
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.25 12.5C1.25 6.56294 6.06294 1.75 12 1.75C17.9371 1.75 22.75 6.56294 22.75 12.5C22.75 18.4371 17.9371 23.25 12 23.25C6.06294 23.25 1.25 18.4371 1.25 12.5ZM12 3.25C6.89136 3.25 2.75 7.39136 2.75 12.5C2.75 17.6086 6.89136 21.75 12 21.75C17.1086 21.75 21.25 17.6086 21.25 12.5C21.25 7.39136 17.1086 3.25 12 3.25Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.75 9.5C8.75 7.70509 10.2051 6.25 12 6.25C13.7949 6.25 15.25 7.70509 15.25 9.5C15.25 11.2949 13.7949 12.75 12 12.75C10.2051 12.75 8.75 11.2949 8.75 9.5ZM12 7.75C11.0335 7.75 10.25 8.53351 10.25 9.5C10.25 10.4665 11.0335 11.25 12 11.25C12.9665 11.25 13.75 10.4665 13.75 9.5C13.75 8.53351 12.9665 7.75 12 7.75Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 15.75C7.74849 15.75 5.90541 17.5012 5.75939 19.7154C5.73213 20.1287 5.37497 20.4416 4.96166 20.4144C4.54834 20.3871 4.23538 20.03 4.26264 19.6166C4.46031 16.6194 6.95314 14.25 10 14.25H14C17.0429 14.25 19.5332 16.6131 19.7366 19.6048C19.7647 20.018 19.4525 20.3758 19.0393 20.4039C18.626 20.432 18.2682 20.1198 18.2401 19.7065C18.0898 17.4966 16.2486 15.75 14 15.75H10Z"
			fill="#E41472"
		/>
	</svg>
);
