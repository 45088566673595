import { FC } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { IPropsPreviewProperty } from 'components/PropertyManagementComponents/types';
import { Map } from '../Map';

export const StartMapGoogle: FC<IPropsPreviewProperty> = ({ values }) => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: 'AIzaSyBc53kj9yf_g2dedzzMg_tnqP5qksU5ukM',
	});

	if (!isLoaded) return <div>Loading...</div>;
	return <Map values={values} />;
};
