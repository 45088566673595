/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IDistributeRequest,
	IDividendsHistory,
	IDividendsHistoryRequest,
	IDividendsManagementState,
	IDividendsRequest,
	IDividendsResponse,
	IDividendsSum,
	IStatusRequest,
} from './types';

export const initialState: IDividendsManagementState = {
	dividends: null,
	dividendsHistory: null,
	dividendsSum: null,
	dividendsLoader: false,
	statusLoader: false,
};

const dividendsManagement = createSlice({
	name: '@@dividendsManagement',
	initialState,
	reducers: {
		getDividendsRequest: (state, action: PayloadAction<IDividendsRequest>) => {
			const getDividendsState = state;
			getDividendsState.dividendsLoader = true;
			getDividendsState.dividendsHistory = null;
		},

		getDividendsSuccess: (state, action: PayloadAction<IDividendsResponse>) => {
			const { payload } = action;
			const getDividendsState = state;

			getDividendsState.dividendsLoader = false;
			getDividendsState.dividends = payload;
		},

		postStatusRequest: (state, action: PayloadAction<IStatusRequest>) => {
			const postStatusState = state;
			postStatusState.statusLoader = true;
		},

		postStatusSuccess: (state, action: PayloadAction<IStatusRequest>) => {
			const { payload } = action;
			const postStatusState = state;

			postStatusState.statusLoader = false;
			if (postStatusState.dividends) {
				postStatusState.dividends?.data.forEach((dividend, i) => {
					if (dividend.id === payload.estate_id)
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						postStatusState.dividends!.data[i].rent_status = payload.rent_status;
				});
			}
			if (postStatusState.dividendsHistory) {
				postStatusState.dividendsHistory?.data.forEach((dividend, i) => {
					if (dividend.id === payload.estate_id)
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						postStatusState.dividendsHistory!.data[i].rent_status = payload.rent_status;
				});
			}
		},

		postDistributeRequest: (state, action: PayloadAction<IDistributeRequest>) => {},

		getDividendsSumRequest: (state) => {},

		getDividendsSumSuccess: (state, action: PayloadAction<IDividendsSum>) => {
			const { payload } = action;
			const getDividendsState = state;
			getDividendsState.dividendsSum = payload;
		},

		getDividendHistoryRequest: (state, action: PayloadAction<IDividendsHistoryRequest>) => {
			const getDividendsHistoryState = state;
			getDividendsHistoryState.dividendsLoader = true;
			getDividendsHistoryState.dividends = null;
		},

		getDividendsHistorySuccess: (state, action: PayloadAction<IDividendsHistory | null>) => {
			const { payload } = action;
			const getDividendsHistoryState = state;
			getDividendsHistoryState.dividendsLoader = false;
			getDividendsHistoryState.dividendsHistory = payload;
		},

		dividendsManagementInitState: (state) => {
			const propertyManagementInitState = state;
			propertyManagementInitState.dividendsLoader = false;
			propertyManagementInitState.statusLoader = false;
		},
	},
});

export default dividendsManagement.reducer;
export const {
	getDividendsRequest,
	getDividendsSuccess,
	dividendsManagementInitState,
	postStatusRequest,
	postStatusSuccess,
	postDistributeRequest,
	getDividendsSumRequest,
	getDividendsSumSuccess,
	getDividendHistoryRequest,
	getDividendsHistorySuccess,
} = dividendsManagement.actions;
