import classNames from 'classnames';
import { Sidebar } from 'components/Sidebar';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import { WithdrawCrypto } from './WithdrawCrypto';
import { WithdrawFiat } from './WithdrawFiat';

const Withdraw: FC = () => {
	const params: { id: string; tab: string } = useParams();

	const definePage = () => {
		switch (params.tab) {
			case EParamsNameWallet.CRYPTO:
				return <WithdrawCrypto />;
			default:
				return <WithdrawFiat />;
		}
	};

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<div className="profile-header profile-header--type2">
					<div className="profile-header__group">
						<p className="block-title">Withdraw</p>
					</div>
					<div className="switcher switcher--smaller ">
						<Link
							to={`${ELinksName.WITHDRAW}/${EParamsNameWallet.FIAT}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active:
									params.tab === EParamsNameWallet.FIAT ||
									params.tab === EParamsNameWallet.ADD_CARD ||
									!params.tab,
							})}
						>
							<span>Withdraw Fiat</span>
						</Link>
						<Link
							to={`${ELinksName.WITHDRAW}/${EParamsNameWallet.CRYPTO}`}
							className={classNames('switcher__item switcher__item--cnter', {
								active: params.tab === EParamsNameWallet.CRYPTO,
							})}
						>
							<span>Withdraw Crypto</span>
						</Link>
					</div>
				</div>
				{definePage()}
			</div>
		</div>
	);
};

export default Withdraw;
