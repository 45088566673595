import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getOrdersUserRequest } from 'redux/reducers/usersManagement/reducer';
import {
	getOrdersListSelector,
	getUsersManagementListLoader,
} from 'redux/reducers/usersManagement/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { SvgArrowBackSmall } from 'ui/svg-image/SvgArrowBackSmall';
import { HeaderOrders } from './HeaderOrders';
import { TableHistory } from './TableHistory';

export const OrdersHistory: FC = () => {
	const dispatch = useDispatch();
	const { location } = useHistory();
	const params: { id: string } = useParams();
	const ordersList = useSelector(getOrdersListSelector);
	const transactionLoader = useSelector(getUsersManagementListLoader);

	const submitFilter = (value: number) => {
		dispatch(
			getOrdersUserRequest({
				params: {
					current_page: value || +location.search.slice(1),
				},
				id: +params.id,
			}),
		);
	};

	useEffect(() => {
		submitFilter(+location.search.slice(1) || 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="admin__content">
			<Link to={ELinksName.USERS_MANAGEMENT} className="back-link back-link--mb20">
				<span className="back-link__arrow">
					<SvgArrowBackSmall />
				</span>
				Back to user management
			</Link>
			<div className="table-title table-title--type-2">
				<p>Orders history</p>
			</div>
			<div className="table table--order-history table--second-type table--border-top">
				<HeaderOrders />
				{transactionLoader ? (
					<Loader />
				) : (
					ordersList?.data && <TableHistory ordersList={ordersList?.data} />
				)}
			</div>
			{ordersList?.last_page && ordersList?.last_page > 1 && !transactionLoader && (
				<Pagination
					request={submitFilter}
					currentPage={ordersList.current_page}
					numberPage={ordersList.last_page}
				/>
			)}
		</div>
	);
};
