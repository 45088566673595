/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDevidendsReq, ITransactionReq, ITransctionsHistoryRequest } from '../wallets/types';
import {
	IAddFundsToUserRequest,
	IAddFundsToUserResponse,
	IBlockUserRequest,
	IDeleteUserRequest,
	IDownloadZipResponse,
	IInvestmentResponse,
	IOrdersResponse,
	IPaginationRequest,
	ITransactionHistoryUserResponse,
	ITransactionsHistoryConvert,
	IUserDividendsResponse,
	IUsersManagement,
	IUsersManagementListResponse,
	IUsersManagementState,
} from './types';

export const initialState: IUsersManagementState = {
	usersManagementList: null,
	userManagement: null,
	investments: null,
	transactions: null,
	converts: null,
	orders: null,
	dividends: null,
	dividendsSum: '',
	usersManagementLoader: false,
	userBlockLoader: false,
	zip: null,
};

const usersManagement = createSlice({
	name: '@@usersManagement',
	initialState,
	reducers: {
		getUsersManagementListRequest: (state, action: PayloadAction<IPaginationRequest>) => {
			const getUsersManagementList = state;
			getUsersManagementList.usersManagementLoader = true;
		},

		getUsersManagementListSuccess: (state, action: PayloadAction<IUsersManagementListResponse>) => {
			const { payload } = action;
			const getUsersManagementList = state;

			getUsersManagementList.usersManagementLoader = false;
			getUsersManagementList.usersManagementList = payload;
		},

		deleteUserRequest: (state, action: PayloadAction<IDeleteUserRequest>) => {},

		deleteUserSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const getUsersManagementList = state;

			if (getUsersManagementList.usersManagementList?.data) {
				getUsersManagementList.usersManagementList.data =
					getUsersManagementList.usersManagementList.data.filter((user) => user.id !== payload);
			}
		},

		getBlockedRequest: (state, action: PayloadAction<IBlockUserRequest>) => {
			const getUsersBlock = state;
			getUsersBlock.userBlockLoader = true;
		},

		getBlockedSuccess: (state, action: PayloadAction<IUsersManagement>) => {
			const { payload } = action;
			const getUsersBlock = state;

			getUsersBlock.userBlockLoader = false;
			if (getUsersBlock.usersManagementList) {
				getUsersBlock.usersManagementList.data.forEach((user) => {
					if (user.id === payload.id) {
						// eslint-disable-next-line no-param-reassign
						user.blocked = payload.blocked;
					}
				});
			}
			if (getUsersBlock.userManagement) {
				getUsersBlock.userManagement.blocked = payload.blocked;
			}
		},

		getUserManagementRequest: (state, action: PayloadAction<string>) => {},

		getUserManagementSuccess: (state, action: PayloadAction<IUsersManagement>) => {
			const { payload } = action;
			const getUsersManagementList = state;
			getUsersManagementList.userManagement = payload;
		},

		getInvestmentsRequest: (state, action: PayloadAction<IPaginationRequest>) => {
			const getInvestments = state;
			getInvestments.usersManagementLoader = true;
		},

		getInvestmentsSuccess: (state, action: PayloadAction<IInvestmentResponse>) => {
			const { payload } = action;
			const getInvestments = state;

			getInvestments.usersManagementLoader = false;
			getInvestments.investments = payload;
		},

		getTransactionsHistoryUserRequest: (state, action: PayloadAction<ITransactionReq>) => {
			const getTransactions = state;
			getTransactions.usersManagementLoader = true;
		},

		getTransactionsHistoryUserSuccess: (
			state,
			action: PayloadAction<ITransactionHistoryUserResponse>,
		) => {
			const { payload } = action;
			const getTransactions = state;

			getTransactions.usersManagementLoader = false;
			getTransactions.transactions = payload;
		},

		getTransactionsHistoryConvertUserRequest: (
			state,
			action: PayloadAction<ITransctionsHistoryRequest>,
		) => {
			const getTransactions = state;
			getTransactions.usersManagementLoader = true;
		},

		getTransactionsHistoryConvertUserSuccess: (
			state,
			action: PayloadAction<ITransactionsHistoryConvert>,
		) => {
			const { payload } = action;
			const getTransactions = state;

			getTransactions.usersManagementLoader = false;
			getTransactions.converts = payload;
		},

		getOrdersUserRequest: (state, action: PayloadAction<ITransactionReq>) => {
			const getTransactions = state;
			getTransactions.usersManagementLoader = true;
		},

		getOrdersUserSuccess: (state, action: PayloadAction<IOrdersResponse>) => {
			const { payload } = action;
			const getTransactions = state;

			getTransactions.usersManagementLoader = false;
			getTransactions.orders = payload;
		},

		getDividendsUserRequest: (state, action: PayloadAction<IDevidendsReq>) => {
			const getTransactions = state;
			getTransactions.usersManagementLoader = true;
		},

		getDividendsUserSuccess: (state, action: PayloadAction<IUserDividendsResponse>) => {
			const { payload } = action;
			const getTransactions = state;

			getTransactions.usersManagementLoader = false;
			getTransactions.dividends = payload.dividends;
			getTransactions.dividendsSum = payload.sum;
		},

		getZipFileRequest: (state, action: PayloadAction<number>) => {},

		getZipFileSuccess: (state, action: PayloadAction<IDownloadZipResponse>) => {
			const { payload } = action;
			const getZipState = state;

			getZipState.zip = payload.path;
		},

		addFundsToUserRequest: (state, action: PayloadAction<IAddFundsToUserRequest>) => {
			const getTransactions = state;
			getTransactions.usersManagementLoader = true;
		},
		addFundsToUserSuccess: (state, action: PayloadAction<IAddFundsToUserResponse>) => {
			const { payload } = action;
			const getTransactions = state;

			getTransactions.usersManagementLoader = false;
			if (getTransactions.userManagement) {
				const usdBalance = getTransactions.userManagement.balances.find((b) => b.asset_id === 2);
				if (usdBalance) {
					usdBalance.total_balance = payload.totalBalance;
				}
			}
		},

		usersManagementInitState: (state) => {
			const propertyManagementInitState = state;
			propertyManagementInitState.usersManagementLoader = false;
			propertyManagementInitState.userBlockLoader = false;
		},
	},
});

export default usersManagement.reducer;
export const {
	getUsersManagementListRequest,
	getUsersManagementListSuccess,
	getBlockedRequest,
	getBlockedSuccess,
	usersManagementInitState,
	deleteUserRequest,
	deleteUserSuccess,
	getUserManagementRequest,
	getUserManagementSuccess,
	getInvestmentsRequest,
	getInvestmentsSuccess,
	getTransactionsHistoryUserRequest,
	getTransactionsHistoryUserSuccess,
	getTransactionsHistoryConvertUserRequest,
	getTransactionsHistoryConvertUserSuccess,
	getOrdersUserRequest,
	getOrdersUserSuccess,
	getDividendsUserRequest,
	getDividendsUserSuccess,
	getZipFileRequest,
	getZipFileSuccess,
	addFundsToUserRequest,
	addFundsToUserSuccess,
} = usersManagement.actions;
