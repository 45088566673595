/* eslint-disable @typescript-eslint/no-explicit-any */
import ErrorMsg from 'components/ErrorMsg';
import { Field } from 'formik';
import moment from 'moment';
import { FC, MouseEvent } from 'react';
import ReactDatePicker from 'react-datepicker';

export interface IProps {
	errors: IValuesPropertyDividends;
	values: IValuesPropertyDividends;
	handleClick: (e: MouseEvent) => void;
	dateDividends: (d: Date) => void;
	isOpen: boolean;
	handleBlur: any;
}

export interface IValuesPropertyDividends {
	date_expected_dividends: string | Date;
	expected_profit: string;
	expected_expenses: string;
	dividends_period: string;
}

export const PropertyDividends: FC<IProps> = ({
	values,
	errors,
	handleClick,
	dateDividends,
	isOpen,
	handleBlur,
}) => {
	return (
		<div className="property-dividends">
			<div className="category-title">
				<p className="category-title__item">Dividends distribution</p>
			</div>
			<div className="property-dividends-grid">
				<div className="input">
					<div className="input-name">The dividends expected start date</div>
					<div className="input-wrapper input-wrapper-custom">
						<button
							onClick={handleClick}
							type="button"
							name="date_expected_dividends"
							className="input-item"
							placeholder="Enter the dividends expected start date"
						>
							<span className="input-item-custom">
								{values.date_expected_dividends ? (
									moment(values.date_expected_dividends).format('DD.MM.YY')
								) : (
									<span className="input-item-light-custom">
										Enter the dividends expected start date
									</span>
								)}
							</span>
						</button>
						<div className="block-inline-custom">
							{isOpen && (
								<div className="block-position-calendar-custom">
									<ReactDatePicker
										name="date_expected_dividends"
										onBlur={handleBlur}
										value={String(values.date_expected_dividends || '')}
										selected={values.date_expected_dividends as Date}
										onChange={(date: Date) => dateDividends(date)}
										dropdownMode="select"
										showMonthDropdown
										showYearDropdown
										inline
									/>
								</div>
							)}
							{errors?.date_expected_dividends ? (
								<ErrorMsg msg={errors?.date_expected_dividends as string} />
							) : null}
						</div>
					</div>
				</div>
				<div className="input">
					<div className="input-name">Expected Rental</div>
					<div className="input-wrapper">
						<Field
							name="expected_profit"
							className="input-item input-item-custom"
							type="text"
							placeholder="Enter Expected Rental"
							value={+values.expected_profit || ''}
						/>
						<span className="input-coin">USD</span>
					</div>
					{errors?.expected_profit ? <ErrorMsg msg={errors?.expected_profit} /> : null}
				</div>

				<div className="input">
					<div className="input-name">Expected Monthly Expenses</div>

					<div className="input-wrapper">
						<Field
							name="expected_expenses"
							className="input-item input-item-custom"
							type="text"
							placeholder="Enter Expected Monthly Expenses"
							value={values.expected_expenses || ''}
						/>
						<span className="input-coin">USD</span>
					</div>
					{errors?.expected_expenses ? <ErrorMsg msg={errors?.expected_expenses} /> : null}
				</div>
			</div>
			<div className="dividends-select">
				<div className="dividends-select__title">
					<p>Select the period of receiving dividends:</p>
				</div>
			</div>
			<div className="radio">
				<label className="radio__label">
					<Field
						name="dividends_period"
						type="radio"
						className="hidden"
						checked={+values.dividends_period === 0}
						value="0"
					/>
					<span className="radio__item" />
					<span className="radio__text">Monthly</span>
				</label>
				{errors?.dividends_period ? <ErrorMsg msg={errors?.dividends_period} /> : null}
			</div>

			<div className="radio">
				<label className="radio__label">
					<Field
						name="dividends_period"
						type="radio"
						className="hidden"
						checked={+values.dividends_period === 1}
						value="1"
					/>
					<span className="radio__item" />
					<span className="radio__text">Quarterly</span>
				</label>
				{errors?.dividends_period ? <ErrorMsg msg={errors?.dividends_period} /> : null}
			</div>
		</div>
	);
};
