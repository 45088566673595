import ErrorMsg from 'components/ErrorMsg';
import { IPropsRowUsdc } from 'components/WalletsComponents/types';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { limitsModifyReguest } from 'redux/reducers/wallets/reducer';
import { SvgCheck, SvgClose, SvgEdit } from 'ui/svg-image/SvgLimits';

export const UsdcRow: FC<IPropsRowUsdc> = ({ openEditUsdc, handleEditBatton, limits }) => {
	const [usdcMax, setUsdcMax] = useState(String(limits.withdraw_without_approve_max));
	const [usdcMin, setUsdcMin] = useState(String(limits.withdraw_min));
	const [message, setMessage] = useState('');
	const dispatch = useDispatch();

	const handelInputs = (e: ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		target.name === 'max' &&
			(+target.value > 0 || target.value.length === 0) &&
			setUsdcMax(target.value);
		target.name === 'min' &&
			(+target.value > 0 || target.value.length === 0) &&
			setUsdcMin(target.value);
		setMessage('');
	};

	const submitLimits = (e: MouseEvent) => {
		if (usdcMax && usdcMin) {
			dispatch(
				limitsModifyReguest({
					id: 1,
					asset_id: 1,
					withdraw_min: +usdcMin,
					withdraw_max: 999999999999999,
					withdraw_without_approve_max: +usdcMax,
				}),
			);
			handleEditBatton(e);
		} else {
			setMessage(usdcMax.length ? 'min' : 'max');
		}
	};

	const resetInputs = () => {
		setUsdcMax('');
		setUsdcMin('');
	};

	return openEditUsdc ? (
		<>
			<div className="td">
				<p className="td-hidden-name">Withdraw min</p>
				<div className="td-edit-wrapper">
					<div className="input input--limitations  input--no-mb ">
						<div className="input-wrapper">
							<input
								onChange={handelInputs}
								name="min"
								required
								className="input-item input-item--td"
								type="text"
								placeholder="Set min withdrawal amount"
								value={
									usdcMin[0] === '.'
										? `1${usdcMin.replace(/[^0-9.]/, '')}`
										: usdcMin.replace(/[^0-9.]{0,1}$/, '')
								}
							/>
							{(!usdcMin && !usdcMax && message) || message === 'min' ? (
								<ErrorMsg msg="Input field is required" />
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdraw max</p>
				<div className="td-edit-wrapper">
					<div className="input  input--limitations input--no-mb ">
						<div className="input-wrapper">
							<input
								onChange={handelInputs}
								name="max"
								required
								className="input-item input-item--td"
								type="text"
								placeholder="Set max withdrawal amount"
								value={
									usdcMax[0] === '.'
										? `1${usdcMax.replace(/[^0-9.]/, '')}`
										: usdcMax.replace(/[^0-9.]{0,1}$/, '')
								}
							/>
							{(!usdcMax && !usdcMin && message) || message === 'max' ? (
								<ErrorMsg msg="Input field is required" />
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button name="usdc" onClick={submitLimits} type="button">
						<SvgCheck />
					</button>
					<button
						name="usdc"
						onClick={(e: MouseEvent) => {
							resetInputs();
							handleEditBatton(e);
						}}
						type="button"
					>
						<SvgClose />
					</button>
				</div>
			</div>
		</>
	) : (
		<>
			<div className="td">
				<p className="td-hidden-name">Withdraw min</p>
				<div className="td-edit-wrapper">
					<p className="td-value td-value--medium">{limits.withdraw_min}</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Withdraw max</p>
				<div className="td-edit-wrapper">
					<p className="td-value td-value--medium">{limits.withdraw_without_approve_max}</p>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button name="usdc" onClick={handleEditBatton} type="button">
						<SvgEdit />
					</button>
				</div>
			</div>
		</>
	);
};
