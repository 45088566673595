import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	ELinksName,
	EParamsNameWallet,
	EParamsUserAcount,
} from 'services/constants/list-links-sidebar';

export interface IProps {
	addButton: () => void;
	openDrop: boolean;
}

export const LeftSidebarAcount: FC<IProps> = ({ addButton, openDrop }) => {
	const params: { tab: string; id: string } = useParams();

	return (
		<div
			className={classNames('select__drop', {
				'select__drop--active': openDrop,
			})}
		>
			<div className="select__drop-scroll">
				<div className="select__drop-item">
					<ul>
						<li
							className={classNames({
								active: params.tab === EParamsUserAcount.USER_INFO,
							})}
						>
							<Link
								onClick={addButton}
								to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USER_INFO}/${params.id}`}
							>
								User account
							</Link>
						</li>
						<li
							className={classNames({
								active: params.tab === EParamsUserAcount.INVESTMENTS,
							})}
						>
							<Link
								onClick={addButton}
								to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.INVESTMENTS}/${params.id}`}
							>
								List of investments
							</Link>
						</li>
						<li
							className={classNames({
								active: params.tab === EParamsUserAcount.DIVIDENDS_DISTRIBUTED,
							})}
						>
							<Link
								onClick={addButton}
								to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.DIVIDENDS_DISTRIBUTED}/${params.id}`}
							>
								Dividends distributed
							</Link>
						</li>
						<li
							className={classNames({
								active: params.tab === EParamsUserAcount.ORDERS_HISTORY,
							})}
						>
							<Link
								onClick={addButton}
								to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.ORDERS_HISTORY}/${params.id}`}
							>
								Orders history
							</Link>
						</li>
						<li
							className={classNames({
								active: params.tab === EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY,
							})}
						>
							<Link
								onClick={addButton}
								to={`${ELinksName.USER_ACCOUNT}/${EParamsUserAcount.USERS_ACCOUNT_TRANSATION_HISTORY}/${params.id}/${EParamsNameWallet.DEPOSIT}/${EParamsNameWallet.CRYPTO}`}
							>
								Transaction history
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
