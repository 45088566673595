import { ICreateNewPasswordFormDataTypes } from 'components/Forms/CreateNewPassForm/type';
import { IAdminAuthUserResponseData, ILoginRequestPayload } from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi } from './types';

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) =>
		http
			.post<ILoginRequestPayload, { data: IAdminAuthUserResponseData }>(
				endpoint.auth.LOGIN,
				payload,
			)
			.then((response) => response.data),

	logout: () => http.post(endpoint.auth.LOGOUT),

	forgotPassword: (payload) =>
		http.post(endpoint.auth.SEND_RESET_PASSWORD, payload).then((response) => response.data),

	newPassword: (payload: ICreateNewPasswordFormDataTypes) => {
		return http.post(endpoint.auth.RECOVER_PASSWORD, payload).then((response) => response.data);
	},
};
