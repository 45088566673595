export const SvgSellPropertyRemove = () => (
	<svg
		className="stroke"
		width="20"
		height="22"
		viewBox="0 0 20 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19 4.98047C15.67 4.65047 12.32 4.48047 8.98 4.48047C7 4.48047 5.02 4.58047 3.04 4.78047L1 4.98047"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.5 3.97L6.72 2.66C6.88 1.71 7 1 8.69 1H11.31C13 1 13.13 1.75 13.28 2.67L13.5 3.97"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.8499 8.14062L16.1999 18.2106C16.0899 19.7806 15.9999 21.0006 13.2099 21.0006H6.7899C3.9999 21.0006 3.9099 19.7806 3.7999 18.2106L3.1499 8.14062"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.33008 15.5H11.6601"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.5 11.5H12.5"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgSellPropertyUPload = () => (
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.44 7.90039C19.04 8.21039 20.51 10.0604 20.51 14.1104V14.2404C20.51 18.7104 18.72 20.5004 14.25 20.5004H7.73998C3.26998 20.5004 1.47998 18.7104 1.47998 14.2404V14.1104C1.47998 10.0904 2.92998 8.24039 6.46998 7.91039"
			stroke="#25252E"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11 13.9991V2.61914"
			stroke="#25252E"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.3499 4.85L10.9999 1.5L7.6499 4.85"
			stroke="#25252E"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgSellPropertyShowImg = () => (
	<svg
		className="stroke"
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4 9H6C8 9 9 8 9 6V4C9 2 8 1 6 1H4C2 1 1 2 1 4V6C1 8 2 9 4 9Z"
			stroke="white"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16 9H18C20 9 21 8 21 6V4C21 2 20 1 18 1H16C14 1 13 2 13 4V6C13 8 14 9 16 9Z"
			stroke="white"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16 21H18C20 21 21 20 21 18V16C21 14 20 13 18 13H16C14 13 13 14 13 16V18C13 20 14 21 16 21Z"
			stroke="white"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4 21H6C8 21 9 20 9 18V16C9 14 8 13 6 13H4C2 13 1 14 1 16V18C1 20 2 21 4 21Z"
			stroke="white"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
