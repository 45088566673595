import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authInitState } from 'redux/reducers/auth/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import { changeEmailSuccess } from 'redux/reducers/settings/reducer';

export const PopupConfirmEmail: FC = () => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const { innerBorderRef } = useOnOutsideClick(() => dispatch(changeEmailSuccess('')));

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div ref={innerBorderRef} className="popup popup--type2 popup--huge popup--center">
					<div className="popup-body">
						<div className="popup-alert">
							<div className="popup-success-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
										fill="#31BDAA"
									/>
									<path
										d="M7.75 12L10.58 14.83L16.25 9.17"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<p className="popup-title popup-title--mb-0">
								Confirm new email, Confirmation <br /> link as sent to{' '}
								{userData?.email && (
									<a
										onClick={() => {
											changeEmailSuccess('');
											dispatch(authInitState());
										}}
										href={`https://${userData?.email}`}
										rel="noreferrer"
									>
										{userData?.email}
									</a>
								)}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
