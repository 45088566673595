import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { getInterestRequest, getInterestSuccess } from './reducer';
import { IInterestRequest } from './types';
// import L from 'i18n-react';

// import { notificationContainer } from 'untils/notificationContainer';

function* getInterestRequestWorker(action: PayloadAction<IInterestRequest>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: AxiosResponse = yield call(api.interestRequests.getUsersInterestList, payload);
		yield put(getInterestSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* interestRequestsSaga() {
	yield takeEvery(getInterestRequest.type, getInterestRequestWorker);
}
