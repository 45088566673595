import { CreateNewPassForm } from 'components/Forms/CreateNewPassForm';
import { ICreateNewPasswordPayload } from 'components/Forms/CreateNewPassForm/type';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { createNewPasswordRequest } from 'redux/reducers/auth/reducer';

const CreateNewPassword: FC = () => {
	const dispatch = useDispatch();

	const handleCreateNewPass = (values: ICreateNewPasswordPayload) => {
		dispatch(createNewPasswordRequest(values));
	};

	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<CreateNewPassForm createNewPassSubmit={handleCreateNewPass} />
				</div>
			</div>
		</section>
	);
};

export default CreateNewPassword;
