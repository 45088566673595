/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { ITokensListRequest } from 'redux/reducers/platform-tokens/types';
import { IPlatformTokensApi } from './types';

export const platformTokens: IPlatformTokensApi = {
	getListTokens: (params: ITokensListRequest) =>
		http.get(endpoint.platformTokens.TOKENS_LIST, { params }),
	getBalanceTokens: () => http.get(endpoint.platformTokens.BALANCE),
	editPrice: (payload: { estate_id: number; price: number }) =>
		http.post(endpoint.platformTokens.EDIT_PRICE, payload),
};
