import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ISellManagementState } from './types';

// ==========================================:
const getSellManagementState = (state: IStoreState): ISellManagementState => state.sellManagement;

export const getAllSell = createSelector(
	[getSellManagementState],
	(sellManagement: ISellManagementState) => sellManagement.sellProperty,
);

export const getSellProperty = createSelector(
	[getSellManagementState],
	(sellManagement: ISellManagementState) => sellManagement.oneSellProperty,
);

export const getAllSellLoader = createSelector(
	[getSellManagementState],
	(sellManagement: ISellManagementState) => sellManagement.sellPropertyLoader,
);
