import { FeeManagementHeader } from 'components/FeeManagementComponents/FeeManagementHeader';
import { FeeManagementTable } from 'components/FeeManagementComponents/FeeManagementTable';
import { Loader } from 'components/Loader';
import { Sidebar } from 'components/Sidebar';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFeeManagementRequest,
	postFeeManagementRequest,
} from 'redux/reducers/fee-management/reducer';
import {
	getFeeManagementLoaderSelector,
	getFeeManagementSelector,
} from 'redux/reducers/fee-management/selectors';

export interface IFeeManagement {
	asset_id: number | string;
	buy_token: number | string;
	sell_token: number | string;
	convert: number | string;
	deposit: number | string;
	withdrawal: number | string;
	invest: number | string;
}

const FeeManagement: FC = () => {
	const feeManagement = useSelector(getFeeManagementSelector);
	const loader = useSelector(getFeeManagementLoaderSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getFeeManagementRequest());
	}, [dispatch]);

	const feeSubmit = (values: IFeeManagement) => {
		dispatch(postFeeManagementRequest(values));
	};

	return (
		<div className="admin">
			<Sidebar />

			<div className="admin__content">
				<div className="table-top-wrapper table-top-wrapper--center">
					<div className="table-title table-title--m0 table-title--fz34">
						<p>Fee management</p>
					</div>
				</div>
				<div className="table table--fee-management table--second-type">
					<FeeManagementHeader />
					{loader ? (
						<Loader />
					) : (
						feeManagement?.commissions &&
						feeManagement?.commissions.map((commission) => (
							<FeeManagementTable
								key={commission.id}
								feeSubmit={feeSubmit}
								commission={commission}
							/>
						))
					)}
				</div>
			</div>
		</div>
	);
};

export default FeeManagement;
