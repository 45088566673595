import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { getSmsCodeRequest } from 'redux/reducers/settings/reducer';
import { withdrawUsdcWalletRequest } from 'redux/reducers/wallets/reducer';
// import { getCommisionCalculate } from 'redux/reducers/wallets/selectors';
import { IPropsWithdrawConfirm } from '../types';

export const WithdrawCryptoPopUpConfirm: FC<IPropsWithdrawConfirm> = ({
	closePopUp,
	withdrawData,
}) => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [smsCode, setSmsCode] = useState('');
	// const commisionCalculate = useSelector(getCommisionCalculate);

	useEffect(() => {
		userData?.sms_2fa_enabled && dispatch(getSmsCodeRequest({ phone: userData.phone || '' }));
	}, [dispatch, userData]);

	const submitWithdrawCrypto = () => {
		withdrawData &&
			dispatch(
				withdrawUsdcWalletRequest({
					openPopUp: withdrawData.openPopUp,
					data: {
						payment_system: withdrawData.data.payment_system,
						type: withdrawData.data.type,
						amount: withdrawData.data.amount,
						[userData?.google2fa_enabled ? 'totp' : 'sms_code']: smsCode,

						payerData: [
							{
								address: withdrawData.data.payerData[0].address,
							},
						],
					},
				}),
			);
	};

	const heandelInput = (e: ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		setSmsCode(target.value);
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--type2 popup--bigger">
					<div className="popup-header">
						<p className="popup-title popup-title--center">Confirm Withdrawal</p>
					</div>

					<div className="popup-body">
						<div className="order-ooptions order-ooptions--mt-24">
							<div className="order-option order-option--type2 order-option--mob-column">
								<p className="order-option__name order-option__name--color2">Amount</p>
								<p className="order-option__value">
									{`Recieve ${String(withdrawData?.data.amount)} USDC`}{' '}
									<span className="br br--on-mob" />
									{/* {`(Network fee ${String(commisionCalculate?.commission)} USDC)`} */}
								</p>
							</div>
							<div className="order-option order-option--type2 order-option--mob-column">
								<p className="order-option__name order-option__name--color2">Address</p>
								<p className="order-option__value">{withdrawData?.data.payerData[0].address}</p>
							</div>
							<div className="order-option order-option--type2 order-option--mob-column">
								<p className="order-option__name order-option__name--color2">Network</p>
								<p className="order-option__value">Algorand</p>
							</div>
						</div>
						<div className="form-group">
							<div className="input input--mb-0 ">
								<p className="input-name">Code</p>
								<div className="input-wrapper">
									<input
										onChange={heandelInput}
										className="input-item"
										type="text"
										placeholder="Enter 2FA 6-digit code"
										value={smsCode}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="popup-footer popup-footer--more-mt popup-footer--mob-column">
						<button
							onClick={closePopUp}
							type="button"
							className="button button--type6 button--full-width"
						>
							Cancel
						</button>
						<button
							onClick={submitWithdrawCrypto}
							type="button"
							className="button button--full-width "
							disabled={smsCode.length < 5 || smsCode.length > 6}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
