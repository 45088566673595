// import ErrorMsg from 'components/ErrorMsg';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ELinksName, EParamsNameWallet } from 'services/constants/list-links-sidebar';
import { IPropsFiat } from '../../types';

export const Fiat: FC<IPropsFiat> = ({ addDataToForm, amount }) => {
	const history = useHistory();
	const location = useLocation();

	const handleForm = (e: React.SyntheticEvent) => {
		e.preventDefault();
		history.push(`${ELinksName.DEPOSIT}/${EParamsNameWallet.ADD_CARD}/${location.search.slice(1)}`);
	};

	return (
		<form onSubmit={handleForm} action="/" className="operation-form">
			<div className="form-group">
				<p className="input-name input-name--big">Payment method:</p>
				<div className="radio-group">
					<div className="radio">
						<label className="radio__label">
							<input
								onChange={addDataToForm}
								id="1"
								required
								type="radio"
								className="hidden"
								name="one-group"
								disabled
							/>
							<span className="radio__item" />
							<span className="radio__text">PayPal</span>
						</label>
					</div>
					<div className="radio radio--mb-0">
						<label className="radio__label">
							<input
								onChange={addDataToForm}
								id="1"
								required
								type="radio"
								className="hidden"
								name="one-group"
							/>
							<span className="radio__item" />
							<span className="radio__text">Bank card</span>
						</label>
					</div>
				</div>
			</div>
			<div className="form-group form-group--more-mt">
				<div className="input input--mb-0 ">
					<p className="input-name ">Amount to deposit:</p>
					<div className="input-wrapper">
						<input
							onChange={addDataToForm}
							required
							name="amount"
							className="input-item input-item--pr-125 "
							type="number"
							min={1}
							max={999999}
							placeholder="Enter 1 - 999999"
							value={amount}
						/>
						<span className="input-coin">USD</span>
					</div>
					{/* {(+amount < 1 || Number.isNaN(+amount)) && amount ? (
						<ErrorMsg msg="The invalid format or sum less than one" />
					) : null} */}
				</div>
			</div>
			<div className="form-footer form-footer--end form-footer--more-mt">
				<button
					// disabled={+amount < 1 || Number.isNaN(+amount)}
					type="submit"
					className="button button--long button--mob-full-width"
				>
					Deposit
				</button>
			</div>
		</form>
	);
};
