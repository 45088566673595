import classNames from 'classnames';
import { FC, MouseEvent, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { createUsdcWalletRequest, createUsdcWalletSuccess } from 'redux/reducers/wallets/reducer';
import { getQrUsdc } from 'redux/reducers/wallets/selectors';
import { notificationContainer } from 'untils/notificationContainer';

export const Crypto: FC = () => {
	const [network, setNetwork] = useState<string>('Select the Network');
	const dispatch = useDispatch();
	const qrUsdc = useSelector(getQrUsdc);
	const [dropDawn, setDropDawn] = useState(false);

	const openDropDawn = () => {
		setDropDawn(!dropDawn);
	};

	const selectNetwork = (e: MouseEvent) => {
		const target = e.target as HTMLSelectElement;
		setNetwork(target.name);
		openDropDawn();
	};

	const submitDepositUsdc = () => {
		dispatch(
			createUsdcWalletRequest({
				payment_system: 'circle',
				type: 1,
			}),
		);
	};

	const handleAddressIdCopy = () => {
		notificationContainer('Address id copied successfully!', 'info');
	};

	useEffect(() => {
		return () => {
			dispatch(createUsdcWalletSuccess(null));
		};
	}, [dispatch]);

	return (
		<div className="operation-form">
			<div className="form-group">
				<div className="select-block">
					<p className="input-name ">Network of deposit:</p>

					<div className="select select--bigger select--networks">
						<button
							onClick={openDropDawn}
							type="button"
							className={classNames('select__current select__current--unselect', {
								'select__current--activ': network !== 'Select the Network',
							})}
						>
							{network}
							<span className="select__current-arrow">
								<svg
									width="14"
									height="8"
									viewBox="0 0 14 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.307112 0.712987C0.423198 0.432732 0.696675 0.25 1.00002 0.25H13C13.3034 0.25 13.5768 0.432732 13.6929 0.712987C13.809 0.993243 13.7449 1.31583 13.5304 1.53033L7.53035 7.53033C7.23746 7.82322 6.76259 7.82322 6.46969 7.53033L0.469692 1.53033C0.255193 1.31583 0.191027 0.993243 0.307112 0.712987ZM2.81068 1.75L7.00002 5.93934L11.1894 1.75H2.81068Z"
										fill="#25252E"
									/>
								</svg>
							</span>
						</button>
						<div
							className={classNames('select__drop', {
								'select__drop--active': dropDawn,
							})}
						>
							<div className="select__drop-scroll">
								<div className="select__drop-item">
									<ul>
										<li>
											<button name="Algorand" onClick={selectNetwork} value={network} type="button">
												Algorand <span className="network-name"> </span>
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{qrUsdc && (
				<div className="form-group form-group--more-mt">
					<p className="input-name input-name--big ">Address</p>
					<div className="wallet-address">
						<p className="wallet-address__value ">{qrUsdc.address}</p>
						<CopyToClipboard text={qrUsdc.address || ''} onCopy={handleAddressIdCopy}>
							<button type="button" className="link link--bigger link--fw-600">
								Copy
							</button>
						</CopyToClipboard>
					</div>
					<div className="deposit-address">
						<div className="deposit-address__qr">
							<img src={qrUsdc.QR_code} alt="" />
						</div>
					</div>
				</div>
			)}

			{!qrUsdc && (
				<div className="form-footer form-footer--end form-footer--more-mt">
					<button
						type="button"
						onClick={submitDepositUsdc}
						className="button button--long button--mob-full-width"
						disabled={network !== 'Algorand'}
					>
						Deposit
					</button>
				</div>
			)}
		</div>
	);
};
