import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IInterestRequestsState, IInterestResponse } from './types';

const getInterestRequestsState = (state: IStoreState): IInterestRequestsState =>
	state.interestRequests;

export const getUserInterestListSelector = createSelector(
	[getInterestRequestsState],
	(interestRequests: IInterestRequestsState): IInterestResponse | null =>
		interestRequests.userInterestList,
);

export const getLoaderRequestSelector = createSelector(
	[getInterestRequestsState],
	(interestRequests: IInterestRequestsState): boolean => interestRequests.loader,
);
