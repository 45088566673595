import { FC } from 'react';

export const InvestHeaderTable: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p className="td-name">#</p>
				</div>
				<div className="td">
					<p className="td-name">Full Name</p>
				</div>
				<div className="td">
					<p className="td-name">Investment</p>
				</div>
				<div className="td td--right">
					<p className="td-name">Tokens amount</p>
				</div>
			</div>
		</div>
	);
};
