import { PropertyForm } from 'components/Forms/PropertyForm';
import { Sidebar } from 'components/Sidebar';
import { Uploading } from 'components/Uploading';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
	editPropertyRequest,
	getPropertyRequest,
	publishPropertyRequest,
} from 'redux/reducers/propertyManagement/reducer';
import { getProperty } from 'redux/reducers/propertyManagement/selectors';
import { IPropertyRequest } from 'redux/reducers/propertyManagement/types';
import { publishBtn } from 'services/constants/name-button';

const EditProperty: FC = () => {
	const param: { id: string } = useParams();
	const dispatch = useDispatch();
	const property = useSelector(getProperty);
	const history = useHistory();
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [count, setCount] = useState<number>(0);
	const [amount, setAmount] = useState<number>(0);

	useEffect(() => {
		dispatch(getPropertyRequest(param.id));
	}, [dispatch, param]);

	const handleIPropertySubmit = (
		values: IPropertyRequest,
		images: Array<{ [key: string]: string | number }>,
		submitAction: string | undefined,
	) => {
		setIsUploading(true);
		setAmount(images.filter((img) => !img.id).length);
		// publishBtn.save === submitAction &&
		// 	dispatch(
		// 		editPropertyRequest({
		// 			data: { estate: values },
		// 			history,
		// 			images,
		// 			count: setCount,
		// 			setIsUploading,
		// 		}),
		// 	);

		publishBtn.publish === submitAction &&
			dispatch(
				publishPropertyRequest({
					data: { estate: values },
					history,
					images,
					count: setCount,
					setIsUploading,
				}),
			);
	};

	const handleSave = (
		values: IPropertyRequest,
		images: Array<{ [key: string]: string | number }>,
	) => {
		setIsUploading(true);
		setAmount(images.filter((img) => !img.id).length);
		// anton
		// const valuesWithImages = { ...values, images };
		dispatch(
			editPropertyRequest({
				data: { estate: values },
				history,
				images,
				count: setCount,
				setIsUploading,
			}),
		);
	};

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				{isUploading && <Uploading amount={amount} count={count} />}

				<PropertyForm
					propertySubmit={handleIPropertySubmit}
					property={property}
					handleSave={handleSave}
					unpublish="true"
				/>
			</div>
		</div>
	);
};

export default EditProperty;
