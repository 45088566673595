/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	IAddPictures,
	IInvestorsRequest,
	IPropertyRequest,
	IPublishRequest,
	IUnpublishParams,
} from 'redux/reducers/propertyManagement/types';
import { IPropertyManagementApi } from './types';

export const propertyManagement: IPropertyManagementApi = {
	getListProperty: (params) =>
		http.get(endpoint.propertyManagement.GET_PROPERTY_MANAGEMENT_LIST, { params }),
	getProperty: (param: string) =>
		http.get(endpoint.propertyManagement.GET_PROPERTY_MANAGEMENT(param)),
	getTokenPrice: () => http.get(endpoint.propertyManagement.GET_TOKEN_PRICE),
	editProperty: (params: { estate: IPropertyRequest }) =>
		http.put(endpoint.propertyManagement.PUT_EDIT_PROPERTY, {
			estate: params.estate,
		}),
	publishProperty: (payload: IPublishRequest) =>
		http.post(endpoint.propertyManagement.PUBLISH_PROPERTY, {
			estate: payload.data.estate,
		}),
	createProperty: () => http.post(endpoint.propertyManagement.POST_CREATE_PROPERTY),
	deleteProperty: (params: { id: number }) =>
		http.delete(endpoint.propertyManagement.DELETE_PROPERTY(params.id)),
	editProfit: (payload) => http.post(endpoint.propertyManagement.POST_PROFIT_PRICE, payload),
	addPictures: (payload: IAddPictures) =>
		http.post(endpoint.propertyManagement.ADD_PICTURES, payload),
	getInvestors: (params: IInvestorsRequest) =>
		http.get(endpoint.propertyManagement.GET_INVESTORS, { params }),
	getCounries: () => http.get(endpoint.propertyManagement.GET_COUNTRIES),
	postUnpublish: (payload: IUnpublishParams) =>
		http.post(endpoint.propertyManagement.POST_UNPUBLISH, payload),
	getStates: () => http.get(endpoint.propertyManagement.STATES_LIST),
};
