import classNames from 'classnames';
import { IPropsSubDropFilter } from 'components/PropertyManagementComponents/types';
import { FC } from 'react';
import { byStatusPrice } from 'services/constants/status';

export const SubDropFilter: FC<IPropsSubDropFilter> = ({
	sortPrice,
	isClose,
	openDrop,
	sortProperty,
}) => {
	return openDrop ? (
		<div
			className={classNames('select__drop', {
				'select__drop--active': openDrop,
			})}
		>
			<div className="select__drop-scroll">
				<div className="select__drop-item">
					<ul>
						{sortPrice !== 'Reset' && (
							<li>
								<button
									onClick={() => {
										isClose();
										sortProperty({ sort_price: 'Reset' });
									}}
									type="button"
								>
									Reset
								</button>
							</li>
						)}
						{byStatusPrice.map((element) => (
							<li key={element.name}>
								<button
									onClick={() => {
										isClose();
										sortProperty({ sort_price: element.sort });
									}}
									type="button"
								>
									{element.name}
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	) : null;
};
