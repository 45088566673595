import { FC } from 'react';

export const SvgCopy: FC = () => {
	return (
		<svg
			className="stroke"
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 7C17.6569 7 19 5.65685 19 4C19 2.34315 17.6569 1 16 1C14.3431 1 13 2.34315 13 4C13 5.65685 14.3431 7 16 7Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 14C5.65685 14 7 12.6569 7 11C7 9.34315 5.65685 8 4 8C2.34315 8 1 9.34315 1 11C1 12.6569 2.34315 14 4 14Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 21C17.6569 21 19 19.6569 19 18C19 16.3431 17.6569 15 16 15C14.3431 15 13 16.3431 13 18C13 19.6569 14.3431 21 16 21Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.58984 12.51L13.4198 16.49"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.41 5.51L6.59003 9.49"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgFavorite: FC = () => {
	return (
		<svg
			className="stroke"
			width="20"
			height="17"
			viewBox="0 0 20 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5167 15.7583C10.2334 15.8583 9.76675 15.8583 9.48341 15.7583C7.06675 14.9333 1.66675 11.4917 1.66675 5.65833C1.66675 3.08333 3.74175 1 6.30008 1C7.81675 1 9.15841 1.73333 10.0001 2.86667C10.8417 1.73333 12.1917 1 13.7001 1C16.2584 1 18.3334 3.08333 18.3334 5.65833C18.3334 11.4917 12.9334 14.9333 10.5167 15.7583Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgShowAll: FC = () => {
	return (
		<svg
			className="fill"
			width="23"
			height="21"
			viewBox="0 0 23 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.82918 1.16459C7.95622 0.910502 8.21592 0.75 8.5 0.75H14.5C14.7841 0.75 15.0438 0.910502 15.1708 1.16459L16.6708 4.16459C16.7871 4.39708 16.7746 4.67319 16.638 4.8943C16.5013 5.11541 16.2599 5.25 16 5.25H7C6.74007 5.25 6.49867 5.11541 6.36201 4.8943C6.22536 4.67319 6.21293 4.39708 6.32918 4.16459L7.82918 1.16459ZM8.96353 2.25L8.21353 3.75H14.7865L14.0365 2.25H8.96353Z"
				fill="#25252E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 5.25C2.58579 5.25 2.25 5.58579 2.25 6V18C2.25 18.4142 2.58579 18.75 3 18.75H20C20.4142 18.75 20.75 18.4142 20.75 18V6C20.75 5.58579 20.4142 5.25 20 5.25H3ZM0.75 6C0.75 4.75736 1.75736 3.75 3 3.75H20C21.2426 3.75 22.25 4.75736 22.25 6V18C22.25 19.2426 21.2426 20.25 20 20.25H3C1.75736 20.25 0.75 19.2426 0.75 18V6Z"
				fill="#25252E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.75 12C6.75 9.37664 8.87664 7.25 11.5 7.25C14.1234 7.25 16.25 9.37664 16.25 12C16.25 14.6234 14.1234 16.75 11.5 16.75C8.87664 16.75 6.75 14.6234 6.75 12ZM11.5 8.75C9.70506 8.75 8.25 10.2051 8.25 12C8.25 13.7949 9.70506 15.25 11.5 15.25C13.2949 15.25 14.75 13.7949 14.75 12C14.75 10.2051 13.2949 8.75 11.5 8.75Z"
				fill="#25252E"
			/>
		</svg>
	);
};

export const SvgSwiperButtonPrev: FC = () => {
	return (
		<svg
			className="fill"
			width="9"
			height="14"
			viewBox="0 0 9 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L8.03033 6.46967C8.32322 6.76256 8.32322 7.23744 8.03033 7.53033L2.03033 13.5303C1.73744 13.8232 1.26256 13.8232 0.96967 13.5303C0.676777 13.2374 0.676777 12.7626 0.96967 12.4697L6.43934 7L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"
				fill="#25252E"
			/>
		</svg>
	);
};

export const SvgSwiperButtonNext: FC = () => {
	return (
		<svg
			className="fill"
			width="9"
			height="14"
			viewBox="0 0 9 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L8.03033 6.46967C8.32322 6.76256 8.32322 7.23744 8.03033 7.53033L2.03033 13.5303C1.73744 13.8232 1.26256 13.8232 0.96967 13.5303C0.676777 13.2374 0.676777 12.7626 0.96967 12.4697L6.43934 7L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"
				fill="#25252E"
			/>
		</svg>
	);
};

export const SvgIconTotalPrice: FC = () => {
	return (
		<svg
			className="stroke"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 11.4C8 12.17 8.6 12.8 9.33 12.8H10.83C11.47 12.8 11.99 12.25 11.99 11.58C11.99 10.85 11.67 10.59 11.2 10.42L8.8 9.58001C8.32 9.41001 8 9.15001 8 8.42001C8 7.75001 8.52 7.20001 9.16 7.20001H10.66C11.4 7.21001 12 7.83001 12 8.60001"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 12.85V13.59"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 6.40997V7.18997"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.99 17.98C14.4028 17.98 17.98 14.4028 17.98 9.99C17.98 5.57724 14.4028 2 9.99 2C5.57724 2 2 5.57724 2 9.99C2 14.4028 5.57724 17.98 9.99 17.98Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.98 19.88C13.88 21.15 15.35 21.98 17.03 21.98C19.76 21.98 21.98 19.76 21.98 17.03C21.98 15.37 21.16 13.9 19.91 13"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgAmountOfTokens: FC = () => {
	return (
		<svg
			className="stroke"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 8H21"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgTokenPrice: FC = () => {
	return (
		<svg
			className="stroke"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 11.4C8 12.17 8.6 12.8 9.33 12.8H10.83C11.47 12.8 11.99 12.25 11.99 11.58C11.99 10.85 11.67 10.59 11.2 10.42L8.8 9.58001C8.32 9.41001 8 9.15001 8 8.42001C8 7.75001 8.52 7.20001 9.16 7.20001H10.66C11.4 7.21001 12 7.83001 12 8.60001"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 12.85V13.59"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 6.40997V7.18997"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.99 17.98C14.4028 17.98 17.98 14.4028 17.98 9.99C17.98 5.57724 14.4028 2 9.99 2C5.57724 2 2 5.57724 2 9.99C2 14.4028 5.57724 17.98 9.99 17.98Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.98 19.88C13.88 21.15 15.35 21.98 17.03 21.98C19.76 21.98 21.98 19.76 21.98 17.03C21.98 15.37 21.16 13.9 19.91 13"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgRealEstate: FC = () => {
	return (
		<svg
			className="stroke"
			width="32"
			height="28"
			viewBox="0 0 32 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.33334 26.3333H30.6667"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.3734 26.3467V20.4"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.4 11.52C24.7733 11.52 23.4666 12.8267 23.4666 14.4534V17.48C23.4666 19.1066 24.7733 20.4133 26.4 20.4133C28.0266 20.4133 29.3333 19.1066 29.3333 17.48V14.4534C29.3333 12.8267 28.0266 11.52 26.4 11.52Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.79996 26.3334V5.04006C2.79996 2.36006 4.13336 1.01337 6.78669 1.01337H15.0933C17.7467 1.01337 19.0666 2.36006 19.0666 5.04006V26.3334"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.73331 8H14.3333"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.73331 13H14.3333"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11 26.3333V21.3333"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgAddress: FC = () => {
	return (
		<svg
			className="stroke"
			width="26"
			height="30"
			viewBox="0 0 26 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 16.9067C15.2975 16.9067 17.16 15.0442 17.16 12.7467C17.16 10.4492 15.2975 8.58667 13 8.58667C10.7025 8.58667 8.84 10.4492 8.84 12.7467C8.84 15.0442 10.7025 16.9067 13 16.9067Z"
				stroke="#25252E"
				strokeWidth="1.5"
			/>
			<path
				d="M1.82667 10.32C4.45333 -1.22664 21.56 -1.21331 24.1733 10.3334C25.7067 17.1067 21.4933 22.84 17.8 26.3867C15.12 28.9734 10.88 28.9734 8.18667 26.3867C4.50667 22.84 0.293332 17.0934 1.82667 10.32Z"
				stroke="#25252E"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export const SvgBedrooms: FC = () => {
	return (
		<svg
			className="stroke"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 26V18C3 16.6667 3.975 14 7.875 14C11.775 14 20.3333 14 24.125 14C25.75 14 29 14.8 29 18C29 21.2 29 24.6667 29 26"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path d="M3 21H29" stroke="#25252E" strokeWidth="1.5" strokeLinecap="round" />
			<path
				d="M5 14.5L5 7.60002C5 7.06669 5.31429 6.00002 6.57143 6.00002C7.82857 6.00002 23.3333 6 25.4286 6C25.9524 6 27 6.32 27 7.6C27 8.88 27 12.9 27 14.5"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M9 14V10.2C9 9.8 9.2 9 10 9C10.8 9 13.6667 9 15 9C15.3333 9 16 9.24 16 10.2C16 11.16 16 12.8 16 14"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M16 14V10.2C16 9.8 16.2 9 17 9C17.8 9 20.6667 9 22 9C22.3333 9 23 9.24 23 10.2C23 11.16 23 12.8 23 14"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export const SvgBathrooms: FC = () => {
	return (
		<svg
			className="stroke"
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 24.5397V27.5397"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23 24.5397V27.5397"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7 13.5397V7.03967C7 6.37663 7.26339 5.74075 7.73223 5.27191C8.20107 4.80306 8.83696 4.53967 9.5 4.53967C10.163 4.53967 10.7989 4.80306 11.2678 5.27191C11.7366 5.74075 12 6.37663 12 7.03967"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M25 12.5397H17V18.5397H25V12.5397Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M25 13.5397H29C29.2652 13.5397 29.5196 13.645 29.7071 13.8326C29.8946 14.0201 30 14.2745 30 14.5397V18.5397C30 20.131 29.3679 21.6571 28.2426 22.7823C27.1174 23.9075 25.5913 24.5397 24 24.5397H8C6.4087 24.5397 4.88258 23.9075 3.75736 22.7823C2.63214 21.6571 2 20.131 2 18.5397V14.5397C2 14.2745 2.10536 14.0201 2.29289 13.8326C2.48043 13.645 2.73478 13.5397 3 13.5397H17"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgSquareMeters: FC = () => {
	return (
		<svg
			className="stroke"
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.2267 3.54645L25.9067 8.22645C26.92 8.77312 26.92 10.3331 25.9067 10.8798L17.2267 15.5598C16.4533 15.9731 15.5467 15.9731 14.7733 15.5598L6.09333 10.8798C5.08 10.3331 5.08 8.77312 6.09333 8.22645L14.7733 3.54645C15.5467 3.13312 16.4533 3.13312 17.2267 3.54645Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.81333 14.0398L12.88 18.0798C13.88 18.5864 14.52 19.6131 14.52 20.7331V28.3598C14.52 29.4664 13.36 30.1731 12.3733 29.6798L4.30667 25.6398C3.30667 25.1331 2.66667 24.1064 2.66667 22.9864V15.3598C2.66667 14.2531 3.82667 13.5464 4.81333 14.0398Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M27.1867 14.0398L19.12 18.0798C18.12 18.5864 17.48 19.6131 17.48 20.7331V28.3598C17.48 29.4664 18.64 30.1731 19.6267 29.6798L27.6933 25.6398C28.6933 25.1331 29.3333 24.1064 29.3333 22.9864V15.3598C29.3333 14.2531 28.1733 13.5464 27.1867 14.0398Z"
				stroke="#25252E"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const SvgBackArrow: FC = () => (
	<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.36597 16C3.36597 15.4477 3.81368 15 4.36597 15H28.366C28.9182 15 29.366 15.4477 29.366 16C29.366 16.5523 28.9182 17 28.366 17H4.36597C3.81368 17 3.36597 16.5523 3.36597 16Z"
			fill="#E41472"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.0729 7.29289C13.4635 7.68342 13.4635 8.31658 13.0729 8.70711L5.78006 16L13.0729 23.2929C13.4635 23.6834 13.4635 24.3166 13.0729 24.7071C12.6824 25.0976 12.0492 25.0976 11.6587 24.7071L3.65874 16.7071C3.26821 16.3166 3.26821 15.6834 3.65874 15.2929L11.6587 7.29289C12.0492 6.90237 12.6824 6.90237 13.0729 7.29289Z"
			fill="#E41472"
		/>
	</svg>
);
