import { useRef, useEffect } from 'react';

const useMountEffect = (fun: () => void) => useEffect(fun, [fun]);

const useOnOutsideClick = (handleOutsideClick: () => void) => {
	const innerBorderRef = useRef<HTMLDivElement | null>(null);

	const onClick = (event: Event) => {
		const target = event.target as HTMLDivElement;
		if (innerBorderRef.current && !innerBorderRef.current.contains(target)) {
			handleOutsideClick();
		}
	};

	useMountEffect(() => {
		document.addEventListener('click', onClick, true);
		return () => {
			document.removeEventListener('click', onClick, true);
		};
	});

	return { innerBorderRef };
};

export default useOnOutsideClick;
