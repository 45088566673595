import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SvgShowPass } from 'ui/svg-image/SvgLogin';
import yup from 'untils/capsLockValidate';
import queryString from 'query-string';
import ErrorMsg from 'components/ErrorMsg';
import { ICreateNewPassSubmitValue, ICreateNewPasswordPayload, IPropsNewPass } from './type';
import logo from '../../../assets/images/logo.svg';

export const CreateNewPassForm: FC<IPropsNewPass> = ({ createNewPassSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const queryPar = queryString.parse(location.search);

	const initialValues = {
		password: '',
		confirmPassword: '',
	};

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(String('Please, enter your password!'))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(
					'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
				),
			)
			.max(25, String('Max characters must be not more than 25!')),
		confirmPassword: yup
			.string()
			.required(String('Please, enter your confirm password!'))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(
					'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
				),
			)
			.oneOf([yup.ref('password'), null], String('Passwords must match!'))
			.max(25, String('Max characters must be not more than 25!')),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ICreateNewPassSubmitValue, { resetForm, setSubmitting }) => {
				const dataValues: ICreateNewPasswordPayload = {
					data: {
						password: values.password,
						new_password: values.password,
						token: queryPar.token,
					},
					history,
				};
				createNewPassSubmit(dataValues);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors }) => {
				return (
					<Form className="form">
						<div className="form__header">
							<div className="form-logo">
								<img src={logo} alt="logo" />
							</div>
							<p className="form-title form-title--center">Create New Password</p>
						</div>
						<div className="form__body">
							<div className="form-group">
								<div className="input">
									<div className="input-wrapper">
										<Field
											className="input-item input-item--right-icon"
											placeholder="New password"
											type={isShowPass ? 'text' : 'password'}
											name="password"
										/>
										<button onClick={handlePassDisplay} type="button" className="show-pass">
											<SvgShowPass />
										</button>
									</div>
									{errors.password ? <ErrorMsg msg={errors.password} /> : null}
								</div>
								<div className="input">
									<div className="input-wrapper">
										<Field
											className="input-item input-item--right-icon"
											placeholder="Confirm password"
											type={isShowConfirmPass ? 'text' : 'password'}
											name="confirmPassword"
										/>
										<button onClick={handleConfirmPassDisplay} type="button" className="show-pass">
											<SvgShowPass />
										</button>
									</div>
									{errors.confirmPassword ? <ErrorMsg msg={errors.confirmPassword} /> : null}
								</div>
							</div>
						</div>
						<div className="form-footer">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="button button--long"
							>
								Submit
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
