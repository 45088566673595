import { DevidendsHistory } from 'components/DividendsManagementComponents/DevidendsHistory';
import { DividendsFilter } from 'components/DividendsManagementComponents/DividendsFilter';
import { DividendsTableBody } from 'components/DividendsManagementComponents/DividendsTableBody';
import { DividendsTableHeader } from 'components/DividendsManagementComponents/DividendsTableHeader';
import { DividendsTabs } from 'components/DividendsManagementComponents/DividendsTabs';
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { Sidebar } from 'components/Sidebar';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
	getDividendHistoryRequest,
	getDividendsRequest,
} from 'redux/reducers/dividends-management/reducer';
import { getDevidends, getDevidendsLoader } from 'redux/reducers/dividends-management/selectors';
import {
	IDividendsHistory,
	IDividendsHistoryRequest,
	IDividendsRequest,
} from 'redux/reducers/dividends-management/types';
import { ISellPropertisRequest } from 'redux/reducers/sellManagement/types';
import { ETabsDividends } from 'services/constants/list-links-sidebar';

const DividendsManagement: FC = () => {
	const dispatch = useDispatch();
	const { location } = useHistory();
	const params: { tab: string } = useParams();
	const dividends = useSelector(getDevidends);
	const loader = useSelector(getDevidendsLoader);
	const [search, setSearch] = useState<string>('');
	const [status, setStatus] = useState<string>('');
	const [dividendsHistory, setDividendsHistory] = useState<IDividendsHistory | null>(null);
	const pagination = params.tab === ETabsDividends.HISTORY ? dividendsHistory : dividends;
	const [calendarData, setCalendarData] = useState<ISellPropertisRequest>();

	const submitFilter = (value?: number, calendar?: ISellPropertisRequest) => {
		if (params.tab === ETabsDividends.MANAGEMENT || !params.tab) {
			const data: IDividendsRequest = {
				current_page: value || +location.search.slice(1),
			};
			if (search) data.search_value = search;
			if (status) data.rent_status = +status;
			dispatch(getDividendsRequest(data));
		}

		if (params.tab === ETabsDividends.HISTORY) {
			calendar && setCalendarData(calendar);
			calendar?.date_from === null && setCalendarData(undefined);
			const data: IDividendsHistoryRequest = {
				current_page: value,
				...(calendar || calendarData),
			};

			dispatch(getDividendHistoryRequest(data));
		}
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => submitFilter(+location.search.slice(1) || 1), 500);
		return () => clearTimeout(timeOutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, search, params.tab]);

	const searchInput = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement;
		setSearch(target.value);
	};

	const chooseStatus = (value: string) => {
		setStatus(value);
	};

	return (
		<div className="admin">
			<Sidebar />
			<div className="admin__content">
				<DividendsTabs />

				{params.tab === ETabsDividends.HISTORY ? (
					<DevidendsHistory submitFilter={submitFilter} setDividendsHistory={setDividendsHistory} />
				) : (
					<>
						<DividendsFilter
							search={search}
							searchInput={searchInput}
							chooseStatus={chooseStatus}
						/>
						<div className="table table--fee-dividends table--second-type">
							<DividendsTableHeader />
							{loader ? (
								<Loader />
							) : (
								dividends?.data && <DividendsTableBody dividends={dividends?.data} />
							)}
						</div>
					</>
				)}
				{pagination?.last_page && pagination?.last_page > 1 && (
					<Pagination
						request={submitFilter}
						currentPage={pagination.current_page}
						numberPage={pagination.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default DividendsManagement;
