import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from 'redux/reducers/auth/reducer';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import { LoginForm } from 'components/Forms/LoginForm';
import { open2FaPageSuccess } from 'redux/reducers/settings/reducer';
import { getSms2Fa } from 'redux/reducers/settings/selectors';
import logo from '../../../assets/images/logo.svg';

// ==========================================:
const Login: FC = () => {
	const dispatch = useDispatch();
	const sms2Fa = useSelector(getSms2Fa);

	const handleLoginSubmit = (value: ILoginSubmitValue) => {
		const data =
			sms2Fa === 'sms_2fa_enabled'
				? {
						email: value.email,
						password: value.password,
						sms_code: value.totp,
				  }
				: value;
		dispatch(loginRequest(data));
	};

	useEffect(() => {
		return () => {
			dispatch(open2FaPageSuccess(false));
		};
	}, [dispatch]);

	const resetTimer = (value: (num: number) => void) => {
		value(90);
	};

	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<div className="form">
						<div className="form__header">
							<div className="form-logo">
								<img src={logo} alt="logo" />
							</div>
							<p className="form-title form-title--center">Admin Login</p>
							<div className="form-subtitle">
								<p>Please input your email and password</p>
							</div>
						</div>
						<LoginForm resetTimer={resetTimer} loginSubmit={handleLoginSubmit} sms2Fa={sms2Fa} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Login;
